import React from 'react'
import { Redirect } from 'react-router-dom'
// Authentication related pages
import Login from '../pages/Authentication/Login'
import Register from '../pages/Authentication/Register'
import Logout from '../pages/Authentication/Logout'
// Dashboard
import Dashboard from '../pages/Dashboard/index'
import Sorte from '../pages/Sorte/index'
import SorteMessage from '../pages/SorteMessage/index'
import Transaction from '../pages/Transaction/index'

import Instancias from '../pages/Instancias/index'
import Tutorial from '../pages/Tutorial/index'
import Privacidade from '../pages/Privacidade/index'
import Partners from '../pages/Partners/index'

import Grupos from '../pages/Grupos/index'
import Security from '../pages/Security/index'
import Integration from '../pages/Integration/index'
import Leads from '../pages/Leads/index'
import Cashback from '../pages/Cashback/index'
import Segmentation from '../pages/Segmentation/index'

import Bot from '../pages/Bot/index'

import Copy from '../pages/Copy/index'
import { Subscriptions } from '../pages/Subscriptions/index'

import EnviarPlanilha from '../pages/EnviarPlanilha/index'
import EnviarSms from '../pages/EnviarSms/index'
import MeuChat from '../pages/MeuChat/index'
import Page from '../pages/Page/index'
import PageInterna from '../pages/Page/pageInterna'

import { InstanceDetails } from 'pages/InstanciasDetails/zApi'
import { InstanceDetailsV2 } from 'pages/InstanciasDetails/zapMais'
import { Users } from 'pages/Users'
import { AutomationCampaign } from 'pages/AutomationCampaigns'
import { RelatoriosMaisModas } from 'pages/RelatoriosMaisModas'
import { CustomPlans } from 'pages/CustomPlans'
import { Logs } from 'pages/Logs'
import { CreateSubscription } from 'pages/CreateSubscription'
import { FinancialReport } from 'pages/Financial'
import EnvioSimplificao from 'pages/EnvioSimplificado'

const userRoutes = [
  { path: '/dashboard', component: Dashboard },
  { path: '/painel', component: Sorte },
  { path: '/transaction', component: Transaction },
  { path: '/list', component: SorteMessage },
  { path: '/users', component: Users },
  { path: '/relatorio-financeiro', component: FinancialReport },
  { path: '/enviar-planilha', component: EnviarPlanilha },
  { path: '/envio-simplificado', component: EnvioSimplificao },
  { path: '/enviar-sms', component: EnviarSms }, // /painel
  { path: '/meu-chat', component: MeuChat },
  { path: '/campanhas-de-automacao', component: AutomationCampaign },
  { path: '/instance', component: Instancias },

  { path: '/cashback', component: Cashback },
  { path: '/segmentation', component: Segmentation },
  
  { path: '/bot', component: Bot },
  { path: '/mais-modas-relatorios', component: RelatoriosMaisModas },

  { path: '/copy', component: Copy },
  { path: '/assinaturas', component: Subscriptions },
  { path: '/grupos', component: Grupos },
  { path: '/security', component: Security },
  { path: '/integration', component: Integration },
  { path: '/leads', component: Leads },
  { path: '/pages', component: Page },
  { path: '/page/:idPage', component: PageInterna },
  { path: '/planos-personalizados', component: CustomPlans },
  { path: '/criar-assinatura', component: CreateSubscription },
  { path: '/logs', component: Logs },

  { path: '/tutorial', component: Tutorial },
  { path: '/partners', component: Partners },
  { path: '/politica-de-privacidade', component: Privacidade },


  {
    path: '/ZApiDetails/:instanceId/:instanceToken',
    component: InstanceDetails
  },
  { path: '/ZAapMaisDetails/:instanceId', component: InstanceDetailsV2 },
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> }
]

const authRoutes = [
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/register/:indicacao', component: Register }
]

export { userRoutes, authRoutes }
