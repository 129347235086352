/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Row, Col, Card } from 'reactstrap'
import { toast } from 'react-toastify'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
import CollapseComponent from '../../components/Table/OrderCollapse'
///MODAIS
import { CardIndicador } from '../../components/CardIndicador'

// import images

import { formatCurrency } from '../../util/string'
import './styles.scss'

import 'chartist/dist/scss/chartist.scss'
import { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import {
  Accordion,
  Button,
  Container,
  FormGroup,
  Tab,
  Tabs
} from 'react-bootstrap'
import { financialReportServices } from 'services/financialReport'
import {
  ExpensesListProps,
  ExpensesProps,
  RecurringExpensesListProps,
  SmsOrdersProps,
  SubscriptionListProps
} from 'types/global'
import MonthYearSelect from 'components/SelectMes'
import { ModalExpense } from 'components/modalExpense'
import { ModalConfirm } from 'components/modalConfirm'
import { SubscriptionCollapseTable } from 'components/Table/SubscriptionCollapse'
import { PageHeader } from 'components/pageHeader'
import { ButtonComponent } from 'components/buttonComponent'

registerLocale('pt-BR', ptBR)
interface ResumeDataProps {
  activeSubscriptions: number
  activeSubscriptionsInstances: number
  paidSubscriptions: number
  paidSubscriptionsInstances: number
  pendingSubscriptions: number
  pendingSubscriptionsInstances: number
  overdueSubscriptions: number
  overdueSubscriptionsInstances: number
  forecastedAmount: number
  paidAmount: number
  expensesAmount: number
  profitAmount: number
  paidSmsAmount: number
  paidSmsCreditAmount: number
  paidSmsQuantity: number
}

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Nome', field: 'name' },
  { label: 'Plano', field: 'plan' },
  { label: 'N° de Instancias', field: 'quantity' },
  { label: 'Valor', field: 'paymentAmountFormatted' },
  { label: 'Vencimento', field: 'dueDate' },
  { label: 'Forma de pagamento', field: 'paymentMethod' },
  { label: 'Status', field: 'status', html: true }
]

const columnsSms = [
  { label: 'Id', field: 'id' },
  { label: 'valor', field: 'formattedValue' },
  { label: 'Quantidade de créditos', field: 'formattedQuantity' },
  { label: 'Usuário', field: 'userName' },
  { label: 'Data', field: 'date' }
]

const columnsExpenses = [
  { label: 'Id', field: 'id' },
  { label: 'Descrição', field: 'description' },
  { label: 'Valor', field: 'formattedValue' },
  { label: 'Data da cobrança', field: 'formattedDate' }
]

const columnsRecurringExpenses = [
  { label: 'Id', field: 'id' },
  { label: 'Descrição', field: 'description' },
  { label: 'Valor', field: 'formattedValue' },
  { label: 'Dia da cobrança', field: 'formattedDate' }
]

const today = new Date()
const defaultCompetence = today.toISOString().substring(0, 7)

export const FinancialReport = () => {
  const {
    getResume,
    listSubscriptions,
    listSmsOrders,
    listExpenses,
    listRecurringExpenses,
    deleteExpense
  } = financialReportServices
  const [rendered, setRendered] = useState(false)
  const [showModalExpense, setShowModalExpense] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [currentExpense, setCurrentExpense] = useState<ExpensesProps>(null)
  const [expenseRecurring, setExpenseRecurring] = useState<boolean>(false)
  const [rows, setRows] = useState<SubscriptionListProps[]>([])
  const [rowsSmsOrders, setRowsSmsOrders] = useState<SmsOrdersProps[]>([])
  const [rowsExpenses, setRowsExpenses] = useState<ExpensesListProps[]>([])
  const [rowsRecurringExpenses, setRowsRecurringExpenses] = useState<
    RecurringExpensesListProps[]
  >([])
  const [resumeData, setResumeData] = useState<null | ResumeDataProps>(null)

  const [loading, setLoading] = useState(false)

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      more: false
    },
    config: {
      deleteText: 'Excluir despesa',
      editText: 'Editar despesa'
    },
    filters: {
      competence: defaultCompetence
    }
  })

  const [activeKey, setActiveKey] = useState(['0', '1', '2']) // Define o estado inicial como ["0", "1", "2"] para deixar todos abertos

  const handleSelect = (eventKey: string) => {
    if (activeKey.includes(eventKey)) {
      setActiveKey(activeKey.filter((item) => item !== eventKey))
    } else {
      setActiveKey([...activeKey, eventKey])
    }
  }

  const loadSubscriptionData = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await listSubscriptions(tableConfig?.filters?.competence)

      if (response.status === 200) {
        const items = response?.data?.data

        //////////////////////////////////////////

        const formattedData = items.map((item) => {
          const dateObj = new Date(item.dueDate)
          const formattedDate = new Intl.DateTimeFormat('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }).format(dateObj)
          return {
            ...item,
            dueDate: formattedDate,
            paymentAmountFormatted: formatCurrency({
              value: item.paymentAmount
            }),
            status:
              item.status === 'PAGO'
                ? `<span class="badge bg-success">Pago</span>`
                : item.status === 'ATRASADO'
                ? `<span class="badge bg-danger">Atrasado</span>`
                : `<span class="badge bg-secondary">Pendente</span>`
          }
        })
        console.log({ formattedData })

        setRows(formattedData)
        setLoading(false)
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Erro ao listar os dados de assinaturas'
        )
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [tableConfig.filters])

  const loadSmsData = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await listSmsOrders(tableConfig?.filters?.competence)

      if (response.status === 200) {
        const items = response?.data?.data

        //////////////////////////////////////////

        const formattedData = items.map((item) => {
          const dateObj = new Date(item.date)
          const formattedDate = new Intl.DateTimeFormat('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'America/Sao_Paulo'
          }).format(dateObj)
          return {
            ...item,
            formattedValue: formatCurrency({ value: item.value }),
            formattedQuantity: formatCurrency({
              value: item.quantity,
              style: 'decimal',
              decimal: 0
            }),
            date: formattedDate
          }
        })
        console.log({ formattedData })

        setRowsSmsOrders(formattedData)
        setLoading(false)
      } else {
        toast.error(
          response?.response?.data?.msg || 'Erro ao listar os dados de sms'
        )
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [tableConfig.filters])

  const loadExpensesData = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await listExpenses(tableConfig?.filters?.competence)

      if (response.status === 200) {
        const items = response?.data?.data
        console.log(items)

        //////////////////////////////////////////

        const formattedData = items.map((item) => {
          const dateObj = new Date(item.date)
          const formattedDate = new Intl.DateTimeFormat('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          }).format(dateObj)
          return {
            ...item,
            formattedDate: formattedDate,
            formattedValue: formatCurrency({ value: item.value })
          }
        })
        console.log({ formattedData })

        setRowsExpenses(formattedData)
        setLoading(false)
      } else {
        toast.error(
          response?.response?.data?.msg || 'Erro ao listar os dados de sms'
        )
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [tableConfig.filters])

  const loadRecurringExpensesData = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await listRecurringExpenses()

      if (response.status === 200) {
        const items = response?.data?.data

        //////////////////////////////////////////

        const formattedData = items.map((item) => {
          const dateObj = new Date(item.date)
          const formattedDate = new Intl.DateTimeFormat('pt-BR', {
            day: '2-digit'
          }).format(dateObj)
          return {
            ...item,
            formattedDate: formattedDate,
            formattedValue: formatCurrency({ value: item.value }),
            status: `<span class="badge bg-${
              item.active === 0 ? 'secondary' : 'success'
            }">${item.status_name}</span>`
          }
        })
        console.log({ formattedData })

        setRowsRecurringExpenses(formattedData)
        setLoading(false)
      } else {
        toast.error(
          response?.response?.data?.msg || 'Erro ao listar os dados de sms'
        )
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [])

  const loadResume = useCallback(async () => {
    try {
      const response = await getResume(tableConfig.filters.competence)
      if (response.status === 200 && response.data) {
        console.log({ data: response.data })
        setResumeData(response.data.data)
      } else {
        toast.error(
          response?.response?.data?.msg || 'Não foi possível buscar os dados'
        )
      }
    } catch (error) {
      console.log({ error })
    }
  }, [tableConfig.filters])

  function onChangeCompetence(date) {
    console.log({ date })
    setTableConfig({
      ...tableConfig,
      filters: {
        competence: date
      }
    })
  }

  function onCloseModal() {
    setCurrentExpense(null)
    setShowModalExpense(false)
    setExpenseRecurring(false)
  }

  function callbackSuccessModalExpense() {
    loadExpensesData()
    loadRecurringExpensesData()
    loadResume()
  }

  function handleEdit(row: ExpensesProps) {
    console.log({ row })
    setCurrentExpense(row)
    setExpenseRecurring(false)
    setShowModalExpense(true)
  }

  function handleDelete(row: ExpensesProps) {
    console.log({ row })
    setShowModalConfirm(true)
    setExpenseRecurring(false)
    setCurrentExpense(row)
  }

  function handleEditRecurrent(row: ExpensesProps) {
    console.log({ row })
    setCurrentExpense(row)
    setShowModalExpense(true)
    setExpenseRecurring(true)
  }

  function handleCancelDelete() {
    setShowModalConfirm(false)
    setCurrentExpense(null)
    setExpenseRecurring(false)
  }

  async function handleConfirmDelete() {
    try {
      setLoading(true)
      const response = await deleteExpense(currentExpense.id)
      setLoading(false)
      console.log({ response })
      if (response.status === 200) {
        setShowModalConfirm(false)
        setCurrentExpense(null)
        setExpenseRecurring(false)
        toast.success(response?.data?.msg || 'Despesa excluída')
      } else {
        toast.error(
          response?.response?.data?.msg || 'Não foi possível excluir a despesa'
        )
      }
    } catch (error) {
      setLoading(false)
      setShowModalConfirm(false)
    }
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
      return
    }
    loadSubscriptionData()
    loadResume()
    loadSmsData()
    loadExpensesData()
  }, [rendered, tableConfig.filters.competence])

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
      return
    }
    loadRecurringExpensesData()
  }, [rendered])

  return (
    <div className="container-financial">
      {loading && <Loading open={loading} />}

      <div className="page-content">
        <MetaTags>
          <title>Financeiro | Zap+</title>
        </MetaTags>
        <Container fluid>
          <PageHeader
            title="Financeiro | Zap+"
            subtitle="Bem vindo a gestão Financeira"
          />
          <Row>
            <Card>
              <div className="page-content pb-10">
                <div className="container-fluid">
                  <Row>
                    <Col xs={12} sm={6}>
                      <FormGroup className="formSelect">
                        <MonthYearSelect
                          onChange={onChangeCompetence}
                          value={tableConfig.filters.competence}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} sm={6} className="justify-content-end d-flex">
                      <ButtonComponent
                        text="+ Adicionar despesa"
                        extraClass="h-fit"
                        onClick={() => setShowModalExpense(true)}
                      />
                    </Col>
                  </Row>
                  <Col xl={12} md={12} style={{ paddingInline: 10 }}>
                    <Accordion activeKey={activeKey} onSelect={handleSelect}>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Assinaturas</Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <CardIndicador
                              xl={3}
                              md={3}
                              xs={12}
                              color="primary"
                              icon={'mdi mdi-repeat'}
                              icon2={'mdi mdi-cellphone-nfc'}
                              value={resumeData?.activeSubscriptions || 0}
                              value2={
                                resumeData?.activeSubscriptionsInstances || 0
                              }
                              type={'number'}
                              text={'Assinaturas ativas'}
                              text2={'Total de instâncias'}
                              style="2-info"
                            />
                            <CardIndicador
                              color="success"
                              xl={3}
                              md={3}
                              xs={12}
                              icon={'mdi mdi-repeat'}
                              value={resumeData?.paidSubscriptions || 0}
                              value2={
                                resumeData?.paidSubscriptionsInstances || 0
                              }
                              type={'number'}
                              text={'Assinaturas pagas'}
                              text2={'Total de instâncias'}
                              icon2={'mdi mdi-cellphone-nfc'}
                              style="2-info"
                            />

                            <CardIndicador
                              color="info"
                              xl={3}
                              md={3}
                              xs={12}
                              icon={'mdi mdi-repeat'}
                              value={resumeData?.pendingSubscriptions || 0}
                              value2={
                                resumeData?.pendingSubscriptionsInstances || 0
                              }
                              type={'number'}
                              text={'Assinaturas pendentes'}
                              text2={'Total de instâncias'}
                              icon2={'mdi mdi-cellphone-nfc'}
                              style="2-info"
                            />

                            <CardIndicador
                              color="warning"
                              xl={3}
                              md={3}
                              xs={12}
                              icon={'mdi mdi-repeat'}
                              value={resumeData?.overdueSubscriptions || 0}
                              value2={
                                resumeData?.overdueSubscriptionsInstances || 0
                              }
                              type={'number'}
                              text={'Assinaturas atrasadas'}
                              text2={'Total de instâncias'}
                              icon2={'mdi mdi-cellphone-nfc'}
                              style="2-info"
                            />
                          </Row>
                          <Row>
                            <CardIndicador
                              xl={3}
                              md={3}
                              xs={12}
                              color="primary"
                              icon={'mdi mdi-check'}
                              value={resumeData?.forecastedAmount || 0}
                              type={'money'}
                              text={'Valor previsto'}
                            />
                            <CardIndicador
                              color="success"
                              xl={3}
                              md={3}
                              xs={12}
                              icon={'mdi mdi-check-all'}
                              value={resumeData?.paidAmount || 0}
                              type={'money'}
                              text={'Valor recebido'}
                            />
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Sms</Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <CardIndicador
                              color="success"
                              xl={4}
                              md={4}
                              xs={12}
                              icon={'mdi mdi-cash'}
                              value={resumeData?.paidSmsAmount || 0}
                              type={'money'}
                              text={'Valor recebido'}
                            />

                            <CardIndicador
                              color="info"
                              xl={4}
                              md={4}
                              xs={12}
                              icon={'mdi mdi-cart-plus'}
                              value={resumeData?.paidSmsCreditAmount || 0}
                              type={'number-formatted'}
                              text={'Quantidade de créditos'}
                            />

                            <CardIndicador
                              color="primary"
                              xl={4}
                              md={4}
                              xs={12}
                              icon={'mdi mdi-credit-card'}
                              value={resumeData?.paidSmsQuantity || 0}
                              type={'number'}
                              text={'Quantidade de compras'}
                            />
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Geral</Accordion.Header>
                        <Accordion.Body>
                          <Row>
                            <CardIndicador
                              color="success"
                              xl={4}
                              md={4}
                              xs={12}
                              icon={'mdi mdi-check-all'}
                              value={
                                (resumeData?.paidAmount || 0) +
                                (resumeData?.paidSmsAmount || 0)
                              }
                              type={'money'}
                              text={'Valor recebido'}
                            />

                            <CardIndicador
                              color="warning"
                              xl={4}
                              md={4}
                              xs={12}
                              icon={'mdi mdi-briefcase-download'}
                              value={resumeData?.expensesAmount || 0}
                              type={'money'}
                              text={'Despesas'}
                            />

                            <CardIndicador
                              color="success"
                              xl={4}
                              md={4}
                              xs={12}
                              icon={'mdi mdi-cash'}
                              value={resumeData?.profitAmount || 0}
                              type={'money'}
                              text={'Lucro'}
                            />
                          </Row>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </div>
              </div>
              <div className="page-content">
                <div className="container-fluid">
                  <Col xl={12} md={12} style={{ paddingInline: 10 }}>
                    <Tabs
                      defaultActiveKey="subscriptions"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="subscriptions" title="Assinaturas">
                        <DatatableTables
                          title={'Assinaturas'}
                          columns={columns}
                          rows={rows}
                          tableConfig={{
                            ...tableConfig,
                            options: {
                              ...tableConfig.options,
                              collapse: true
                            }
                          }}
                          setTableConfig={setTableConfig}
                          CollapseComponent={SubscriptionCollapseTable}
                          layoutCollapse={true}
                        />
                      </Tab>
                      <Tab eventKey="payments-sms" title="Créditos SMS">
                        <DatatableTables
                          title={'Compras de créditos SMS'}
                          columns={columnsSms}
                          rows={rowsSmsOrders}
                          tableConfig={tableConfig}
                          setTableConfig={setTableConfig}
                          CollapseComponent={CollapseComponent}
                          layoutCollapse={true}
                        />
                      </Tab>
                      <Tab eventKey="expenses" title="Despesas">
                        <DatatableTables
                          title={'Despesas'}
                          columns={columnsExpenses}
                          rows={rowsExpenses}
                          tableConfig={{
                            ...tableConfig,
                            options: {
                              ...tableConfig.options,
                              more: true,
                              edit: true,
                              delete: true
                            }
                          }}
                          setTableConfig={setTableConfig}
                          CollapseComponent={CollapseComponent}
                          layoutCollapse={true}
                          onDelete={handleDelete}
                          onEdit={handleEdit}
                        />
                      </Tab>
                      <Tab
                        eventKey="recurring-expenses"
                        title="Despesas recorrentes"
                      >
                        <DatatableTables
                          title={'Despesas recorrentes'}
                          columns={columnsRecurringExpenses}
                          rows={rowsRecurringExpenses}
                          tableConfig={{
                            ...tableConfig,
                            options: {
                              ...tableConfig.options,
                              more: true,
                              edit: true
                            }
                          }}
                          setTableConfig={setTableConfig}
                          CollapseComponent={CollapseComponent}
                          layoutCollapse={true}
                          onEdit={handleEditRecurrent}
                        />
                      </Tab>
                    </Tabs>
                  </Col>
                </div>
              </div>
            </Card>
          </Row>
        </Container>
      </div>
      <ModalExpense
        expense={currentExpense}
        showModal={showModalExpense}
        onClose={onCloseModal}
        expenseRecurring={expenseRecurring}
        callback={callbackSuccessModalExpense}
      />

      <ModalConfirm
        showModal={showModalConfirm}
        text={'Essa despesa será removida dos cálculos de lucro da plataforma!'}
        title="Excluir despesa?"
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  )
}
