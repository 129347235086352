import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import Services from '../../services/user'
import './styles.scss'
import { Loading } from '../Loading'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { cnpjMask, onlyUnsignedNumbers, phoneMask } from 'util/string'

export const ModalChangeUserPassword = ({ user, showModal, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [showCopy, setShowCopy] = useState(false)
  const [dados, setDados] = useState({
    rootPassword: null,
    password: null,
    confirmPassword: null,
    errorMessage: {
      rootPassword: null,
      password: null,
      confirmPassword: null
    }
  })

  const handleSubmitUpdatePassword = async () => {
    try {
      console.log({ user })

      if (!user.id) {
        toast.error('Não identificamos o id do usuário para alterar a senha')
        return
      }
      if (!dados.rootPassword || !dados.password || !dados.confirmPassword) {
        toast.error('Preencha todos os campos')
        const newDados = { ...dados }

        if (!dados.rootPassword) {
          newDados.errorMessage.rootPassword =
            'Preencha com a senha do usuário root logado'
        }
        if (!dados.password) {
          newDados.errorMessage.password = 'Preencha com a nova senha'
        }
        if (!dados.confirmPassword) {
          newDados.errorMessage.confirmPassword = 'Confirme a nova senha'
        }
        console.log({ newDados })

        setDados(newDados)
        return
      }
      if (dados.password !== dados.confirmPassword) {
        const newDados = { ...dados }
        newDados.errorMessage.password = 'As senhas não conferem'

        newDados.errorMessage.confirmPassword = 'As senhas não conferem'
        setDados(newDados)
        toast.error('As senhas não conferem')
        return
      }

      setLoading(true)
      const response = await Services.updatePassword({
        userId: user.id,
        ...dados
      })
      console.log({ response })

      if (response.status === 200) {
        toast.success(
          response?.data?.msg ? response?.data?.msg : 'Senha atualizada'
        )
        setLoading(false)
        setShowCopy(true)
      } else {
        toast.error(
          response?.response?.data?.msg || 'Ocorreu um erro ao tentar salvar'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const handleCopyUser = () => {
    navigator.clipboard.writeText(
      `Email: ${user.email}\nSenha: ${dados.password}`
    )
    toast.success('Email e senha copiados')
    onClose()
  }

  const handleCancelCreateUser = () => {
    onClose()
  }

  const handlerDados = (key, value) => {
    console.log('heeeere')

    setDados((prev) => ({
      ...prev,
      errorMessage: {
        ...prev.errorMessage,
        [key]: null
      },
      [key]: value
    }))
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container className="containerModalAddUser">
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Alterar senha do usuário</h1>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col xl={4} md={4}>
              <FormGroup>
                <Form.Label>Senha do usuário root*</Form.Label>
                <Form.Control
                  value={dados.rootPassword}
                  type="password"
                  onChange={(event) =>
                    handlerDados('rootPassword', event.target.value)
                  }
                  isInvalid={!!dados?.errorMessage?.rootPassword}
                />

                <Form.Control.Feedback type="invalid">
                  {dados?.errorMessage?.rootPassword}
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
            <Col xl={4} md={4}>
              <FormGroup>
                <Form.Label>Nova senha do usuário</Form.Label>
                <Form.Control
                  value={dados.password}
                  type="password"
                  onChange={(event) => {
                    handlerDados('password',event.target.value)
                  }}
                  isInvalid={!!dados?.errorMessage?.password}
                />

                <Form.Control.Feedback type="invalid">
                  {dados?.errorMessage?.password}
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
            <Col xl={4} md={4}>
              <FormGroup>
                <Form.Label>Confirme a nova senha</Form.Label>
                <Form.Control
                  type="password"
                  value={dados.confirmPassword}
                  onChange={(event) =>
                    handlerDados('confirmPassword', event.target.value)
                  }
                  isInvalid={!!dados?.errorMessage?.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {dados?.errorMessage?.confirmPassword}
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>
          <Row className="container-buttons">
            <Button
              onClick={handleCancelCreateUser}
              variant="secondary"
              color="secondary"
            >
              Fechar
            </Button>
            {showCopy ? (
              <Button onClick={handleCopyUser} variant="success">
                Copiar email e senha
              </Button>
            ) : (
              <Button onClick={handleSubmitUpdatePassword} variant="primary">
                Confirmar
              </Button>
            )}
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
