import React, { useState, useEffect } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import Services from '../../services/sendMessage'
import './styles.scss'
import { Container, Row, Col, Button, Input, Label } from 'reactstrap'
import { Loading } from '../Loading'
import { SelectComponent } from '../selectComponent'


export const ModalAccess = ({ showModal, onClose, loadUsers }) => {
  const [loading, setLoading] = useState(false)
  const [instanciasList, setInstanciasList] = useState([])
  const [dados, setDados] = useState({
    value: ''
  })
  const [instancia, setInstancia] = useState([])


  const handleSubmitNewGroup = async () => {
    const response = await Services.saveAccess({
      users: dados?.value,
      instancia
    })

    if (response.status === 200) {
      onClose()
      loadUsers()
      return true
    } else if (response.status === 400) {
      toast.error("Dados Invalidos")
      return false
    } else {
      toast.error("Erro ao salvar acesso")
      return false
    }
  }

  useEffect(() => {
    loadInstancias()
  }, [])

  const loadInstancias = async () => {
    const response = await Services.loadInstancias()

    if (response.status === 200) {
      const data = []
      response?.data?.data?.data.map((item) => {
        data.push({
          label: item.name,
          value: item.id
        })
      })

      setInstanciasList(data)
    } else {
      toast.error(response?.msg)
      return false
    }
  }

  const handleCancelSendQrcodes = () => {
    onClose()
  }

  const handlerDados = (key, value) => {
    setDados((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container>
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Liberar novo acesso</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={12} item>
              <SelectComponent
                options={instanciasList}
                value={
                  instanciasList.find((a) => a.value === instancia)
                    ? instanciasList.find((a) => a.value === instancia)
                    : null
                }
                onChange={(value) => setInstancia(value?.value)}
                placeholder="Instância"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 50 }}>
            <Col xl={12} md={12}>
              <Label>Email ou Id do usuario</Label>
              <Input
                value={dados?.value}
                onChange={(event) => handlerDados('value', event.target.value)}
              />
            </Col>
          </Row>
          <Row className="footerModalButtons" style={{ marginTop: 40 }}>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handleSubmitNewGroup}
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handleCancelSendQrcodes}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
