/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card } from 'reactstrap'
import { toast } from 'react-toastify'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
///MODAIS
import { ModalAddUser } from '../../components/modalAddUser'
import { CardIndicador } from '../../components/CardIndicador'

// import images
import Services from '../../services/auth'
import { cnpjMask, phoneMask, formatDateT } from '../../util/string'
import './styles.css'

import 'chartist/dist/scss/chartist.scss'
import { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { ModalChangeUserPassword } from 'components/modalChangeUserPassword'
import { UserCollapse } from 'components/Table/UserCollapse'
import { ButtonComponent } from 'components/buttonComponent'

registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Nome', field: 'name' },
  { label: 'CNPJ', field: 'cnpj' },
  { label: 'Responsável', field: 'responsavel' },
  { label: 'Email', field: 'email' },
  { label: 'Telefone', field: 'phone' },
  { label: 'Status', field: 'status' },
  { label: 'Plano', field: 'plano' }
]

interface ICardProps {
  totalUsers: number
  totalInstances: number
}

export const Users = () => {
  const { listUsers } = Services
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const [showModalUpdateUserPassword, setShowModalUpdateUserPassword] =
    useState(false)
  const [showModalInstance, setShowModalInstance] = useState(false)
  const [showModalAddUser, setShowModalAddUser] = useState(false)

  const [cardValues, setCardValues] = useState<ICardProps>({
    totalUsers: 0,
    totalInstances: 0
  })
  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: true,
      order: true,
      more: true,
      pagination: true,
      createInstancia: true,
      updatePassword: true,
      edit: true
    },
    config: {
      resendPurchaseText: 'Reenviar Qr codes da compra',
      updatePasswordText: 'Atualizar senha',
      editText: 'Editar usuário'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null,
      sellerId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 30
  })

  const loadUsers = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await listUsers({
        rowsPerPage: tableConfig.rowsPerPage,
        page: tableConfig.currentPage,
        searchTerm: tableConfig?.filters?.searchTerm
      })

      if (response.status === 200) {
        const responseData = response?.data?.data
        const total = responseData?.total
        const totalInstancias = responseData?.totalInstancias

        //////////////////////////////////////////

        const formattedData = responseData.items.map((item) => ({
          ...item,
          cnpj: item.cnpj ? cnpjMask(item.cnpj) : null,
          phone: item.phone ? phoneMask(item.phone) : null,
          status: item.status === 1 ? 'Ativo' : 'Desativado',
          createdAt: formatDateT(item.createdAt)
        }))
        console.log({ formattedData })

        setRows(formattedData)
        setCardValues({
          totalUsers: total,
          totalInstances: totalInstancias
        })
        setTableConfig({
          ...tableConfig,
          total: total
        })
        setLoading(false)
      } else {
        toast.error(response)
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  const onCloseModalAddUser = () => {
    setCurrentRow(null)
    setShowModalAddUser(false)
  }

  const onCloseModalUpdateUserPassword = () => {
    setCurrentRow(null)
    setShowModalUpdateUserPassword(false)
  }

  const addUser = () => {
    setShowModalAddUser(true)
  }
  const handleAddInstance = (row) => {
    setCurrentRow(row)
    setShowModalInstance(true)
  }
  const handleUpdatePassword = (row) => {
    console.log({ row })

    setCurrentRow(row)
    setShowModalUpdateUserPassword(true)
  }
  const handleEdit = (row) => {
    console.log({ row })

    setCurrentRow(row)
    setShowModalAddUser(true)
  }
  useEffect(() => {
    loadUsers()
  }, [tableConfig.rowsPerPage, tableConfig.currentPage, tableConfig.filters])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} />}

      <div className="page-content">
        <MetaTags>
          <title>Usuários | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Usuarios
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Card>
              <Row>
                <Col xl={12} md={12} style={{ padding: 20 }}>
                  <Row>
                    <CardIndicador
                      icon={'mdi mdi-account'}
                      value={cardValues.totalUsers}
                      type={'number'}
                      text={'Usuários'}
                      color="primary"
                      xl={2}
                      md={4}
                      xs={4}
                    />
                    <CardIndicador
                      icon={'mdi mdi-cellphone-nfc'}
                      value={cardValues.totalInstances}
                      type={'number'}
                      text={'Instâncias'}
                      color="primary"
                      xl={2}
                      md={4}
                      xs={4}
                    />
                  </Row>
                  <Row>
                    <Col
                      xl={12}
                      md={12}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <ButtonComponent
                        text="+ Criar Usuário"
                        extraClass="h-fit"
                        onClick={() => addUser()}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <DatatableTables
                title={'Usuários'}
                columns={columns}
                rows={rows}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                CollapseComponent={UserCollapse}
                onAddSeller={handleAddInstance}
                onUpdatePassword={handleUpdatePassword}
                onEdit={handleEdit}
                callback={loadUsers}
              />
            </Card>
          </Row>
        </Container>
      </div>
      <ModalAddUser
        onClose={onCloseModalAddUser}
        loadUsers={loadUsers}
        showModal={showModalAddUser}
        user={currentRow}
      />
      <ModalChangeUserPassword
        onClose={onCloseModalUpdateUserPassword}
        showModal={showModalUpdateUserPassword}
        user={currentRow}
      />
    </React.Fragment>
  )
}
