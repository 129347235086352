import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import services from '../../services/instance'
import Select from 'react-select'
import './styles.scss'

import { Loading } from '../Loading'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { listType } from 'util/mocks'
import { CurrencyInput } from 'components/CurrencyInput'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { ExpensesProps } from 'types/global'
import { financialReportServices } from 'services/financialReport'
import { format } from 'date-fns'

interface IModalModalExpenseProps {
  showModal: boolean
  onClose: () => void
  callback: () => void
  expense?: ExpensesProps
  expenseRecurring?: boolean
}

interface IErrorMessage {
  description: string | null
  value: string | null
  dateObj: string | null
}

interface IExpense extends ExpensesProps {
  dateObj: Date
  errorMessage: IErrorMessage
}

registerLocale('pt-BR', ptBR)

export const ModalExpense = ({
  showModal,
  onClose,
  callback,
  expense,
  expenseRecurring
}: IModalModalExpenseProps) => {
  const { createExpense, updateRecurringExpense, updateExpense } =
    financialReportServices

  const [loading, setLoading] = useState(false)
  const [expenseData, setExpenseData] = useState<IExpense>({
    description: null,
    value: null,
    date: null,
    dateObj: null,
    recurrent: false,
    active: 0,
    errorMessage: {
      description: null,
      value: null,
      dateObj: null
    }
  })

  const handleSubmitExpense = async () => {
    try {
      if (
        !expenseData?.description ||
        !expenseData?.value ||
        !expenseData?.dateObj
      ) {
        toast.error('Preencha todos os campos')
        const newDados = { ...expenseData }

        if (!expenseData?.description) {
          newDados.errorMessage.description =
            'Preencha com uma descrição para a despesa'
        }

        if (!expenseData?.value) {
          newDados.errorMessage.value = 'Preencha com o valor da despesa'
        }

        if (!expenseData?.dateObj) {
          newDados.errorMessage.dateObj = 'Preencha com a data da despesa'
        }
        console.log({ newDados })

        setExpenseData(newDados)
        return false
      }

      let payload = {
        ...expenseData,
        date: format(expenseData?.dateObj, 'yyyy-MM-dd')
      }

      setLoading(true)
      let response = null
      if (expense) {
        payload = {
          ...payload,
          id: expense.id
        }
        if (expenseRecurring) {
          response = await updateRecurringExpense(payload)
        } else {
          response = await updateExpense(payload)
        }
      } else {
        response = await createExpense(payload)
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(response.data?.msg ? response.data?.msg : 'Despesa salva')
        onClose()
        callback()
        setLoading(false)
        setExpenseData(null)
      } else {
        toast.error(response?.msg || 'Ocorreu um erro ao tentar salvar')
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const handleCancelExpense = () => {
    onClose()
    setExpenseData(null)
  }

  const handlerChangeData = (
    key: string,
    value: string | number | boolean | Date
  ) => {
    console.log({ key, value })

    setExpenseData((prev) => ({
      ...prev,
      errorMessage: {
        ...prev?.errorMessage,
        [key]: null
      },
      [key]: value
    }))
  }

  useEffect(() => {
    console.log({ expense })

    if (expense && showModal) {
      setExpenseData({
        ...expenseData,
        description: expense.description || null,
        value: expense.value || null,
        dateObj: expense.date ? new Date(expense.date) : null,
        recurrent: expense.recurrent,
        active: expense.active
      })
    }
  }, [expense, showModal])

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="containerModalAutomationCampaing reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container className="containerModalAddInstance">
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>
                {expense ? 'Atualizar despesa' : 'Criar nova despesa'}
              </h1>
            </Col>
          </Row>
          <Row style={{ margin: 10, rowGap: 15 }}>
            <Col xs={12} sm={6}>
              <FormGroup>
                <Form.Label>Descrição*</Form.Label>
                <Form.Control
                  value={expenseData?.description}
                  onChange={(event) =>
                    handlerChangeData('description', event.target.value)
                  }
                  isInvalid={!!expenseData?.errorMessage?.description}
                />

                <Form.Control.Feedback type="invalid">
                  {expenseData?.errorMessage?.description}
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
            <Col xs={12} sm={6}>
              <FormGroup>
                <CurrencyInput
                  variant="outlined"
                  label="Valor*"
                  value={expenseData?.value}
                  handleChange={(value: number) => {
                    handlerChangeData('value', value)
                  }}
                  error={
                    !!expenseData?.errorMessage?.value &&
                    expenseData?.value !== 0
                  }
                  helperText={expenseData?.errorMessage?.value}
                  className={
                    !!expenseData?.errorMessage?.value ? 'select-error' : ''
                  }
                />
                {!!expenseData?.errorMessage?.value && (
                  <>
                    <span className="is-invalid"></span>
                    <p className="invalid-feedback">
                      {expenseData?.errorMessage?.value}
                    </p>
                  </>
                )}
              </FormGroup>
            </Col>

            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Data*</Form.Label>
                <ReactDatePicker
                  className="form-control"
                  selected={expenseData?.dateObj}
                  onChange={(date) => {
                    console.log({ date })
                    handlerChangeData('dateObj', date)
                  }}
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                />

                {expenseData?.errorMessage?.dateObj && (
                  <>
                    <span className="is-invalid"></span>
                    <p className="invalid-feedback">
                      {expenseData?.errorMessage?.dateObj}
                    </p>
                  </>
                )}
              </Form.Group>
            </Col>
            {!expense && (
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Check
                    checked={expenseData?.recurrent}
                    label="Recorrente"
                    onChange={(event) =>
                      handlerChangeData('recurrent', event.target.checked)
                    }
                  />
                </Form.Group>
              </Col>
            )}
            {expenseRecurring && (
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Check
                    checked={expenseData?.active === 1}
                    label="Ativa"
                    onChange={(event) =>
                      handlerChangeData('active', event.target.checked)
                    }
                  />
                </Form.Group>
              </Col>
            )}

            {/* <Col xs={12} md={3}>
              <Form.Group>
                <Form.Check
                  checked={expenseData?.active}
                  label="Ativo"
                  onChange={(event) =>
                    handlerChangeData('recurrent', event.target.checked)
                  }
                />
              </Form.Group>
            </Col> */}
          </Row>

          <Row className="container-buttons">
            <Button onClick={handleCancelExpense} variant="secondary">
              Fechar
            </Button>

            <Button onClick={handleSubmitExpense} variant="primary">
              Salvar
            </Button>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
