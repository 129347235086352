import { useCallback } from 'react'
import { ErrorCode, FileRejection } from 'react-dropzone'
import Swal from 'sweetalert2'

export function useFileUpload() {
  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      maxSize: number,
      callback: () => void
    ) => {
      if (fileRejections.length > 0) {
        const fileRejection = fileRejections[0].errors[0]

        switch (fileRejection.code) {
          case ErrorCode.FileInvalidType:
            return Swal.fire({
              icon: 'error',
              title: 'Ops..',
              text: 'Este formato de arquivo não é permitido',
              confirmButtonText: 'Fechar'
            })

          case ErrorCode.FileTooLarge:
            const size = new Intl.NumberFormat('en', {
              notation: 'compact'
            }).format(maxSize)
            return Swal.fire({
              icon: 'error',
              title: 'Ops..',
              text: `O arquivo pode ter no máximo ${size}`,
              confirmButtonText: 'Fechar'
            })
          default:
            return Swal.fire({
              icon: 'error',
              title: 'Ops..',
              text: fileRejection.message,
              confirmButtonText: 'Fechar'
            })
        }
      }

      callback()
    },
    []
  )

  return {
    onDrop
  }
}
