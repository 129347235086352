import React, { createContext, useContext, useEffect, useState } from 'react'
import { api } from 'services/axios'
import instance from 'services/instance'

type UserContextType = {
  userPlan: string
  setUserPlan: (userPlan: string) => void
  userName: string
  setUserName: (userPlan: string) => void
  isRoot: boolean
  setIsRoot: (isRoot: boolean) => void
  hasAutomationCampaign: boolean,
  userId: string
}

const UserContext = createContext<UserContextType>({
  userPlan: '',
  setUserPlan: (userPlan) => {},
  isRoot: false,
  setIsRoot: (isRoot) => {},
  userName: '',
  setUserName: (userName) => {},
  hasAutomationCampaign: false,
  userId: ''
})

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [userPlan, setUserPlan] = useState('')
  const [userId, setUserId] = useState('')
  const [isRoot, setIsRoot] = useState(false)
  const [userName, setUserName] = useState('')
  const [hasAutomationCampaign, setHasAutomationCampaign] = useState(false)

  const obj = JSON.parse(localStorage.getItem('authUser'))

  api.interceptors.response.use(async (response) => {
    const checkRoot = response.headers['is-root'] === '1'
    if (response.headers['user-plan'] !== userPlan) {
      setUserPlan(response.headers['user-plan'])
      setUserId(response.headers['user-id'])
      localStorage.setItem('plano', response.headers['user-plan'])
    }

    if (checkRoot !== isRoot) {
      setIsRoot(checkRoot)
    }
    return response
  })

  if (!userName && localStorage.getItem('userName')) {
    setUserName(localStorage.getItem('userName'))
  }

  if (!userId && localStorage.getItem('userId')) {
    setUserId(localStorage.getItem('userId'))
  }

  if (!userPlan && obj?.plano) {
    setUserPlan(obj?.plano)
  }

  async function checkHasAutomationCampaigns() {
    try {
      const response = await instance.getUserAutomationCampaign()
      if (response?.status === 200) {
        if (response.data?.length > 0) {
          setHasAutomationCampaign(true)
        } else {
          setHasAutomationCampaign(false)
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    console.log('heeeere')

    checkHasAutomationCampaigns()
  }, [])

  return (
    <UserContext.Provider
      value={{
        userPlan,
        setUserPlan,
        isRoot,
        setIsRoot,
        userName,
        setUserName,
        hasAutomationCampaign,
        userId
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useUserInfo() {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}
