import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import Switch from 'react-switch'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import PaginationComponent from './Pagination'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import './datatable.css'
import {
  ChevronDown,
  ChevronUp,
  MoreHorizontal,
  Plus,
  Trash,
  Edit,
  Download,
  Eye
} from 'react-feather'
import Services from '../../services/auth'
import { toast } from 'react-toastify'
import ReactInputMask from 'react-input-mask'

const FrontTable = ({
  title = '',
  columns = [],
  rows = [],
  tableConfig = {},
  setTableConfig = null,
  CollapseComponent = null,
  callback = () => {},
  onCreateCheckout = () => {},
  onResendPurchaseTickets = () => {},
  onAddSeller = () => {},
  onCreateLink = () => {},
  layoutCollapse = false,
  onAddCourtesyTicket = () => {},
  onMarkAsUsed = () => {},
  onResendTickets = () => {},
  onUploadContatos = () => {},
  handleClickSellerLink = () => {},
  handleDownload = () => {},
  handlerTrash = () => {},
  actualEmail = '',
  addOrganizer = null,
  setCheck = () => {},
  all = null,
  setGroup = () => {},
  exportXlsx = () => {},
  group = null,
  justGroup = false,
  setJustGroup = () => {},
  handlerAll = () => {},
  onExportGroupContacts = () => {},
  loadUsers,
  availableFields,
  showActions = false,
  showAddButton = false
}) => {
  const pageNumber = Math.ceil(
    Number(tableConfig?.total) / Number(tableConfig?.rowsPerPage)
  )

  function switchFieldDescription(field) {
    let fieldDescription = ''

    switch (field) {
      case 'companyName':
        fieldDescription = 'Empresa'
        break
      case 'position':
        fieldDescription = 'Cargo'
        break
      case 'shirtSize':
        fieldDescription = 'Camisa'
        break

      default:
        fieldDescription = field
        break
    }
    return fieldDescription
  }

  // Modals state
  const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false)
  const [isEditClientModalOpen, setIsEditClientModalOpen] = useState(false)
  const [isDeleteClientModalOpen, setIsDeleteClientModalOpen] = useState(false)
  const [isViewClientModalOpen, setIsViewClientModalOpen] = useState(false)
  const [selectedClient, setSelectedClient] = useState(null)
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')
  const [camposExtraAdd, setCamposExtraAdd] = useState([{ key: '', value: '' }])
  const [camposExtraEdit, setCamposExtraEdit] = useState([
    { key: '', value: '' }
  ])
  const [tagsInput, setTagsInput] = useState('')
  const [tagsArray, setTagsArray] = useState([])

  const handleAddTag = () => {
    setTagsArray([...tagsArray, ''])
  }

  const handleRemoveTag = (index) => {
    const newTagsArray = tagsArray.filter((_, i) => i !== index)
    setTagsArray(newTagsArray)
  }

  const handleUpdateTag = (index, value) => {
    const newTagsArray = tagsArray.map((tag, i) =>
      i === index ? value.trim().toUpperCase() : tag
    )
    setTagsArray(newTagsArray)
  }

  // Função para adicionar um novo par de campos extras
  const handleAddCampoAdd = () => {
    setCamposExtraAdd([...camposExtraAdd, { key: '', value: '' }])
  }

  // Função para remover um campo extra específico
  const handleRemoveCampoAdd = (index) => {
    const newCamposExtra = camposExtraAdd.filter((_, i) => i !== index)
    setCamposExtraAdd(newCamposExtra)
  }

  // Função para atualizar o valor de um campo extra específico
  const handleUpdateCampoAdd = (index, field, value) => {
    const newCamposExtra = camposExtraAdd.map((campo, i) =>
      i === index ? { ...campo, [field]: value } : campo
    )
    setCamposExtraAdd(newCamposExtra)
  }

  const toggleAddClientModal = () => {
    setIsAddClientModalOpen(!isAddClientModalOpen)
    if (!isAddClientModalOpen) {
      setCamposExtraAdd([{ key: '', value: '' }])
    }
  }

  // Funções para manipulação dos campos extras durante a edição
  const handleAddCampoEdit = () => {
    setCamposExtraEdit([...camposExtraEdit, { key: '', value: '' }])
  }

  const handleRemoveCampoEdit = (index) => {
    const newCamposExtra = camposExtraEdit.filter((_, i) => i !== index)
    setCamposExtraEdit(newCamposExtra)
  }

  const handleUpdateCampoEdit = (index, field, value) => {
    const newCamposExtra = camposExtraEdit.map((campo, i) =>
      i === index ? { ...campo, [field]: value } : campo
    )
    setCamposExtraEdit(newCamposExtra)
  }

  const loadCamposExtraEdit = (camposExtra) => {
    try {
      const parsedCamposExtra =
        typeof camposExtra === 'string' ? JSON.parse(camposExtra) : camposExtra

      if (!Array.isArray(parsedCamposExtra)) {
        throw new Error('CamposExtras não é um array.')
      }

      setCamposExtraEdit(parsedCamposExtra)
    } catch (error) {
      console.error('Invalid JSON format:', error)
      toast.error(
        'Os campos extras estão em um formato inválido. Por favor, verifique os dados.'
      )
      setCamposExtraEdit([])
    }
  }

  useEffect(() => {
    if (selectedClient && selectedClient.camposExtra) {
      loadCamposExtraEdit(selectedClient.camposExtra)
    }
  }, [selectedClient])

  const toggleEditClientModal = (client) => {
    setSelectedClient(client)
    setIsEditClientModalOpen(!isEditClientModalOpen)
    if (!isEditClientModalOpen) {
      loadCamposExtraEdit(client?.camposExtra || '[]')
    } else {
      setCamposExtraEdit([])
    }
  }

  const toggleDeleteClientModal = (client) => {
    setSelectedClient(client)
    setIsDeleteClientModalOpen(!isDeleteClientModalOpen)
  }

  const handleAddClient = async () => {
    try {
      const name = document.getElementById('clientName').value.trim()
      const telefone = document.getElementById('clientTelefone').value.trim()

      if (!name || !telefone) {
        toast.error('Nome e Telefone são obrigatórios.')
        return
      }

      const email = document.getElementById('clientEmail').value.trim()
      const cpf = document.getElementById('clientCpf').value.trim()
      const genero = document.getElementById('clientGenero').value.trim()

      // Se necessário, adiciona "MANUAL" ao array de tags
      const tags = [...tagsArray]
      if (!tags.includes('MANUAL')) {
        tags.push('MANUAL')
      }

      const clientData = {
        name,
        telefone,
        email,
        cpf,
        genero,
        camposExtra: JSON.stringify(camposExtraAdd),
        tags, // Use o array de strings diretamente
        instanceId: tableConfig.instanceId
      }

      const response = await Services.addClient(clientData)

      if (response.status === 201 && response.data?.success) {
        toast.success(response.data.message)
        await loadUsers(tableConfig.instanceId)
        toggleAddClientModal()
      } else {
        const errorMessage =
          response.data?.message || 'Erro ao criar o cliente.'
        console.error('Erro na criação do cliente:', errorMessage)
        toast.error(errorMessage)
      }
    } catch (error) {
      console.error('Erro ao adicionar o cliente:', error)
      const errorMessage =
        error.data?.message || 'Erro ao criar o cliente. Tente novamente.'
      toast.error(errorMessage)
    }
  }

  useEffect(() => {
    if (selectedClient) {
      const tags = Array.isArray(selectedClient.tags)
        ? selectedClient.tags.map((tag) => tag.trim())
        : selectedClient.tags
        ? JSON.parse(selectedClient.tags).map((tag) => tag.trim())
        : []

      setTagsArray(tags)
      loadCamposExtraEdit(selectedClient.camposExtra || '[]')
    } else {
      setTagsArray([])
      setCamposExtraEdit([])
    }
  }, [selectedClient])

  const handleEditClient = async () => {
    try {
      const name = document.getElementById('clientName').value.trim()
      const telefone = document.getElementById('clientTelefone').value.trim()
      const email = document.getElementById('clientEmail').value.trim()
      const cpf = document.getElementById('clientCpf').value.trim()
      const genero = document.getElementById('clientGenero').value.trim()

      // Unificar `tagsArray` em um único array
      const tags = tagsArray
        .map((tag) => tag.trim().toUpperCase())
        .filter((tag) => tag !== '')

      const clientData = {
        name,
        telefone,
        email,
        cpf,
        genero,
        camposExtra: JSON.stringify(camposExtraEdit),
        tags, // Enviar o array de tags
        instanceId: tableConfig.instanceId
      }

      const response = await Services.editClient(selectedClient.id, clientData)

      if (response.status === 200 && response.data.success) {
        toast.success('Cliente atualizado com sucesso!')
        await loadUsers(tableConfig.instanceId)
        toggleEditClientModal()
      } else {
        const errorMessage =
          response.data?.message || 'Erro ao atualizar o cliente.'
        console.error('Erro na atualização do cliente:', errorMessage)
        toast.error(errorMessage)
      }
    } catch (error) {
      console.error('Erro ao editar o cliente:', error)
      if (error.response) {
        const errorMessage =
          error.response.data?.message || 'Erro ao atualizar o cliente.'
        toast.error(errorMessage)
      } else {
        toast.error('Erro ao atualizar o cliente. Tente novamente.')
      }
    }
  }

  // Resetar o campo tags ao fechar o modal sem salvar
  const handleCloseModal = () => {
    setTagsInput('')
    toggleEditClientModal()
  }

  const toggleViewClientModal = (client) => {
    setSelectedClient(client)
    setIsViewClientModalOpen(!isViewClientModalOpen)
    if (!isViewClientModalOpen) {
      loadCamposExtraEdit(client?.camposExtra || '[]')
    } else {
      setCamposExtraEdit([])
    }
  }

  const handleDeleteClient = async (clientId) => {
    try {
      const response = await Services.deleteClient(clientId)

      if (response.status === 200 && response.data.success) {
        toast.success(response.data.message)

        await loadUsers(tableConfig.instanceId)
        toggleDeleteClientModal()
      } else {
        console.error('Erro ao excluir o cliente:', response.data)
        toast.error(response.data?.message || 'Erro ao excluir o cliente.')
      }
    } catch (error) {
      console.error('Erro ao excluir o cliente (catch):', error)

      if (error.response) {
        console.error('Resposta da API:', error.response)
        toast.error(
          error.response.data?.message || 'Erro ao excluir o cliente.'
        )
      } else {
        toast.error('Erro ao excluir o cliente. Tente novamente.')
      }
    }
  }

  const ColAditionalFields = ({ additionalFields }) => {
    try {
      // Verifica e converte o valor de camposExtra para um array, se necessário
      const parsedFields =
        typeof additionalFields === 'string'
          ? JSON.parse(additionalFields)
          : Array.isArray(additionalFields)
          ? additionalFields
          : []

      // Se o resultado não for um array, lança um erro
      if (!Array.isArray(parsedFields)) {
        throw new Error('CamposExtras não é um array.')
      }

      return (
        <ul>
          {parsedFields.map((field, index) => {
            const fieldDescription = switchFieldDescription(field.key)

            const isValidField = availableFields.some(
              (availableField) => availableField.key_field === field.key
            )

            if (isValidField) {
              return (
                <li key={index}>
                  <i>{fieldDescription}</i> - <strong>{field.value}</strong>
                </li>
              )
            } else {
              return null
            }
          })}
        </ul>
      )
    } catch (error) {
      console.error('Erro ao renderizar campos extras:', error)
      return <>Erro ao carregar campos extras</>
    }
  }

  const ColTagsWithDots = ({ tags }) => {
    if (typeof tags === 'string') {
      try {
        tags = JSON.parse(tags)
      } catch (error) {
        console.error('Erro ao parsear tags: ', error)
        return <>-</> // Retorna apenas um hífen se houver erro no parse
      }
    }

    if (!tags || !Array.isArray(tags) || tags.length === 0) {
      return <>-</> // Se as tags não forem válidas, retorna um hífen
    }

    return (
      <div>
        {tags.map((tag, index) => (
          <span key={index}>
            {tag}
            {index < tags.length - 1 && (
              <span style={{ margin: '0 5px' }}>•</span>
            )}
          </span>
        ))}
      </div>
    )
  }

  const ColOptions = ({
    tableConfig,
    handleAddInstance,
    handleLinkInstance,
    handleExportGroupContacts,
    row
  }) => {
    const [open, setOpen] = useState(false)
    const handleClick = () => {
      setOpen(!open)
    }

    return (
      <div>
        <Button
          id="basic-button"
          onClick={() => {
            handleClick()
          }}
        >
          <MoreHorizontal />
        </Button>
        {open && (
          <>
            <div className="divClose" onClick={() => setOpen(false)}></div>
            <div className="divFlutuante">
              <ul>
                {tableConfig?.options?.createInstancia && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleAddInstance()
                    }}
                  >
                    <div className="menuItem">
                      <Plus /> Adicionar Instancia
                    </div>
                  </li>
                )}
                {tableConfig?.options?.linkInstance && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleLinkInstance()
                    }}
                  >
                    <div className="menuItem">
                      <Plus /> Copiar Link da Instancia
                    </div>
                  </li>
                )}
                {tableConfig?.options?.exportGroup && !row.hideMoreOptions && (
                  <li
                    onClick={() => {
                      setOpen(false)
                      handleExportGroupContacts()
                    }}
                  >
                    <div className="menuItem">
                      <Download /> {tableConfig.config.exportGroupText}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    )
  }

  const ActionButtons = ({ row }) => {
    return (
      <Td>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            color="dark"
            outline="false"
            onClick={() => toggleViewClientModal(row)}
          >
            <Eye size={16} />
          </Button>
          <Button
            color="dark"
            outline="false"
            onClick={() => toggleEditClientModal(row)}
          >
            <Edit size={16} />
          </Button>
          <Button
            color="danger"
            outline="false"
            onClick={() => toggleDeleteClientModal(row)}
          >
            <Trash size={16} />
          </Button>
        </div>
      </Td>
    )
  }

  const Rows = ({
    row,
    columns,
    tableConfig,
    onAddSeller,
    onResendTickets,
    CollapseComponent,
    onCreateCheckout,
    callback,
    setCheck,
    onExportGroupContacts
  }) => {
    const [open, setOpen] = useState(false)

    const handleOpenOrder = async () => {
      setOpen(!open)
    }

    const handleAddInstance = () => {
      onAddSeller(row)
    }

    const handleLinkInstance = () => {
      onResendTickets(row)
    }

    const handleExportGroupContacts = () => {
      onExportGroupContacts(row)
    }

    return (
      <>
        <Tr>
          {tableConfig?.options?.collapse && (
            <Td>
              <span onClick={() => handleOpenOrder()}>
                {open ? <ChevronUp /> : <ChevronDown />}
              </span>
            </Td>
          )}
          {tableConfig?.options?.checkWhatsapp && (
            <Td>
              <div className="menuItemCheck">
                <Switch onChange={() => setCheck(row)} checked={row['check']} />
              </div>
            </Td>
          )}
          {columns.map((col) => (
            <Td key={col.field}>
              {col.field === 'camposExtra' ? (
                <ColAditionalFields additionalFields={row.camposExtra} />
              ) : col.field === 'tags' ? (
                <ColTagsWithDots tags={row.tags} />
              ) : (
                row?.[col.field]
              )}
            </Td>
          ))}
          {tableConfig?.options?.trash && (
            <Td onClick={() => handlerTrash(row)}>
              <div className="menuItem">
                <Trash />
                {tableConfig?.config?.handleTrashButton}
              </div>
            </Td>
          )}
          {tableConfig?.options?.more && !row.hideMoreOptions && (
            <ColOptions
              tableConfig={tableConfig}
              handleAddInstance={handleAddInstance}
              handleLinkInstance={handleLinkInstance}
              handleExportGroupContacts={handleExportGroupContacts}
              row={row}
            />
          )}
          {showActions && <ActionButtons row={row} />}
        </Tr>
        {tableConfig?.options?.collapse && CollapseComponent && (
          <CollapseComponent
            open={open}
            tableConfig={tableConfig}
            onCreateCheckout={onCreateCheckout}
            row={row}
            columns={columns}
            callback={callback}
          />
        )}
      </>
    )
  }

  return (
    <React.Fragment>
      <div className={`page-content ${layoutCollapse ? 'tableCollapse' : ''}`}>
        <div className="container-fluid">
          <Row>
            <Col>
              <Card>
                <CardBody>
                  {addOrganizer && (
                    <div className="organizzer">
                      <Button
                        type="button"
                        color="danger"
                        onClick={() => addOrganizer()}
                      >
                        {' '}
                        Adicionar Organizador
                      </Button>
                    </div>
                  )}
                  {tableConfig?.options?.downloadXlsx && showAddButton && (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        marginBottom: 10
                      }}
                    >
                      <Button
                        color="dark"
                        onClick={toggleAddClientModal}
                        style={{ marginRight: 10 }}
                      >
                        Novo Cliente
                      </Button>
                      <a
                        style={{
                          fontSize: 14,
                          cursor: 'pointer',
                          fontWeight: 600
                        }}
                        onClick={() => exportXlsx()}
                      >
                        Exportar Excel
                      </a>
                    </div>
                  )}
                  {tableConfig?.options?.uploadContacts && (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        marginBottom: 10
                      }}
                    >
                      <a
                        style={{
                          fontSize: 14,
                          cursor: 'pointer',
                          fontWeight: 600
                        }}
                        onClick={() => onUploadContatos()}
                      >
                        Upload Contatos
                      </a>
                    </div>
                  )}
                  <CardTitle
                    className="h4"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <div style={{ display: 'flex' }}>
                      {tableConfig?.options?.checkWhatsapp && (
                        <Td>
                          <div
                            className="menuItemCheck"
                            style={{ marginRight: 10 }}
                          >
                            <Switch
                              onChange={() => {
                                handlerAll()
                              }}
                              checked={all}
                            />
                          </div>
                        </Td>
                      )}
                      {title} ({tableConfig.total} total)
                    </div>

                    {tableConfig?.options?.groupFilter && (
                      <div style={{ display: 'flex' }}>
                        <Td>
                          <div
                            className="menuItemCheck"
                            style={{ marginRight: 10 }}
                          >
                            <Switch
                              onChange={() => {
                                setGroup(!group)
                              }}
                              checked={group}
                            />
                          </div>
                        </Td>
                        Incluir Grupos
                      </div>
                    )}

                    {tableConfig?.options?.justGroup && (
                      <div style={{ display: 'flex' }}>
                        <Td>
                          <div
                            className="menuItemCheck"
                            style={{ marginRight: 10 }}
                          >
                            <Switch
                              onChange={() => {
                                setJustGroup(!justGroup)
                              }}
                              checked={justGroup}
                            />
                          </div>
                        </Td>
                        Somente Grupos
                      </div>
                    )}
                  </CardTitle>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="tech-companies-1"
                        className="table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr className="tableHead">
                            {tableConfig?.options?.collapse && (
                              <Th
                                data-priority="index"
                                className="tableHeadName"
                              ></Th>
                            )}
                            {tableConfig?.options?.checkWhatsapp && (
                              <Th
                                data-priority="index"
                                className="tableHeadName"
                              >
                                Selecionar
                              </Th>
                            )}
                            {columns.map((column, index) => (
                              <Th
                                key={index}
                                data-priority="index"
                                className="tableHeadName"
                              >
                                {column?.label}
                              </Th>
                            ))}
                            {showActions && (
                              <Th
                                data-priority="index"
                                className="tableHeadName"
                              >
                                Ações
                              </Th>
                            )}
                            {tableConfig?.options?.more && (
                              <Th
                                data-priority="index"
                                className="tableHeadName"
                              >
                                Opções
                              </Th>
                            )}
                            {tableConfig?.options?.trash && (
                              <Th
                                data-priority="index"
                                className="tableHeadName"
                              >
                                Excluir
                              </Th>
                            )}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {rows.map((row, index) => (
                            <Rows
                              key={`${index}_${row.field}`}
                              row={row}
                              columns={columns}
                              tableConfig={tableConfig}
                              CollapseComponent={CollapseComponent}
                              onAddSeller={onAddSeller}
                              onCreateLink={onCreateLink}
                              onAddCourtesyTicket={onAddCourtesyTicket}
                              onCreateCheckout={onCreateCheckout}
                              onMarkAsUsed={onMarkAsUsed}
                              onResendPurchaseTickets={onResendPurchaseTickets}
                              onResendTickets={onResendTickets}
                              onUploadContatos={onUploadContatos}
                              callback={callback}
                              handleClickSellerLink={handleClickSellerLink}
                              onDownload={handleDownload}
                              setCheck={setCheck}
                              all={all}
                              group={group}
                              setGroup={setGroup}
                              justGroup={justGroup}
                              setJustGroup={setJustGroup}
                              exportXlsx={exportXlsx}
                              handlerAll={handlerAll}
                              onExportGroupContacts={onExportGroupContacts}
                            />
                          ))}
                        </Tbody>
                      </Table>
                      {!layoutCollapse && (
                        <div className="paginationContent">
                          {tableConfig?.options?.pagination && (
                            <PaginationComponent
                              pageNumber={pageNumber}
                              tableConfig={tableConfig}
                              setTableConfig={setTableConfig}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Modal para Adicionar Cliente */}
      <Modal isOpen={isAddClientModalOpen} toggle={toggleAddClientModal}>
        <ModalHeader toggle={toggleAddClientModal}>
          Adicionar Cliente
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="clientName">Nome</Label>
              <Input
                type="text"
                id="clientName"
                placeholder="Nome do cliente"
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 5 }}>
              <Label for="clientTelefone">Telefone</Label>
              <ReactInputMask
                type="text"
                mask="559999999999"
                id="clientTelefone"
                className="form-control"
                placeholder="Telefone do cliente no formato 559491252324"
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 5 }}>
              <Label for="clientEmail">Email</Label>
              <Input
                type="email"
                id="clientEmail"
                placeholder="Email do cliente"
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 5 }}>
              <Label for="clientCpf">CPF</Label>
              <ReactInputMask
                type="text"
                id="clientCpf"
                mask="999.999.999-99"
                placeholder="CPF do cliente"
                className="form-control"
              />
            </FormGroup>

            {/* Campo para Tags */}
            <FormGroup style={{ marginTop: 5 }}>
              <Label>Tags</Label>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {tagsArray.map((tag, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      flexBasis: 'calc(50% - 10px)',
                      marginRight: '10px'
                    }}
                  >
                    <Input
                      type="text"
                      placeholder="Tag"
                      value={tag}
                      onChange={(e) => handleUpdateTag(index, e.target.value)}
                      style={{ marginRight: '5px', width: '100%' }}
                    />
                    <Button
                      color="danger"
                      outline="false"
                      onClick={() => handleRemoveTag(index)}
                    >
                      <Trash size={16} />
                    </Button>
                  </div>
                ))}
              </div>
              <Button
                color="dark"
                onClick={handleAddTag}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '5px'
                }}
              >
                +
              </Button>
            </FormGroup>

            {/* Campos Extras Dinâmicos */}
            <FormGroup style={{ marginTop: 5 }}>
              <Label>Campos Extras</Label>
              <div style={{ marginBottom: '10px' }}>
                {camposExtraAdd.map((campo, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: '5px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Input
                      type="text"
                      placeholder="Nome do Campo"
                      value={campo.key}
                      onChange={(e) =>
                        handleUpdateCampoAdd(index, 'key', e.target.value)
                      }
                      style={{ marginRight: '5px', width: '45%' }}
                    />
                    <Input
                      type="text"
                      placeholder="Descrição"
                      value={campo.value}
                      onChange={(e) =>
                        handleUpdateCampoAdd(index, 'value', e.target.value)
                      }
                      style={{ marginRight: '5px', width: '45%' }}
                    />
                    <Button
                      color="danger"
                      outline="false"
                      onClick={() => handleRemoveCampoAdd(index)}
                    >
                      <Trash size={16} />
                    </Button>
                  </div>
                ))}
              </div>
              <Button
                color="dark"
                onClick={handleAddCampoAdd}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '5px'
                }}
              >
                +
              </Button>
            </FormGroup>

            <FormGroup style={{ marginTop: 5 }}>
              <Label for="clientGenero">Sexo</Label>
              <Input type="select" id="clientGenero">
                <option value="">Selecione o sexo</option>
                <option value="feminino">Feminino</option>
                <option value="masculino">Masculino</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleAddClient}>
            Adicionar
          </Button>
          <Button color="secondary" onClick={toggleAddClientModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal para Editar Cliente */}
      <Modal isOpen={isEditClientModalOpen} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>Editar Cliente</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="clientName">Nome</Label>
              <Input
                type="text"
                id="clientName"
                placeholder="Nome do cliente"
                defaultValue={selectedClient?.name}
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 5 }}>
              <Label for="clientTelefone">Telefone</Label>
              <ReactInputMask
                mask="559999999999"
                type="text"
                id="clientTelefone"
                className="form-control"
                placeholder="Telefone do cliente no formato 559491252324"
                defaultValue={selectedClient?.telefone}
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 5 }}>
              <Label for="clientEmail">Email</Label>
              <Input
                type="email"
                id="clientEmail"
                placeholder="Email do cliente"
                defaultValue={selectedClient?.email}
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 5 }}>
              <Label for="clientCpf">CPF</Label>
              <ReactInputMask
                type="text"
                id="clientCpf"
                mask="999.999.999-99"
                placeholder="CPF do cliente"
                className="form-control"
                defaultValue={selectedClient?.cpf}
              />
            </FormGroup>

            {/* Campo para Tags */}
            <FormGroup style={{ marginTop: 5 }}>
              <Label>Tags</Label>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {tagsArray.map((tag, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginBottom: '5px',
                      flexBasis: 'calc(50% - 10px)',
                      marginRight: '10px'
                    }}
                  >
                    <Input
                      type="text"
                      placeholder="Tag"
                      value={tag}
                      onChange={(e) => handleUpdateTag(index, e.target.value)}
                      style={{ marginRight: '5px', width: '100%' }}
                    />
                    <Button
                      color="danger"
                      outline="false"
                      onClick={() => handleRemoveTag(index)}
                    >
                      <Trash size={16} />
                    </Button>
                  </div>
                ))}
              </div>
              <Button
                color="dark"
                onClick={handleAddTag}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '5px'
                }}
              >
                +
              </Button>
            </FormGroup>

            {/* Campos Extras Dinâmicos */}
            <FormGroup style={{ marginTop: 5 }}>
              <Label>Campos Extras</Label>
              <div style={{ marginBottom: '10px' }}>
                {camposExtraEdit.map((campo, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: '5px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Input
                      type="text"
                      placeholder="Nome do Campo"
                      value={campo.key}
                      onChange={(e) =>
                        handleUpdateCampoEdit(index, 'key', e.target.value)
                      }
                      style={{ marginRight: '5px', width: '45%' }}
                    />
                    <Input
                      type="text"
                      placeholder="Descrição"
                      value={campo.value}
                      onChange={(e) =>
                        handleUpdateCampoEdit(index, 'value', e.target.value)
                      }
                      style={{ marginRight: '5px', width: '45%' }}
                    />
                    <Button
                      color="danger"
                      outline
                      onClick={() => handleRemoveCampoEdit(index)}
                    >
                      <Trash size={16} />
                    </Button>
                  </div>
                ))}
              </div>
              <Button
                color="dark"
                onClick={handleAddCampoEdit}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '5px'
                }}
              >
                +
              </Button>
            </FormGroup>

            <FormGroup style={{ marginTop: 5 }}>
              <Label for="clientGenero">Sexo</Label>
              <Input
                type="select"
                id="clientGenero"
                defaultValue={selectedClient?.genero}
              >
                <option value="">Selecione o sexo</option>
                <option value="feminino">Feminino</option>
                <option value="masculino">Masculino</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={handleEditClient}>
            Salvar
          </Button>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal para Visualizar Cliente */}
      <Modal isOpen={isViewClientModalOpen} toggle={toggleViewClientModal}>
        <ModalHeader toggle={toggleViewClientModal}>
          Visualizar Cliente
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="viewClientName">Nome</Label>
              <Input
                type="text"
                id="viewClientName"
                placeholder="Nome do cliente"
                defaultValue={selectedClient?.name}
                readOnly
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 5 }}>
              <Label for="viewClientTelefone">Telefone</Label>
              <ReactInputMask
                mask="559999999999"
                type="text"
                id="viewClientTelefone"
                className="form-control"
                placeholder="Telefone do cliente no formato 559491252324"
                defaultValue={selectedClient?.telefone}
                readOnly
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 5 }}>
              <Label for="viewClientEmail">Email</Label>
              <Input
                type="email"
                id="viewClientEmail"
                placeholder="Email do cliente"
                defaultValue={selectedClient?.email}
                readOnly
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 5 }}>
              <Label for="viewClientCpf">CPF</Label>
              <ReactInputMask
                type="text"
                id="viewClientCpf"
                mask="999.999.999-99"
                placeholder="CPF do cliente"
                className="form-control"
                defaultValue={selectedClient?.cpf}
                readOnly
              />
            </FormGroup>

            {/* Campo para Tags */}
            <FormGroup style={{ marginTop: 5 }}>
              <Label>Tags</Label>
              <div
                style={{
                  marginBottom: '10px',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {tagsArray.map((tag, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      flexBasis: 'calc(50% - 10px)',
                      marginRight: '10px'
                    }}
                  >
                    <Input
                      type="text"
                      placeholder="Tag"
                      value={tag}
                      readOnly
                      style={{ marginRight: '5px', width: '100%' }}
                    />
                  </div>
                ))}
              </div>
            </FormGroup>

            {/* Campos Extras Dinâmicos */}
            <FormGroup style={{ marginTop: 5 }}>
              <Label>Campos Extras</Label>
              <div style={{ marginBottom: '10px' }}>
                {camposExtraEdit.map((campo, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: '5px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Input
                      type="text"
                      placeholder="Nome do Campo"
                      value={campo.key}
                      style={{ marginRight: '5px', width: '45%' }}
                      readOnly
                    />
                    <Input
                      type="text"
                      placeholder="Descrição"
                      value={campo.value}
                      style={{ marginRight: '5px', width: '45%' }}
                      readOnly
                    />
                  </div>
                ))}
              </div>
            </FormGroup>

            <FormGroup style={{ marginTop: 5 }}>
              <Label for="viewClientGenero">Sexo</Label>
              <Input
                type="select"
                id="viewClientGenero"
                defaultValue={selectedClient?.genero}
                disabled
              >
                <option value="">Selecione o sexo</option>
                <option value="feminino">Feminino</option>
                <option value="masculino">Masculino</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleViewClientModal}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal para Confirmar Exclusão de Cliente */}
      <Modal isOpen={isDeleteClientModalOpen} toggle={toggleDeleteClientModal}>
        <ModalHeader toggle={toggleDeleteClientModal}>
          Excluir Cliente
        </ModalHeader>
        <ModalBody>
          Tem certeza que deseja excluir o cliente{' '}
          <strong>{selectedClient?.name}</strong>?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => handleDeleteClient(selectedClient?.id)}
          >
            Excluir
          </Button>
          <Button color="secondary" onClick={toggleDeleteClientModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

export default FrontTable
