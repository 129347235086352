import React from 'react';

function MonthYearSelect({ value, onChange }) {
  // Define o valor inicial do componente

  // Define a função para lidar com a mudança de seleção do input
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  // Renderiza o componente Select
  return (
    <input
      type="month"
      value={value}
      onChange={handleChange}
    />
  );
}

export default MonthYearSelect;
