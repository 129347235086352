import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import React, { useState } from 'react'
import './styles.css'

import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap'
import { Link, Redirect } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

// actions
import { loginUser } from '../../store/actions'

// import images
import logoSm from '../../assets/images/logos/zap_mais.png'
import backGround from '../../assets/images/whats.jpg'

import { useUserInfo } from 'context/userContext'

const Login = () => {
  const { setUserName } = useUserInfo()
  const [login, setLogin] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')

  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    const result = await loginUser(values)
    console.log({result});
    if (result.status === 200) {
      setError(false)
      setMessage('')
      console.log(result.message)
      setUserName(result.userName)
      setLogin(true)
    } else {
      setLogin(false)
      setError(true)
      setMessage(result.message)
    }
  }

  return login ? (
    <Redirect to="/dashboard" />
  ) : (
    <div className='login-page'>
      <MetaTags>
        <title>Login | Zap+</title>
      </MetaTags>
          <Row className="justify-content-center">
            <Col xs={12} md={5} xl={3}
             style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
             }}
            >
                  <div className="text-primary text-center p-4"  >
                    <h5 className="font-size-20">Bem Vindo</h5>
                    <Link to="/" className="logo">
                      <img src={logoSm} height="60" alt="logo" />
                    </Link>
                  </div>
                  <div className="p-3">
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {error && <Alert color="danger">{message}</Alert>}
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          value=""
                          className="form-control"
                          placeholder="Insira o email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Senha"
                          value=""
                          type="password"
                          required
                          placeholder="Insira a Senha"
                        />
                      </div>

                      <Row className="mb-3">
                        <Col sm={6}></Col>
                        <Col sm={6} className="text-end">
                          <button
                            className="btn  w-md waves-effect waves-light newBg"
                            type="submit" >
                            Entrar
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
            </Col>
            <Col xs={12} md={7} xl={9}
              className='background-login'
              style={{ backgroundImage: `url(${backGround})` }}
              >
            </Col>
          </Row>
    </div>
  )
}

export default Login

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func
}
