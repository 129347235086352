/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody
} from 'reactstrap'

import './styles.css'

// Importar as logos
import FeegowLogo from './logo/feegow.webp'
import MaxDataLogo from './logo/maxdata.png'
import ShopifyLogo from './logo/shopify.png'
import MauticLogo from './logo/mautic.png'
import WebhookLogo from './logo/webhooks.webp'
import RadLogo from './logo/rad.svg'
import GmailLogo from './logo/gmail.webp'
import TrelloLogo from './logo/trello.png'
import ClickupLogo from './logo/clickup.png'
import AdsLogo from './logo/ads.png'
import GoogleLogo from './logo/google.webp'
import MysqlLogo from './logo/mysql.png'
import GoogleCalendarLogo from './logo/calendar.png'


const Partners = () => {

  const IconPartners = ({logo})=> {
 
    return(
    <Col xl={3} md={4} xs={6}
     style={{
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      padding: 10
     }}
    >
        <img src={logo}  className="img-fluid mb-3" style={{ maxWidth: '150px' }} />
      </Col>
      )
    }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Partners | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                      Integrações Disponíveis
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Card style={{
            padding: 20
          }}>
          <Row style={{
            marginBottom: 50
          }}>
          <Col xl={12} md={12} xs={12}>
            
            <h3>Conheça alguns dos sistemas que conseguimos nos integrar e fazer processos automatizados</h3>
       
              </Col>
          </Row>
          <Row>
              <IconPartners logo={FeegowLogo} />
              <IconPartners logo={MaxDataLogo} />
              <IconPartners logo={RadLogo} />
              <IconPartners logo={ShopifyLogo} />
              <IconPartners logo={MauticLogo} />
              <IconPartners logo={GmailLogo} />
              <IconPartners logo={TrelloLogo} />
              <IconPartners logo={ClickupLogo} />
              <IconPartners logo={AdsLogo} />
              <IconPartners logo={GoogleLogo} />
              <IconPartners logo={GoogleCalendarLogo} />
              <IconPartners logo={MysqlLogo} />

              
              <IconPartners logo={WebhookLogo} />

              <h5>Outros Sistemas</h5>
              <p>Outros sistemas podem ser integrados via Webhook ou leitura de API. Entre em contato para mais informações. Se você deseja uma integração personalizada, entre em contato conosco através do suporte para discutir suas necessidades específicas.</p>
               
            </Row>
            </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

Partners.propTypes = {
  t: PropTypes.any
}

export default Partners
