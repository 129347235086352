import { api } from './axios'

export const BotService = {
  updateFluxoCadastro: async (params) => {
    const response = await api
      .post('/fluxo-cadastro',
        {
          ...params
        }
      )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateStatusFluxoCadastro: async (params) => {
    const response = await api
      .post('/fluxo-cadastro-status',
        {
          ...params
        }
      )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getFluxoCadastro: async ({
    instance_id
  }) => {
    const response = await api
      .get('/fluxo-cadastro',
        {
          params :{
            instance_id
        }}
      )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
