/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'
import Service from '../../services/auth'
import Swal from 'sweetalert2'
import {formatDateSimplesWithHours} from '../../util/string'

import './styles.css'

const Privacidade = () => {
  const [data, setData] = useState(null)
  const [status, setStatus] = useState(false)
  const [rendered, setRendered] = useState(false)

  const loadPrivacy = async () => {
    const privacy = await Service.loadPrivacy();
    if (privacy.status === 200) {
      setData(privacy.data.data.privacy_date)
      setStatus(privacy.data.data.privacy_and_terms)
      localStorage.setItem("privacy_and_terms", privacy.data.data.privacy_and_terms)

      setRendered(true)
    }
  }

  const handleAcceptPrivacy = async () => {
    const response = await Service.acceptPrivacy();
    if (response.status === 200) {
      Swal.fire({
        title: 'Política de Privacidade',
        text: 'Obrigado por aceitar nossa Política de Privacidade!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      loadPrivacy(); // Recarrega os dados para atualizar o estado
    } else {
      Swal.fire({
        title: 'Erro',
        text: 'Ocorreu um erro ao aceitar a Política de Privacidade. Tente novamente.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  }

  useEffect(() => {
    loadPrivacy()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Políticas de Privacidade | Zap+ 2.0</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo aos nossos tutoriais
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Col md={12} style={{
              paddingBottom: "5%",
              paddingLeft: "5%",
              paddingRight: "5%",
              justifyContent: "center"
            }}>
              <h2>Política de Privacidade</h2>

              <section>
                <h3>1. Introdução</h3>
                <p>
                  Esta Política de Privacidade descreve como a CTECH D C LTDA coleta, usa, e protege as informações pessoais que você fornece ao utilizar nossos serviços, incluindo o Zap+. Estamos comprometidos em garantir a privacidade e a segurança de seus dados pessoais, cumprindo todas as leis e regulamentos aplicáveis.
                </p>
              </section>

              <section>
                <h3>2. Coleta de Dados</h3>
                <p>
                  Coletamos diferentes tipos de informações para diversas finalidades, como melhorar nossos serviços e fornecer uma experiência personalizada. Entre os dados coletados estão:
                  <ul>
                    <li>Informações fornecidas diretamente por você, como nome, endereço de e-mail e telefone.</li>
                    <li>Dados de uso, como interações com nossa plataforma, páginas visitadas e tempo gasto em cada página.</li>
                    <li>Informações de localização, quando você permite o uso da sua localização pelo aplicativo.</li>
                  </ul>
                </p>
              </section>

              <section>
                <h3>3. Tratamento de Leads e Uso de Dados</h3>
                <p>
                  Todos os leads coletados através do Zap+ são armazenados com segurança pela CTECH D C LTDA. Esses dados podem ser utilizados para cálculos de indicadores da plataforma, auxiliando na análise de performance e na geração de insights estratégicos. Além disso, os dados podem ser usados para melhorias contínuas dos produtos da CTECH D C LTDA, garantindo uma experiência aprimorada para todos os usuários.
                </p>
              </section>

              <section>
                <h3>4. Compartilhamento de Informações</h3>
                <p>
                  Não compartilhamos suas informações pessoais com terceiros, exceto nas seguintes situações:
                  <ul>
                    <li>Com provedores de serviço que auxiliam na operação da nossa plataforma, sempre sob acordos de confidencialidade rigorosos.</li>
                    <li>Quando exigido por lei ou por ordens judiciais.</li>
                    <li>Para proteger os direitos e propriedades da CTECH D C LTDA e de seus usuários.</li>
                  </ul>
                </p>
              </section>

              <section>
                <h3>5. Segurança dos Dados</h3>
                <p>
                  Implementamos medidas de segurança técnicas e organizacionais para proteger seus dados contra acesso não autorizado, alteração, divulgação ou destruição. Isso inclui o uso de criptografia, firewalls e controles de acesso rigorosos.
                </p>
              </section>

              <section>
                <h3>6. Política de Retenção de Dados</h3>
                <p>
                  As mensagens enviadas pelo Zap+ são armazenadas por um período de 12 meses. Após esse período, os dados são automaticamente excluídos. Dados relacionados ao envio de SMS são mantidos por apenas 6 meses, seguindo práticas de retenção de dados responsáveis. Mantemos seus dados apenas pelo tempo necessário para cumprir os propósitos descritos nesta política.
                </p>
              </section>

              <section>
                <h3>7. Responsabilidade Sobre Bloqueios no WhatsApp</h3>
                <p>
                  A CTECH D C LTDA não é responsável por bloqueios de números de telefone no WhatsApp. Esse risco é inerente ao uso da plataforma, pois o WhatsApp possui políticas próprias de uso e detecção de spam. Para minimizar o risco de bloqueio, recomendamos o uso de números maduros e envios em volumes controlados.
                </p>
              </section>

              <section>
                <h3>8. Consequências do Não Pagamento</h3>
                <p>
                  O não pagamento da mensalidade do Zap+ pode resultar no bloqueio ou pausa no funcionamento de qualquer campanha ou integração associada à plataforma. A retomada dos serviços só ocorrerá após a regularização dos pagamentos pendentes. É importante manter suas informações de pagamento atualizadas para evitar interrupções nos serviços.
                </p>
              </section>

              <section>
                <h3>9. Direitos dos Usuários</h3>
                <p>
                  Você tem o direito de acessar, corrigir ou solicitar a exclusão de seus dados pessoais a qualquer momento. Para exercer esses direitos, entre em contato conosco por meio das informações de contato fornecidas abaixo. Também respeitamos seu direito de restringir o processamento dos seus dados em determinadas circunstâncias.
                </p>
              </section>

              <section>
                <h3>10. Alterações nesta Política</h3>
                <p>
                  Podemos atualizar nossa Política de Privacidade periodicamente para refletir mudanças em nossos serviços ou nas leis aplicáveis. Notificaremos você sobre quaisquer alterações significativas, mas recomendamos que reveja esta política regularmente.
                </p>
              </section>

              <section>
                <h3>11. Contato</h3>
                <p>
                  Se você tiver qualquer dúvida ou preocupação sobre esta Política de Privacidade, ou sobre o tratamento dos seus dados, por favor entre em contato conosco através do e-mail: suporte@ctech.com.br.
                </p>
              </section>
              <b>
                Última atualização 02/09/2024 às 17h30
              </b>
              {
                status ? (
                  <p><b>{`Esta política foi aceita no dia ${formatDateSimplesWithHours(data)}`}</b></p>
                ) : (
                  <div style={{ marginTop: "20px" }}>
                    <Button color="dark" onClick={handleAcceptPrivacy}>
                      Aceitar Política de Privacidade
                    </Button>
                  </div>
                )
              }
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Privacidade.propTypes = {
  t: PropTypes.any
}

export default Privacidade
