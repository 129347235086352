import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import Services from '../../services/sorte'
import './styles.scss'
import { Loading } from '../Loading'
import MonthYearSelect from '../SelectMes'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { format, startOfMonth } from 'date-fns';
import Select from 'react-select'
import { useUserInfo } from 'context/userContext'


export const ModalPix = ({
  showModal,
  onClose,
  idSelecionado
}) => {

  const { isRoot, userId } = useUserInfo()

  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState()
  const [img, setImg] = useState(false)
  const [copiaECola, setCopiaECola] = useState(false)
  const [dataExpiration, setDataExpiration] = useState(false)
  const [month, setMonth] = useState(format(startOfMonth(new Date()), 'yyyy-MM'))
  const [pacoteList, setPacoteList] = useState([])
  const [pacote, setPacote] = useState(false)

  useEffect(() => {
    if(idSelecionado) {
      getPrice()
    }
  }, [idSelecionado])

  const getPrice = async () => {
    const result = await Services.getPrice({idSelecionado})
    if (result?.status === 200) {
      console.log(result?.data)
      setPacoteList(result?.data?.data)
    } else {
      toast.error('Error ao buscar preço')
    }
  }

  const gerarPix = async () => {
    setLoading(true)
    let options = pacoteList.find((a) => a?.value === pacote)
    
    const response = await Services.makePayment({pacote: options})
    if (response?.status === 200) {

      console.log("response", response?.data?.data)
      const mPaysResponse = response?.data?.data;

      const {
        id,
        transactionId,
        expiresAt,
        qrCode,
        qrCodeUrl,
        status,
        paymentStatusDescription
      } = mPaysResponse;

      setImg(qrCodeUrl)
      setCopiaECola(qrCode)
      setDataExpiration(expiresAt)

      setLoading(false)

    } else {
      setLoading(false)
      toast.error('Error ao gerar PIX')
    }
  }

  const copiarLink = () => {
    navigator.clipboard.writeText(copiaECola);
  }

  return (
    <ReactModal
      isOpen={showModal && idSelecionado}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container className="containerModalAddUser"
      
      style={{
        minHeight: "550px",
        minWidth: "800px"
      }}>
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Adicionar Crédito</h1>
            </Col>
          </Row>
          <Row>
            {img ? (
                <Col xl={12} md={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column"
                }}
                >
                    <Row>
                    <Col xl={8} md={8}>
                    <img 
                  src={img}
                />
                    </Col>
                    </Row>
                    <Row>
                <label type="text" id="campo-copia">
                  {copiaECola}
                </label>
                <Button
              onClick={copiarLink}
              variant="secondary"
              color="secondary"
            >
              Copiar link
            </Button>
                <span>Após o pagamento feche essa tela</span>
                </Row>
              </Col>
            ) : (
              <Row>
                <Col xl={12} md={12}>
                <FormGroup>
                  <Form.Label>Pacote</Form.Label>
                  <Select
                  options={pacoteList}
                  value={
                    pacoteList.find((a) => a.value === pacote)
                      ? pacoteList.find(
                          (a) => a.value === pacote
                        )
                      : null
                  }
                  onChange={(value) => setPacote(value?.value)}
                  placeholder="Instancia"
                />
                </FormGroup>
              </Col>
              </Row>
            )
            }
          
          </Row>
          <Row className="container-buttons">
            <Button
              onClick={onClose}
              variant="secondary"
              color="secondary"
            >
              Fechar
            </Button>
              <Button onClick={gerarPix} variant="primary">
                Gerar PIX
              </Button>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
