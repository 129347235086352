import { api } from './axios'

const Services = {
  newInstance: async ({ name }) => {
    const response = await api
      .get('/new-instance', {
        params: {
          name
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getLanding: async () => {
    const response = await api
      .get('/page/list')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getLandingEspecific: async (slug) => {
    const response = await api
      .get(`/get-pages-dados?slug=${slug}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getInstance: async () => {
    const response = await api
      .get('/instance/list')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getEspecificGrupo: async ({ id }) => {
    const response = await api
      .get(`/group/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getGrupos: async (instanceId) => {
    const response = await api
      .get('/groups', {
        params: {
          page: 0,
          rowsPerPage: 10000,
          instanceId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  checkInstanceSms: async ({ value }) => {
    const response = await api
      .get('/instance/check/sms', {
        params: {
          idInstancia: value
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getCampanhas: async ({ user }) => {
    const response = await api
      .get('/campanha/list', {
        params: {
          idInstancia: user
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  loadInstancias: async () => {
    const response = await api
      .get('/load-instancias')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  loadIndicators: async ({
    rowsPerPage,
    page,
    instanceId,
    startsAt,
    endsAt
  }) => {
    const response = await api
      .get('/dashboard', {
        params: {
          rowsPerPage,
          page,
          instanceId,
          startsAt,
          endsAt
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  CheckInstance: async ({ idInstancia }) => {
    const response = await api
      .get('/instance/check', {
        params: {
          idInstancia
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  listChat: async ({ user, filtro }) => {
    const response = await api
      .get('/message/list-chat', {
        params: {
          user,
          filtro
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  sendMessage: async ({ formData }) => {
    const response = await api
      .post('/message/send', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  saveAccess: async (props) => {
    const response = await api
      .post('/access/save', {
        ...props
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  sendSms: async (props) => {
    const response = await api
      .post('/message/send/sms', {
        ...props
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  sendVideo: async ({ formData }) => {
    const response = await api
      .post('/message/send-video', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  exportGroupContacts: async ({ groupId, instanceId }) => {
    const response = await api
      .get('/exportar-grupo', {
        params: {
          groupId,
          instanceId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
export default Services
