import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import DatatableTables from '../index.js';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { Redirect } from 'react-router-dom'
import { formatCurrency } from '../../../util/string'

import styles from './styles.module.scss';

import { toast } from 'react-toastify';


export default function CollapseComponent({ open, row, callback, onCreateCheckout }) {
  const [orderTickets, setOrderTickets] = useState([]);

  const columns = [
    { label: "Cod", field: "id" },
    { label: "Name", field: "name" },
    { label: "Valor", field: "valor" },
    { label: "Status", field: "status" },
    { label: "Connected", field: "connected" },
    { label: "Api Brasil", field: "use_local" }
  ]

  const [tableConfig, setTableConfig] = useState({
    options: {
      edit: true,
      pagination: false,
      linkInstance: false
    },
    config: {
      resendTicketText: 'Reenviar Qr code do ingresso'
    },
    total: 0,
    currentPage: 1,
    rowsPerPage: 10
  })

  useEffect(() => {
    if (row?.instancias) {
      const ticketsTemp = row?.instancias.map((instancias) => {
        return {
          ...instancias,
          use_local: instancias.use_local ? 'Sim' : 'Não',
          connected: instancias.connected ? 'Sim' : 'Não',
          status: instancias.status ? 'Ativo' : 'Inativo',
          valor: instancias.valor ? formatCurrency({value:instancias.valor}) : '0,00',
        }
      })
      setOrderTickets(ticketsTemp)
    }
  }, [row])

  
  return (
    <Tr>
      <Td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columns.length + 4 + (tableConfig?.options?.more ? 1 : 0)}>
        <Collapse
          isOpen={open}
          timeout="auto" unmountOnExit className={styles.tableContainer}>
          <DatatableTables
            rows={orderTickets}
            columns={columns}
            tableConfig={tableConfig}
            onChangePage={() => { }}
            onChangeRowsPerPage={() => { }}
            hideBackgroundHeader={true}
            onCreateCheckout={onCreateCheckout}
            layoutCollapse={true}
          />
        </Collapse>
      </Td>
    </Tr>
  );

}

CollapseComponent.propTypes = {
  history: PropTypes.object
}
