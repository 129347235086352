/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col } from 'reactstrap'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
///MODAIS

import './styles.scss'

import 'chartist/dist/scss/chartist.scss'
import instance from 'services/instance'
import { ModalAutomationCampaign } from 'components/modalAutomationCampaing'

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Instância', field: 'instanceName' },
  { label: 'Nome', field: 'name' },
  { label: 'Tipo', field: 'type' },
  { label: 'Ativa', field: 'activeText' }
]

export const AutomationCampaign = () => {
  const [loading, setLoading] = useState(false)
  const [instanceCampaigns, setInstanceCampaigns] = useState([])
  const [currentRow, setCurrentRow] = useState(null)
  const [showModalAutomationCampaign, setShowModalAutomationCampaign] =
    useState(false)
  const handleClose = () => {
    setLoading(false)
  }
  const [tableConfig, setTableConfig] = useState({
    options: {
      more: true,
      pagination: false,
      edit: true
    },
    config: {
      editText: 'Editar Campanha'
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  async function loadInstanceCampaign() {
    try {
      setLoading(true)
      const response = await instance.getUserAutomationCampaign()
      console.log(response.data)

      setLoading(false)
      if (response?.status === 200) {
        const data = response.data.map((item) => ({
          ...item,
          activeText: item.active ? 'Sim' : 'Não'
        }))
        setInstanceCampaigns(data)
      }
    } catch (error) {
      setLoading(false)
      console.log({ error })
    }
  }

  const handleEdit = (row) => {
    console.log({ row })
    setCurrentRow(row)
    setShowModalAutomationCampaign(true)
  }

  const onCloseModalAddAutomationCampaign = () => {
    setCurrentRow(null)
    setShowModalAutomationCampaign(false)
  }

  useEffect(() => {
    loadInstanceCampaign()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}

      <div className="page-content automation-campaign-page">
        <MetaTags>
          <title>Campanhas de automação | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Campanhas de automação | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de campanhas de automação
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <DatatableTables
            title={'Campanhas de automação'}
            columns={columns}
            rows={instanceCampaigns}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            onEdit={handleEdit}
          />

          <ModalAutomationCampaign
            onClose={onCloseModalAddAutomationCampaign}
            loadUsers={loadInstanceCampaign}
            showModal={showModalAutomationCampaign}
            instanceId={currentRow?.instanceId}
            campaign={currentRow}
            isUser={true}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
