import { api } from './axios'

export const IA = {
  createCopy: async (params) => {
    const response = await api
      .get('/create-copy', {
        params: {
          ...params
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
