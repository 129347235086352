import { api } from './axios';

const Services = {
  order: async () => {
    const response = await api.get('/order/select')
    .then(({ ...response }) => response)
    .catch(({ ...response }) => response)

    return response
},
cycle: async () => {
    const response = await api.get('/cycle/select')
    .then(({ ...response }) => response)
    .catch(({ ...response }) => response)

    return response
},
entrie: async () => {
    const response = await api.get('/entrie/select')
    .then(({ ...response }) => response)
    .catch(({ ...response }) => response)

    return response
},
origin: async () => {
    const response = await api.get('/origin/select')
    .then(({ ...response }) => response)
    .catch(({ ...response }) => response)

    return response
},
customer: async () => {
    const response = await api.get('/customer/select')
    .then(({ ...response }) => response)
    .catch(({ ...response }) => response)

    return response
},
product: async () => {
    const response = await api.get('/product/select')
    .then(({ ...response }) => response)
    .catch(({ ...response }) => response)

    return response
},
paymentMethod: async () => {
    const response = await api.get('/payment-method/select')
    .then(({ ...response }) => response)
    .catch(({ ...response }) => response)

    return response
},
productCategory: async () => {
    const response = await api.get('/product/category/select')
    .then(({ ...response }) => response)
    .catch(({ ...response }) => response)

    return response
},
eventzz: async () => {
    const response = await api.get('/eventzz/select')
    .then(({ ...response }) => response)
    .catch(({ ...response }) => response)

    return response
},
seller: async (eventId) => {
    const response = await api.get('/seller/select',{
        params: {
            eventId
        }
    })
    .then(({ ...response }) => response)
    .catch(({ ...response }) => response)

    return response
},
organizerSelect: async () => {
    const response = await api.get('/organizer/select')
    .then(({ ...response }) => response)
    .catch(({ ...response }) => response)

    return response
}
}
export default Services;