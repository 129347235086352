/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button
} from 'reactstrap'
import { ToastContainer, toast } from 'react-toastify'
import RefreshIcon from '../../assets/images/refresh-icon.png'
import QrCodeBackground from '../../assets/images/qr-code-background.png'

import { Loading } from '../../components/Loading'

import styles from './styles.module.scss'
// import images

import 'chartist/dist/scss/chartist.scss'
import Services from '../../services/user'
import instanceService from 'services/instance'

export function InstanceDetails({ match }) {
  const instanceId = match?.params?.instanceId
  const instanceToken = match?.params?.instanceToken

  const { getQueue, getInstanceId } = instanceService

  const [loading, setLoading] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isLoadingQrcode, setIsLoadingQrcode] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [instanceData, setInstanceData] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [qrcodeUrl, setQrcodeUrl] = useState(null)
  const [rendered, setRendered] = useState(false)
  const [instanceLocalId, setInstanceLocalId] = useState(null)
  const [queueCount, setQueueCount] = useState(0)
  const [showRefreshButton, setShowRefreshButton] = useState(false)
  const countQrCode = useRef(0)

  const controlInterval = useRef(null)
  const handleClose = () => {
    setLoading(false)
  }

  const fetchInstanceId = useCallback(async () => {
    console.log({ instanceId })
    const response = await getInstanceId({ zapiId: instanceId })
    console.log({ response: response.data })
    if (response.status === 200 && response?.data?.id) {
      console.log('here')
      console.log(response.data.id)
      setInstanceLocalId(response.data.id)
    }
  }, [instanceId])

  const loadInstance = useCallback(async () => {
    fetchInstanceId()
    console.log(countQrCode)
    if (countQrCode.current >= 10) {
      clearInterval(controlInterval.current)
      countQrCode.current = 0
      setIsLoadingQrcode(false)
      setShowRefreshButton(true)
      return
    }
    console.log('sldk')
    countQrCode.current = countQrCode.current + 1
    // return;
    const response = await Services.getQrCode({ instanceId, instanceToken })
    if (response.status === 200) {
      if (response.data.connected) {
        setIsLoadingQrcode(false)
        setIsAuthenticated(true)
        clearInterval(controlInterval.current)
        countQrCode.current = 0
        const responseDevice = await Services.getDeviceData({
          instanceId,
          instanceToken
        })
        if (responseDevice.status === 200) {
          if (responseDevice.data) {
            setInstanceData({
              instanceName: responseDevice?.data?.name,
              instancePhone: responseDevice?.data?.phone,
              instanceToken,
              connected: 'Sim'
            })
          }
        }
      } else {
        setQrcodeUrl(response?.data?.value)
        setShowRefreshButton(false)
        setIsLoadingQrcode(false)
        setIsAuthenticated(false)
      }
      setLoading(false)
      return true
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }, [instanceId, instanceToken, countQrCode])

  function handleClickRefresh() {
    setIsLoadingQrcode(true)
    setShowRefreshButton(false)
    loadInstance()
    controlInterval.current = setInterval(() => {
      console.log('aqui')
      loadInstance()
    }, 13000)
  }

  async function getMessageQueue() {
    try {
      const response = await getQueue({ instanceLocalId })
      console.log({ response })
      if (response.status === 200 && response?.data?.count) {
        setQueueCount(response.data.count)
      } else {
        setQueueCount(0)
      }
    } catch (error) {
      console.log({ error })
    }
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }

    if (instanceId && rendered) {
      loadInstance()
      controlInterval.current = setInterval(() => {
        console.log('aqui')
        loadInstance()
      }, 13000)
    }
  }, [rendered])

  useEffect(() => {
    if (instanceLocalId) {
      getMessageQueue()
    }
    return () => {
      clearInterval(controlInterval.current);
    }
  }, [instanceLocalId])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <ToastContainer />
      <div className={`page-content ${styles.paddingBottom250}`}>
        <MetaTags>
          <title>Detalhes da Instância | Eventozz</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">
                  Detalhes da Instância {countQrCode.current}
                </h6>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xs={12} md={8}>
              <Col xs={12} style={{ paddingBottom: 15 }}>
                <FormGroup>
                  <Label>Nome</Label>
                  <Input
                    type="text"
                    name="instanceName"
                    value={instanceData.instanceName}
                    readOnly
                  />
                </FormGroup>
              </Col>

              <Col xs={12} style={{ paddingBottom: 15 }}>
                <FormGroup>
                  <Label>Telefone conectado</Label>
                  <Input
                    type="text"
                    name="instanceName"
                    value={instanceData.instancePhone}
                    readOnly
                  />
                </FormGroup>
              </Col>

              <Col xs={12} style={{ paddingBottom: 15 }}>
                <FormGroup>
                  <Label>Token</Label>
                  <Input
                    type="text"
                    name="instanceName"
                    value={instanceData.instanceToken}
                    readOnly
                  />
                </FormGroup>
              </Col>

              <Col xs={12} style={{ paddingBottom: 15 }}>
                <FormGroup>
                  <Label>Conectada</Label>
                  <Input
                    type="text"
                    name="instanceName"
                    value={instanceData.connected}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Col>
            <Col xs={12} md={4}>
              <div className={styles.textHeaderContainer}>
                <h4 className="page-title">
                  {isAuthenticated ? 'Conectado' : 'Leia o QRCode'}
                </h4>
                <h6 className="page-title">
                  {isAuthenticated
                    ? 'Sua instância está conectada, você já pode enviar mensagens via API'
                    : 'Abra o aplicativo do whatsapp e leia o QRCode abaixo para se conectar a esta instância'}
                </h6>
              </div>
              <div className={styles.containerQrcode}>
                {isLoadingQrcode ? (
                  <div className={styles.loadingQrcodeContainer}>
                    <div className={`spinner-border`} role="status"></div>
                    <div className={styles.loadingText}>Carregando Qr code</div>
                  </div>
                ) : showRefreshButton ? (
                  <div
                    className={styles.containerRefreshQrcode}
                    onClick={handleClickRefresh}
                  >
                    <div
                      style={{ color: '#00ac5a' }}
                      className={styles.containerImgRefreshQrcode}
                    >
                      <img
                        src={RefreshIcon}
                        alt="Ícone de refresh"
                        className={styles.iconRefresh}
                      />
                      <img
                        src={QrCodeBackground}
                        alt="qr code background"
                        className={styles.qrCodeBackground}
                      />
                    </div>
                    Clique para recarregar o qr code
                  </div>
                ) : isAuthenticated ? (
                  <div
                    style={{ color: '#00ac5a' }}
                    className={styles.constainerIsAuthenticated}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      viewBox="0 0 512 512"
                      height="40"
                      width="40"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                    </svg>
                  </div>
                ) : (
                  <img src={qrcodeUrl} alt="Imagem qr code" />
                )}
              </div>
              {queueCount && (
                <div className={styles.queueCount}>
                  Você possui {queueCount} {queueCount > 1 ? 'mensagens' : 'mensagem'} na fila de envio dessa instância
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
