/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  Button,
} from 'reactstrap'
import { toast } from 'react-toastify'
import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'

import Services from '../../services/integration'
import Swal from 'sweetalert2'
import './styles.css'
import 'chartist/dist/scss/chartist.scss'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

registerLocale('pt-BR', ptBR)
const columns = [
  { label: 'Nome', field: 'name' },
  { label: 'Token API', field: 'public_token' }
]

const Integration = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const {getIntegration } = Services;

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      more: false,
    },
    total: 0,
    currentPage: 0,
    userId: null,
    rowsPerPage: 20
  })

  useEffect(() => {
    buscaDados();
  }, [])

  const buscaDados = async () => {
    setLoading(true)
    const response = await getIntegration();
    setLoading(false)
    if(response.status === 200){
      console.log("=========================")
      console.log(response?.data?.data)
      console.log("=========================")
      setData(response?.data?.data)
    }else{
      toast.error('Erro ao buscar os dados')
    }
  }
  
  const handleClose = () => {
    setLoading(false)
  }
 
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success('Copiado para a área de transferência')
      })
      .catch(error => {
        toast.error('Erro ao copiar')
      });
  };

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <div className="page-content">
        <MetaTags>
          <title>Leads | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Integrações  | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Integrações
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Card style={{minHeight:600, marginTop: 20, padding: 20}}>
            <Row>
            <Button
                  variant="outlined"
                  onClick={() => {
                    window.open('http://api.zapmais.app/docs', '_blank')
                  }}
                  className="newBg"
                  style={{
                    padding: 10,
                    marginBottom: 15,
                    cursor: 'pointer',
                    backgroundColor: '#0D071B !important',
                    color: 'white'
                  }}
                >
                  Ir para documentação da API
                </Button>
            </Row>
          <Row>
            <Col xl={12}>
              <DatatableTables
                title={'Instancias para integração'}
                columns={columns}
                rows={data}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
              />
            </Col>
          </Row>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

Integration.propTypes = {
  t: PropTypes.any
}

export default Integration
