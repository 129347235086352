import React from 'react';
import Switch from 'react-switch';

const SwitchWithLabel = ({ label, checked, onChange }) => {
  return (
    <label style={{
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    }}>
      <span style={{marginRight:10}}>{label}</span>
      <Switch onChange={onChange} checked={checked} />
    </label>
  );
};

export default SwitchWithLabel;
