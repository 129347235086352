import React from 'react'
import { Button } from 'react-bootstrap'
//@ts-ignore
import styles from './styles.module.scss'

export const MessageVariableButtons = ({ onAddVariable }) => {
  return (
    <div className={styles.containerButtons}>
        <Button type='button' onClick={() => onAddVariable('{Nome do participante}') }>Nome do participante</Button>
    </div>
  )
}