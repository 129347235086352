import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import './styles.scss'
import { Col, Container } from 'reactstrap'
import { Button, Card } from 'react-bootstrap'
//@ts-ignore
import styles from './styles.module.scss'
import Services from 'services/user'
import { toast } from 'react-toastify'
//@ts-ignore
import RefreshIcon from '../../assets/images/refresh-icon.png'
//@ts-ignore
import QrCodeBackground from '../../assets/images/qr-code-background.png'

interface ModalQrcodeProps {
  showModal: boolean
  onClose: () => void
  onSuccess: () => void
  instanceId: null | number
  instanceName: null | string
  statusInstance: number
  instanceChecked: boolean
  zapiData: {
    id: string,
    token: string
  }
}

export const ModalQrcodeZApi = ({
  showModal,
  onClose,
  instanceId,
  instanceName,
  statusInstance,
  onSuccess,
  instanceChecked,
  zapiData
}: ModalQrcodeProps) => {
  const countQrCode = useRef(0)
  const controlInterval = useRef(null)

  //   const [loading, setLoading] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoadingQrcode, setIsLoadingQrcode] = useState(true)
  const [qrcodeUrl, setQrcodeUrl] = useState(null)
  const [showRefreshButton, setShowRefreshButton] = useState(false)

  const loadInstance = useCallback(async () => {
    if (countQrCode.current >= 5) {
      clearInterval(controlInterval.current)
      countQrCode.current = 0
      setIsLoadingQrcode(false)
      setShowRefreshButton(true)
      return
    }
  
    countQrCode.current += 1
  
    try {
      const response = await Services.getQrCode({ instanceId: zapiData.id, instanceToken: zapiData.token })
  
      if (response.status === 200) {
        if (response.data.connected) {
          setIsLoadingQrcode(false)
          setIsAuthenticated(true)
          clearInterval(controlInterval.current)
          countQrCode.current = 0
          onSuccess()
        } else {
          setQrcodeUrl(response?.data?.value)
          setShowRefreshButton(false)
          setIsLoadingQrcode(false)
          setIsAuthenticated(false)
        }
      } else {
        toast.error(response?.msg)
      }
    } catch (error) {
      toast.error('Não foi possível carregar o QR Code')
    }
  }, [instanceId, countQrCode, onSuccess])
  
  function handleClickRefresh() {
    setIsLoadingQrcode(true)
    setShowRefreshButton(false)
    loadInstance()
    controlInterval.current = setInterval(() => {
      loadInstance()
    }, 13000)
  }
  
  useEffect(() => {
    if (instanceId && showModal && zapiData.id && zapiData.token) {
      loadInstance()
      controlInterval.current = setInterval(() => {
        loadInstance()
      }, 11000)
    }
    return () => clearInterval(controlInterval.current)
  }, [instanceId, showModal, loadInstance, zapiData])
  


  if (!showModal || !instanceId || !instanceChecked || statusInstance || !zapiData.id || !zapiData.token) {
    return null
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="modal-zap-mais"
      overlayClassName="modal-zap-mais-overlay"
    >
      <Container>
        <Card.Body>
          {/* <Col xs={12} md={4}> */}
          <div className={styles.textHeaderContainer}>
            <h4 className="page-title">
              {isAuthenticated ? 'Conectado' : 'Leia o QRCode'}
            </h4>
            <h6
              className="page-title"
              dangerouslySetInnerHTML={{
                __html: isAuthenticated
                  ? 'Sua instância está conectada, você já pode enviar mensagens'
                  : `Abra o aplicativo do whatsapp e leia o QRCode abaixo para se conectar a instância <b>${instanceName}</b>`
              }}
            />
          </div>
          <div className={styles.containerQrcode}>
            {isLoadingQrcode ? (
              <div className={styles.loadingQrcodeContainer}>
                <div className={`spinner-border`} role="status"></div>
                <div className={styles.loadingText}>Carregando Qr code</div>
              </div>
            ) : showRefreshButton ? (
              <div
                className={styles.containerRefreshQrcode}
                onClick={handleClickRefresh}
              >
                <div
                  style={{ color: '#00ac5a' }}
                  className={styles.containerImgRefreshQrcode}
                >
                  <img
                    src={RefreshIcon}
                    alt="Ícone de refresh"
                    className={styles.iconRefresh}
                  />
                  <img
                    src={QrCodeBackground}
                    alt="qr code background"
                    className={styles.qrCodeBackground}
                  />
                </div>
                Clique para recarregar o qr code
              </div>
            ) : isAuthenticated ? (
              <div
                style={{ color: '#00ac5a' }}
                className={styles.constainerIsAuthenticated}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  viewBox="0 0 512 512"
                  height="40"
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                </svg>
              </div>
            ) : (
              <img src={qrcodeUrl} alt="Imagem qr code" />
            )}
          </div>
          {/* </Col> */}
        </Card.Body>
        <Card.Footer className="modal-zap-mais-footer">
          {statusInstance ? (
            <Button onClick={onClose} color='success' >Fechar</Button>
          ) : (
            <Button onClick={onClose}>Escolher outra instância</Button>
          )}
        </Card.Footer>
      </Container>
    </ReactModal>
  )
}
