import { ButtonComponent } from 'components/buttonComponent'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import {
  Download,
  Edit,
  FilePlus,
  Lock,
  LogOut,
  MoreHorizontal,
  Plus,
  ToggleLeft,
  ToggleRight,
  Trash,
  Upload,
  Zap
} from 'react-feather'
import { Td } from 'react-super-responsive-table'
import { TableConfig } from 'types/global'

export interface ColOptionsProps {
  row: any
  tableConfig: TableConfig
  handleAddInstance: () => void
  handleLinkInstance: () => void
  handleDelete: () => void
  handlerUploadContatos: () => void
  handlerResgatarCashback: () => void
  handlerUpdatePassword: () => void
  handlerEdit: () => void
  handlerAdd: () => void
  handlerLogout: () => void
  handlerSwitch: () => void
  handlerDownload: () => void
}

export const ColOptions = ({
  row,
  tableConfig,
  handleAddInstance,
  handleLinkInstance,
  handleDelete,
  handlerResgatarCashback,
  handlerUploadContatos,
  handlerUpdatePassword,
  handlerEdit,
  handlerAdd,
  handlerLogout,
  handlerSwitch,
  handlerDownload,
  options = []
}) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <Td>
      <ButtonComponent
        icon={<MoreHorizontal />}
        applyPadding={false}
        text=""
        onClick={() => {
          handleClick()
        }}
      />
      {open && (
        <>
          <div className="divClose" onClick={() => setOpen(false)}></div>
          <div className="divFlutuante">
            <ul>
              {tableConfig?.options?.createInstancia && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handleAddInstance()
                  }}
                >
                  <div className="menuItem">
                    <Plus /> Adicionar Instancia
                  </div>
                </li>
              )}
              {tableConfig?.options?.linkInstance && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handleLinkInstance()
                  }}
                >
                  <div className="menuItem">
                    <Plus /> Verificar Conexão
                  </div>
                </li>
              )}

              {tableConfig?.options?.uploadGrupo && !row.hideUploadGrupo && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handlerUploadContatos()
                  }}
                  style={{
                    marginBottom: '6px'
                  }}
                >
                  <div className="menuItem">
                    <Upload /> Upload de Contatos
                  </div>
                </li>
              )}

              {tableConfig?.options?.delete && !row.hideDelete && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handleDelete()
                  }}
                >
                  <div className="menuItem">
                    <Trash /> {tableConfig.config.deleteText}
                  </div>
                </li>
              )}

              {tableConfig?.options?.deleteCashback && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handleDelete()
                  }}
                >
                  <div className="menuItem">
                    <Trash /> {tableConfig.config.deleteText}
                  </div>
                </li>
              )}

              {tableConfig?.options?.resgatarCashback && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handlerResgatarCashback()
                  }}
                >
                  <div className="menuItem">
                    <Zap /> {tableConfig.config.resgatarText}
                  </div>
                </li>
              )}

              {tableConfig?.options?.updatePassword && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handlerUpdatePassword()
                  }}
                >
                  <div className="menuItem">
                    <Lock /> {tableConfig.config.updatePasswordText}
                  </div>
                </li>
              )}

              {tableConfig?.options?.edit && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handlerEdit()
                  }}
                >
                  <div className="menuItem">
                    <Edit /> {tableConfig.config.editText}
                  </div>
                </li>
              )}
              {tableConfig?.options?.add && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handlerAdd()
                  }}
                >
                  <div className="menuItem">
                    <FilePlus /> {tableConfig.config.addText}
                  </div>
                </li>
              )}

              {tableConfig?.options?.logout && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handlerLogout()
                  }}
                >
                  <div className="menuItem">
                    <LogOut /> {tableConfig.config.logoutText}
                  </div>
                </li>
              )}

              {tableConfig?.options?.download && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handlerDownload()
                  }}
                >
                  <div className="menuItem">
                    <Download /> {tableConfig.config.downloadText}
                  </div>
                </li>
              )}

              {tableConfig?.options?.switch && (
                <li
                  onClick={() => {
                    setOpen(false)
                    handlerSwitch()
                  }}
                >
                  <div className="menuItem">
                    {row.active === 1 ? <ToggleRight /> : <ToggleLeft />}{' '}
                    {row.active === 1
                      ? tableConfig.config.switchTextInactive
                      : tableConfig.config.switchTextActive}
                  </div>
                </li>
              )}
              {options?.length > 0
                ? options
                    .filter((item) => !item.hidden)
                    .map((item) => (
                      <li
                        onClick={() => {
                          setOpen(false)
                          item.handleClick(row)
                        }}
                      >
                        <div className="menuItem customOption">
                          {item.icon} {item.labelText[row[item.field]]}
                        </div>
                      </li>
                    ))
                : null}
            </ul>
          </div>
        </>
      )}
    </Td>
  )
}
