import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DatatableTables from '../index.js'
import { Collapse } from 'reactstrap'
import { Tr, Td } from 'react-super-responsive-table'
import { formatCurrency } from '../../../util/string'

import './styles.scss'
import { Instance } from 'types/global.js'
import { ModalInstancia } from 'components/modalInstancia'
import { ModalAutomationCampaign } from 'components/modalAutomationCampaing'
import { UserInstanceCollapse } from '../UserInstanceCollapse'

export function UserCollapse({ open, row, callback }) {
  const [orderTickets, setOrderTickets] = useState([])
  const [showModalUpdateInstance, setShowModalUpdateInstance] = useState(false)
  const [showModalAutomationCampaign, setShowModalAutomationCampaign] =
    useState(false)
  const [currentRow, setCurrentRow] = useState(null)

  const columns = [
    { label: 'Cod', field: 'id' },
    { label: 'Name', field: 'name' },
    { label: 'Valor', field: 'valor' },
    { label: 'Status', field: 'status' },
    { label: 'Connected', field: 'connected' }
  ]

  const [tableConfig, setTableConfig] = useState({
    options: {
      edit: true,
      pagination: false,
      linkInstance: false,
      more: true,
      add: true,
      collapse: true
    },
    config: {
      editText: 'Editar instância',
      addText: 'Adicionar campanha de automação'
    },
    total: 0,
    currentPage: 1,
    rowsPerPage: 10
  })

  const handleEdit = (row: Instance) => {
    console.log({ row })
    setCurrentRow(row)
    setShowModalUpdateInstance(true)
  }

  const handleAddAutomationCampaign = (row: Instance) => {
    console.log({ row })
    setCurrentRow(row)
    setShowModalAutomationCampaign(true)
  }

  const onCloseModalInstance = () => {
    setCurrentRow(null)
    setShowModalUpdateInstance(false)
  }

  const onCloseModalAddAutomationCampaign = () => {
    setCurrentRow(null)
    setShowModalAutomationCampaign(false)
  }

  useEffect(() => {
    if (row?.instancias) {
      const ticketsTemp = row?.instancias.map((instancias) => {
        return {
          ...instancias,
          use_local: instancias.use_local ? 'Sim' : 'Não',
          connected: instancias.connected ? 'Sim' : 'Não',
          status: instancias.status ? 'Ativo' : 'Inativo',
          valor: instancias.valor
            ? formatCurrency({ value: instancias.valor })
            : '0,00'
        }
      })
      setOrderTickets(ticketsTemp)
    }
  }, [row])

  return (
    <Tr>
      <Td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
        <Collapse isOpen={open} timeout="auto" unmountOnExit>
          <DatatableTables
            rows={orderTickets}
            columns={columns}
            tableConfig={tableConfig}
            layoutCollapse={true}
            onEdit={handleEdit}
            onAdd={handleAddAutomationCampaign}
            CollapseComponent={UserInstanceCollapse}
            callback={callback}
          />

          <ModalAutomationCampaign
            onClose={onCloseModalAddAutomationCampaign}
            loadUsers={callback}
            showModal={showModalAutomationCampaign}
            instanceId={currentRow?.id}
          />
        </Collapse>
      </Td>
    </Tr>
  )
}
