import React from 'react'
import { useDropzone } from 'react-dropzone'
import './styles.scss'
import { Container, Label } from 'reactstrap'

export function FileUpload({
  text,
  suggestion,
  onDrop,
  maxSize = 500000,
  accept = ['image/jpeg', 'image/png']
}) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize,
    multiple: false,
    accept
  })

  return (
    <>
      <Container
        {...getRootProps({
          className: `customDropzone`
        })}
      >
        <input {...getInputProps()} />
        <Label dangerouslySetInnerHTML={{__html: text}} />
        <em dangerouslySetInnerHTML={{__html: suggestion}} />
      </Container>
    </>
  )
}
