/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label
} from 'reactstrap'
import {
  Accordion,
  Tab,
  Tabs
} from 'react-bootstrap'
import { toast } from 'react-toastify'

import { Loading } from '../../components/Loading'
///MODAIS
import './styles.css'
import Services from '../../services/sendMessage'

import 'chartist/dist/scss/chartist.scss'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { SelectComponent } from '../../components/selectComponent'
import SwitchWithLabel from '../../components/Switch'
import { TextArea } from '../../components/TextArea'
import Swal from 'sweetalert2'

import { BotService } from '../../services/bot'

registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Cod', field: 'id' },
  { label: 'Name', field: 'name' },
  { label: 'Quantidade de Contatos', field: 'numeroDeContatos' },
  { label: 'Criado Em', field: 'created_at' }
]

const Bot = () => {
  const [loading, setLoading] = useState(false)
  const [instanciasList, setInstanciasList] = useState([])
  const [rendered, setRendered] = useState(false)
  const [checked, setChecked] = useState(false)
  const [fullName, setFullName] = useState(false)
  const [dados, setDados] = useState({})
  const [message, setMessage] = useState('')

  const handleClose = () => {
    setLoading(false)
  }
  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      pagination: true,
      delete: true,
      more: true,
      uploadGrupo: true
    },
    config: {
      deleteText: 'Deletar grupo'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null,
      sellerId: null
    },
    total: 0,
    currentPage: 0,
    userId: null,
    rowsPerPage: 20
  })

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }

    if (rendered) {
      loadInstancias()
    }
  }, [rendered])


  const loadInstancias = async () => {
    const response = await Services.loadInstancias()

    console.log(response)

    if (response.status === 200) {
      const data = []
      response?.data?.data?.data.map((item) => {
        data.push({
          ...item,
          label: item.name,
          value: item.id
        })
      })

      setInstanciasList(data)
    } else {
      toast.error(response?.msg)
      return false
    }
  }

  const handlerInstancia = (value) => {
    if (value?.active) {
      setTableConfig((prev) => ({
        ...prev,
        userId: value?.value
      }))
  
      getFluxoCadastro(value)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Ops..',
        text: 'Essa instância está desativada por falta de pagamento',
        cancelButtonText: 'Fechar',
        allowEscapeKey: false,
        allowOutsideClick: false
      })
    }
    
  }

  const getFluxoCadastro = async (value) => {
    setLoading(true)
    console.log(value)
    const result = await BotService.getFluxoCadastro({
      instance_id: value?.id
    })


    if (result.status === 200) {
      setChecked(result?.data?.data?.status)
      setDados(result?.data?.data)
      setMessage(result?.data?.data?.gift)
      setFullName(result?.data?.data?.fullName)
    } else {
      toast.error('Falha ao carregar fluxo de cadastro')
    }

    setLoading(false)

  }

  const handlerDados = (value, type) => {
    setDados((prev) => ({
      ...prev,
      [type]: value
    }))
  }

  const handlerSave = async () => {
  
    setLoading(true)
    const result = await BotService.updateFluxoCadastro({
      ...dados,
      instance_id: tableConfig.userId,
      gift: message,
      fullName
    })


    if (result.status === 200) {
      toast.success(result?.data?.msg)
    } else {
      toast.error('Error ao atualizar fluxo de cadastro')
    }

    setLoading(false)
      
  }


  const handlerChecked = async () => {

    
    setChecked(!checked)
    setLoading(true)
    const result = await BotService.updateStatusFluxoCadastro({
      status: !checked,
      instance_id: tableConfig.userId
    })


    if (result.status === 200) {
      toast.success(result?.data?.msg)
    } else {
      toast.error('Falha ao atualizar fluxo de cadastro')
    }
      
    setLoading(false)

  }


  const handleAddVariable = (variable) => {
    const cursorPosition =
      document.getElementById('message-text-area')?.selectionStart
    const newMessage = `${message.substring(
      0,
      cursorPosition
    )} ${variable} ${message.substring(cursorPosition)}`

    setMessage(newMessage)
  }


  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <div className="page-content">
        <MetaTags>
          <title>Bot | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Bot | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Bem vindo a gestão de Bot</li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row style={{marginTop: 20}}>
            <Col xl={6} md={6} xs={12}>
                <SelectComponent
                  options={instanciasList}
                  value={
                    instanciasList.find((a) => a.value === tableConfig.userId)
                      ? instanciasList.find((a) => a.value === tableConfig.userId)
                      : null
                  }
                  onChange={(value) => handlerInstancia(value)}
                  placeholder="Instância"
                />
            </Col>
          </Row>
         
          <Col style={{marginTop: 20, backgroundColor: 'white', padding: 20, borderRadius: 10}}>
           
            <Tabs
              defaultActiveKey="fluxo"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="fluxo" title="Fluxo de Cadastro">
                <Row  style={{marginTop: 20}}>
                  <SwitchWithLabel
                    onChange={() => {
                     handlerChecked()
                    }}
                    checked={checked}
                    label="Ativar Fluxo de Cadastro"
                  />
                  <ul className='legenda-bot'>
                    <li>Ao ativar o fluxo de cadastro e preencher algum dos campos abaixo, todos os clientes que enviarem mensagem para o numero selecionado receberá automaticamente as mensagens para preencher seus dados e serão salvos em uma lista no menu "Clientes"</li>
                    <li>Nos campos abaixo você deve escrever a frase que deseja para solicitar os respectivos campos</li>
                    <li>A recompensa pode ser um link de promoção, cupom de desconto, algo que será enviado quando o usuario finalizar o cadastro</li>
                  </ul>
                </Row>
                <Row  style={{marginTop: 10}}>
                  <Col xl={8} md={8} xs={8}>
                    <FormGroup>
                      <Label for="exampleEmail">Nome</Label>
                      <div className="file-uploader">
                        <Input
                          type="textarea"
                          label="Nome"
                          placeholder="Qual o seu nome completo?"
                          value={dados.name}
                          onChange={(e) =>
                            handlerDados(e.target.value, 'name')
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xl={4} md={4} xs={4} style={{marginTop: 40}}>
                    <SwitchWithLabel
                      onChange={() => {
                        setFullName(!fullName)
                      }}
                      checked={fullName}
                      label="Somente Nome Completo"
                    />
                    </Col>
                  <Col xl={6} md={6} xs={12}>
                    <FormGroup>
                      <Label for="exampleEmail">E-mail</Label>
                      <div className="file-uploader">
                        <Input
                          type="textarea"
                          label="E-mail"
                          placeholder="Qual o seu E-mail?"
                          value={dados.email}
                          onChange={(e) =>
                            handlerDados(e.target.value, 'email')
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xl={6} md={6} xs={12}>
                    <FormGroup>
                      <Label for="exampleEmail">CPF</Label>
                      <div className="file-uploader">
                        <Input
                          type="textarea"
                          value={dados.cpf}
                          label="CPF"
                          placeholder="Qual o seu CPF?"
                          onChange={(e) =>
                            handlerDados(e.target.value, 'cpf')
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xl={6} md={6} xs={12}>
                    <FormGroup>
                      <Label for="exampleEmail">Genero</Label>
                      <div className="file-uploader">
                        <Input
                          type="textarea"
                          label="Genero"
                          placeholder="Qual o seu Sexo?"
                          value={dados.genero}
                          onChange={(e) =>
                            handlerDados(e.target.value, 'genero')
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xl={6} md={6} xs={12}>
                    <FormGroup>
                      <Label for="exampleEmail">Palavra Inicial</Label>
                      <div className="file-uploader">
                        <Input
                          type="text"
                          value={dados.initial}
                          label="Palavra Inicial"
                          placeholder="Caso deseje, coloque uma palavra de ativação"
                          onChange={(e) =>
                            handlerDados(e.target.value, 'initial')
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xl={12} md={12} xs={12}>
                  <FormGroup>
                      <TextArea
                         value={message}
                         title='Recompensa'
                         setValue={setMessage}
                         onAddVariable={handleAddVariable}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginBottom: 50, marginTop: 20}}> 
                <Col xl={6} md={6} xs={12}>
                </Col>
                <Col xl={6} md={6} xs={12}  style={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}>
                  <Button variant="outlined"
                    onClick={() => handlerSave()}
                    className="newBg"> 
                    Salvar Mudanças
                  </Button>
                </Col>
                </Row>
              </Tab>
              {/* <Tab eventKey="bot" title="Bot de Atendimento">
                <BotEditor 
                id={tableConfig.userId}
                />
              </Tab> */}
            </Tabs>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

Bot.propTypes = {
  t: PropTypes.any
}

export default Bot
