import { api } from './axios'
import {
  ApiResponse,
  FilterLogsProps,
  LogsProps,
  PaginationProps
} from 'types/global'

export default {
  getLogs: async ({
    rowsPerPage,
    page,
    errorFile,
    errorFunction,
    startDate,
    endDate,
    searchTerm,
    errorType
  }): Promise<ApiResponse<PaginationProps<LogsProps>>> => {
    const response = await api
      .get('/logs', {
        params: {
          rowsPerPage,
          page,
          errorFile,
          errorFunction,
          startDate,
          endDate,
          searchTerm,
          type: errorType
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getLogsFilters: async (): Promise<ApiResponse<FilterLogsProps>> => {
    const response = await api
      .get('/logs/filters')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  }
}
