export const momentOptions = ['now', 'after'] as const

export const messageTypeOptions = [
  'texto',
  'imagem',
  'video-arquivo',
  'audio-arquivo',
  'arquivo',
  'link'
] as const

export enum STATUS_OPTIONS {
  DISCONNCECTED = 0,
  CONNECTED = 1,
  // 2,
  PAST_DUE = 3
}
