/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { withRouter, Link } from 'react-router-dom'


import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Input,
  FormGroup,
  Label
} from 'reactstrap'

import { Loading } from '../../components/Loading'
import Select from 'react-select'
import Services from '../../services/sendMessage'
import { useUserInfo } from 'context/userContext'


const Page = () => {
  const { isRoot, userPlan } = useUserInfo();

  const [rendered, setRendered] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])

  const handleClose = () => {
    setLoading(false)
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }
    if (rendered) {
      getLanding();
    }
  }, [rendered])

  const getLanding = async () => {
    setLoading(true)
    try {
      const response = await Services.getLanding();
      console.log("------------------")
      console.log(response)
      if (response.status === 200) {
        setRows(response?.data?.data)
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }


  const addLandingPage = async () => {

  }


  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <div className="page-content">
        <MetaTags>
          <title>Zap Page | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
              <br></br>
                <h6 className="page-title">Zap Page | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo ao Zap Page
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Card style={{ padding: 20, paddingBottom: 150 }}>
            <Row>
                    <Col
                      xl={12}
                      md={12}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => addLandingPage()}
                        style={{ 
                          backgroundColor: '#0D071B',
                          marginBottom: '-50px',
                          marginRight: 20, 
                          marginTop: 20,
                          cursor: 'pointer',
                          zIndex: 200 
                      }}
                      >
                        Criar Landing Page
                      </Button>
                    </Col>
                  </Row>
              <Row style={{
                marginTop: 50,
              }}>
                {
                  rows.map((row, index) => {
                    return(
                      <Col xl={6} xs={12} style={{ padding: 20 }}>
                         <Link to={"/page/"+row?.slug}
                          className="waves-effect"
                          style={{
                            width: '100%',
                          }}>
                        <Card style={{ 
                          padding: 20,
                           borderRadius: 20,
                           cursor: 'pointer',
                           width: '100%',
                           minHeight: 700
                           }}
                           >
                          <Row>
                          <Col xl={9} md={9}>
                            <Col xl={12} md={12}>
                              <h3
                              style={{
                                fontSize: 30,
                                fontWeight: 'bold',
                                color: '#0D071B'
                              }}
                              >{row?.name}</h3>
                            </Col>
                            <Col xl={12} md={12}>
                              <p>{`https://page.zapmais.app/${row?.modelo}/${row?.slug}`}</p>
                            </Col>
                            </Col>
                            <Col xl={3} md={3}>
                              <i style={{
                                fontSize: 50,
                              }} className='mdi mdi-cellphone-cog'  ></i>
                            </Col>
                            </Row>
                        </Card>
                        </Link>
                      </Col>
                    )
                  })
                }
                </Row>

             
        
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Page.propTypes = {
  t: PropTypes.any
}

export default Page
