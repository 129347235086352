/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card } from 'reactstrap'
import { toast } from 'react-toastify'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
///MODAIS
import Select from 'react-select'

// import images
import userServices from '../../services/user'
import './styles.scss'

import 'chartist/dist/scss/chartist.scss'
import { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import plansService from 'services/plans'
import { Button, Form, FormGroup } from 'react-bootstrap'
import { ModalAddCustomPlan } from 'components/modalAddCustomPlan'
import SwitchWithLabel from 'components/Switch'
import { formatCurrency } from 'util/string'
import { ButtonComponent } from 'components/buttonComponent'

registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Nome personalizado', field: 'name' },
  { label: 'Preço', field: 'price' },
  { label: 'Plano', field: 'relatedPlanName' },
  { label: 'Usuário', field: 'userName' },
  { label: 'Status', field: 'status' }
]

export const CustomPlans = () => {
  const { listCustomPlans, selectPlans, updateCustomPlanStatus } = plansService
  const { selectUsers } = userServices
  const [rows, setRows] = useState([])
  const [plans, setPlans] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [rendered, setRendered] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const [showModalAddUser, setShowModalAddUser] = useState(false)

  const [tableConfig, setTableConfig] = useState({
    options: {
      more: true,
      pagination: true,
      switch: true
    },
    config: {
      switchTextInactive: 'Desativar plano',
      switchTextActive: 'Ativar plano'
    },
    filters: {
      planId: null,
      userId: null,
      active: true
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const loadCustomPlans = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await listCustomPlans({
        rowsPerPage: tableConfig.rowsPerPage,
        page: tableConfig.currentPage,
        planId: tableConfig.filters.planId,
        userId: tableConfig.filters.userId,
        active: tableConfig.filters.active ? tableConfig.filters.active : null
      })

      if (response.status === 200) {
        const responseData = response?.data?.data
        const total = responseData?.total
        const formattedData = responseData.items.map((item) => ({
          ...item,
          status: item.active === 1 ? 'Ativo' : 'Desativado',
          price: formatCurrency({ value: item.price })
        }))
        console.log({ formattedData })

        setRows(formattedData)
        setTableConfig({
          ...tableConfig,
          total: total
        })
        setLoading(false)
      } else {
        toast.error(response?.response?.data?.msg)
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  const loadPlans = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await selectPlans()

      if (response.status === 200) {
        setPlans(response.data.data)
        setLoading(false)
      } else {
        toast.error(response)
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [])

  const loadUsers = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await selectUsers()

      if (response.status === 200) {
        setUsers(response.data.data)
        setLoading(false)
      } else {
        toast.error(response)
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [])

  const handleSwitch = (row) => {
    updateStatus(row.id, row.active)
  }

  const updateStatus = async (id: number, currentStatus: boolean) => {
    try {
      setLoading(true)
      const response = await updateCustomPlanStatus({
        id,
        active: currentStatus ? 0 : 1
      })

      if (response.status === 200) {
        toast.success(response.data.msg || 'Status atualizado')
        loadCustomPlans()
      } else {
        toast.error(
          response.response?.data?.msg || 'Não foi possível atualizar'
        )
      }
      setLoading(false)
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }
  const onCloseModalAddUser = () => {
    setCurrentRow(null)
    setShowModalAddUser(false)
  }

  const handleClearFilters = () => {
    setTableConfig({
      ...tableConfig,
      filters: {
        planId: null,
        userId: null,
        active: true
      }
    })
  }

  const addUser = () => {
    setShowModalAddUser(true)
  }

  const addSubscription = () => {
    window.location.href = `${process.env.REACT_APP_SITE_URL}criar-assinatura`
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    } else {
      loadCustomPlans()
    }
  }, [
    tableConfig.rowsPerPage,
    tableConfig.currentPage,
    tableConfig.filters,
    rendered
  ])

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    } else {
      loadPlans()
      loadUsers()
    }
  }, [rendered])

  return (
    <div className="plansPageContainer">
      {loading && <Loading open={loading} />}

      <div className="page-content">
        <MetaTags>
          <title>Planos personalizados | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de planos personalizados
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Card>
              <Row>
                <Col xl={12} md={12} style={{ padding: 20 }}>
                  <Row className="mb-4">
                    <Col xs={12} md={3}>
                      <FormGroup>
                        <Form.Label for="exampleEmail">Usuário</Form.Label>
                        <Select
                          value={
                            users.find(
                              (a) => a.value === tableConfig.filters.userId
                            )
                              ? users.find(
                                  (a) => a.value === tableConfig.filters.userId
                                )
                              : null
                          }
                          onChange={(value) => {
                            setTableConfig({
                              ...tableConfig,
                              filters: {
                                ...tableConfig.filters,
                                userId: value.value
                              }
                            })
                          }}
                          options={users}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>

                    <Col xs={12} md={3}>
                      <FormGroup>
                        <Form.Label for="exampleEmail">Plano</Form.Label>
                        <Select
                          value={
                            plans.find(
                              (a) => a.value === tableConfig.filters.planId
                            )
                              ? plans.find(
                                  (a) => a.value === tableConfig.filters.planId
                                )
                              : null
                          }
                          onChange={(value) => {
                            setTableConfig({
                              ...tableConfig,
                              filters: {
                                ...tableConfig.filters,
                                planId: value.value
                              }
                            })
                          }}
                          options={plans}
                          placeholder="Selecione..."
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={2} className="mt-auto">
                      <SwitchWithLabel
                        label="Ativos"
                        onChange={() => {
                          setTableConfig({
                            ...tableConfig,
                            filters: {
                              ...tableConfig.filters,
                              active: !tableConfig.filters.active
                            }
                          })
                        }}
                        checked={tableConfig.filters.active}
                      />
                    </Col>
                    <Col xs={12} md={3} className="d-flex">
                      <ButtonComponent
                        text="Limpar filtros"
                        variant="secondary"
                        onClick={handleClearFilters}
                        extraClass="mt-auto"
                        applyPadding={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      xl={12}
                      md={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 20
                      }}
                    >
                      <ButtonComponent
                        text="+ assinatura personalizada"
                        variant="primary"
                        onClick={() => addSubscription()}
                        extraClass="mt-auto"
                        applyPadding={false}
                      />

                      <ButtonComponent
                        text="+ plano personalizado"
                        variant="success"
                        onClick={() => addUser()}
                        extraClass="mt-auto"
                        applyPadding={false}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <DatatableTables
                title={'Planos personalizados'}
                columns={columns}
                rows={rows}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                onSwitch={handleSwitch}
              />
            </Card>
          </Row>
        </Container>
      </div>
      <ModalAddCustomPlan
        onClose={onCloseModalAddUser}
        callback={loadCustomPlans}
        showModal={showModalAddUser}
      />
    </div>
  )
}
