import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import services from '../../services/instance'
import Select from 'react-select'
import './styles.scss'

import { Loading } from '../Loading'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { listType } from 'util/mocks'

interface IModalAutomationCampaignProps {
  showModal: boolean
  onClose: () => void
  loadUsers: () => Promise<void>
  instanceId?: number | null
  campaign?: any
  isUser?: boolean
}

interface IErrorMessage {
  name: string | null
  type: string | null
}

interface ICampaign {
  name: string | null
  type: string | null
  active: boolean
  daysInactive: number[]
  errorMessage: IErrorMessage
}

const daysWeek = [
  { value: 0, label: 'Domingo' },
  { value: 1, label: 'Segunda' },
  { value: 2, label: 'Terça' },
  { value: 3, label: 'Quarta' },
  { value: 4, label: 'Quinta' },
  { value: 5, label: 'Sexta' },
  { value: 6, label: 'Sábado' }
]

export const ModalAutomationCampaign = ({
  showModal,
  onClose,
  loadUsers,
  instanceId,
  campaign,
  isUser = false
}: IModalAutomationCampaignProps) => {
  const [loading, setLoading] = useState(false)
  const [dados, setDados] = useState<ICampaign>({
    name: null,
    type: null,
    active: true,
    daysInactive: [],
    errorMessage: {
      name: null,
      type: null
    }
  })

  const handleSubmitNewCampaign = async () => {
    try {
      if (!dados.name || !dados.type) {
        toast.error('Preencha todos os campos')
        const newDados = { ...dados }

        if (!dados.name) {
          newDados.errorMessage.name = 'Preencha com um nome para a campanha'
        }

        if (!dados.type) {
          newDados.errorMessage.type =
            'Preencha com o tipo de mensagem que será enviado'
        }
        console.log({ newDados })

        setDados(newDados)
        return false
      }

      setLoading(true)
      let response = null
      if (campaign) {
        const payload = {
          ...dados,
          instanceId,
          campaignId: campaign.id
        }
        response = await services.updateAutomationCampaign(payload)
      } else {
        const payload = {
          ...dados,
          instanceId
        }
        response = await services.saveAutomationCampaign(payload)
      }

      if (response.status === 200) {
        toast.success(
          response.data?.msg ? response.data?.msg : 'Instância salva'
        )
        onClose()
        loadUsers()
        setLoading(false)
      } else {
        toast.error(response?.msg || 'Ocorreu um erro ao tentar salvar')
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const handleCancelNewInstance = () => {
    onClose()
  }

  const handlerDados = (key: string, value: string | number | boolean) => {
    setDados((prev) => ({
      ...prev,
      errorMessage: {
        ...prev.errorMessage,
        [key]: null
      },
      [key]: value
    }))
  }

  useEffect(() => {
    console.log({ campaign })

    if (campaign && showModal) {
      setDados({
        ...dados,
        name: campaign.name || null,
        type: campaign.type || null,
        active: campaign.active ? true : false,
        daysInactive: campaign.days_inactive
          ? JSON.parse(campaign.days_inactive)
          : []
      })
    }
  }, [campaign, showModal])

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="containerModalAutomationCampaing reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container className="containerModalAddInstance">
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>
                {instanceId ? 'Atualizar campanha' : 'Criar nova campanha'}
              </h1>
            </Col>
          </Row>
          <Row style={{ margin: 10, rowGap: 15 }}>
            {!isUser && (
              <>
                <Col xs={12} sm={6}>
                  <FormGroup>
                    <Form.Label>Nome*</Form.Label>
                    <Form.Control
                      value={dados.name}
                      onChange={(event) =>
                        handlerDados('name', event.target.value)
                      }
                      isInvalid={!!dados?.errorMessage?.name}
                    />

                    <Form.Control.Feedback type="invalid">
                      {dados?.errorMessage?.name}
                    </Form.Control.Feedback>
                  </FormGroup>
                </Col>
                <Col xs={12} sm={6}>
                  <FormGroup>
                    <Form.Label>Tipo de mensagem*</Form.Label>
                    <Select
                      value={
                        listType.find((a) => a.value === dados.type)
                          ? listType.find((a) => a.value === dados.type)
                          : null
                      }
                      options={listType}
                      classNamePrefix="select2-selection"
                      placeholder="Selecione..."
                      onChange={(value) => {
                        console.log({ value })

                        handlerDados('type', value?.value)
                      }}
                    />
                    {!!dados.errorMessage.type && (
                      <>
                        <span className="is-invalid"></span>
                        <p className="invalid-feedback">
                          {dados.errorMessage.type}
                        </p>
                      </>
                    )}
                  </FormGroup>
                </Col>
              </>
            )}
            <Col xs={12} sm={6}>
              <FormGroup>
                <Form.Label>Dias inativo*</Form.Label>
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  options={daysWeek}
                  value={daysWeek.filter((item) =>
                    dados.daysInactive.includes(item.value)
                  )}
                  onChange={(value) => {
                    console.log({ value })
                    handlerDados(
                      'daysInactive',
                      value?.length > 0 ? value.map((item) => item.value) : []
                    )
                  }}
                  placeholder="Selecione..."
                  className={!!dados.errorMessage.type ? 'select-error' : ''}
                />
              </FormGroup>
            </Col>
            <Col xs={12} md={3}>
              <Form.Group>
                <Form.Check
                  checked={dados.active}
                  label="Ativo"
                  onChange={(event) =>
                    handlerDados('active', event.target.checked)
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="container-buttons">
            <Button onClick={handleCancelNewInstance} variant="secondary">
              Fechar
            </Button>

            <Button onClick={handleSubmitNewCampaign} variant="primary">
              Salvar
            </Button>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
