import { useEffect, useState } from 'react'
import Swal, { SweetAlertResult } from 'sweetalert2'
import services from '../services/sendMessage'
import { SubmitErrorHandler, UseFormReturn } from 'react-hook-form'
import { EnvioSimplificadoSchema } from 'pages/EnvioSimplificado/envioSimplificado.schema'
import { STATUS_OPTIONS } from 'types/envio'
import { formatToBrazillianDateDayMonth } from 'util/function'
import { ContactSchema } from 'components/modalAddContacts/addContact.schema'
import { onlyUnsignedNumbers } from 'util/string'

export const useDisparador = (form: UseFormReturn<EnvioSimplificadoSchema>) => {
  const [loading, setLoading] = useState(false)
  const [listInstances, setListInstances] = useState([])
  const [statusInstancia, setStatusInstancia] = useState<STATUS_OPTIONS>(
    STATUS_OPTIONS.DISCONNCECTED
  )
  const [zapMais, setZapMais] = useState(false)
  const [instanceName, setInstanceName] = useState(null)
  const [openModalQrcode, setOpenModalQrcode] = useState(false)
  const [instanceStateChecked, setInstanceStateChecked] = useState(false)
  const [rendered, setRendered] = useState(false)
  const [zapiData, setZapiData] = useState({
    id: null,
    token: null
  })
  const [table, setTable] = useState<ContactSchema[]>([])

  const watchInstanceId = form.watch('instanceId')

  const loadInstance = async () => {
    const result = await services.getInstance()
    if (result.status === 200) {
      const info = []
      result.data.data.items.map((item) => {
        info.push({
          label: item.name,
          value: item.id,
          tokenZapi: item.token_zapi,
          idZapi: item.id_zapi,
          useLocal: item.use_local
        })
      })
      setListInstances(info)
    }
  }

  const checkInstance = async () => {
    setLoading(true)
    const result = await services.CheckInstance({
      idInstancia: watchInstanceId
    })
    setLoading(false)
    setInstanceStateChecked(true)
    const instance = listInstances.find(
      (item) => item.value === watchInstanceId
    )
    if (instance.useLocal) {
      setZapMais(true)
    } else {
      setZapiData({
        id: instance.idZapi,
        token: instance.tokenZapi
      })
    }

    function handleConfirmation(result: SweetAlertResult) {
      if (result.isConfirmed) {
        setOpenModalQrcode(true)
      } else {
        form.setValue('instanceId', null)
      }
    }

    if (result.status === 200) {
      const data = result.data

      if (data.status === 200) {
        localStorage.setItem("privacy_and_terms", data.privacy_and_terms)

        if (data?.active) {
          setStatusInstancia(STATUS_OPTIONS.CONNECTED)
          setInstanceStateChecked(true)
        } else {
          setStatusInstancia(STATUS_OPTIONS.PAST_DUE)
          Swal.fire({
            icon: 'error',
            title: 'Ops..',
            text: 'Essa instância está desativada por falta de pagamento',
            cancelButtonText: 'Fechar',
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false
          })
        }
      } else {
        setStatusInstancia(STATUS_OPTIONS.DISCONNCECTED)
        Swal.fire({
          icon: 'error',
          title: 'Ops..',
          text: data?.msg
            ? data?.msg
            : 'Seu Whatsapp está desconectado. Não Envie essa campanha antes de conectar',
          confirmButtonText: 'Conectar',
          cancelButtonText: 'Fechar',
          showCancelButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(handleConfirmation)
      }
    } else {
      setStatusInstancia(STATUS_OPTIONS.DISCONNCECTED)
      Swal.fire({
        icon: 'error',
        title: 'Ops..',
        text: 'Seu Whatsapp está desconectado. Não Envie essa campanha antes de conectar',
        confirmButtonText: 'Conectar',
        cancelButtonText: 'Fechar',
        showCancelButton: true
      }).then(handleConfirmation)
    }
  }

  function onCloseModalQrcode() {
    setOpenModalQrcode(false)
    setInstanceName(null)
  }

  function onCloseOnSuccessModalQrcode() {
    setOpenModalQrcode(false)
    setStatusInstancia(STATUS_OPTIONS.CONNECTED)
  }

  const handleFileSelected = (acceptedFiles: FileList) => {
    form.setValue('file', acceptedFiles[0])
  }

  const handleAddVariable = (variable: string) => {
    const message = form.getValues('message')
    const cursorPosition =
      //@ts-ignore
      document.getElementById('message-text-area')?.selectionStart
    const newMessage = `${message.substring(
      0,
      cursorPosition
    )} ${variable} ${message.substring(cursorPosition)}`
    console.log({ newMessage })

    form.setValue('message', newMessage)
  }

  const onError: SubmitErrorHandler<EnvioSimplificadoSchema> = (errors) => {
    console.log({ errors })
    const message = ['<ul>']
    Object.keys(errors).forEach((item) =>
      message.push(
        `<li class="text-start fw-bold">${errors[item].message}</li>`
      )
    )
    message.push('</ul>')

    Swal.fire({
      icon: 'error',
      title: 'Dados incompletos',
      html: message.join(''),
      confirmButtonText: 'Ok'
    })
  }

  const onSubmit = async (data: EnvioSimplificadoSchema) => {
    console.log({ data })

    const tabelaFormatted = data.table.map((item) => ({
      name: item.name,
      fone: onlyUnsignedNumbers(item.phone)
    }))

    setLoading(true)

    const formData = new FormData()

    const date = new Date().toISOString()
    const description = `Envio simplificado ${formatToBrazillianDateDayMonth(
      date
    )}`

    formData.append('campanha', data.file || '')
    formData.append('tabela', JSON.stringify(tabelaFormatted))
    formData.append('type', data.type)
    formData.append('user', data.instanceId.toString())
    formData.append('descricao', description)
    formData.append('momento', data.moment)
    formData.append('horaEnvio', data.scheduleTime?.toISOString() || '')
    formData.append('message', data.message)
    formData.append('nomeDoArquivo', data.fileName || '')
    formData.append('descricaoLink', data.link || '')
    formData.append('tituloLink', data.link || '')
    formData.append('linkUrl', data.link || '')
    formData.append('zapMais', zapMais.toString())

    const result = await services.sendMessage({ formData })

    console.log('============')
    console.log(result)

    if (result.status === 200) {
      setLoading(false)
      Swal.fire({
        icon: 'success',
        title: 'Enviada',
        text: 'Campanha Enviada com Sucesso',
        confirmButtonText: 'Fechar'
      }).then(() => {
        form.reset()
        setTable([])
      })
      return true
    } else {
      setLoading(false)
      Swal.fire({
        icon: 'error',
        title: 'Ops..',
        text: 'Falha no Envio da Campanha',
        confirmButtonText: 'Fechar'
      })
      return false
    }
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }
    if (rendered) {
      loadInstance()
    }
  }, [rendered])

  useEffect(() => {
    if (watchInstanceId) {
      checkInstance()
    }
  }, [watchInstanceId])

  return {
    loading,
    statusInstancia,
    openModalQrcode,
    zapMais,
    instanceName,
    instanceStateChecked,
    zapiData,
    listInstances,
    rendered,
    table,
    onCloseModalQrcode,
    onCloseOnSuccessModalQrcode,
    handleFileSelected,
    handleAddVariable,
    onError,
    onSubmit,
    setTable
  }
}
