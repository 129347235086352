import React from 'react'
import { Form, FormGroup } from 'react-bootstrap'
import Select from 'react-select'

interface Option {
  value: string | number
  label: string
}

export interface SelectComponentProps {
  value: string | number
  onChange: (value: Option) => void
  options: Option[]
  placeholder: string
  label?: string
}

export const SelectComponent = ({
  label = null,
  value,
  onChange,
  options,
  placeholder,
  isMulti = false // Adiciona a capacidade de seleção múltipla
}) => {
  const inputId = `input-${Math.random().toString(36).substring(7)}`

  return (
    <FormGroup>
      {label && <Form.Label htmlFor={inputId}>{label}</Form.Label>}
      <Select
        inputId={inputId}
        value={value}
        onChange={onChange}
        options={options}
        classNamePrefix="select2-selection"
        placeholder={placeholder}
        isMulti={isMulti} // Habilita a seleção múltipla
      />
    </FormGroup>
  )
}
