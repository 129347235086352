import { api } from './axios'

const Services = {
  saveUser: async ({
    name,
    cnpj = null,
    responsavel = null,
    email,
    password,
    phone = null,
    plano
  }) => {
    const response = await api
      .post('/signup', {
        name,
        cnpj,
        responsavel,
        email,
        password,
        phone,
        plano
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  updateUser: async ({
    id,
    name,
    cnpj = null,
    responsavel = null,
    email,
    phone = null,
    plano
  }) => {
    const response = await api
      .put(`/user/${id}`, {
        name,
        cnpj,
        responsavel,
        email,
        phone,
        plano
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  updatePassword: async ({ userId, rootPassword, password }) => {
    const response = await api
      .post('/root-update-password', {
        userId,
        rootPassword,
        password
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  uploadGrupo: async ({ tabela, idGrupo }) => {
    const response = await api
      .put(`/group/${idGrupo}`, {
        tabela
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  cancell: async ({ instance }) => {
    const response = await api
      .get('/pagarme/cancelar-plano', {
        params: {
          instance
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  changePlan: async ({ newPlano }) => {
    const response = await api
      .get('/pagarme/change-plano', {
        params: {
          newPlano
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  payment: async (payload) => {
    const response = await api
      .get('/pagarme/assinar-plano', {
        params: {
          ...payload
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  saveGrupo: async ({ name, instanceId }) => {
    const response = await api
      .post('/group', {
        name,
        instanceId
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  saveInstance: async ({ name, subscriptionId, userId }) => {
    const response = await api
      .post(`/user/${userId}/save-instance`, {
        name,
        subscriptionId
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  updateInstance: async ({ name, instanceId }) => {
    const response = await api
      .put(`/instance/${instanceId}`, {
        name
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getQrCode: async ({ instanceId, instanceToken }) => {
    const response = await api
      .get(
        `https://api.z-api.io/instances/${instanceId}/token/${instanceToken}/qr-code/image`
      )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getStatusInstance: async ({ instanceId }) => {
    const response = await api
      .get(`/get-status/${instanceId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getQrCodeZapmais: async ({ instanceId }) => {
    const response = await api
      .get(`/get-qrcode/${instanceId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getDeviceData: async ({ instanceId, instanceToken }) => {
    const response = await api
      .get(
        `https://api.z-api.io/instances/${instanceId}/token/${instanceToken}/device`
      )
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getInstance: async () => {
    const response = await api
      .get('/instance/list')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  startInstance: async ({ instanceId }) => {
    const response = await api
      .get(`/start/${instanceId}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  selectUsers: async () => {
    console.log()
    const response = await api
      .get('/users/select')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  }
}
export default Services
