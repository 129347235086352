import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import Services from "../../services/user"
import './styles.css';
import {
    Container,
    Row,
    Col,
    Button} from "reactstrap"
import { Loading } from '../Loading';
const obj = JSON.parse(localStorage.getItem('authUser'))


export const ModalMudarPlano = ({ showModal, onClose, newPlano }) => {

    const [loading, setLoading] = useState(false);


    const handleClose = () => {
        setLoading(false);
    }

    const handleSubmitSendQrcodes = async () => {
        setLoading(true);
        const result = await Services.changePlan({newPlano})
         if (result.status === 200) {
            setLoading(false);
            toast.success("Plano alterado com sucesso")
            window.localStorage.setItem(
                'authUser',
                JSON.stringify({
                  accessToken: obj?.accessToken,
                  root: obj?.root,
                  displayName: obj?.displayName,
                  email: obj?.email,
                  uid: obj?.uid,
                  username: obj?.username,
                  plano: newPlano === 1 ? 'BASIC' : 
                    newPlano === 2 ? 'STANDARD' : 
                        newPlano === 3 && 'PREMIUM'
                })
              )
            window.location.reload()
            onClose();
         } else {
            setLoading(false);
            console.log(result?.response)
           toast.error(result?.response?.data?.msg)
         }
    }

    const handleCancelSendQrcodes = () => {
        onClose();
    }


    return (
        <ReactModal
            isOpen={showModal}
            contentLabel={`Modal`}
            className="reactModal"
        >
            <Container>
                <Loading open={loading} handleClose={handleClose} />
                    <form>
                        <Row className='modalText'>
                            <Col xs={12}>
                                <h1 className={'titleRoot'}>
                                    Selecionar Novo Plano
                                </h1>
                            </Col>
                        </Row>
                        <Row className='modalText'>
                        <Col xs={12}>
                            <p style={{ fontSize: 20, fontWeight: 500 }}>Seu plano atual é {obj?.plano}</p>
                            <p>Ao confirmar, você estará migrando para o plano {
                                newPlano === 1 ? <b style={{ fontSize: 18, fontWeight: 700 }}>BASIC - R$ 149,90/mês</b> :
                                newPlano === 2 ? <b style={{ fontSize: 18, fontWeight: 700 }}>STANDARD - R$ 199,90/mês</b> :
                                    newPlano === 3 && <b style={{ fontSize: 18, fontWeight: 700 }}>PREMIUM - R$ 299,90/mês</b>
                            }</p>
                            <p>Ao clicar em confirmar, você concorda em cancelar todas as suas instâncias ativas no plano atual e migrar para o novo plano. Em seguida, será necessário efetuar o pagamento do novo plano para reativar as instâncias.</p>
                            </Col>   
                        </Row>
                        <Row className='footerModalButtons' style={{marginTop: 40}}>
                            <Col xs={6}>
                                <Button className='buttonsModal' onClick={handleSubmitSendQrcodes} variant="contained" color="primary">
                                    Confirmar
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button className='buttonsModal' onClick={handleCancelSendQrcodes} variant="contained" color="secondary">
                                    Cancelar
                                </Button>
                            </Col>
                        </Row>
                    </form>
            </Container>
        </ReactModal>
    )
}