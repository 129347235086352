import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import './styles.scss'
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'
import InstanceService from '../../services/instance'
import { Loading } from '../Loading'

export const ModalDeleteCashback = ({ showModal, onClose, id, idCashback, getCashback }) => {
  const [loading, setLoading] = useState(false)

  const handlerCancel = () => {
    onClose()
  }

  const handlerSubmit = async () => {
    setLoading(true)
    const result = await InstanceService.delCashback({
      id: idCashback
    });

    if (result.status === 200) {
      toast.success('Cashback excluido com sucesso!')
      setLoading(false)
      onClose()
      getCashback(id)
    } else {
      setLoading(false)
      toast.error('Erro ao excluir cashback!')
    }
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container style={{minHeight: 250}}>
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Tem certeza?</h1>
            </Col>
          </Row>
          <Row className="footerModalButtons" style={{ marginTop: 40 }}>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handlerSubmit}
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handlerCancel}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
