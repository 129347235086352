import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, FormGroup } from 'reactstrap'

import { Loading } from '../../components/Loading'
import { toast } from 'react-toastify'
import DatatableTables from '../../components/Table'
import { formatCurrency, formatDateSimplesWithHours } from '../../util/string'

import 'chartist/dist/scss/chartist.scss'
import Services from '../../services/sorte'
import ServicesSendMessage from '../../services/sendMessage'

import { PageHeader } from 'components/pageHeader'
import { Card } from 'react-bootstrap'
import { debounce } from 'lodash'
import { InputComponent } from 'components/inputComponent'
import { ButtonComponent } from 'components/buttonComponent'
import { SelectComponent } from 'components/selectComponent'
import { useUserInfo } from 'context/userContext'


const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Data', field: 'created_at' },
  { label: 'Instancia', field: 'instance_id' },
  { label: 'De', field: 'name_instance'},
  { label: 'Para', field: 'to_number' },
  { label: 'Texto', field: 'body' }
]

const SorteMessage = () => {
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [idSelecionado, setIdSelecionado] = useState(false)
  const [userSmsList, setUserSmsList] = useState([])
  const { isRoot, userId, userName } = useUserInfo()

  const [tableConfig, setTableConfig] = useState({
    options: {
      pagination: true
    },
    config: {},
    filters: {
      searchTerm: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const loadIndicators = async () => {
    setLoading(true)
    const response = await Services.loadList({
      rowsPerPage: tableConfig.rowsPerPage,
      page: tableConfig.currentPage,
      searchTerm: tableConfig.filters.searchTerm || null,
      instancia: idSelecionado
    })

    if (response.status === 200) {
      const formatData = response?.data?.data?.items?.map((item) => {
        return {
          ...item,
          created_at: formatDateSimplesWithHours(item?.created_at)
        }
      })
      setRows(formatData)
      setLoading(false)
      setTableConfig({
        ...tableConfig,
        total: response?.data?.data.total
      })
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }

  const handleClose = () => {
    setLoading(false)
  }

  function handleSearch(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        searchTerm: value
      }
    }))
  }

  const handleClearFilters = () => {
    setSearchTerm('')
    setTableConfig({
      ...tableConfig,
      filters: {
        searchTerm: null
      }
    })
  }

  const deboundChange = useCallback(debounce(handleSearch, 1000), [])
  const getUsersSms = async () => {

      const response = await Services.loadUsersSms()
      if (response.status === 200) {
        setUserSmsList(response?.data?.data)
        setIdSelecionado(response?.data?.data[0]?.value)
      } else {
        toast.error(response?.msg)
      }
  }

  useEffect(() => {
    if(idSelecionado) {
      loadIndicators()
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters, idSelecionado])

  useEffect(() => {
    getUsersSms()
  }, [])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <div className="page-content">
        <MetaTags>
          <title>Historico SMS</title>
        </MetaTags>
        <Container fluid>
          <PageHeader subtitle="Historico SMS" />

          <Row>
            <Card>
              <Card.Body>
                <Row className="mb-4">
                <Col xs={6} sm={4} md={4}>
                  <span>Usuarios</span>
                  <SelectComponent
                    options={userSmsList}
                    value={
                      userSmsList.find((a) => a.value === idSelecionado)
                        ? userSmsList.find((a) => a.value === idSelecionado)
                        : null
                    }
                    onChange={(value) => setIdSelecionado(value.value)}
                    placeholder="Usuário"
                  />
                </Col>
                  <Col xs={12} md={3}>
                    <InputComponent
                      label="Pesquisar"
                      onChange={(e) => {
                        setSearchTerm(e.target.value)
                        deboundChange(e.target.value)
                      }}
                      value={searchTerm}
                    />
                  </Col>
                  <Col xs={12} md={3} className="d-flex">
                    <ButtonComponent
                      variant="secondary"
                      text="Limpar filtros"
                      applyPadding={false}
                      onClick={handleClearFilters}
                      extraClass="mt-auto"
                    />
                  </Col>
                </Row>
                <Col xl={12}>
                  <DatatableTables
                    title={'Mensagens'}
                    columns={columns}
                    rows={rows}
                    tableConfig={tableConfig}
                    setTableConfig={setTableConfig}
                  />
                </Col>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

SorteMessage.propTypes = {
  t: PropTypes.any
}

export default SorteMessage
