import React from 'react'
import { ReactElement } from 'react'
import { Button, ButtonProps } from 'react-bootstrap'
import './styles.scss'

export type ButtonComponentProps = {
  text: string
  icon?: ReactElement
  onClick?: () => void
  extraClass?: string
  applyPadding?: boolean
  variant?: string
} & ButtonProps

export const ButtonComponent = ({
  text,
  icon,
  onClick,
  extraClass = '',
  applyPadding = true,
  variant = '',
  ...rest
}: ButtonComponentProps) => (
  <Button
    {...rest}
    {...(variant ? { variant } : {})}
    className={`${!variant && 'zap-mais-button'} ${extraClass} ${
      applyPadding && 'apply-padding'
    }`}
    onClick={onClick}
  >
    {icon && icon} {text}
  </Button>
)
