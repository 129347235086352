import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import Services from '../../services/user'
import './styles.scss'
import { Container, Row, Col, Button, Input, Label } from 'reactstrap'
import { Loading } from '../Loading'

export const ModalGrupo = ({ showModal, onClose, loadGroups, instanceId }) => {
  const [loading, setLoading] = useState(false)
  const [dados, setDados] = useState({
    name: ''
  })

  const handleClose = () => {
    setLoading(false)
  }

  const handleSubmitNewGroup = async () => {
    setLoading(true)
    const payload = {
      instanceId,
      ...dados
    }
    const response = await Services.saveGrupo(payload)
    console.log({ response })

    if (response.status === 200) {
      toast.success(response?.msg ? response?.msg : 'Salvo')
      onClose()
      loadGroups()
      setLoading(false)
    } else {
      toast.error(
        response?.response?.data?.msg || 'Ocorreu um erro ao tentar salvar',
        {
          autoClose: 8000
        }
      )
      setLoading(false)
      return false
    }
  }

  const handleCancelSendQrcodes = () => {
    onClose()
  }

  const handlerDados = (key, value) => {
    setDados((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container>
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Criar novo Grupo</h1>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col xl={12} md={12}>
              <Label>Nome do Grupo</Label>
              <Input
                value={dados.name}
                onChange={(event) => handlerDados('name', event.target.value)}
              />
            </Col>
          </Row>
          <Row className="footerModalButtons" style={{ marginTop: 40 }}>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handleSubmitNewGroup}
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handleCancelSendQrcodes}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
