import { api } from './axios'

const Services = {
  getPrice: async ({ idSelecionado }) => {
    const response = await api
      .get('/sms/get-price', {
        params: {
          idSelecionado
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  loadList: async ({ rowsPerPage, page, searchTerm, instancia }) => {
    const response = await api
      .get('/sms/list-message', {
        params: {
          rowsPerPage,
          page,
          searchTerm,
          instancia
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  loadTransaction: async ({ rowsPerPage, page, checked }) => {
    const response = await api
      .get('/sms/load-transaction', {
        params: {
          rowsPerPage,
          page,
          checked
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  loadUsersSms: async () => {
    const response = await api
      .get('/sms/get-user-sms')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  loadIndicators: async ({ rowsPerPage, page, month, idSelecionado }) => {
    const response = await api
      .get('/sms/list-indicators', {
        params: {
          rowsPerPage,
          page,
          month,
          idSelecionado
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  makePayment: async ({ pacote }) => {
    const response = await api
      .get('/sms/make-payment', {
        params: {
          pacote
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  }
}

export default Services
