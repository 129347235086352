
import {PaginationItem, PaginationLink, Pagination } from "reactstrap"
import React, {useState, useEffect} from "react"


const PaginationComponent = ({
  pageNumber,
  tableConfig,
  setTableConfig
}) => {

  const [page, setPage] = useState([])

  useEffect(() => {
    let aux = []
    for (let index = 0; index < pageNumber; index++) {
      aux.push(index+1)
    }
    
    setPage(aux)
  }, [
    pageNumber,
    tableConfig.currentPage
  ]);

  const goToPage = (page) => {
    setTableConfig(prev => ({
      ...prev,
      currentPage: page
    }))
  }

  return (
    <React.Fragment>
      <Pagination aria-label="Page navigation example">
      <PaginationItem>
      <PaginationLink previous onClick={()=>goToPage(tableConfig.currentPage-1)} />
      </PaginationItem>
        {
        page.map((item,index) => {
          let init = (tableConfig.currentPage+1) - 3;
          let finish = (tableConfig.currentPage+1) + 3;

          if(item<finish && item>init){
            if(Number(tableConfig.currentPage+1) == item){
              return (
                <PaginationItem active>
                  <PaginationLink onClick={()=>goToPage(item-1)}>
                    {item}
                  </PaginationLink>
                </PaginationItem>
              )
            }else{
              return (
                <PaginationItem>
                  <PaginationLink onClick={()=>goToPage(item-1)}>
                    {item}
                  </PaginationLink>
                </PaginationItem>
              )
            }
          }
    })  
        }
      <PaginationItem>
      <PaginationLink next onClick={()=>goToPage(tableConfig.currentPage+1)} />
      </PaginationItem>
      </Pagination>
      </React.Fragment>
  )
}
export default PaginationComponent;