/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import StatusController from '../../components/statusController'
import MetaTags from 'react-meta-tags'
import FrontTable from '../../components/TableFrontSide'
import { Container, Row, Col, Card, Input, FormGroup, Label } from 'reactstrap'
import Swal from 'sweetalert2'

import { toast } from 'react-toastify'
import { Send } from 'react-feather'
import { Loading } from '../../components/Loading'
import Select from 'react-select'

import 'jodit/build/jodit.min.css'
// import images
import Services from '../../services/sendMessage'
import {
  handlerUser,
  handlerFile,
  handleFileSelectedDropzone
} from '../../services/instanceFunctions'
import { useUserInfo } from 'context/userContext'

import './styles.css'

import 'chartist/dist/scss/chartist.scss'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { Form } from 'react-bootstrap'
import { listType } from '../../util/mocks'
import ServicesSegmentation from '../../services/segmentation'

import ptBR from 'date-fns/locale/pt-BR'
import { format } from 'date-fns'
import { EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ModalQrcode } from 'components/modalQrcode'
import { ModalQrcodeZApi } from 'components/modalQrcodeZApi'
import { TextArea } from '../../components/TextArea'
import { ButtonComponent } from 'components/buttonComponent'
import { SelectComponent } from 'components/selectComponent'
import { InputComponent } from 'components/inputComponent'
import { useFileUpload } from 'hooks/useFileUpload'
import { FileUpload } from 'components/fileUpload'
registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Nome', field: 'name' },
  { label: 'Telefone', field: 'fone' }
]

const customStyles = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
}

const listMomento = [
  { label: 'Enviar Agora', value: 'now' },
  { label: 'Agendar Envio', value: 'after' }
]

const EnviarPlanilha = () => {
  const { userPlan } = useUserInfo()

  const { onDrop } = useFileUpload()

  const [imagem, setImagem] = useState(null)
  const [btn1, setBtn1] = useState(null)
  const [btn2, setBtn2] = useState(null)
  const [btn3, setBtn3] = useState(null)
  const [descricaoLink, setDescricaoLink] = useState(null)
  const [tituloLink, setTituloLink] = useState(null)
  const [linkUrl, setLinkUrl] = useState(null)
  const [linkAction, setLinkAction] = useState(null)
  const [campanha, setCampanha] = useState(null)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const [nomeDoArquivo, setNomeDoArquivo] = useState('')
  const [file, setFile] = useState(null)
  const [zapiData, setZapiData] = useState({
    id: null,
    token: null
  })
  const [momento, setMomento] = useState('now')
  const [horaEnvio, setHoraEnvio] = useState(null)
  const [horaEnvioDatePicker, setHoraEnvioDatePicker] = useState(null)

  const [message, setMessage] = useState('')

  const [type, setType] = useState('texto')
  const [user, setUser] = useState(null)
  const [statusInstancia, setStatusInstancia] = useState(0)
  const [zapMais, setZapMais] = useState(false)
  const [instanceName, setInstanceName] = useState(null)
  const [openModalQrcode, setOpenModalQrcode] = useState(false)
  const [instanceStateChecked, setInstanceStateChecked] = useState(false)
  const [rendered, setRendered] = useState(false)

  const [descricao, setDescricao] = useState('')
  const [block, setBlock] = useState(false)
  const [mensagemCampanhaExistente, setmensagemCampanhaExistente] =
    useState(false)

  const [campanhaExist, setCampanhaExist] = useState(false)
  const [campanhaList, setCampanhaList] = useState([])
  const [grupo, setGrupo] = useState([])
  const [segmentation, setSegmentation] = useState([])

  const [grupoList, setGrupoList] = useState([])
  const [segmentacoesList, setSegmentacoesList] = useState([])

  

  const [rows, setRows] = useState([])

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      order: true,
      more: false,
      pagination: true,
      createInstancia: true
    },
    config: {
      resendPurchaseText: 'Reenviar Qr codes da compra'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null,
      sellerId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 15
  })

  const [listUser, setListUser] = useState([])
  const [tabela, setTabela] = useState([])

  const [loading, setLoading] = useState(false)
  const [checkSair, setCheckSair] = useState(false)

  const loadDadosTable = async () => {
    const init = tableConfig.currentPage * tableConfig.rowsPerPage
    const finish = init + tableConfig.rowsPerPage

    const filterDados = tabela.slice(init, finish)
    setRows(filterDados)
  }

  const loadInstance = async () => {
    const result = await Services.getInstance()
    if (result.status === 200) {
      const info = []
      result.data.data.items.map((item) => {
        info.push({
          label: item.name,
          value: item.id,
          tokenZapi: item.token_zapi,
          idZapi: item.id_zapi,
          useLocal: item.use_local
        })
      })
      setListUser(info)
    }
  }

  const handleClose = () => {
    setLoading(false)
  }

  const handleImgSelected = (acceptedFiles) => {
    const fileReader = new FileReader()
    setCampanha(acceptedFiles[0])
    fileReader.readAsDataURL(acceptedFiles[0])

    fileReader.onload = function (fileLoadedEvent) {
      const imageBase64 = fileLoadedEvent.target?.result

      setImagem(imageBase64)
    }
  }

  const handleVideoSelected = (acceptedFiles) => {
    setCampanha(acceptedFiles[0])
  }

  const handlerType = (e) => {
    setType(e.value)
  }

  const handlerMomento = (e) => {
    setMomento(e.value)

    if (e.value !== 'now') {
      setCampanhaExist(false)
    }
  }

  const handlerSegmentation = async (e) => {
    setSegmentation(e.value)
    const result = await ServicesSegmentation.getSegmentedClients(e.value);

    if (result.status === 200) {
      const formattedData = result?.data?.data?.map((client) => {
        return {
          name: client.name,
          fone: client.telefone
        };
      });

      setTabela(formattedData)
      setRows(formattedData)
      setTableConfig({
        ...tableConfig,
        total: formattedData.length
      })
    } else {
      toast.error(result.data.message)
    }
  }

  const handlerGrupo = async (e) => {
    setGrupo(e.value)
    const result = await Services.getEspecificGrupo({ id: e.value, user })

    if (result.status === 200) {
      setTabela(result.data.data.items)
      setRows(result.data.data.items)
      setTableConfig({
        ...tableConfig,
        total: result.data.data.items.length
      })
    } else {
      toast.error(result.data.message)
    }
  }

  function handleChangeTransferDate(date) {
    const formattedDate = format(date, 'yyyy-MM-dd HH:mm:ss')

    setHoraEnvio(formattedDate)
    setHoraEnvioDatePicker(date)
  }

  const getCampanhas = async () => {
    const result = await Services.getCampanhas({ user })

    if (result.status === 200) {
      const aux = []
      result?.data?.data.map((item, index) => {
        aux.push({
          ...item,
          value: item?.id,
          label: item?.name
        })
      })
      setCampanhaList(aux)
    }
  }

  const getGrupos = async () => {
    const result = await Services.getGrupos(user)
    if (result.status === 200) {
      const aux = []
      result?.data?.data.items.map((item, index) => {
        aux.push({
          ...item,
          value: item?.id,
          label: item?.name
        })
      })
      setGrupoList(aux)
    }
  }

  const getSegmentacoes = async () => {
    const result = await ServicesSegmentation.listSegmentations(user)
    if (result.status === 200) {
      const formattedData = result?.data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name
        }
      });
      setSegmentacoesList(formattedData)
    }
  }

  function onCloseModalQrcode() {
    setOpenModalQrcode(false)
    setUser(null)
    setInstanceName(null)
  }

  function onCloseOnSuccessModalQrcode() {
    setOpenModalQrcode(false)
    setStatusInstancia(1)
  }

  const handlerCampanhaExistente = async (dados) => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção..',
      text: `Você selecionou uma campanha já existente, as configurações da campanha serão setadas automaticamente conforme sua configuração inicial.
      Porém é necessario fazer o upload das midias novamente`,
      confirmButtonText: 'Sair'
    }).then((result) => {
      console.log(result)
    })

    setType(dados?.type)
    setmensagemCampanhaExistente(dados?.body)
    setCampanhaExist(dados?.value)
    setBlock(true)
  }

  const handleClearInstanceId = () => {
    setUser(null)
  }

  const handleAddVariable = (variable) => {
    const cursorPosition =
      document.getElementById('message-text-area')?.selectionStart
    const newMessage = `${message.substring(
      0,
      cursorPosition
    )} ${variable} ${message.substring(cursorPosition)}`

    setMessage(newMessage)
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }
    if (rendered) {
      loadInstance()
    }
  }, [rendered])

  useEffect(() => {
    if (user) {
      getCampanhas()
      getGrupos()
      getSegmentacoes()
    }
  }, [user])

  useEffect(() => {
    if (!rendered) return
    loadDadosTable()
  }, [tableConfig.currentPage, rendered])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      {zapMais && (
        <ModalQrcode
          showModal={openModalQrcode}
          onClose={onCloseModalQrcode}
          instanceId={user}
          statusInstance={statusInstancia}
          instanceName={instanceName}
          onSuccess={onCloseOnSuccessModalQrcode}
          instanceChecked={instanceStateChecked}
        />
      )}
      {!zapMais && (
        <ModalQrcodeZApi
          showModal={openModalQrcode}
          onClose={onCloseModalQrcode}
          instanceId={user}
          statusInstance={statusInstancia}
          instanceName={instanceName}
          onSuccess={onCloseOnSuccessModalQrcode}
          instanceChecked={instanceStateChecked}
          zapiData={zapiData}
        />
      )}

      <div className="page-content">
        <MetaTags>
          <title>Envio de Mensagens | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Envio de Mensagens | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo ao Disparador Zap+
                  </li>
                </ol>
              </Col>
              <Col
                md={4}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                {user && (
                  <>
                    <span
                      style={{
                        marginRight: 10,
                        fontWeight: 500,
                        fontSize: 20
                      }}
                    >
                      {statusInstancia === 1
                        ? 'Conectado'
                        : statusInstancia === 3
                        ? 'Bloqueado'
                        : 'Desconectado'}
                    </span>
                    <StatusController statusInstancia={statusInstancia} />
                  </>
                )}
              </Col>
            </Row>
          </div>
          <Row>
            <Card style={{ padding: 20, paddingBottom: 150 }}>
              <div>
                <span
                  style={{
                    fontSize: 14
                  }}
                >
                  • A planilha DEVE contar na ordem os campos "nome" e
                  "telefone" (<b>Sem cabeçalho, apenas os valores</b>)
                </span>
                <br></br>
                <span
                  style={{
                    fontSize: 14
                  }}
                >
                  • O campo "telefone" não exige um formato. Pode ser tanto (94)
                  99200-0000 como 94992000000, etc
                </span>
              </div>
              <Row style={{ marginTop: 30, rowGap: 16 }}>
                <Col xl={3} md={3}>
                  <SelectComponent
                    label="Quem está enviando a mensagem?"
                    value={
                      listUser.find((a) => a.value === user)
                        ? listUser.find((a) => a.value === user)
                        : null
                    }
                    placeholder="Selecione..."
                    options={listUser}
                    onChange={(value) =>
                      handlerUser(
                        value,
                        setLoading,
                        setUser,
                        Services,
                        Swal,
                        setStatusInstancia,
                        setZapMais,
                        setInstanceName,
                        setOpenModalQrcode,
                        handleClearInstanceId,
                        setInstanceStateChecked,
                        setZapiData
                      )
                    }
                  />
                </Col>
                {userPlan !== 'BASIC' && (
                  <Col xl={3} md={3}>
                    <FormGroup>
                      <Label for="exampleEmail">Momento do envio</Label>
                      <Select
                        value={
                          listMomento.find((a) => a.value === momento)
                            ? listMomento.find((a) => a.value === momento)
                            : null
                        }
                        onChange={(value) => handlerMomento(value)}
                        options={listMomento}
                        classNamePrefix="select2-selection"
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col xl={3} md={3}>
                  <InputComponent
                    label="Descrição da Nova Campanha"
                    onChange={(value) => {
                      setDescricao(value.target.value)
                      setCampanhaExist(false)
                      setBlock(false)
                    }}
                    value={descricao}
                  />
                </Col>

                {descricao.length === 0 && (
                  <Col xl={3} md={3}>
                    <FormGroup>
                      <Label for="exampleEmail">Campanha Existente</Label>
                      <Select
                        value={
                          campanhaList.find((a) => a.value === campanhaExist)
                            ? campanhaList.find(
                                (a) => a.value === campanhaExist
                              )
                            : null
                        }
                        onChange={(value) => handlerCampanhaExistente(value)}
                        options={campanhaList}
                        classNamePrefix="select2-selection"
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                )}

                <Col xl={3} md={3}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Selecione o tipo de mensagem
                    </Label>
                    <Select
                      value={
                        listType.find((a) => a.value === type)
                          ? listType.find((a) => a.value === type)
                          : null
                      }
                      styles={customStyles}
                      onChange={(value) => handlerType(value)}
                      options={listType}
                      isDisabled={block}
                      classNamePrefix="select2-selection"
                      placeholder="Selecione..."
                    />
                  </FormGroup>
                </Col>

                {momento === 'after' && (
                  <Col xl={4} md={4}>
                    <Form.Group>
                      <Label for="exampleEmail">Hora do Envio</Label>
                      <ReactDatePicker
                        className="form-control"
                        selected={horaEnvioDatePicker}
                        onChange={(date) => {
                          handleChangeTransferDate(date)
                        }}
                        timeIntervals={15}
                        showTimeSelect
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                        locale="pt-BR"
                      />
                    </Form.Group>
                  </Col>
                )}

                <Row
                  style={{
                    marginTop: 20
                  }}
                >
                  <Col xl={4} md={4}>
                    <FormGroup>
                      <Label for="exampleEmail">Planilha de Excel</Label>
                      <div className="file-uploader">
                        <FileUpload
                          dropzoneError={false}
                          text="Clique aqui para selecionar ou arraste a <strong>PLANILHA EXCEL</strong>"
                          onDrop={(acceptedFiles, fileRejections) =>
                            onDrop(
                              acceptedFiles,
                              fileRejections,
                              100000000,
                              () =>
                                handleFileSelectedDropzone(
                                  acceptedFiles,
                                  setFile,
                                  setLoading,
                                  setTabela,
                                  setTableConfig,
                                  setRows,
                                  tableConfig,
                                  'planilha',
                                  setGrupo,
                                  Swal
                                )
                            )
                          }
                          maxSize={100000000}
                          accept={['.xlsx']}
                          suggestion={
                            file?.name
                              ? `Arquivo selecionado: <strong>${file.name}</strong>`
                              : null
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  {(userPlan !== 'BASIC') && user && (
                    <>
                      <Col xl={1} md={1}>
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 20,
                            marginTop: 20,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          OU
                        </span>
                      </Col>
                      <Col xl={3} md={3}>
                        <FormGroup>
                          <Label for="exampleEmail">Grupos de Disparo</Label>
                          <Select
                            value={
                              grupoList.find((a) => a.value === grupo)
                                ? grupoList.find((a) => a.value === grupo)
                                : null
                            }
                            onChange={(value) => handlerGrupo(value)}
                            options={grupoList}
                            classNamePrefix="select2-selection"
                            placeholder="Selecione..."
                          />
                        </FormGroup>
                      </Col>
                      <Col xl={1} md={1}>
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 20,
                            marginTop: 20,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          OU
                        </span>
                      </Col>
                      <Col xl={3} md={3}>
                        <FormGroup>
                          <Label for="exampleEmail">Segmentações</Label>
                          <Select
                            value={
                              segmentacoesList.find((a) => a.value === segmentation)
                                ? segmentacoesList.find((a) => a.value === segmentation)
                                : null
                            }
                            onChange={(value) => handlerSegmentation(value)}
                            options={segmentacoesList}
                            classNamePrefix="select2-selection"
                            placeholder="Selecione..."
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>

                {(type === 'imagem' || type === 'imagem-botoes') && (
                  <Col xl={5} md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Imagem</Label>
                      <div className="file-uploader">
                        <FileUpload
                          dropzoneError={false}
                          text="Clique aqui para selecionar ou arraste a <strong>IMAGEM</strong>"
                          onDrop={(acceptedFiles, fileRejections) =>
                            onDrop(
                              acceptedFiles,
                              fileRejections,
                              10000000,
                              () => handleImgSelected(acceptedFiles)
                            )
                          }
                          maxSize={10000000}
                          accept={['.png', '.JPEG', '.jpeg', '.jpg']}
                          suggestion={
                            campanha?.name
                              ? `Imagem selecionada: <strong>${campanha.name}</strong>`
                              : null
                          }
                        />
                      </div>
                      {/* <div className="file-uploader">
                        <Input
                          type="file"
                          isDisabled={block}
                          label="Upload"
                          onChange={handleImgSelected}
                          accept=".pdf, .png, .JPEG, .jpeg, .jpg, .svg, .webp"
                        />
                      </div> */}
                    </FormGroup>
                  </Col>
                )}
                {type === 'arquivo' && (
                  <Col xl={5} md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Arquivo</Label>
                      <div className="file-uploader">
                        <FileUpload
                          dropzoneError={false}
                          text="Clique aqui para selecionar ou arraste o <strong>ARQUIVO PDF</strong>"
                          onDrop={(acceptedFiles, fileRejections) =>
                            onDrop(
                              acceptedFiles,
                              fileRejections,
                              50000000,
                              () => handleImgSelected(acceptedFiles)
                            )
                          }
                          maxSize={50000000}
                          accept={['.pdf']}
                          suggestion={
                            campanha?.name
                              ? `Arquivo de no máximo 50M<br/>Arquivo selecionado: <strong>${campanha.name}</strong>`
                              : 'Arquivo de no máximo 50M'
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                )}
                {type === 'video-arquivo' && (
                  <Col xl={5} md={5}>
                    <FormGroup>
                      <Label for="exampleEmail">Vídeo</Label>
                      <div className="file-uploader">
                        <FileUpload
                          dropzoneError={false}
                          text="Clique aqui para selecionar ou arraste o <strong>VÍDEO</strong>"
                          onDrop={(acceptedFiles, fileRejections) =>
                            onDrop(
                              acceptedFiles,
                              fileRejections,
                              15000000,
                              () => handleVideoSelected(acceptedFiles)
                            )
                          }
                          maxSize={15000000}
                          accept={['.3gp', '.mp4']}
                          suggestion={
                            campanha?.name
                              ? `Arquivo de no máximo 15M<br/>Arquivo selecionado: <strong>${campanha.name}</strong>`
                              : 'Arquivo de no máximo 15M'
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                )}
                {type === 'audio-arquivo' && (
                  <Col xl={4} md={4}>
                    <FormGroup>
                      <Label for="exampleEmail">Arquivo</Label>
                      <div className="file-uploader">
                        <Input
                          type="file"
                          isDisabled={block}
                          label="Upload"
                          onChange={handleVideoSelected}
                          accept=".ogg, .mp3"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                )}
                {type === 'link' && (
                  <>
                    <Col xl={4} md={4}>
                      <InputComponent
                        label="Link*"
                        onChange={(value) => setLinkUrl(value.target.value)}
                        value={linkUrl}
                      />
                    </Col>
                  </>
                )}

                {!block && (
                  <>
                    {(type === 'botoes' || type === 'imagem-botoes') && (
                      <Col xl={4} md={4}>
                        <InputComponent
                          label="Titulo dos Botões"
                          onChange={(value) =>
                            setTituloLink(value.target.value)
                          }
                          value={tituloLink}
                        />
                      </Col>
                    )}
                    {(type === 'botoes' || type === 'imagem-botoes') && (
                      <>
                        <Col xl={4} md={4}>
                          <InputComponent
                            label="Botão 1*"
                            onChange={(value) => setBtn1(value.target.value)}
                            value={btn1}
                          />
                        </Col>
                        <Col xl={4} md={4}>
                          <InputComponent
                            label="Botão 2*"
                            onChange={(value) => setBtn2(value.target.value)}
                            value={btn2}
                          />
                        </Col>
                        <Col xl={4} md={4}>
                          <InputComponent
                            label="Botão 3*"
                            onChange={(value) => setBtn3(value.target.value)}
                            value={btn3}
                          />
                        </Col>
                      </>
                    )}
                  </>
                )}

                {!block && type === 'arquivo' && (
                  <Col xl={4} md={4}>
                    <InputComponent
                      label="Nome do Arquivo"
                      onChange={(value) => setNomeDoArquivo(value.target.value)}
                      value={nomeDoArquivo}
                    />
                  </Col>
                )}

                {/* {!block && (
                  <Col xl={4} md={4} className={styles.checkSair}>
                    <Label for="exampleEmail">Possibilitar Sair</Label>
                    <Switch
                      onChange={() => {
                        setCheckSair(!checkSair)
                      }}
                      checked={checkSair}
                    />
                  </Col>
                )} */}
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col xl={12} md={12}>
                  <FrontTable
                    title={'Sua Tabela'}
                    columns={columns}
                    rows={rows}
                    tableConfig={tableConfig}
                    setTableConfig={setTableConfig}
                  />
                </Col>
                <Col xl={8} md={12}>
                  {!block && (
                    <FormGroup>
                      <TextArea
                        value={message}
                        setValue={setMessage}
                        onAddVariable={handleAddVariable}
                      />
                    </FormGroup>
                  )}
                </Col>
                <Col
                  xl={4}
                  md={6}
                  style={{
                    marginTop: 20,
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    display: 'flex'
                  }}
                >
                  {statusInstancia && (
                    <ButtonComponent
                      text="Enviar Mensagem"
                      icon={<Send />}
                      onClick={() =>
                        handlerFile({
                          functionType: 'disparo',
                          zapMais,
                          tabela,
                          Swal,
                          block,
                          user,
                          descricao,
                          campanhaExist,
                          imagem,
                          type,
                          campanha,
                          linkUrl,
                          horaEnvio,
                          momento,
                          setLoading,
                          editorState,
                          btn1,
                          btn2,
                          btn3,
                          Services,
                          mensagemCampanhaExistente,
                          nomeDoArquivo,
                          descricaoLink,
                          tituloLink,
                          linkAction,
                          checkSair,
                          setImagem,
                          setBtn1,
                          setBtn2,
                          setBtn3,
                          setUser,
                          setDescricaoLink,
                          setTituloLink,
                          setLinkUrl,
                          setLinkAction,
                          setCampanha,
                          setNomeDoArquivo,
                          setFile,
                          setMomento,
                          setHoraEnvio,
                          setHoraEnvioDatePicker,
                          setType,
                          setDescricao,
                          setCampanhaExist,
                          setCampanhaList,
                          setRows,
                          setTabela,
                          setCheckSair,
                          setTableConfig,
                          message,
                          setMessage
                        })
                      }
                    />
                  )}
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default EnviarPlanilha
