import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { cpfCnpjMask, onlyUnsignedNumbers, phoneMask } from 'util/string'

interface CheckoutPersonalInfo {
  checkoutUser: any
  handleChangeCheckoutUser: ({
    field,
    value
  }: {
    field: string
    value: any
  }) => void
  handleCheckCheckoutUser: () => void
}

export const CheckoutPersonalInfo = ({
  checkoutUser,
  handleChangeCheckoutUser,
  handleCheckCheckoutUser
}: CheckoutPersonalInfo) => {
  return (
    <Form>
      <Form.Group className="mb-2">
        <Form.Label>Nome</Form.Label>
        <Form.Control
          value={checkoutUser.name}
          onChange={(event) =>
            handleChangeCheckoutUser({
              field: 'name',
              value: event.target.value
            })
          }
          isInvalid={!!checkoutUser.errorMessage.name}
        />
        <Form.Control.Feedback type="invalid">
          {checkoutUser?.errorMessage?.name}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Email</Form.Label>
        <Form.Control
          value={checkoutUser.email}
          type="email"
          onChange={(event) =>
            handleChangeCheckoutUser({
              field: 'email',
              value: event.target.value
            })
          }
          isInvalid={!!checkoutUser.errorMessage.email}
        />
        <Form.Control.Feedback type="invalid">
          {checkoutUser?.errorMessage?.email}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>CPF/CPNJ</Form.Label>
        <Form.Control
          value={cpfCnpjMask(checkoutUser.document)}
          onChange={(event) =>
            handleChangeCheckoutUser({
              field: 'document',
              value: onlyUnsignedNumbers(event.target.value)
            })
          }
          isInvalid={!!checkoutUser.errorMessage.document}
        />
        <Form.Control.Feedback type="invalid">
          {checkoutUser?.errorMessage?.document}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label>Telefone</Form.Label>
        <Form.Control
          value={phoneMask(checkoutUser.phone || '')}
          onChange={(event) => {
            if (onlyUnsignedNumbers(event.target.value)?.length > 11) {
              return
            }
            handleChangeCheckoutUser({
              field: 'phone',
              value: onlyUnsignedNumbers(event.target.value)
            })
          }}
          isInvalid={!!checkoutUser.errorMessage.phone}
        />
        <Form.Control.Feedback type="invalid">
          {checkoutUser?.errorMessage?.phone}
        </Form.Control.Feedback>
      </Form.Group>
      <Button
        variant="success"
        className="button-next mt-4"
        onClick={handleCheckCheckoutUser}
        type="button"
      >
        Próximo
      </Button>
    </Form>
  )
}
