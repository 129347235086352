/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { Loading } from '../../components/Loading'
import './styles.scss'
import 'chartist/dist/scss/chartist.scss'
import { registerLocale } from 'react-datepicker'
import { toast } from 'react-toastify'
import Select from 'react-select'
import Swal from 'sweetalert2'

import ptBR from 'date-fns/locale/pt-BR'
import { ModalMudarPlano } from '../../components/modalMudarPlano'
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  InputGroup,
  Row
} from 'react-bootstrap'
import plansSercice from 'services/plans'
import {
  SubscriptionProps,
  SelectOptionsProps
} from 'types/global'
import { ModalInstancia } from 'components/modalInstancia'
import userServices from 'services/user'
import plansService from 'services/plans'
import { CurrencyInput } from 'components/CurrencyInput'

interface CheckoutUserProps {
  name: string
  email: string
  document: string
  phone: string
}

interface CheckoutUserProsWitherrorMessage extends CheckoutUserProps {
  errorMessage: CheckoutUserProps
}

interface CheckoutAddressProps {
  cep: string
  street: string
  number: string
  district: string
  city: string
  state: string
}

interface CheckoutAddressProsWitherrorMessage extends CheckoutAddressProps {
  errorMessage: CheckoutAddressProps
}

interface CheckoutSuccessProps {
  image?: string
  action?: string
}

registerLocale('pt-BR', ptBR)
const obj = JSON.parse(localStorage.getItem('authUser'))

const columns = [
  { label: 'Identificador', field: 'id' },
  { label: 'Descrição', field: 'name' },
  { label: 'Data de criação', field: 'createdAt' }
]

export const CreateSubscription = () => {
  const { selectUsers } = userServices
  const { selectPlans, createCustomPlan } = plansService
  const [loading, setLoading] = useState(false)
  const [rendered, setRendered] = useState(false)
  const [totalPrice, setTotalPrice] = useState<number>(null)
  const [paymentMethod, setPaymentMethod] = useState<string>('')
  const [paymentMethodError, setPaymentMethodError] = useState<string>('')
  const [hasPlan, setHasPlan] = useState(false)
  const [plans, setPlans] = useState<SelectOptionsProps[]>([])
  const [openPayment, setOpenPayment] = useState(false)
  const [openCheckout, setOpenCheckout] = useState(false)
  const [openPayment2, setOpenPayment2] = useState(false)
  const [errorUserId, setErrorUserId] = useState<string>(null)
  const [errorPlanId, setErrorPlanId] = useState<string>(null)
  const [free, setFree] = useState(false)
  const [checkoutErrorMessage, setCheckoutErrorMessage] = useState('')
  const [valorInstancia, setValorInstancia] = useState(999)
  const [mudarPlano, setMudarPlano] = useState(false)
  const [newPlano, setNewPlano] = useState(false)
  const [instanceQuantity, setInstanceQuantity] = useState(1)
  const [subscriptions, setSubscriptions] = useState<SubscriptionProps[]>([])
  const [showCheckoutArea, setShowCheckoutArea] = useState(true)
  const [userId, setUserId] = useState<SelectOptionsProps>(null)
  const [planId, setPlanId] = useState<SelectOptionsProps>(null)
  const [showModalInstance, setShowModalInstance] = useState(false)
  const [users, setUsers] = useState<SelectOptionsProps[]>([])
  const [errorTotalPrice, setErrorTotalPrice] = useState<string>(null)

  const [selectedSubscription, setSelectedSubscription] =
    useState<null | SubscriptionProps>(null)

  const [checkoutSuccessFeedback, setCheckoutSuccessFeedback] =
    useState<CheckoutSuccessProps | null>(null)
  // useState<CheckoutSuccessProps | null>({
  //   image: null,
  //   copy: 'fkladjflakdfjalskdfjlsakdjf'
  // })
  const [checkoutUser, setCheckoutUser] =
    useState<CheckoutUserProsWitherrorMessage>({
      name: null,
      email: null,
      document: null,
      phone: null,
      errorMessage: {
        name: null,
        email: null,
        document: null,
        phone: null
      }
    })
  const [checkoutAddress, setCheckoutAddress] =
    useState<CheckoutAddressProsWitherrorMessage>({
      cep: null,
      street: null,
      number: null,
      district: null,
      city: null,
      state: null,
      errorMessage: {
        cep: null,
        street: null,
        number: null,
        district: null,
        city: null,
        state: null
      }
    })

  const [checkoutStep, setCheckoutStep] = useState(1)

  const nextStep = () => {
    setCheckoutStep(checkoutStep + 1)
  }

  const previousStep = () => {
    setCheckoutStep(checkoutStep - 1)
  }

  const handleCloseModal = () => {
    setOpenPayment(false)
    setOpenPayment2(false)
    setMudarPlano(false)
  }

  const loadUsers = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await selectUsers()

      if (response.status === 200) {
        setUsers(response.data.data)
        setLoading(false)
      } else {
        toast.error(response)
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [])

  const loadPlans = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await selectPlans()

      if (response.status === 200) {
        setPlans(response.data.data)
        setLoading(false)
      } else {
        toast.error(response)
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [])

  const loadSubscriptions = async () => {
    try {
      setLoading(true)
      const result = await plansSercice.getSubscriptions()
      console.log(result.data.data)

      setLoading(false)
      if (result.status === 200) {
        setSubscriptions(result.data.data)
        if (result.data?.data?.length > 0) {
          setShowCheckoutArea(false)
        }
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const handleChangeInstanceQtd = async (type: 'minus' | 'plus') => {
    if (type === 'plus') {
      setInstanceQuantity(instanceQuantity + 1)
    } else {
      if (instanceQuantity > 1) {
        setInstanceQuantity(instanceQuantity - 1)
      }
    }
  }
  const handleChangeInputInstanceQtd = async (value) => {
    if (Number(value) >= 1) {
      setInstanceQuantity(Number(value))
    } else {
      setInstanceQuantity(1)
    }
  }

  function handleConfirmation(result) {
    console.log({ result })
  }

  function handleGoBack() {
    window.location.href = `${process.env.REACT_APP_SITE_URL}planos-personalizados`
  }

  const onCloseModalInstance = () => {
    setShowModalInstance(false)
    setSelectedSubscription(null)
  }

  const handleSubmitSubscription = async () => {
    try {
      if (!planId || !userId || !instanceQuantity || !totalPrice) {
        if (!userId) {
          setErrorUserId('Selecione um usuário!')
        }
        if (!planId) {
          setErrorPlanId('Selecione um plano!')
        }
        if (!totalPrice) {
          setErrorTotalPrice('Informe o valor total da assinatura!')
        }
        if (!instanceQuantity) {
          toast.success('Informe a quantidade de instâncias')
        }
        return
      }

      const body = {
        userId: userId.value,
        planId: planId.value,
        price: totalPrice,
        quantity: instanceQuantity
      }

      console.log({ body })
      setCheckoutErrorMessage('')
      setLoading(true)
      const response = await plansSercice.createCustomSubscription(body)
      setLoading(false)

      console.log({ response })
      if (response.status === 200) {
        console.log('success')
        Swal.fire({
          icon: 'success',
          title: response.data.msg?.title || 'Inscrição criada',
          html:
            response.data.msg?.text || 'A inscrição personalizada foi criada',
          confirmButtonText: 'Fechar',
          showCancelButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(handleGoBack)
      } else if (response.response.status === 400) {
        const errorMsg = response.response.data.error
        console.log('400')
        setCheckoutErrorMessage(errorMsg?.title || 'Compra não autorizada!')
        Swal.fire({
          icon: 'error',
          title: errorMsg?.title || 'Não autorizado',
          html: errorMsg?.text || 'Revise os dados e tente novamente',
          confirmButtonText: 'Fechar',
          showCancelButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(handleConfirmation)
      } else {
      }
    } catch (error) {
      setLoading(false)
      console.log({ error })
    }
  }

  useEffect(() => {
    if (!rendered) {
      return setRendered(true)
    }
    loadPlans()
    loadUsers()
  }, [rendered])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} />}

      <div className="page-content container-criar-inscricao">
        <MetaTags>
          <title>Criar assinatura personalizada | Zap+</title>
        </MetaTags>
        <Container fluid>
          <Card>
            <Card.Body>
              <Row className={'justify-content-center'}>
                <Col xs={12} md={6}>
                  <Row className="mb-4">
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Form.Label>Usuário</Form.Label>
                        <Select
                          isDisabled={checkoutStep !== 1}
                          value={
                            users.find((a) => a.value === userId?.value)
                              ? users.find((a) => a.value === userId?.value)
                              : null
                          }
                          onChange={(value) => {
                            setUserId(value)
                            setErrorUserId(null)
                          }}
                          options={users}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                          className={!!errorUserId ? 'select-error' : ''}
                        />
                        {!!errorUserId && (
                          <>
                            <span className="is-invalid"></span>
                            <p className="invalid-feedback">{errorUserId}</p>
                          </>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={6}>
                      <FormGroup>
                        <Form.Label>Plano</Form.Label>
                        <Select
                          isDisabled={checkoutStep !== 1}
                          value={
                            plans.find((a) => a.value === planId?.value)
                              ? plans.find((a) => a.value === planId?.value)
                              : null
                          }
                          onChange={(value) => {
                            setPlanId(value)
                            setErrorPlanId(null)
                          }}
                          options={plans}
                          classNamePrefix="select2-selection"
                          placeholder="Selecione..."
                          className={errorPlanId ? 'select-error' : ''}
                        />
                        {errorPlanId && (
                          <>
                            <span className="is-invalid"></span>
                            <p className="invalid-feedback">{errorPlanId}</p>
                          </>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Card>
                        <Card.Body>
                          <div className="container-instance-number">
                            <div className="container-header-text">
                              <i
                                className="mdi mdi-cellphone"
                                style={{ fontSize: 40 }}
                              ></i>
                              <h5>Quantidade de instâncias</h5>
                            </div>
                            <InputGroup className="mb-3 input-group-instance-qtd">
                              <InputGroup.Text
                                onClick={() => handleChangeInstanceQtd('minus')}
                              >
                                -
                              </InputGroup.Text>
                              <Form.Control
                                className="input-instance-qtd"
                                aria-label="Quantidade de instâncias"
                                value={instanceQuantity}
                                onChange={(event) =>
                                  handleChangeInputInstanceQtd(
                                    event.target.value
                                  )
                                }
                              />
                              <InputGroup.Text
                                onClick={() => handleChangeInstanceQtd('plus')}
                              >
                                +
                              </InputGroup.Text>
                            </InputGroup>
                          </div>
                          <div className="container-footer">
                            <CurrencyInput
                              variant="outlined"
                              label="Valor total*"
                              value={totalPrice}
                              handleChange={(value: number) => {
                                setTotalPrice(value)
                                setErrorTotalPrice(null)
                              }}
                              error={!!errorTotalPrice && totalPrice !== 0}
                              helperText={errorTotalPrice}
                              className={
                                !!errorTotalPrice ? 'select-error' : ''
                              }
                            />
                            {!!errorTotalPrice && (
                              <>
                                <span className="is-invalid"></span>
                                <p className="invalid-feedback">
                                  {errorTotalPrice}
                                </p>
                              </>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="container-buttons">
                    <Button
                      variant="secondary"
                      className="button-previous"
                      onClick={handleGoBack}
                      type="button"
                    >
                      Voltar
                    </Button>
                    <Button
                      variant="success"
                      className="button-next"
                      onClick={handleSubmitSubscription}
                      type="button"
                    >
                      Finalizar
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
      <ModalMudarPlano
        showModal={mudarPlano}
        onClose={handleCloseModal}
        newPlano={newPlano}
      />

      <ModalInstancia
        onClose={onCloseModalInstance}
        callback={loadSubscriptions}
        showModal={showModalInstance}
        subscription={selectedSubscription}
      />
    </React.Fragment>
  )
}
