import { format } from 'date-fns'

export function formatCurrency({ value, style = 'currency', decimal = 2 }) {
  return Intl.NumberFormat('pt-br', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
    style,
    currency: 'BRL'
  }).format(value)
}

export function formatDateIso({ value }) {
  try {
    const valueTemp = value.split('/')
    return valueTemp.reverse().join('-')
  } catch (error) {
    return ''
  }
  return
}
export function removeAcento(text) {
  text = text.toLowerCase()
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c')
  text = text.replace(new RegExp('[Ĺ]', 'gi'), 'l')
  return text
}

export function isValidEmail(email) {
  const usuario = email.substring(0, email.indexOf('@'))
  const dominio = email.substring(email.indexOf('@') + 1, email.length)
  if (
    usuario.length >= 1 &&
    dominio.length >= 3 &&
    usuario.search('@') === -1 &&
    dominio.search('@') === -1 &&
    usuario.search(' ') === -1 &&
    dominio.search(' ') === -1 &&
    dominio.search('.') !== -1 &&
    dominio.indexOf('.') >= 1 &&
    dominio.lastIndexOf('.') < dominio.length - 1
  ) {
    return true
  } else {
    return false
  }
}

export function onlyNumbers(value) {
  return value?.replace(/[^0-9]+/g, '')
}

export function onlyUnsignedNumbers(_string) {
  return _string?.replace(/[^0-9]+/g, '')
}

export const cpfMask = (cpfCnpj) => {
  if (cpfCnpj === null || cpfCnpj === undefined) return
  //if (String(cpfCnpj).length === 11) {
  return String(cpfCnpj)
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
  //}
}

export const cnpjMask = (cpfCnpj) => {
  if (cpfCnpj === null || cpfCnpj === undefined) return
  return String(cpfCnpj)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

export const cpfCnpjMask = (cpfCnpj) => {
  if (!cpfCnpj) return ''

  const cleanedCpfCnpj = cpfCnpj.replace(/\D/g, '')

  if (cleanedCpfCnpj.length <= 11) {
    return cpfMask(cleanedCpfCnpj)
  } else {
    return cnpjMask(cleanedCpfCnpj)
  }
}
export const phoneMaskRegex = (number) => {
  const phone = onlyNumbers(number)
  return phone?.replace(/(\d{2})(\d{5})(\d)/, '($1) $2-$3')
}

export const phoneMask = (number) => {
  const phone = onlyNumbers(number)
  if (phone.length <= 2) return phone
  if (phone.length <= 7) return `(${phone.slice(0, 2)}) ${phone.slice(2)}`
  return `(${phone.slice(0, 2)}) ${phone.slice(2, 7)}-${phone.slice(7)}`
}

export const percentageMask = (number) => {
  return number.replace(/[^0-9,.]+/g, '').replace(/([0-9,.]+?$)/, '$1%')
}

export const formatDate = (date) => {
  const splitDate = date.split('/')
  if (splitDate.length === 3) {
    const formattedDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
    return formattedDate
  }
  return '01-01-2000'
}

export const formatDateT = (date) => {
  let newDate = new Date(date)
  newDate.setHours(newDate.getHours() + 3)
  newDate = format(newDate, 'dd/MM/yyyy HH:mm')
  return newDate
}

export const formatDateSimplesWithHours = (date) => {
  let newDate = date.split('T')[0].split('-')
  const hours = date.split('T')[1].split(':')
  console.log(hours)
  newDate = `${newDate[2]}/${newDate[1]}/${newDate[0]} ${hours[0]}:${hours[1]}`
  return newDate
}

export const formatDateSimples = (date) => {
  let newDate = date.split('T')
  newDate = newDate[0].split('-')
  newDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`
  return newDate
}

export const dataMask = (date) => {
  if (date === null || date === undefined) return
  return String(date)
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})\d+?$/, '$1')
}

export const cepMask = (cep) => {
  if (cep === null || cep === undefined) return

  return String(cep)
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(\d{3})\d+?$/, '$1')
}

export const cepMaskV2 = (cep) => {
  if (!cep) {
    return ''
  }

  const cepArray = cep.split('')
  const cleanedCep = cepArray
    .filter((char) => {
      return /\d/.test(char)
    })
    .join('')
    .slice(0, 8)

  const cepLength = cleanedCep.length
  if (cepLength > 8 && cepArray[cepLength - 1] === '-') {
    return cepArray.slice(0, -1).join('')
  }

  const maskedCep = cleanedCep.replace(
    /(\d{5})?(\d{0,3})?/,
    function (_, firstGroup, secondGroup) {
      if (!firstGroup) {
        return secondGroup
      } else {
        if (secondGroup) {
          return `${firstGroup}-${secondGroup}`
        } else {
          return firstGroup
        }
      }
    }
  )

  return maskedCep
}

export const timeStampToDate = (number) => {
  number = number.slice(0, 10)
  number = Number(number) * 1000
  number = new Date(number)
  let formattedDate = number.toISOString()

  let splitDate = formattedDate.split('T')
  let horas = splitDate[1].split('.')[0]
  horas = horas.split(':')
  splitDate = splitDate[0].split('-')

  formattedDate = `${splitDate[2]}/${splitDate[1]}/${splitDate[0]} ${horas[0]}:${horas[1]}`

  return formattedDate
}

export const creditCardMask = (creditCardNumber) => {
  if (!creditCardNumber) return ''

  const creditCardNumberWithoutMask = creditCardNumber.replace(/\D/g, '')

  let maskedNumber = ''
  for (let i = 0; i < creditCardNumberWithoutMask.length; i++) {
    if (i === 4 || i === 8 || i === 12) {
      maskedNumber += ' '
    }
    maskedNumber += creditCardNumberWithoutMask[i]
  }

  return maskedNumber
}

export const expirationDateMask = (expirationDate) => {
  if (!expirationDate) return ''

  let digitsOnly = expirationDate.replace(/\D/g, '')
  if (digitsOnly.length > 4) {
    digitsOnly = digitsOnly.substring(0, 4)
  }

  let maskedValue = ''
  for (let i = 0; i < digitsOnly.length; i++) {
    if (i === 2) {
      maskedValue += '/'
    }
    maskedValue += digitsOnly[i]
  }

  return maskedValue
}
