import Excel from 'exceljs'
import { onlyUnsignedNumbers } from '../util/string'
const obj = JSON.parse(localStorage.getItem('authUser'))

export const handlerUser = async (
  e,
  setLoading,
  setUser,
  Services,
  Swal,
  setStatusInstancia,
  setZapMais,
  setInstanceName,
  setOpenModalQrcode,
  clearInstanceId,
  setInstanceStateChecked,
  setZapiData
) => {
  setLoading(true)
  setUser(e.value)
  setInstanceName(e.label)
  const result = await Services.CheckInstance({ idInstancia: e.value })
  setLoading(false)
  setInstanceStateChecked(true)
  console.log({ e })
  if (e.useLocal) {
    setZapiData({
      id: e.idZapi,
      token: e.tokenZapi
    })
    setZapMais(true)
  }

  function handleConfirmation(result) {
    console.log({ result })
    if (result.isConfirmed) {
      setOpenModalQrcode(true)
      console.log('O botão Confirmar foi clicado!')
    } else {
      clearInstanceId()
      console.log('O botão Cancelar foi clicado!')
    }
  }

  if (result.status === 200) {
    const data = result.data

    if (data.status === 200) {
      localStorage.setItem("privacy_and_terms", data.privacy_and_terms)

      if (data?.active) {
        setStatusInstancia(1)
        setInstanceStateChecked(true)
      } else {
        setStatusInstancia(3)
        Swal.fire({
          icon: 'error',
          title: 'Ops..',
          text: 'Essa instância está desativada por falta de pagamento',
          cancelButtonText: 'Fechar',
          allowEscapeKey: false,
          allowOutsideClick: false,
          showConfirmButton: false
        })
      }
    } else {
      setStatusInstancia(0)
      Swal.fire({
        icon: 'error',
        title: 'Ops..',
        text: data?.msg
          ? data?.msg
          : 'Seu Whatsapp está desconectado. Não Envie essa campanha antes de conectar',
        confirmButtonText: 'Conectar',
        cancelButtonText: 'Fechar',
        showCancelButton: true,
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then(handleConfirmation)
    }
  } else {
    setStatusInstancia(0)
    Swal.fire({
      icon: 'error',
      title: 'Ops..',
      text: 'Seu Whatsapp está desconectado. Não Envie essa campanha antes de conectar',
      confirmButtonText: 'Conectar',
      cancelButtonText: 'Fechar',
      showCancelButton: true
    }).then(handleConfirmation)
  }
}

export async function handlerFile({
  functionType,
  zapMais,
  tabela,
  Swal,
  block,
  user,
  descricao,
  campanhaExist,
  imagem,
  type,
  campanha,
  linkUrl,
  horaEnvio,
  momento,
  setLoading,
  btn1,
  btn2,
  btn3,
  Services,
  mensagemCampanhaExistente,
  nomeDoArquivo,
  descricaoLink,
  tituloLink,
  linkAction,
  checkSair,
  setImagem,
  setBtn1,
  setBtn2,
  setBtn3,
  setUser,
  setDescricaoLink,
  setTituloLink,
  setLinkUrl,
  setLinkAction,
  setCampanha,
  setNomeDoArquivo,
  setFile,
  setMomento,
  setHoraEnvio,
  setHoraEnvioDatePicker,
  setType,
  setDescricao,
  setCampanhaExist,
  setCampanhaList,
  setRows,
  setTabela,
  setCheckSair,
  setTableConfig,
  message,
  setMessage
}) {
  console.log({ message })
  // return;
  let tabelaFormatted = []

  if (functionType === 'meu-chat') {
    tabela.map((item) => {
      if (item.check) {
        tabelaFormatted.push(item)
      }
    })

    if (tabelaFormatted.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Ops..',
        text: 'Você não selecionou nenhum contato',
        confirmButtonText: 'Sair'
      })
      return
    }
  } else {
    tabelaFormatted = tabela
  }
  /////////////////////////////////////////////
  /////////////////////////////////////////////
  /////////////////////////////////////////////
  if (!block) {
    if (user === null || user === undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Ops..',
        text: 'Selecione um whatsapp de envio',
        confirmButtonText: 'Sair'
      })
      return
    }

    if (descricao === '' && campanhaExist === false) {
      Swal.fire({
        icon: 'error',
        title: 'Ops..',
        text: 'Adicione uma Descrição a sua nova campanha',
        confirmButtonText: 'Fechar'
      })
      return
    }
  }

  if (
    ((type === 'imagem-link' ||
      type === 'imagem-botoes' ||
      type === 'imagem' ||
      type === 'arquivo') &&
      imagem === null) ||
    (type === 'video-arquivo' && !campanha)
  ) {
    Swal.fire({
      icon: 'error',
      title: 'Ops..',
      text: 'Upload do arquivo é obrigatorio',
      confirmButtonText: 'Sair'
    })
    return true
  }

  if (
    (type === 'video' || type === 'link' || type === 'imagem-link') &&
    linkUrl === null
  ) {
    Swal.fire({
      icon: 'error',
      title: 'Ops..',
      text: 'Link é obrigatorio',
      confirmButtonText: 'Sair'
    })
    return true
  }

  if ((type === 'imagem-botoes' || type === 'botoes') && btn1 === null) {
    Swal.fire({
      icon: 'error',
      title: 'Ops..',
      text: 'O primeiro botão é obrigatorio',
      confirmButtonText: 'Sair'
    })
    return true
  }

  if (momento === 'after' && horaEnvio === null) {
    Swal.fire({
      icon: 'error',
      title: 'Ops..',
      text: 'Necessario selecionar a hora do envio',
      confirmButtonText: 'Sair'
    })
    return true
  }

  /////////////////////////////////////////////
  /////////////////////////////////////////////
  /////////////////////////////////////////////
  /////////////////////////////////////////////
  setLoading(true)

  const formData = new FormData()

  let result = {}

  formData.append('campanha', campanha ? campanha : '')
  formData.append(
    'tabela',
    tabelaFormatted ? JSON.stringify(tabelaFormatted) : ''
  )
  formData.append('type', type ? type : '')
  formData.append('user', user ? user : '')
  formData.append('descricao', descricao ? descricao : '')
  formData.append('momento', momento ? momento : '')
  formData.append('horaEnvio', horaEnvio ? horaEnvio : '')
  formData.append(
    'message',
    !block ? (message ? message : '') : mensagemCampanhaExistente
  )
  formData.append('imagem', imagem ? imagem : '')
  formData.append('btn1', btn1 ? btn1 : '')
  formData.append('btn2', btn2 ? btn2 : '')
  formData.append('btn3', btn3 ? btn3 : '')
  formData.append('nomeDoArquivo', nomeDoArquivo ? nomeDoArquivo : '')
  formData.append('descricaoLink', descricaoLink ? descricaoLink : '')
  formData.append('tituloLink', tituloLink ? tituloLink : '')
  formData.append('linkUrl', linkUrl ? linkUrl : '')
  formData.append('linkAction', linkAction ? linkAction : '')
  formData.append('checkSair', checkSair ? 1 : 0)
  formData.append('campanhaExist', campanhaExist)
  formData.append('zapMais', zapMais)

  result = await Services.sendMessage({ formData })

  console.log('============')
  console.log(result)

  if (result.status === 200) {
    setLoading(false)
    Swal.fire({
      icon: 'success',
      title: 'Enviada',
      text: 'Campanha Enviada com Sucesso',
      confirmButtonText: 'Sair'
    }).then(() => {
      //CLEAN FORMS
      setImagem(null)
      setBtn1(null)
      setBtn2(null)
      setBtn3(null)
      setUser(null)
      setDescricaoLink(null)
      setTituloLink(null)
      setLinkUrl(null)
      setLinkAction(null)
      setCampanha(null)
      setNomeDoArquivo('')
      setFile(null)
      setMomento('now')
      setHoraEnvio(null)
      setHoraEnvioDatePicker(null)
      setType('texto')
      setDescricao('')
      setCampanhaExist(false)
      setCampanhaList([])
      setRows([])
      setTabela([])
      setCheckSair(false)
      setMessage('')
      setTableConfig((prev) => ({
        ...prev,
        total: 0
      }))
    })
    return true
  } else {
    setLoading(false)
    Swal.fire({
      icon: 'error',
      title: 'Ops..',
      text: 'Falha no Envio da Campanha',
      confirmButtonText: 'Sair'
    })
    return false
  }
}

export const handleFileSelected = (
  e,
  setFile,
  setLoading,
  setTabela,
  setTableConfig,
  setRows,
  tableConfig,
  type = 'popular',
  setGrupo
) => {
  setLoading(true)
  const files = Array.from(e.target.files)
  setFile(files[0])

  const workbook = new Excel.Workbook()
  const reader = new FileReader()
  reader.readAsArrayBuffer(files[0])
  const dados = []
  reader.onload = () => {
    const buffer = reader.result

    workbook.xlsx.load(buffer).then((workbook) => {
      workbook.eachSheet((sheet) => {
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex > 1) {
            const line = row.values
            let name = line[1]

            if (!line[1] && line[2]) {
              name = '-'
            }

            if (typeof name !== 'string') {
              name = ''
            }

            if (name?.toUpperCase() !== 'NOME') {
              let infoFone = onlyUnsignedNumbers(String(line[2]))

              if (infoFone.startsWith('+55')) {
                infoFone = infoFone.replace('+55', '')
              }

              if (infoFone.startsWith('55')) {
                infoFone = infoFone.replace('55', '')
              }

              const user = {
                id: Number(rowIndex) - 1,
                name: name === '-' ? ' ' : name,
                fone: `55${infoFone}`
              }
              dados.push(user)
            }
          }
        })

        ////////////Send Mensage

        const numerosTelefoneUnicos = {}

        let dadosSemRepeticoes = dados.filter(function (item) {
          // Verificar se o número de telefone já existe no objeto de números de telefone únicos
          if (!numerosTelefoneUnicos[item.fone]) {
            // Se não existir, marcar o número de telefone como encontrado
            numerosTelefoneUnicos[item.fone] = true
            return true
          }
          return false
        })

        console.log({ dadosSemRepeticoes })
        if (obj.plano === 'BASIC') {
          dadosSemRepeticoes = dadosSemRepeticoes.slice(0, 399)
        }

        setTabela(dadosSemRepeticoes)
        if (type !== 'popular') {
          setTableConfig((prev) => ({
            ...prev,
            total: dadosSemRepeticoes.length
          }))
          const filterDados = dadosSemRepeticoes.slice(
            0,
            Number(tableConfig.rowsPerPage)
          )
          console.log({ dadosSemRepeticoes })
          setRows(filterDados)
          setGrupo(false)
          setLoading(false)
        }
      })
    })
  }

  setLoading(false)
}

export const handleFileSelectedDropzone = (
  acceptedFiles,
  setFile,
  setLoading,
  setTabela,
  setTableConfig,
  setRows,
  tableConfig,
  type = 'popular',
  setGrupo,
  Swal
) => {
  setLoading(true)
  setFile(acceptedFiles[0])

  const workbook = new Excel.Workbook()
  const reader = new FileReader()
  reader.readAsArrayBuffer(acceptedFiles[0])
  const dados = []
  reader.onload = () => {
    const buffer = reader.result

    workbook.xlsx.load(buffer).then((workbook) => {
      workbook.eachSheet((sheet) => {
        const quantidadeLinhas = sheet.rowCount;
        sheet.eachRow((row, rowIndex) => {
          if (rowIndex > 1) {
            const line = row.values
            let name = line[1]

            if (!line[1] && line[2]) {
              name = '-'
            }

            if (typeof name !== 'string') {
              name = ''
            }

            if (name?.toUpperCase() !== 'NOME') {

              let infoFone = onlyUnsignedNumbers(String(line[2]))
              if(infoFone === "" || infoFone === null || infoFone === undefined || infoFone.length < 10){
                return;
              }

              if (infoFone.startsWith('+55')) {
                infoFone = infoFone.replace('+55', '')
              }

              if (infoFone.startsWith('55')) {
                infoFone = infoFone.replace('55', '')
              }

              const user = {
                id: Number(rowIndex) - 1,
                name: name === '-' ? ' ' : name,
                fone: `55${infoFone}`
              }
              dados.push(user)
            }
          }
        })

        ////////////Send Mensage

        const numerosTelefoneUnicos = {}
        const numerodeItemsRemovidos = Number(quantidadeLinhas) - Number(dados.length);

        let dadosSemRepeticoes = dados.filter(function (item) {
          if (!numerosTelefoneUnicos[item.fone]) {
            numerosTelefoneUnicos[item.fone] = true
            return true
          }
          return false
        })

        if (obj.plano === 'BASIC') {
          dadosSemRepeticoes = dadosSemRepeticoes.slice(0, 399)
        }

        setTabela(dadosSemRepeticoes)
        if (type !== 'popular') {
          setTableConfig((prev) => ({
            ...prev,
            total: dadosSemRepeticoes.length
          }))
          const filterDados = dadosSemRepeticoes.slice(
            0,
            Number(tableConfig.rowsPerPage)
          )
          setRows(filterDados)
          setGrupo(false)
          setLoading(false)

          if(numerodeItemsRemovidos > 0){
            Swal.fire({
              icon: 'warning',
              text: `Foram removidos ${numerodeItemsRemovidos} contatos inválidos da sua lista`,
              confirmButtonText: 'Sair'
            }).then(() => {})
          }

          if(dadosSemRepeticoes.length > 5000){
            Swal.fire({
              icon: 'warning',
              title: "⚠️ Atenção! ⚠️",
              html: `
                <p style="text-align: justify; font-size: 1em;">
                  Você está prestes a enviar mensagens para uma lista que contém mais de <b>5.000 contatos</b>.
                </p>
                <p style="text-align: justify; font-size: 1em;">
                  É importante lembrar que enviar mensagens em alto volume pelo WhatsApp, especialmente se você estiver utilizando um número novo ou não verificado, pode levar ao bloqueio do seu número.
                </p>
                <p style="text-align: justify; font-size: 1em;">
                  O WhatsApp monitora atividades suspeitas, e disparos em massa podem ser identificados como spam, resultando na suspensão temporária ou permanente da sua conta.
                </p>
                <p style="text-align: justify; font-size: 1em;">
                  <b>Recomendação:</b> Utilize um número que já tenha um histórico de uso, com interações regulares, para minimizar os riscos de bloqueio. Além disso, considere fazer envios em lotes menores para um público específico e evite mensagens repetitivas ou sem personalização para proteger a integridade do seu número.
                </p>
              `,
              confirmButtonText: '<i class="fas fa-check"></i> Entendi',
              confirmButtonColor: '#3085d6',
              customClass: {
                popup: 'swal2-popup-custom',
                title: 'swal2-title-custom',
                content: 'swal2-content-custom',
              }
            }).then(() => {});
            
          }


        }
      })
    })
  }

  setLoading(false)
}

export const checkInstanceSms = async (
  value,
  setLoading,
  setUser,
  Services,
  setBlock
) => {
  setLoading(true)
  const result = await Services.checkInstanceSms({ value: value.value })

  if (result.status === 200) {
    if (result.data?.data?.isSms) {
      setUser(result.data?.data?.id)
      setBlock(false)
    } else {
      setUser(result.data?.data?.id)
      setBlock(true)
    }
  } else {
    setUser(null)
    setBlock(true)
  }
  setLoading(false)
}

export const handlerSms = async (
  message,
  tabela,
  user,
  Services,
  setLoading,
  Swal
) => {
  setLoading(true)
  const result = await Services.sendSms({ message, tabela, user })

  console.log('==============')
  console.log({ result })

  if (result.status === 200) {
    setLoading(false)
    Swal.fire({
      icon: 'success',
      title: 'Enviada',
      text: 'Campanha Enviada com Sucesso',
      confirmButtonText: 'Sair'
    }).then(() => {})
    return true
  } else {
    setLoading(false)
    Swal.fire({
      icon: 'error',
      title: 'Ops..',
      text: 'Falha no Envio da Campanha',
      confirmButtonText: 'Sair'
    })
    return false
  }
}
