import { contactSchema } from 'components/modalAddContacts/addContact.schema'
import { messageTypeOptions, momentOptions } from 'types/envio'
import { z } from 'zod'

const momentSchema = z.enum(momentOptions)
const messageTypeSchema = z.enum(messageTypeOptions)

export const envioSimplificado = z
  .object({
    instanceId: z
      .number({ message: 'Selecione a instância' })
      .positive('Selecione a instância'),
    message: z
      .string({ message: 'Escreva uma mensagem para ser enviada' })
      .min(1, 'Escreva uma mensagem para ser enviada'),
    moment: momentSchema,
    scheduleTime: z.date({ message: 'Defina o horário do envio' }).optional(),
    type: messageTypeSchema,
    file: z.any().optional(), // Make file optional initially
    fileName: z
      .string({ message: 'Informe o nome do arquivo que deve ser enviado' })
      .optional(), // Make fileName optional initially
    link: z
      .string({ message: 'Informe o link que deve ser enviado' })
      .optional(), // Make link optional initially
    table: z.array(contactSchema, {
      message: 'É necessário adicionar no mínimo 1 contato'
    })
  })
  .refine(
    (data) =>
      data.moment === 'now' || (data.moment === 'after' && data.scheduleTime),
    {
      message: 'Defina o horário do envio',
      path: ['scheduleTime'] // This will make the error appear on the scheduleTime field
    }
  )
  .refine(
    (data) =>
      !['imagem', 'video-arquivo', 'audio-arquivo', 'arquivo'].includes(
        data.type
      ) || data.file,
    {
      message: 'Arquivo é obrigatório para o tipo selecionado',
      path: ['file'] // This will make the error appear on the file field
    }
  )
  .refine(
    (data) => data.type !== 'link' || (data.type === 'link' && data.link),
    {
      message: 'Informe o link que deve ser enviado',
      path: ['link'] // This will make the error appear on the link field
    }
  )
  .refine((data) => data.table.length > 0, {
    message: 'É necessário adicionar no mínimo 1 contato',
    path: ['table'] // This will make the error appear on the table field
  })

export type EnvioSimplificadoSchema = z.infer<typeof envioSimplificado>
