/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label
} from 'reactstrap'
import { toast } from 'react-toastify'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
///MODAIS
import Select from 'react-select'

import Services from '../../services/auth'
import {
  formatCurrency,
  cnpjMask,
  phoneMask,
  formatDateT
} from '../../util/string'
import './styles.css'

import 'chartist/dist/scss/chartist.scss'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { format } from 'date-fns'
import { ModalAccess } from '../../components/modalAccess'

registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Cod', field: 'id' },
  { label: 'Usuario', field: 'name' },
  { label: 'Status', field: 'status', html: true },
  { label: 'Acesso', field: 'instancia_name' },
  { label: 'Data', field: 'created_at' }
]

const Security = () => {
  const { listGrupos, listAcess } = Services
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [idGrupo, setIdGrupo] = useState(false)
  const [showModalGrupo, setShowModalGrupo] = useState(false)
  const [showModalAddGrupo, setShowModalAddGrupo] = useState(false)

  const handleClose = () => {
    setLoading(false)
  }
  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      pagination: false,
      delete: true,
      more: true,
      uploadGrupo: false
    },
    config: {
      deleteText: 'Deletar Acesso'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null,
      sellerId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 100
  })

  const loadUsers = useCallback(async () => {
    setLoading(true)

    const response = await listAcess();

    if (response.status === 200) {
      const responseData = response?.data?.data
      const formattedData = responseData.map((item) => ({
        ...item,
        created_at: formatDateT(item.created_at),
        status: item.status ? '<span>Ativo</span>' : '<span>Inativo</span>'
      }))

      console.log("===================")
      console.log(formattedData)
      setRows(formattedData)
      setLoading(false)
    } else {
      toast.error(response)
      setLoading(false)
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  useEffect(() => {
    loadUsers()
  }, [tableConfig.rowsPerPage, tableConfig.currentPage, tableConfig.filters])

  const handleCreateLinkInstance = (row) => {
    if (row?.checkApi) {
      const Url = `${process.env.REACT_APP_SITE_URL}ZAapMaisDetails/${row?.id}`
      window.open(
        Url,
        '_blank' // <- This is what makes it open in a new window.
      )
    } else {
      const idZapi = row?.id_zapi
      const tokenZapi = row?.token_zapi
      const Url = `${process.env.REACT_APP_SITE_URL}ZApiDetails/${idZapi}/${tokenZapi}`

      window.open(
        Url,
        '_blank' // <- This is what makes it open in a new window.
      )
    }
  }

  const handlerCriarGrupo = async () => {
    setShowModalGrupo(true)
  }

  const onCloseModalGrupo = () => {
    setShowModalGrupo(false)
  }
  const onCloseModalAddGrupo = () => {
    setShowModalAddGrupo(false)
  }

  const onUploadContatos = async (row) => {
    setShowModalAddGrupo(true)
    setIdGrupo(row?.id)
  }

  const onDeleteGrupo = async (row) => {
    let response = await Services.deleteAcess({ id: row?.id })
    if (response.status === 200) {
      toast.success('Acesso excluído com sucesso!')
      loadUsers()
    }
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}

      <div className="page-content">
        <MetaTags>
          <title>Nível de Acesso | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Nível de Acesso  | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Nível de Acesso 
                  </li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col
                xl={12}
                md={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handlerCriarGrupo()}
                  className="newBg"
                  style={{
                    marginBottom: '-50px',
                    marginRight: 5,
                    marginTop: 5,
                    cursor: 'pointer',
                    backgroundColor: '#0D071B !important',
                    color: 'white'
                  }}
                >
                  Liberar Acesso
                </Button>
              </Col>
            </Row>
          </div>
          <DatatableTables
            title={'Meus Acessos'}
            columns={columns}
            rows={rows}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            onResendTickets={handleCreateLinkInstance}
            onUploadContatos={onUploadContatos}
            onDelete={onDeleteGrupo}
          />
        </Container>
      </div>
      <ModalAccess
        onClose={onCloseModalGrupo}
        loadUsers={loadUsers}
        showModal={showModalGrupo}
      />
    </React.Fragment>
  )
}

Security.propTypes = {
  t: PropTypes.any
}

export default Security
