/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useRef, useEffect, useState } from 'react'
import StatusController from '../../components/statusController'
import MetaTags from 'react-meta-tags'
import FrontTable from '../../components/TableFrontSide'
import { stateFromHTML } from 'draft-js-import-html'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Input,
  FormGroup,
  Label
} from 'reactstrap'
import Switch from 'react-switch'
import Swal from 'sweetalert2'

import { toast } from 'react-toastify'
import { Send } from 'react-feather'
import styles from './styles.module.scss'
import { Loading } from '../../components/Loading'
import Select from 'react-select'

import 'jodit/build/jodit.min.css'
// import images
import Services from '../../services/sendMessage'
import draftToHtml from 'draftjs-to-html'
import {
  handlerUser,
  checkInstanceSms,
  handlerSms,
  handlerFile,
  handleFileSelected
} from '../../services/instanceFunctions'
import { useUserInfo } from 'context/userContext'
import ServicesSegmentation from '../../services/segmentation'

import './styles.css'

import 'chartist/dist/scss/chartist.scss'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { Form } from 'react-bootstrap'
import { listType } from '../../util/mocks'

import ptBR from 'date-fns/locale/pt-BR'
import { format } from 'date-fns'
import { convertToRaw, EditorState, Modifier } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ModalQrcode } from 'components/modalQrcode'
import { ModalQrcodeZApi } from 'components/modalQrcodeZApi'
import { TextArea } from '../../components/TextArea'
registerLocale('pt-BR', ptBR)

const columns = [
  // { label: 'Cod', field: 'id' },
  { label: 'Nome', field: 'name' },
  { label: 'Telefone', field: 'fone' }
]

const obj = JSON.parse(localStorage.getItem('authUser'))

const customStyles = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
}

const EnviarSms = () => {

  const { isRoot, userPlan } = useUserInfo();

  const [imagem, setImagem] = useState(null)
  const [btn1, setBtn1] = useState(null)
  const [btn2, setBtn2] = useState(null)
  const [btn3, setBtn3] = useState(null)
  const [tituloLink, setTituloLink] = useState(null)
  const [linkUrl, setLinkUrl] = useState(null)
  const [campanha, setCampanha] = useState(null)
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const [nomeDoArquivo, setNomeDoArquivo] = useState('')
  const [file, setFile] = useState(null)
 
  const [message, setMessage] = useState('')

  const [type, setType] = useState('texto')
  const [user, setUser] = useState(null)
  const [zapMais, setZapMais] = useState(false)
  const [instanceName, setInstanceName] = useState(null)
  const [openModalQrcode, setOpenModalQrcode] = useState(false)
  const [instanceStateChecked, setInstanceStateChecked] = useState(false)
  const [rendered, setRendered] = useState(false)

  const [msgError, setMsgError] = useState(false)
  const [block, setBlock] = useState(false)
  const [mensagemCampanhaExistente, setmensagemCampanhaExistente] =
    useState(false)

  const [campanhaExist, setCampanhaExist] = useState(false)
  const [campanhaList, setCampanhaList] = useState([])
  const [grupo, setGrupo] = useState([])
  const [grupoList, setGrupoList] = useState([])

  const [segmentacoesList, setSegmentacoesList] = useState([])
  const [segmentation, setSegmentation] = useState([])

  const [rows, setRows] = useState([])

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      order: true,
      more: false,
      pagination: true,
      createInstancia: true
    },
    config: {
      resendPurchaseText: 'Reenviar Qr codes da compra'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null,
      sellerId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 15
  })


  const [listUser, setListUser] = useState([])
  const [rowsUser, setRowsUser] = useState([])
  const [tabela, setTabela] = useState([])

  const [loading, setLoading] = useState(false)
  const [checkSair, setCheckSair] = useState(false)

  const loadDadosTable = async () => {
    const init = tableConfig.currentPage * tableConfig.rowsPerPage
    const finish = init + tableConfig.rowsPerPage

    const filterDados = tabela.slice(init, finish)
    setRows(filterDados)
  }

  const loadInstance = async () => {
    const result = await Services.getInstance()
    if (result.status === 200) {
      const info = []
      setRowsUser(result.data.data.items)
      result.data.data.items.map((item) => {
        info.push({
          label: item.name,
          value: item.id,
          tokenZapi: item.token_zapi,
          idZapi: item.id_zapi,
          useLocal: item.use_local
        })
      })
      setListUser(info)
    }
  }


  const handlerSegmentation = async (e) => {
    setSegmentation(e.value)
    const result = await ServicesSegmentation.getSegmentedClients(e.value);

    if (result.status === 200) {
      const formattedData = result?.data?.data?.map((client) => {
        return {
          name: client.name,
          fone: client.telefone
        };
      });

      setTabela(formattedData)
      setRows(formattedData)
      setTableConfig({
        ...tableConfig,
        total: formattedData.length
      })
    } else {
      toast.error(result.data.message)
    }
  }

  const getSegmentacoes = async () => {
    const result = await ServicesSegmentation.listSegmentations(user)
    if (result.status === 200) {
      const formattedData = result?.data?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name
        }
      });
      setSegmentacoesList(formattedData)
    }
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }
    if (rendered) {
      loadInstance()
    }
  }, [rendered])


  useEffect(() => {
    if (!rendered) return
    loadDadosTable()
  }, [tableConfig.currentPage, rendered])

  const handleAddVariable = (variable) => {
    const cursorPosition =
      document.getElementById('message-text-area')?.selectionStart
    const newMessage = `${message.substring(
      0,
      cursorPosition
    )} ${variable} ${message.substring(cursorPosition)}`

    setMessage(newMessage)
  }

  const handleClose = () => {
    setLoading(false)
  }

  useEffect(() => {
    if (user) {
      getSegmentacoes()
    }
  }, [user])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <div className="page-content">
        <MetaTags>
          <title>Envio de Sms | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Envio de Sms | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo ao Disparador Zap+
                  </li>
                </ol>
              </Col>
              <Col
                md={4}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                {user && (
                  <>
                    <span
                      style={{
                        marginRight: 10,
                        fontWeight: 500,
                        fontSize: 20
                      }}
                    >
                      {!block ? 'Autorizado' : 'Não Autorizado'}
                    </span>
                    <StatusController statusInstancia={!block ? 1 : 0} />
                  </>
                )}
              </Col>
            </Row>
          </div>
          <Row>
            <Card style={{ padding: 20, paddingBottom: 150 }}>
              <div>
                <span
                  style={{
                    fontSize: 14
                  }}
                >
                  • A planilha DEVE contar na ordem os campos "nome" e
                  "telefone" (<b>Sem cabeçalho, apenas os valores</b>)
                </span>
                <br></br>
                <span
                  style={{
                    fontSize: 14
                  }}
                >
                  • O campo "telefone" não exige um formato. Pode ser tanto (94)
                  99200-0000 como 94992000000, etc
                </span>
              </div>
              <Row style={{ marginTop: 30 }}>
                <Col xl={4} md={4}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Quem está enviando a mensagem?
                    </Label>
                    <Select
                      value={
                        listUser.find((a) => a.value === user)
                          ? listUser.find((a) => a.value === user)
                          : null
                      }
                      onChange={(value) =>
                        checkInstanceSms(
                          value,
                          setLoading,
                          setUser,
                          Services,
                          setBlock
                        )
                      }
                      options={listUser}
                      classNamePrefix="select2-selection"
                      placeholder="Selecione..."
                    />
                  </FormGroup>
                </Col>
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col xl={4} md={4}>
                    <FormGroup>
                      <Label for="exampleEmail">Planilha de Excel</Label>
                      <div className="file-uploader">
                        <Input
                          type="file"
                          label="Upload"
                          accept=".xlsx"
                          onChange={(e) =>
                            handleFileSelected(
                              e,
                              setFile,
                              setLoading,
                              setTabela,
                              setTableConfig,
                              setRows,
                              tableConfig,
                              'planilha',
                              setGrupo
                            )
                          }
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col xl={1} md={1}>
                        <span
                          style={{
                            fontWeight: 500,
                            fontSize: 20,
                            marginTop: 20,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          OU
                        </span>
                      </Col>
                      <Col xl={3} md={3}>
                        <FormGroup>
                          <Label for="exampleEmail">Segmentações</Label>
                          <Select
                            value={
                              segmentacoesList.find((a) => a.value === segmentation)
                                ? segmentacoesList.find((a) => a.value === segmentation)
                                : null
                            }
                            onChange={(value) => handlerSegmentation(value)}
                            options={segmentacoesList}
                            classNamePrefix="select2-selection"
                            placeholder="Selecione..."
                          />
                        </FormGroup>
                      </Col>
                      </Row>
         
              <Row style={{ marginTop: 10 }}>
                <Col xl={12} md={12}>
                  <FrontTable
                    title={'Sua Tabela'}
                    columns={columns}
                    rows={rows}
                    tableConfig={tableConfig}
                    setTableConfig={setTableConfig}
                  />
                </Col>
                <Col xl={8} md={12}>
                  {!block && (
                    <FormGroup>
                      <TextArea
                        value={message}
                        setValue={setMessage}
                        onAddVariable={handleAddVariable}
                      />
                    </FormGroup>
                  )}
                </Col>
                <Col
                  xl={4}
                  md={6}
                  style={{
                    marginTop: 20,
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    display: 'flex'
                  }}
                >
                    {!block && (
                    <Button
                      id="basic-button"
                      style={{
                        padding: '15px 30px 15px 30px',
                        backgroundColor: '#0D071B',
                        color: 'white',
                        fontWeight: 500
                      }}
                      onClick={(e) => {
                      handlerSms(
                        message,
                        tabela,
                        user,
                        Services,
                        setLoading,
                        Swal
                      )
                      }}
                    >
                      <Send /> Enviar Mensagem
                    </Button>
                     )}
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

EnviarSms.propTypes = {
  t: PropTypes.any
}

export default EnviarSms
