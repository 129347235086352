import React, { ChangeEvent } from 'react'
import { Form, FormGroup } from 'react-bootstrap'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

registerLocale('pt-BR', ptBR)

export interface DatePickerComponentProps {
  value: Date
  onChange: (date: Date) => void
  label: string
}

export const DatePickerComponent = ({
  label,
  value,
  onChange
}: DatePickerComponentProps) => {
  const inputId = `input-${Math.random().toString(36).substring(7)}`

  return (
    <FormGroup>
      <Form.Label htmlFor={inputId}>{label}</Form.Label>
      <ReactDatePicker
        id={inputId}
        className="form-control"
        selected={value}
        onChange={onChange}
        placeholderText="dd/mm/aaaa"
        dateFormat="dd/MM/yyyy"
        locale="pt-BR"
      />
    </FormGroup>
  )
}
