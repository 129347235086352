import instance from 'services/instance'
import Swal from 'sweetalert2'

export const useInstance = () => {
  const handleRefreshInstance = async (instanceId: number) => {
    Swal.fire({
      title: 'Atualizando instância',
      showLoaderOnConfirm: true,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading()
      },
      willClose: () => {
        Swal.hideLoading()
      }
    })
    const result = await instance.refreshInstance(instanceId)
    Swal.close()
    if (result.status === 200) {
      Swal.fire({
        icon: 'success',
        title: 'Instância atualizada',
        confirmButtonText: 'Fechar'
      }).then(() => {
        window.location.reload()
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: result?.response?.data?.message
          ? result?.response?.data.message[0]
          : 'Não foi possível atualizar',
        confirmButtonText: 'Fechar'
      })
    }
    return true
  }
  const confirmRefreshInstance = (instanceId: number) => {
    Swal.fire({
      icon: 'info',
      title: 'Atualizar instância?',
      text: 'Vamos criar uma nova instância e substituir a atual',
      confirmButtonText: 'Atualizar',
      cancelButtonText: 'Fechar',
      showCancelButton: true
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        handleRefreshInstance(instanceId)
      }
    })
  }

  return { confirmRefreshInstance }
}
