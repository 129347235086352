/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col
} from 'reactstrap'

import './styles.css'

const Tutorial = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Tutorial | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo aos nossos tutoriais
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
           
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Tutorial.propTypes = {
  t: PropTypes.any
}

export default Tutorial
