import { api } from './axios'

const Services = {
  createUser: async (proṕs) => {
    const response = await api
      .post('/create/user', proṕs)
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  LoginNative: async (cpfEmail, senha) => {
    const response = await api
      .post('/signin', {
        cpfEmail,
        password: senha,
        organizer: true
      })
      .then(({ ...response }) => {
        return response
      })
      .catch(({ ...response }) => {
        return response
      })
    return response
  },
  checkPhoneIsWhatsApp: async (phone) => {
    const response = await api
      .get('/customer/check-phone-is-whatsapp', { params: { phone } })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  CreateLoginNative: async ({
    email,
    cpf,
    nomeCompleto,
    telefone,
    senha,
    nascimento,
    organizer
  }) => {
    console.log(nomeCompleto)
    const response = await api
      .post('/signup', {
        email,
        cpf,
        name: nomeCompleto,
        fone: telefone,
        nascimento,
        password: senha,
        organizer
      })
      .then(({ ...response }) => {
        console.log(response)
        return response
      })
      .catch(({ ...response }) => {
        console.log(response)
        return response
      })
    return response
  },
  getInfoUserDash: async () => {
    const response = await api
      .get('/get-user-dash')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  listAcess: async () => {
    const response = await api
      .get('/list/access')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  listInstance: async ({ rowsPerPage, page, searchTerm }) => {
    console.log({
      rowsPerPage,
      page,
      searchTerm
    })
    const response = await api
      .get('/instance/listAll', {
        params: {
          rowsPerPage,
          page,
          searchTerm
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  deleteAcess: async ({ id }) => {
    const response = await api
      .delete(`/access/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  deleteGrupo: async ({ id }) => {
    const response = await api
      .delete(`/group/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  exportGroupContacts: async ({ groupId }) => {
    const response = await api
      .post(`/group/exportGroup/${groupId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  listGrupos: async ({ rowsPerPage, page, searchTerm, instanceId }) => {
    const response = await api
      .get('/groups', {
        params: {
          rowsPerPage,
          page,
          searchTerm,
          instanceId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  listClients: async (params) => {
    const response = await api
      .get('/clients/list', {
        params: {
          ...params
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  listClientsAll: async (params) => {
    const response = await api
      .get('/clients/list-all', {
        params: {
          ...params
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  addClient: async (clientData) => {
    try {
      const response = await api.post('/clients', clientData)
      return response
    } catch (error) {
      if (error.response) {
        return Promise.reject(error.response)
      } else {
        return Promise.reject({
          status: 500,
          data: { message: 'Erro ao conectar com o servidor.' }
        })
      }
    }
  },
  editClient: async (clientId, clientData) => {
    try {
      const response = await api.put(`/clients/edit/${clientId}`, clientData)
      return response
    } catch (error) {
      return error.response || error
    }
  },
  deleteClient: async (clientId) => {
    console.log(
      `Enviando solicitação DELETE para o cliente com ID: ${clientId}`
    )
    const response = await api
      .delete(`/clients/delete/${clientId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    console.log('Resposta da API (deleteClient):', response)
    return response
  },
  filterClients: async (filterData) => {
    const response = await api
      .get(`/clients/filter`, {
        params: filterData
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  loadCamposExtras: async (instanceId) => {
    const response = await api
      .get(`/extraFields?instanceId=${instanceId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  loadPrivacy: async () => {
    const response = await api
      .get(`/privacy`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  acceptPrivacy: async () => {
    const response = await api
      .get(`/privacy/accept`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  listUsers: async ({ rowsPerPage, page, searchTerm }) => {
    console.log({
      rowsPerPage,
      page,
      searchTerm
    })
    const response = await api
      .get('/user/list', {
        params: {
          rowsPerPage,
          page,
          searchTerm
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },

  updateUserDashboard: async ({
    name,
    address_cep,
    address_city,
    address_district,
    address_number,
    address_state,
    address_street,
    cpf,
    fone,
    nascimento
  }) => {
    const response = await api
      .post('/user/update', {
        name,
        address_cep,
        address_city,
        address_district,
        address_number,
        address_state,
        address_street,
        cpf,
        fone,
        nascimento
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
export default Services
