import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Label,
  Card,
  CardBody,
} from 'reactstrap'

import { toast } from 'react-toastify'

import { Loading } from '../../components/Loading'
import Select from 'react-select'
import {
  formatDateT,
} from '../../util/string'
import './styles.css'
import ServicesSegmentation from '../../services/segmentation'
import Services from '../../services/sendMessage'
import DatatableTables from '../../components/Table'
import { ModalSegmentacao } from '../../components/modalSegmentacao'

import { SelectComponent } from '../../components/selectComponent'

const ITEM_PER_PAGE = 4; 

const columns = [
  { label: 'ID', field: 'id' },
  { label: 'Nome', field: 'name' },
  { label: 'Descrição', field: 'description' },
  { label: 'Criado Em', field: 'created_at' }
];

const Segmentation = () => {
  const [rows, setRows] = useState([]);
  const [clientRows, setClientRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [instanciasList, setInstanciasList] = useState([]);
  const [rendered, setRendered] = useState(false);
  const [segmentation, setSegmentation] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); 
  const [modal, setModal] = useState(false)

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      pagination: true,
      view: true
    },
    filters: {},
    total: 0,
    currentPage: 0,
    userId: false,
    rowsPerPage: 50
  });

  const loadInstancias = async () => {
    const response = await Services.loadInstancias();

    if (response.status === 200) {
      const data = response?.data?.data?.data.map((item) => ({
        label: item.name,
        value: item.id
      }));

      setInstanciasList(data);
    } else {
      toast.error(response?.msg);
    }
  };

  const getSegmentations = async (instanceId) => {  
    setLoading(true);
    
    const result = await ServicesSegmentation.listSegmentations(instanceId);

    if (result.status === 200) {
      const formattedData = result?.data?.data?.map((item) => {
        return {
          ...item,
          created_at: formatDateT(item.created_at),
          updated_at: formatDateT(item.updated_at)
        }
      });

      setTableConfig((prev) => ({
        ...prev,
        total: result?.data?.data?.length
      }));

      setRows(formattedData);
      setLoading(false);
    } else {
      toast.error("Falha ao carregar segmentações");
      setLoading(false);
    }
  };

  const getSegmentedClients = async (id) => {
    setLoading(true);
    
    const result = await ServicesSegmentation.getSegmentedClients(id);

    if (result.status === 200) {
      const formattedData = result?.data?.data?.map((client) => {
        return {
          ...client,
          created_at: formatDateT(client.created_at),
        };
      });

      setClientRows(formattedData);
      setLoading(false);
    } else {
      toast.error("Falha ao carregar clientes segmentados");
      setLoading(false);
    }
  };

  const handlerInstancia = (value) => {
    setTableConfig((prev) => ({
      ...prev,
      userId: value
    }));

    getSegmentations(value);  
  };

  const onView = (value) => {
    setSegmentation(value);
    getSegmentedClients(value.id);
  };
    
  useEffect(() => {
    if (!rendered) {
      setRendered(true);
    }

    if (rendered) {
      loadInstancias();
    }
  }, [rendered]);

  const loadMoreClients = () => {
    setCurrentPage(currentPage + 1);
  };


  const handler = async () => {
    if (tableConfig?.userId) {
      setModal(true)
    } else {
      toast.error("Selecione uma instancia")
    } 
  }

  const onClose  = async () => {
    setModal(false)
    getSegmentations(tableConfig.userId);  
  }

  const paginatedClients = clientRows.slice(0, currentPage * ITEM_PER_PAGE);

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={() => setLoading(false)} />}
      <div className="page-content">
        <MetaTags>
          <title>Segmentações | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Segmentações | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Bem vindo à gestão de Segmentações</li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col
                xl={12}
                md={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button variant="outlined"
                  onClick={() => handler()}
                  className="newBg">  Adicionar Segmentações </Button>
              </Col>
            </Row>
          </div>
          <Row style={{marginTop: 50}}>
            <Col xl={4} md={4} xs={12}>
              <Label for="instancia">Instância</Label>
              <SelectComponent
                options={instanciasList}
                value={
                  instanciasList.find((a) => a.value === tableConfig.userId) || null
                }
                onChange={(value) => handlerInstancia(value?.value)}
                placeholder="Selecione a Instância"
              />
            </Col>
          </Row>

          <Row>
          <Col xl={segmentation ? 8 : 12} md={segmentation ? 8 : 12} xs={12}>
            <DatatableTables
              title={'Lista de Segmentações'}
              columns={columns}
              rows={rows}
              tableConfig={tableConfig}
              setTableConfig={setTableConfig}
              onView={onView}
            />
          </Col>

          {segmentation && (
            
            <Col xl={4} md={4} xs={12}>
              <Card style={{
                padding: 10,
                marginTop: 40
              }}>
                  <h4>{segmentation.name} <b>    ({clientRows.length} Leads)</b> </h4> 
                  <Row>
                    {paginatedClients.map((client, index) => (
                      <Col xl={12} md={12} xs={12} key={index}>
                        <Card style={{ padding: 15}}>
                            <h5>{client.name}</h5>
                            <p><b>Telefone:</b> {client.phone}</p>
                            <p><b>Email:</b> {client.email}</p>
                        </Card>
                      </Col>
                    ))}
                  </Row>

                  {/* Botão de Carregar Mais */}
                  {paginatedClients.length < clientRows.length && (
                    <div className="text-center">
                      <Button onClick={loadMoreClients} color="primary">
                        Carregar Mais
                      </Button>
                    </div>
                  )}
               </Card>
            </Col>
           
          )}
           </Row>
        </Container>
      </div>
      <ModalSegmentacao
        onClose={() => onClose()}
        showModal={modal}
        instanceId={tableConfig.userId}
      />
    </React.Fragment>
  )
}

Segmentation.propTypes = {
  t: PropTypes.any
}

export default Segmentation;
