import React from 'react'
import { Button } from 'react-bootstrap'
import { CardTitle } from 'reactstrap'

export interface HeaderProps {
  addOrganizer?: () => void
  title: string
}

export const Header = ({ addOrganizer, title }: HeaderProps) => {
  return (
    <>
      {addOrganizer && (
        <div className="organizzer">
          <Button type="button" color="danger" onClick={() => addOrganizer()}>
            Adicionar Organizador
          </Button>
        </div>
      )}
      <CardTitle className="h4">{title}</CardTitle>
    </>
  )
}
