/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Input,
  FormGroup,
  Label
} from 'reactstrap'
import SwitchWithLabel from '../../components/Switch'
import { Loading } from '../../components/Loading'
import './styles.css'
import { IA } from '../../services/chatgpt'
import 'chartist/dist/scss/chartist.scss'
import { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import Select from 'react-select'
import { toast } from 'react-toastify'

registerLocale('pt-BR', ptBR)
const obj = JSON.parse(localStorage.getItem('authUser'))

const Copy = () => {

  const { createCopy } = IA
  const [textarea, setTextarea] = useState('')
  const [resposta, setResposta] = useState('')
  const [indicadores, setIndicadores] = useState({
    used: 0,
    limit: 3
  })

  const [loading, setLoading] = useState(false)

  const [informal, setInformal] = useState(true)
  const [emoji, setEmoji] = useState(true)
  const [hastags, setHastags] = useState(false)
  const [tam, setTam] = useState('Pequeno')
  const [tamList, setTamList] = useState([
    { label: 'Pequeno', value: 'Pequeno' },
    { label: 'Medio', value: 'Medio' },
    { label: 'Grande', value: 'Grande' }
  ])
  const [redeSocial, setRedeSocial] = useState('Whatsapp')
  const [redeSocialList, setRedeSocialList] = useState([
    { label: 'Whatsapp', value: 'Whatsapp' },
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Instagram', value: 'Instagram' },
    { label: 'Twitter', value: 'Twitter' },
    { label: 'Linkedin', value: 'Linkedin' },
    { label: 'Telegram', value: 'Telegram' },
    { label: 'Pinterest', value: 'Pinterest' },
    { label: 'Youtube', value: 'Youtube' },
    { label: 'Tiktok', value: 'Tiktok' },
    { label: 'Snapchat', value: 'Snapchat' },
    { label: 'Twitch', value: 'Twitch' },
    { label: 'Discord', value: 'Discord' }
  ])


  const handleClose = () => {
    setLoading(false)
  }

  const handlerSubmit = async () => {
    setResposta('')
    setLoading(true)
    const result = await createCopy({
      textarea,
      informal: informal ? 1 : 0,
      emoji: emoji ? 1 : 0,
      hastags: hastags ? 1 : 0,
      redeSocial,
      tam
    });
    console.log(result)

    if (result.status === 200) {
      setResposta(result.data.msg)
      setIndicadores({
        used: result.data.data?.used,
        limit: result.data.data?.limit
      })
    } else {
      if (result.status === 400) {
        toast.error(result?.data?.msg)
      } else {
        toast.error("Erro ao gerar copys")
      }
    }
    setLoading(false)
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}

      <div className="page-content">
        <MetaTags>
          <title>Copyright | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={12}>
              <br></br>
                <h6 className="page-title">Copyright | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    O novo menu do sistema utiliza inteligência artificial para criar mensagens personalizadas em campanhas de marketing.
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Card style={{ padding: 15 }}>
              {obj.plano === 'PREMIUM' ? (
                <span
                  style={{
                    color: '#1e0746',
                    fontSize: 18,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    fontWeight: '700',
                    marginLeft: 5,
                    marginRight: 5
                  }}
                >Você tem copys ilimitadas</span>
              ) : (
                <span style={{
                  color: 'red',
                  fontSize: 14,
                  fontWeight: '500',
                  marginBottom: 5,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}>
                  Você usou <span
                    style={{
                      color: '#1e0746',
                      fontSize: 18,
                      fontWeight: '700',
                      marginLeft: 5,
                      marginRight: 5
                    }}
                  >{indicadores?.used}</span> Copys de
                  <span
                    style={{
                      color: '#1e0746',
                      fontSize: 18,
                      fontWeight: '700',
                      marginLeft: 5,
                      marginRight: 5
                    }}
                  >{indicadores?.limit}</span>por dia (por instância)
                </span>
              )}


              <Row>
                <Row style={{ marginBottom: 5 }}>
                  <Col xl={3} md={3}>
                    <SwitchWithLabel
                      onChange={() => {
                        setInformal(!informal)
                      }}
                      checked={informal}
                      label="Tom informal"
                    />
                  </Col>
                  <Col xl={3} md={3}>
                    <SwitchWithLabel
                      onChange={() => {
                        setEmoji(!emoji)
                      }}
                      checked={emoji}
                      label="Emoji"
                    />
                  </Col>
                  <Col xl={3} md={3}>
                    <SwitchWithLabel
                      onChange={() => {
                        setHastags(!hastags)
                      }}
                      checked={hastags}
                      label="Hastags"
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: 20 }}>
                  <Col xl={3} md={3}>
                    <FormGroup>
                      <Label for="exampleEmail">Rede Social</Label>
                      <Select
                        value={
                          redeSocialList.find((a) => a.value === redeSocial)
                            ? redeSocialList.find((a) => a.value === redeSocial)
                            : null
                        }
                        onChange={(e) => setRedeSocial(e.value)}
                        options={redeSocialList}
                        classNamePrefix="select2-selection"
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={3} md={3}>
                    <FormGroup>
                      <Label for="exampleEmail">Tamanho do Texto</Label>
                      <Select
                        value={
                          tamList.find((a) => a.value === tam)
                            ? tamList.find((a) => a.value === tam)
                            : null
                        }
                        onChange={(e) => setTam(e.value)}
                        options={tamList}
                        classNamePrefix="select2-selection"
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Col xl={9} md={9}>
                  <FormGroup>
                    <Label>Prompt</Label>
                    <p
                      style={{
                        fontSize: 14,
                        color: '#0D071B',
                        fontWeight: 500
                      }}
                    >Descreva o que você quer enviar para seus clientes</p>
                    <p
                      style={{
                        fontSize: 12,
                        color: '#0D071B',
                        fontWeight: 400
                      }}
                    >Ex.: "All-star na promoção por apenas R$ 99.90" ou "Promoção de natal, descontos de até 70%"</p>
                    <Input
                      type="textarea"
                      style={{ minHeight: '120px' }}
                      value={textarea}
                      maxLength={150}
                      onChange={(e) => setTextarea(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col xl={3} md={3} style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end'
                }}>
                  <Button
                    id="basic-button"
                    style={{
                      padding: '15px 30px 15px 30px',
                      backgroundColor: '#0D071B',
                      color: 'white',
                      fontWeight: 500
                    }}
                    onClick={() => { handlerSubmit() }}
                  >
                    Gerar Mensagem
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: 25 }}>
                <Col xl={12} md={12}>
                  <FormGroup>
                    <Label>Resposta</Label>
                    <Input
                      type="textarea"
                      style={{ minHeight: '350px' }}
                      value={resposta}
                      disabled={true}
                      onChange={(e) => setResposta(e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Copy.propTypes = {
  t: PropTypes.any
}

export default Copy
