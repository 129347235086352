import React, { ChangeEvent } from 'react'
import { Form, FormGroup } from 'react-bootstrap'

export interface InputComponentProps {
  value: string | number
  onChange: (value: ChangeEvent) => void
  label: string
}

export const InputComponent = ({ label, value, onChange }) => {
  const inputId = `input-${Math.random().toString(36).substring(7)}`;

  return (
    <FormGroup>
      <Form.Label htmlFor={inputId}>{label}</Form.Label>
      <Form.Control id={inputId} type="text" onChange={onChange} value={value} />
    </FormGroup>
  )
}
