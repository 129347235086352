import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import './styles.scss'
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'
import {  Form, FormGroup } from 'react-bootstrap'
import InstanceService from '../../services/instance'
import { Loading } from '../Loading'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { phoneMaskRegex } from '../../util/string'
import { CurrencyInput } from '../../components/CurrencyInput'

export const ModalAddCashback = ({ showModal, onClose, id, getCashback }) => {
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState(null)
  const [tabela, setTabela] = useState([])
  const [dados, setDados] = useState({
    name: '',
    dateObj: null,
    fone: '',
    value: ''
  })

  const handlerCancel = () => {
    onClose()
  }

  const handlerSubmit = async () => {
    setLoading(true)
    const result = await InstanceService.saveCashback({
      ...dados,
      id
    });

    if (result.status === 200) {
      toast.success('Cashback adicionado com sucesso!')
      setLoading(false)
      onClose()
      setDados({
        name: '',
        dateObj: null,
        fone: '',
        value: ''
      })
      getCashback(id)
    } else {
      setLoading(false)
      toast.error('Erro ao adicionar cashback!')
    }
  }

  const handlerDados = (key, value) => {
    setDados((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container style={{minHeight: 450}}>
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Adicionar Cashback</h1>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col xl={6} md={6} xs={12}>
                <FormGroup>
                  <Form.Label>Nome</Form.Label>
                  <Form.Control
                    value={dados?.name}
                    onChange={(event) => handlerDados('name', event.target.value)}
                  />
                </FormGroup>
            </Col>
            <Col xl={6} md={6} xs={12}>
                <FormGroup>
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    value={phoneMaskRegex(dados.fone)}
                    onChange={(event) => handlerDados('fone', event.target.value)}
                  />
                </FormGroup>
            </Col>
            <Col xl={6} md={6} xs={12}>
                <CurrencyInput
                variant="outlined"
                label="Valor"
                value={dados.value}
                handleChange={(value) => {
                  handlerDados('price', value)
                }}
              />
            </Col>
            <Col xl={6} md={6} xs={12}>
              <Form.Group>
                <Form.Label>Vencimento</Form.Label>
                <ReactDatePicker
                  className="form-control"
                  selected={dados?.dateObj}
                  onChange={(date) => {
                    handlerDados('dateObj', date)
                  }}
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="footerModalButtons" style={{ marginTop: 40 }}>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handlerSubmit}
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handlerCancel}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
