/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card } from 'reactstrap'
import { toast } from 'react-toastify'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import Select from 'react-select'

// import images
import userServices from '../../services/user'
import './styles.scss'

import 'chartist/dist/scss/chartist.scss'
import ptBR from 'date-fns/locale/pt-BR'
import plansService from 'services/plans'
import { Button, Form, FormGroup } from 'react-bootstrap'
import logsService from 'services/logs'
import { debounce } from 'lodash'
import { LogsCollapse } from 'components/Table/LogsCollapse'
import { SelectComponent } from 'components/selectComponent'
import { InputComponent } from 'components/inputComponent'
import { ButtonComponent } from 'components/buttonComponent'
import { DatePickerComponent } from 'components/datePickerComponent'
import { PageHeader } from 'components/pageHeader'

registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Arquivo', field: 'file' },
  { label: 'Função', field: 'function' },
  { label: 'Tipo', field: 'type' },
  { label: 'Data', field: 'createdAt' }
]

export const Logs = () => {
  const { getLogs, getLogsFilters } = logsService
  const [rows, setRows] = useState([])
  const [functions, setFunctions] = useState([])
  const [files, setFiles] = useState([])
  const [types, setTypes] = useState([])
  const [loading, setLoading] = useState(false)
  const [rendered, setRendered] = useState(false)

  const [tableConfig, setTableConfig] = useState({
    options: {
      pagination: true,
      collapse: true
    },
    filters: {
      errorFile: null,
      errorFunction: null,
      errorType: null,
      startDate: null,
      endDate: null,
      searchTerm: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 50
  })

  const loadLogs = useCallback(async () => {
    setLoading(true)
    console.log('loading')
    console.log(tableConfig.filters)

    try {
      const response = await getLogs({
        rowsPerPage: tableConfig.rowsPerPage,
        page: tableConfig.currentPage,
        errorFile: tableConfig.filters.errorFile,
        errorFunction: tableConfig.filters.errorFunction,
        startDate: tableConfig.filters.startDate
          ? tableConfig.filters.startDate.toISOString().substring(0, 10)
          : null,
        endDate: tableConfig.filters.endDate
          ? tableConfig.filters.endDate.toISOString().substring(0, 10) +
            'T23:59:59'
          : null,
        searchTerm: tableConfig.filters.searchTerm,
        errorType: tableConfig.filters.errorType
      })

      if (response.status === 200) {
        const responseData = response?.data?.data
        const total = responseData?.total
        const formattedData = responseData.items.map((item) => {
          const createdAt = new Date(item.createdAt)
          const options: Intl.DateTimeFormatOptions = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
          }
          return {
            ...item,
            createdAt: new Intl.DateTimeFormat('pt-BR', options).format(
              createdAt
            )
          }
        })
        console.log({ formattedData })

        setRows(formattedData)
        setTableConfig({
          ...tableConfig,
          total: total
        })
        setLoading(false)
      } else {
        toast.error(response?.response?.data?.msg)
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  const loadLogsFilters = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await getLogsFilters()
      console.log(response)

      if (response.status === 200) {
        setFiles(response.data.data.files)
        setFunctions(response.data.data.functions)
        setTypes(response.data.data.types)
        setLoading(false)
      } else {
        toast.error(
          response?.response?.data?.msg || 'Erro ao buscar os filtros'
        )
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [])

  const handleClearFilters = () => {
    setTableConfig({
      ...tableConfig,
      filters: {
        errorFile: null,
        errorFunction: null,
        errorType: null,
        startDate: null,
        endDate: null,
        searchTerm: null
      }
    })
  }

  function handleSearch(value) {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      filters: {
        ...prev.filters,
        searchTerm: value
      }
    }))
  }

  const deboundChange = useCallback(debounce(handleSearch, 1000), [])

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    } else {
      loadLogs()
    }
  }, [
    tableConfig.rowsPerPage,
    tableConfig.currentPage,
    tableConfig.filters,
    rendered
  ])

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    } else {
      loadLogsFilters()
    }
  }, [rendered])

  return (
    <div className="plansPageContainer">
      {loading && <Loading open={loading} />}

      <div className="page-content">
        <MetaTags>
          <title>Logs | Zap+</title>
        </MetaTags>
        <Container fluid>
          <PageHeader subtitle="Bem vindo a gestão de logs" />
          <Row>
            <Card>
              <Row>
                <Col xl={12} md={12} style={{ padding: 20 }}>
                  <Row className="mb-4">
                    <Col xs={12} md={3}>
                      <SelectComponent
                        value={
                          files.find(
                            (a) => a.value === tableConfig.filters.errorFile
                          )
                            ? files.find(
                                (a) => a.value === tableConfig.filters.errorFile
                              )
                            : null
                        }
                        onChange={(value) => {
                          setTableConfig({
                            ...tableConfig,
                            filters: {
                              ...tableConfig.filters,
                              errorFile: value.value
                            }
                          })
                        }}
                        options={files}
                        placeholder="Selecione..."
                        label="Arquivo"
                      />
                    </Col>

                    <Col xs={12} md={3}>
                      <SelectComponent
                        value={
                          functions.find(
                            (a) => a.value === tableConfig.filters.errorFunction
                          )
                            ? functions.find(
                                (a) =>
                                  a.value === tableConfig.filters.errorFunction
                              )
                            : null
                        }
                        onChange={(value) => {
                          setTableConfig({
                            ...tableConfig,
                            filters: {
                              ...tableConfig.filters,
                              errorFunction: value.value
                            }
                          })
                        }}
                        options={functions}
                        placeholder="Selecione..."
                        label="Função"
                      />
                    </Col>

                    <Col xs={12} md={3}>
                      <SelectComponent
                        label="Tipo"
                        value={
                          types.find(
                            (a) => a.value === tableConfig.filters.errorType
                          )
                            ? types.find(
                                (a) => a.value === tableConfig.filters.errorType
                              )
                            : null
                        }
                        onChange={(value) => {
                          setTableConfig({
                            ...tableConfig,
                            filters: {
                              ...tableConfig.filters,
                              errorType: value.value
                            }
                          })
                        }}
                        options={types}
                        placeholder="Selecione..."
                      />
                    </Col>

                    <Col xs={12} md={3}>
                      <InputComponent
                        label="Pesquisar"
                        onChange={(e) => {
                          deboundChange(e.target.value)
                        }}
                        value={tableConfig.filters.searchTerm}
                      />
                    </Col>

                    <Col xs={12} md={3}>
                      <DatePickerComponent
                        label="Início"
                        onChange={(date) => {
                          setTableConfig({
                            ...tableConfig,
                            filters: {
                              ...tableConfig.filters,
                              startDate: date
                            }
                          })
                        }}
                        value={tableConfig.filters.startDate}
                      />
                    </Col>
                    <Col xs={12} md={3}>
                      <DatePickerComponent
                        label="Fim"
                        onChange={(date) => {
                          setTableConfig({
                            ...tableConfig,
                            filters: {
                              ...tableConfig.filters,
                              endDate: date
                            }
                          })
                        }}
                        value={tableConfig.filters.endDate}
                      />
                    </Col>
                    <Col xs={12} md={3} className="d-flex">
                      <ButtonComponent
                        variant="secondary"
                        text="Limpar filtros"
                        applyPadding={false}
                        onClick={handleClearFilters}
                        extraClass="mt-auto"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <DatatableTables
                title={'Logs do sistema'}
                columns={columns}
                rows={rows}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
                CollapseComponent={LogsCollapse}
              />
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  )
}
