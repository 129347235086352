import {
  ApiResponse,
  ExpensesListProps,
  ExpensesProps,
  RecurringExpensesListProps,
  SmsOrdersProps,
  SubscriptionListProps
} from 'types/global'
import { api } from './axios'

export const financialReportServices = {
  getResume: async (competence: string) => {
    const response = await api
      .get('/financial/resume', {
        params: {
          competence
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  listSubscriptions: async (
    competence: string
  ): Promise<ApiResponse<SubscriptionListProps[]>> => {
    const response = await api
      .get('/financial/list-subscriptions', {
        params: { competence }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  listSmsOrders: async (
    competence: string
  ): Promise<ApiResponse<SmsOrdersProps[]>> => {
    const response = await api
      .get('/financial/sms', {
        params: { competence }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  listExpenses: async (
    competence: string
  ): Promise<ApiResponse<ExpensesListProps[]>> => {
    const response = await api
      .get('/expenses', {
        params: { competence }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  listRecurringExpenses: async (): Promise<
    ApiResponse<RecurringExpensesListProps[]>
  > => {
    const response = await api
      .get('/recurring-expenses')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  createExpense: async (body: ExpensesProps): Promise<ApiResponse<any>> => {
    const response = await api
      .post(`/expense`, body)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateRecurringExpense: async (
    body: ExpensesProps
  ): Promise<ApiResponse<any>> => {
    const response = await api
      .put(`/recurring-expense/${body.id}`, body)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  updateExpense: async (body: ExpensesProps): Promise<ApiResponse<any>> => {
    const response = await api
      .put(`/expense/${body.id}`, body)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  deleteExpense: async (id: number): Promise<ApiResponse<any>> => {
    const response = await api
      .delete(`/expense/${id}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },  
}
