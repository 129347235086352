import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import StatusController from '../../components/statusController'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Card, Input, FormGroup, Label } from 'reactstrap'

import { Send, PlusCircle } from 'react-feather'
import { Loading } from '../../components/Loading'

// import images
import { useUserInfo } from 'context/userContext'

import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { Button, Form } from 'react-bootstrap'
import { listType } from '../../util/mocks'

import ptBR from 'date-fns/locale/pt-BR'
import { ModalQrcode } from 'components/modalQrcode'
import { ModalQrcodeZApi } from 'components/modalQrcodeZApi'
import { TextArea } from '../../components/TextArea'
import { ButtonComponent } from 'components/buttonComponent'
import { SelectComponent } from 'components/selectComponent'
import { InputComponent } from 'components/inputComponent'
import { useFileUpload } from 'hooks/useFileUpload'
import { FileUpload } from 'components/fileUpload'
//@ts-ignore
import styles from './styles.module.scss'
import { useDisparador } from 'hooks/useDisparador'
import { Controller, useForm } from 'react-hook-form'
import {
  envioSimplificado,
  EnvioSimplificadoSchema
} from './envioSimplificado.schema'
import { zodResolver } from '@hookform/resolvers/zod'
import Table from 'components/Table'
import { ModalAddContacts } from 'components/modalAddContacts'
import { ContactSchema } from 'components/modalAddContacts/addContact.schema'
import { phoneMaskForList } from 'util/function'

registerLocale('pt-BR', ptBR)

const TableHeader = ({
  onClick
}: {
  onClick: Dispatch<SetStateAction<boolean>>
}) => {
  return (
    <Row>
      <Col xs={6}>Seus contatos</Col>
      <Col xs={6} className="text-end">
        <Button variant="success" onClick={() => onClick(true)}>
          Adicionar contatos <PlusCircle />
        </Button>
      </Col>
    </Row>
  )
}

const columns = [
  { label: 'Nome', field: 'name' },
  { label: 'Telefone', field: 'phone' }
]

const listMomento = [
  { label: 'Enviar Agora', value: 'now' },
  { label: 'Agendar Envio', value: 'after' }
]

const EnvioSimplificao = () => {
  const { userPlan } = useUserInfo()

  const { onDrop } = useFileUpload()

  const pageForm = useForm<EnvioSimplificadoSchema>({
    resolver: zodResolver(envioSimplificado),
    defaultValues: {
      moment: 'now',
      type: 'texto',
      message: ''
    }
  })
  const { control, watch, handleSubmit, setValue } = pageForm
  const watchInstanceId = watch('instanceId')
  const watchMoment = watch('moment')
  const watchType = watch('type')
  const watchFile = watch('file')

  const {
    loading,
    openModalQrcode,
    statusInstancia,
    zapMais,
    instanceName,
    instanceStateChecked,
    zapiData,
    listInstances,
    rendered,
    table,
    onCloseModalQrcode,
    onCloseOnSuccessModalQrcode,
    handleFileSelected,
    handleAddVariable,
    onError,
    onSubmit,
    setTable
  } = useDisparador(pageForm)

  const [rows, setRows] = useState([])
  const [showModalAdd, setShowModalAdd] = useState(false)

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      more: true,
      uploadContacts: true,
      delete: true
    },
    config: {
      deleteText: 'Remover contato'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null,
      sellerId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 15
  })
  const loadDadosTable = async () => {
    const init = tableConfig.currentPage * tableConfig.rowsPerPage
    const finish = init + tableConfig.rowsPerPage

    const filterDados = table.slice(init, finish)
    setRows(filterDados)
  }

  const handleAddContacts = (data: ContactSchema[]) => {
    console.log({ data })

    const newTable = [
      ...table,
      ...data.map((item, index) => ({
        ...item,
        id: index,
        phone: phoneMaskForList(item.phone)
      }))
    ]
    setTable(newTable)
    setValue('table', newTable)
    setShowModalAdd(false)
  }

  const handleRemoveContact = (row) => {
    const newTable = table.filter((item) => item.id !== row.id)
    setTable(newTable)
  }

  useEffect(() => {
    if (!rendered) return
    loadDadosTable()
  }, [tableConfig.currentPage, rendered, table])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} />}
      <div className="page-content">
        <MetaTags>
          <title>Envio simplificado | Zap+</title>
        </MetaTags>
        <Container fluid>
          <Form onSubmit={handleSubmit(onSubmit, onError)}>
            <div className="page-title-box">
              <Row className="align-items-center">
                <Col md={8}>
                  <br></br>
                  <h6 className="page-title">Envio simplificado | Zap+</h6>
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item active">
                      Bem vindo ao Envio simplificado Zap+
                    </li>
                  </ol>
                </Col>
                <Col
                  md={4}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}
                >
                  {watchInstanceId && (
                    <>
                      <span
                        style={{
                          marginRight: 10,
                          fontWeight: 500,
                          fontSize: 20
                        }}
                      >
                        {statusInstancia === 1
                          ? 'Conectado'
                          : statusInstancia === 3
                          ? 'Bloqueado'
                          : 'Desconectado'}
                      </span>
                      <StatusController statusInstancia={statusInstancia} />
                    </>
                  )}
                </Col>
              </Row>
            </div>
            <Row>
              <Card style={{ padding: 20, paddingBottom: 150 }}>
                <Row style={{ marginTop: 30, rowGap: 16 }}>
                  <Col xl={4} md={4}>
                    <Controller
                      control={control}
                      name="instanceId"
                      render={({ field }) => (
                        <SelectComponent
                          {...field}
                          label="Quem está enviando a mensagem?"
                          onChange={(value) => field.onChange(value.value)}
                          value={
                            listInstances.find((a) => a.value === field.value)
                              ? listInstances.find(
                                  (a) => a.value === field.value
                                )
                              : null
                          }
                          placeholder="Selecione..."
                          options={listInstances}
                        />
                      )}
                    />
                  </Col>
                  {userPlan !== 'BASIC' && (
                    <>
                      <Col xl={4} md={4}>
                        <FormGroup>
                          <Controller
                            control={control}
                            name="moment"
                            render={({ field }) => (
                              <SelectComponent
                                {...field}
                                value={
                                  listMomento.find(
                                    (a) => a.value === field.value
                                  )
                                    ? listMomento.find(
                                        (a) => a.value === field.value
                                      )
                                    : null
                                }
                                label="Momento do envio"
                                onChange={(value) =>
                                  field.onChange(value.value)
                                }
                                options={listMomento}
                                placeholder="Selecione..."
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                      {watchMoment === 'after' && (
                        <Col xl={4} md={4}>
                          <Form.Group>
                            <Label>Hora do Envio</Label>
                            <Controller
                              control={control}
                              name="scheduleTime"
                              render={({ field }) => (
                                <ReactDatePicker
                                  className="form-control"
                                  selected={field.value}
                                  onChange={(date) => {
                                    field.onChange(date)
                                  }}
                                  timeIntervals={15}
                                  showTimeSelect
                                  dateFormat="dd/MM/yyyy HH:mm:ss"
                                  locale="pt-BR"
                                />
                              )}
                            />
                          </Form.Group>
                        </Col>
                      )}
                    </>
                  )}
                  <Col xl={4} md={4}>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="type"
                        render={({ field }) => (
                          <SelectComponent
                            {...field}
                            value={
                              listType.find((a) => a.value === field.value)
                                ? listType.find((a) => a.value === field.value)
                                : null
                            }
                            label="Selecione o tipo de mensagem"
                            onChange={(value) => field.onChange(value.value)}
                            options={listType}
                            placeholder="Selecione..."
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>

                  {watchType === 'imagem' && (
                    <Col xl={4} md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Imagem</Label>
                        <div className={styles.fileUploader}>
                          <FileUpload
                            text="Clique aqui para selecionar ou arraste a <strong>IMAGEM</strong>"
                            onDrop={(acceptedFiles, fileRejections) =>
                              onDrop(
                                acceptedFiles,
                                fileRejections,
                                10000000,
                                () => handleFileSelected(acceptedFiles)
                              )
                            }
                            maxSize={10000000}
                            accept={['.png', '.JPEG', '.jpeg', '.jpg']}
                            suggestion={
                              watchFile?.name
                                ? `Imagem selecionada: <strong>${watchFile.name}</strong>`
                                : null
                            }
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  )}

                  {watchType === 'arquivo' && (
                    <>
                      <Col xl={4} md={6}>
                        <FormGroup>
                          <Label>Arquivo</Label>
                          <div className={styles.fileUploader}>
                            <FileUpload
                              text="Clique aqui para selecionar ou arraste o <strong>ARQUIVO PDF</strong>"
                              onDrop={(acceptedFiles, fileRejections) =>
                                onDrop(
                                  acceptedFiles,
                                  fileRejections,
                                  50000000,
                                  () => handleFileSelected(acceptedFiles)
                                )
                              }
                              maxSize={50000000}
                              accept={['.pdf']}
                              suggestion={
                                watchFile?.name
                                  ? `Arquivo de no máximo 50M<br/>Arquivo selecionado: <strong>${watchFile.name}</strong>`
                                  : 'Arquivo de no máximo 50M'
                              }
                            />
                          </div>
                        </FormGroup>
                      </Col>

                      <Col xl={4} md={4}>
                        <Controller
                          control={control}
                          name="fileName"
                          render={({ field }) => (
                            <InputComponent
                              {...field}
                              label="Nome do Arquivo"
                            />
                          )}
                        />
                      </Col>
                    </>
                  )}

                  {watchType === 'video-arquivo' && (
                    <Col xl={4} md={5}>
                      <FormGroup>
                        <Label for="exampleEmail">Vídeo</Label>
                        <div className={styles.fileUploader}>
                          <FileUpload
                            text="Clique aqui para selecionar ou arraste o <strong>VÍDEO</strong>"
                            onDrop={(acceptedFiles, fileRejections) =>
                              onDrop(
                                acceptedFiles,
                                fileRejections,
                                15000000,
                                () => handleFileSelected(acceptedFiles)
                              )
                            }
                            maxSize={15000000}
                            accept={['.3gp', '.mp4']}
                            suggestion={
                              watchFile?.name
                                ? `Arquivo de no máximo 15M<br/>Arquivo selecionado: <strong>${watchFile.name}</strong>`
                                : 'Arquivo de no máximo 15M'
                            }
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  )}

                  {watchType === 'audio-arquivo' && (
                    <Col xl={4} md={4}>
                      <FormGroup>
                        <Label for="exampleEmail">Arquivo</Label>
                        <div className={styles.fileUploader}>
                          <Input
                            type="file"
                            label="Upload"
                            onChange={(e) => handleFileSelected(e.target.files)}
                            accept=".ogg, .mp3"
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  )}

                  {watchType === 'link' && (
                    <>
                      <Col xl={4} md={4}>
                        <Controller
                          control={control}
                          name="link"
                          render={({ field }) => (
                            <InputComponent {...field} label="Link" />
                          )}
                        />
                      </Col>
                    </>
                  )}
                </Row>
                <Row style={{ marginTop: 10 }}>
                  <Col xl={12} md={12}>
                    <Table
                      title={<TableHeader onClick={setShowModalAdd} />}
                      columns={columns}
                      rows={rows}
                      tableConfig={tableConfig}
                      setTableConfig={setTableConfig}
                      emptyText="Nenhum contato adicionado"
                      onDelete={handleRemoveContact}
                    />
                  </Col>
                  <Col xl={8} md={12}>
                    <FormGroup>
                      <Controller
                        control={control}
                        name="message"
                        render={({ field }) => (
                          <TextArea
                            {...field}
                            setValue={(value: string) => field.onChange(value)}
                            onAddVariable={handleAddVariable}
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    xl={4}
                    md={6}
                    style={{
                      marginTop: 20,
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end',
                      display: 'flex'
                    }}
                  >
                    <ButtonComponent
                      text="Enviar Mensagem"
                      icon={<Send />}
                      onClick={() => {}}
                      // disabled={!statusInstancia}
                      type="submit"
                    />
                  </Col>
                </Row>
              </Card>
            </Row>
          </Form>
        </Container>
      </div>
      {zapMais && (
        <ModalQrcode
          showModal={openModalQrcode}
          onClose={onCloseModalQrcode}
          instanceId={watchInstanceId}
          statusInstance={statusInstancia}
          instanceName={instanceName}
          onSuccess={onCloseOnSuccessModalQrcode}
          instanceChecked={instanceStateChecked}
        />
      )}
      {!zapMais && (
        <ModalQrcodeZApi
          showModal={openModalQrcode}
          onClose={onCloseModalQrcode}
          instanceId={watchInstanceId}
          statusInstance={statusInstancia}
          instanceName={instanceName}
          onSuccess={onCloseOnSuccessModalQrcode}
          instanceChecked={instanceStateChecked}
          zapiData={zapiData}
        />
      )}
      {showModalAdd && (
        <ModalAddContacts
          onClose={() => setShowModalAdd(false)}
          onConfirm={handleAddContacts}
          showModal={showModalAdd}
        />
      )}
    </React.Fragment>
  )
}

export default EnvioSimplificao
