import { useState, useEffect } from 'react';

function StatusController({ statusInstancia }) {
  const [color, setColor] = useState('#FF0000'); // cor vermelha padrão
  const [blink, setBlink] = useState(false);

  useEffect(() => {
    // Definir a cor com base no valor do statusInstancia
    if (statusInstancia === 1) {
      setColor('#00FF00'); // cor verde
    } else {
      setColor('#FF0000'); // cor vermelha
    }
  }, [statusInstancia]);

  useEffect(() => {
    // Fazer o efeito de piscar
    const intervalId = setInterval(() => {
      setBlink((prevState) => !prevState);
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      style={{
        backgroundColor: blink ? color : 'transparent',
        transition: 'background-color 1.5s ease',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end'
      }}
    >
      {/* Conteúdo do componente aqui */}
    </div>
  );
}

export default StatusController;
