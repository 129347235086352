import { LOGIN_SUCCESS, LOGOUT_USER_SUCCESS, API_ERROR } from './actionTypes'
import Services from '../../../services/auth'
import { Redirect } from 'react-router-dom'


export const createUser = async (values) => {

  const response = await Services.createUser(
    values
  )

  if (response.status === 200) {
    if (response?.data?.token) {
      window.localStorage.setItem(
        'authUser',
        JSON.stringify({
          accessToken: response?.data?.token,
          root: response?.data?.user?.root,
          displayName: response?.data?.user?.name,
          email: response?.data?.user?.email,
          plano: response?.data?.user?.plano,
          uid: false,
          username: response?.data?.user?.name
        })
      )
      window.localStorage.setItem('userName', response?.data?.user?.name)
      if (response?.data?.user?.root === 1) {
        window.localStorage.setItem('isRoot', '1')
      } else {
        window.localStorage.removeItem('isRoot')
      }
      return {
        status: 200,
        userName: response?.data?.user?.name ? response.data.user.name : "",
        message: 'Login realizado com sucesso'
      }
    } else {
      return {
        status: 500,
        message: 'Falha no Login'
      }
    }
  } else {
    return {
      status: 400,
      message: response?.response?.data?.msg
    }
  }
}


export const loginUser = async (user) => {
  const response = await Services.LoginNative(
    user.email,
    user.password
  )
  if (response.status === 200) {
    if (response?.data?.token) {
      window.localStorage.setItem(
        'authUser',
        JSON.stringify({
          accessToken: response?.data?.token,
          root: response?.data?.user?.root,
          displayName: response?.data?.user?.name,
          email: response?.data?.user?.email,
          plano: response?.data?.user?.plano,
          uid: false,
          username: response?.data?.user?.name
        })
      )
      window.localStorage.setItem('userName', response?.data?.user?.name)
      if (response?.data?.user?.root === 1) {
        window.localStorage.setItem('isRoot', '1')
      } else {
        window.localStorage.removeItem('isRoot')
      }
      return {
        status: 200,
        userName: response?.data?.user?.name ? response.data.user.name : "",
        message: 'Login realizado com sucesso'
      }
    } else {
      return {
        status: 500,
        message: 'Falha no Login'
      }
    }
  } else {
    return {
      status: 400,
      message: response?.response?.data?.msg
    }
  }
}

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user
  }
}

export const logoutUser = () => {
  return <Redirect to="/login" />
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  }
}

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error
  }
}
