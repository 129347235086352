/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { withRouter, Link } from 'react-router-dom'


import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Input,
  FormGroup,
  Label
} from 'reactstrap'

import { Loading } from '../../components/Loading'
import Select from 'react-select'
import Services from '../../services/sendMessage'
import { useUserInfo } from 'context/userContext'


const PageInterna = ({ match }) => {

  console.log("-")
  const idPage = match?.params?.idPage

  const { isRoot, userPlan } = useUserInfo();
  const [rendered, setRendered] = useState(false)
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])

  const handleClose = () => {
    setLoading(false)
  }

  useEffect(() => {
     getLanding();
  }, [])

  const getLanding = async () => {
    setLoading(true)
    try {
      const response = await Services.getLandingEspecific(idPage);
      console.log("------------------")
      console.log(response)
      if (response.status === 200) {
        setRows(response?.data?.data)
      }
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    } 
  }


  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <div className="page-content">
        <MetaTags>
          <title>Zap Page | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
              <br></br>
                <h6 className="page-title">Zap Page | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo ao Zap Page
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Card style={{ padding: 40, paddingBottom: 150, minHeight: 900 }}>
            <Row>
                <Col
                      xl={4}
                      md={4}
                      xs={12}
                    >
                     <div
                      style={{
                        width: "100%",
                        height: "800px",
                        backgroundColor: '#6c757d',
                        padding: 20,
                        borderRadius: 10,
                        position: "relative"
                      }}
                    >
                      <iframe
                        src={`https://page.zapmais.app/m1/${idPage}`}
                        title="ZapMais"
                        style={{ 
                          position: "absolute", 
                          width: "92%",
                          height: "96%"
                        }}
                      ></iframe>
                    </div>
                    </Col>
                    <Col
                      xl={8}
                      md={8}
                      xs={12}
                    >
                     
                    </Col>
                   
                  </Row>
             
        
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}


PageInterna.propTypes = {
  t: PropTypes.any
}

export default PageInterna
