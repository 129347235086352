/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import Swal from 'sweetalert2'
import Excel from 'exceljs'
import Service from '../../services/auth'
import { formatDateT } from '../../util/string'
import { ModalGrupo } from '../../components/modalGrupo'
import { ModalAddContato } from '../../components/modalAddContato'

import StatusController from '../../components/statusController'

import FrontTable from '../../components/TableFrontSide'

import { ModalQrcode } from 'components/modalQrcode'
import { ModalQrcodeZApi } from 'components/modalQrcodeZApi'

import { Row, Col, Button, Card, Input, FormGroup, Label } from 'reactstrap'
import { toast } from 'react-toastify'
import { Send } from 'react-feather'
import styles from './styles.module.scss'
import { Loading } from '../../components/Loading'
import Select from 'react-select'

import 'jodit/build/jodit.min.css'
// import images
import Services from '../../services/sendMessage'
import { handlerUser, handlerFile } from '../../services/instanceFunctions'
import { listType, listTypeZapi } from '../../util/mocks'

import './styles.css'

import 'chartist/dist/scss/chartist.scss'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { Container, Form } from 'react-bootstrap'
import Switch from 'react-switch'

import ptBR from 'date-fns/locale/pt-BR'
import { format } from 'date-fns'
import { EditorState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { TextArea } from '../../components/TextArea'
import { useUserInfo } from 'context/userContext'
import { exportFile } from 'util/function'

registerLocale('pt-BR', ptBR)

const columns = [
  // { label: 'Cod', field: 'id' },
  { label: 'Nome', field: 'name' },
  { label: 'Telefone', field: 'fone' },
  { label: 'Ultima Mensagem', field: 'lastMessage' }
]

const customStyles = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
}

const filtroList = [
  { label: 'Conversas das Últimas 24h', value: '24h' },
  { label: 'Conversas das Últimas 48h', value: '48h' },
  { label: 'Conversas das Últimas 72h', value: '72h' },
  { label: 'Conversas dos Últimos 7 dias', value: '7dias' },
  { label: 'Conversas dos Últimos 15 dias', value: '15dias' },
  { label: 'Conversas dos Últimos 30 dias', value: '30dias' },
  { label: 'Últimas 100 Conversas', value: '100' },
  { label: 'Últimas 200 Conversas', value: '200' },
  { label: 'Últimas 300 Conversas', value: '300' },
  { label: 'Últimas 400 Conversas', value: '400' },
  { label: 'Últimas 500 Conversas', value: '500' },
  { label: 'Últimas 1000 Conversas', value: '1000' },
  { label: 'Últimas 2000 Conversas', value: '2000' }
]

const listMomento = [
  { label: 'Enviar Agora', value: 'now' },
  { label: 'Agendar Envio', value: 'after' }
]

const MeuChat = () => {
  const { userPlan } = useUserInfo()

  const [imagem, setImagem] = useState(null)
  const [all, SetAll] = useState(false)

  const [btn1, setBtn1] = useState(null)
  const [btn2, setBtn2] = useState(null)
  const [btn3, setBtn3] = useState(null)
  const [descricaoLink, setDescricaoLink] = useState(null)
  const [tituloLink, setTituloLink] = useState(null)
  const [linkUrl, setLinkUrl] = useState(null)
  const [linkAction, setLinkAction] = useState(null)
  const [campanha, setCampanha] = useState(null)
  const [message, setMessage] = useState('')

  const [campanhaExist, setCampanhaExist] = useState(false)
  const [campanhaList, setCampanhaList] = useState([])

  const [nomeDoArquivo, setNomeDoArquivo] = useState('')
  const [file, setFile] = useState(null)
  const [zapiData, setZapiData] = useState({
    id: null,
    token: null
  })

  const [momento, setMomento] = useState('now')
  const [horaEnvio, setHoraEnvio] = useState(null)
  const [horaEnvioDatePicker, setHoraEnvioDatePicker] = useState(null)
  const [mensagemCampanhaExistente, setmensagemCampanhaExistente] =
    useState(false)

  const [filtro, setFiltro] = useState('24h')
  const [group, setGroup] = useState(false)

  const [type, setType] = useState('texto')
  const [user, setUser] = useState(null)
  const [statusInstancia, setStatusInstancia] = useState(0)
  const [zapMais, setZapMais] = useState(false)

  const [instanceName, setInstanceName] = useState(null)
  const [openModalQrcode, setOpenModalQrcode] = useState(false)
  const [instanceStateChecked, setInstanceStateChecked] = useState(false)
  const [rendered, setRendered] = useState(false)

  const [descricao, setDescricao] = useState('')
  const [block, setBlock] = useState(false)

  const [rows, setRows] = useState([])
  const [checkSair, setCheckSair] = useState(false)

  const [tableConfig, setTableConfig] = useState({
    options: {
      checkWhatsapp: true,
      groupFilter: true,
      justGroup: true,
      downloadXlsx: true,
      uploadContacts: true,
      pagination: true
    },
    config: {
      exportGroupText: 'Exportar contatos do grupo'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null,
      sellerId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const { listGrupos } = Service
  const [listUser, setListUser] = useState([])
  const [rowsUser, setRowsUser] = useState([])
  const [tabela, setTabela] = useState([])
  const [showModalGrupo, setShowModalGrupo] = useState(false)
  const [showModalAddGrupo, setShowModalAddGrupo] = useState(false)
  const [idGrupo, setIdGrupo] = useState(false)
  const [justGroup, setJustGroup] = useState(false)

  const [loading, setLoading] = useState(false)

  const loadChat = async () => {
    setLoading(true)
    const response = await Services.listChat({ user, filtro })
    const dados = []

    if (response.status === 200) {
      response?.data?.data.map((item, index) => {
        const nome = item.name
          ? item.name === 'undefined'
            ? ''
            : item.name === undefined
            ? ''
            : item.name
          : ''

        if (justGroup) {
          if (item.phone.indexOf('-') !== -1 || item.phone.length > 14) {
            dados.push({
              id: index + 1,
              name: nome,
              nameGroup: 'Grupo',
              fone: item.phone,
              lastMessage: item?.lastMessageTime,
              check: false
            })
          } else {
            // console.log('não é grupo')
          }
        } else if (!group) {
          if (item.phone.indexOf('-') !== -1 || item.phone.length > 14) {
            // console.log('grupo')
          } else {
            dados.push({
              id: index + 1,
              name: nome,
              fone: item.phone,
              lastMessage: item?.lastMessageTime,
              check: false
            })
          }
        } else {
          if (item.phone.indexOf('-') !== -1 || item.phone.length > 14) {
            dados.push({
              id: index + 1,
              name: nome,
              nameGroup: 'Grupo',
              fone: item.phone,
              lastMessage: item?.lastMessageTime,
              check: false
            })
          } else {
            dados.push({
              id: index + 1,
              name: nome,
              fone: item.phone,
              lastMessage: item?.lastMessageTime,
              check: false,
              hideMoreOptions: true
            })
          }
        }
      })

      setTabela(dados)
      setTableConfig((prev) => ({
        ...prev,
        options: {
          ...tableConfig.options,
          more: group,
          exportGroup: group
        },
        total: dados.length
      }))
      const filterDados = dados.slice(0, Number(tableConfig.rowsPerPage))

      setRows(filterDados)

      setLoading(false)
    } else {
      toast.error(response?.msg)
      setLoading(false)
    }
  }
  const loadUsers = useCallback(async () => {
    setLoading(true)

    const response = await listGrupos({
      rowsPerPage: tableConfig.rowsPerPage,
      page: tableConfig.currentPage,
      searchTerm: tableConfig?.filters?.searchTerm
    })

    if (response.status === 200) {
      const responseData = response?.data?.data
      const formattedData = responseData.items.map((item) => ({
        ...item,
        created_at: formatDateT(item.created_at)
      }))

      setRows(formattedData)
      setLoading(false)
    } else {
      toast.error(response)
      setLoading(false)
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  const getCampanhas = async () => {
    const result = await Services.getCampanhas({ user })

    if (result.status === 200) {
      const aux = []
      result?.data?.data.map((item, index) => {
        aux.push({
          ...item,
          value: item?.id,
          label: item?.name
        })
      })
      setCampanhaList(aux)
    }
  }

  async function exportarParaXlsx() {
    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet('Planilha1')
    worksheet.columns = [
      { header: 'Nome', key: 'nome' },
      { header: 'Telefone', key: 'telefone' }
    ]

    // adiciona apenas as linhas que não contêm "@" ou "-", e que não são vazias, undefined ou null
    const linhas = tabela.filter(
      (item) =>
        item?.fone &&
        item.fone.trim() !== '' &&
        !item.fone.includes('@') &&
        !item.fone.includes('-')
    )
    worksheet.addRows(
      linhas.map((item) => ({
        nome: item?.name ?? ' ',
        telefone: item?.fone ?? ''
      }))
    )

    const planilhaExcel = await workbook.xlsx.writeBuffer()

    // cria um objeto Blob com o buffer da planilha Excel
    const blob = new Blob([planilhaExcel], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })

    // cria uma URL para o objeto Blob
    const url = URL.createObjectURL(blob)

    // cria uma tag <a> com o link da URL e configura o download automático da planilha Excel
    const a = document.createElement('a')
    a.href = url
    a.download = 'PlanilhaMeusContatos-ZAp+.xlsx'
    a.click()

    // libera a URL e o objeto Blob da memória
    URL.revokeObjectURL(url)
    return planilhaExcel
  }

  const loadDadosTable = async (vetor) => {
    const init = tableConfig.currentPage * tableConfig.rowsPerPage
    const finish = init + tableConfig.rowsPerPage
    const filterDados = vetor.slice(init, finish)
    setRows(filterDados)
    setLoading(false)
  }

  const handlerAll = async (dadosRow) => {
    setLoading(true)
    const tabelaTemp = []

    tabela.map((item, index) => {
      tabelaTemp.push({
        ...item,
        check: !all
      })
    })
    SetAll(!all)
    setTabela(tabelaTemp)
    loadDadosTable(tabelaTemp)
  }

  const setCheck = async (dadosRow) => {
    setLoading(true)

    const tabelaTemp = []

    tabela.map((item, index) => {
      tabelaTemp.push({
        ...item,
        check: item.fone === dadosRow.fone ? !item.check : item.check
      })
    })

    setTabela(tabelaTemp)
    loadDadosTable(tabelaTemp)
  }

  const loadInstance = async () => {
    const result = await Services.getInstance()

    if (result.status === 200) {
      const info = []
      setRowsUser(result.data.data.items)
      result.data.data.items.map((item) => {
        info.push({
          label: item.name,
          value: item.id,
          tokenZapi: item.token_zapi,
          idZapi: item.id_zapi,
          useLocal: item.use_local
        })
      })
      setListUser(info)
    }
  }

  const handleClose = () => {
    setLoading(false)
  }

  const handleImgSelected = (e) => {
    const fileReader = new FileReader()
    setCampanha(e.target.files[0])
    fileReader.readAsDataURL(e.target.files[0])

    fileReader.onload = function (fileLoadedEvent) {
      const imageBase64 = fileLoadedEvent.target?.result

      setImagem(imageBase64)
    }
  }

  const handleVideoSelected = (e) => {
    setCampanha(e.target.files[0])
  }

  const handlerType = (e) => {
    setType(e.value)
  }

  const handlerMomento = (e) => {
    setMomento(e.value)

    if (e.value !== 'now') {
      setCampanhaExist(false)
    }
  }

  const handlerFiltro = (e) => {
    setFiltro(e.value)
  }

  function handleChangeTransferDate(date) {
    const formattedDate = format(date, 'yyyy-MM-dd HH:mm:ss')

    setHoraEnvio(formattedDate)
    setHoraEnvioDatePicker(date)
  }

  const handlerCampanhaExistente = async (dados) => {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção..',
      text: `Você selecionou uma campanha já existente, as configurações da campanha serão setadas automaticamente conforme sua configuração inicial.
      Porém é necessario fazer o upload das midias novamente`,
      confirmButtonText: 'Sair'
    }).then((result) => {})

    setType(dados?.type)
    setmensagemCampanhaExistente(dados?.body)
    setCampanhaExist(dados?.value)
    setBlock(true)
  }

  function onCloseModalQrcode() {
    setOpenModalQrcode(false)
    setUser(null)
    setInstanceName(null)
  }

  function onCloseOnSuccessModalQrcode() {
    setOpenModalQrcode(false)
    setStatusInstancia(1)
  }

  const handleClearInstanceId = () => {
    setUser(null)
  }

  const handleAddVariable = (variable) => {
    const cursorPosition =
      document.getElementById('message-text-area')?.selectionStart
    const newMessage = `${message.substring(
      0,
      cursorPosition
    )} ${variable} ${message.substring(cursorPosition)}`

    setMessage(newMessage)
  }
  const onUploadContatos = async (row) => {
    setShowModalAddGrupo(true)
    setIdGrupo(row?.id)
  }
  const handlerCriarGrupo = async () => {
    setShowModalGrupo(true)
  }
  const onCloseModalGrupo = () => {
    setShowModalGrupo(false)
  }
  const onCloseModalAddGrupo = () => {
    setShowModalAddGrupo(false)
  }

  const handleExportGroupContacts = async (row) => {
    try {
      setLoading(true)
      const response = await Services.exportGroupContacts({
        groupId: row.fone,
        instanceId: user
      })
      setLoading(false)
      if (response.status === 200) {
        exportFile({
          base64: response.data.report,
          fileName: `contatos-grupo-${row.name}`,
          extension: 'xlsx'
        })
        toast.success(
          response?.data?.msg || 'O arquivo foi exportado com sucesso!'
        )
      } else {
        toast.error(
          response?.response?.data?.msg ||
            'Erro ao exportar os contatos do grupo'
        )
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      toast.error('Ocorreu um erro ao exportar os contatos do grupo')
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }
    if (rendered) {
      loadInstance()
    }
  }, [rendered])

  useEffect(() => {
    if (!rendered) return
    loadDadosTable(tabela)
  }, [tableConfig.currentPage, rendered])

  useEffect(() => {
    if (user !== null) {
      loadChat()
    }
  }, [filtro, user, group, justGroup])

  useEffect(() => {
    if (user !== null && user !== undefined) {
      getCampanhas()
    }
  }, [user])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      {zapMais && (
        <ModalQrcode
          showModal={openModalQrcode}
          onClose={onCloseModalQrcode}
          instanceId={user}
          statusInstance={statusInstancia}
          instanceName={instanceName}
          onSuccess={onCloseOnSuccessModalQrcode}
          instanceChecked={instanceStateChecked}
        />
      )}
      {!zapMais && (
        <ModalQrcodeZApi
          showModal={openModalQrcode}
          onClose={onCloseModalQrcode}
          instanceId={user}
          statusInstance={statusInstancia}
          instanceName={instanceName}
          onSuccess={onCloseOnSuccessModalQrcode}
          instanceChecked={instanceStateChecked}
          zapiData={zapiData}
        />
      )}
      <div className="page-content">
        <MetaTags>
          <title>Meu Chat | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Meu Chat | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo ao Disparador Zap+
                  </li>
                </ol>
              </Col>
              <Col
                md={4}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                {user && (
                  <>
                    <span
                      style={{
                        marginRight: 10,
                        fontWeight: 500,
                        fontSize: 20
                      }}
                    >
                      {statusInstancia === 1 ? 'Conectado' : 
                        statusInstancia === 3 ? 'Bloqueado' : 'Desconectado'}
                    </span>
                    <StatusController 
                    statusInstancia={statusInstancia} />
                  </>
                )}
              </Col>
            </Row>
          </div>
          <Row>
            <Card style={{ padding: 20, paddingBottom: 150 }}>
              <Row style={{ marginTop: 30 }}>
                <Col xl={4} md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Quem está enviando a mensagem?
                    </Label>
                    <Select
                      value={
                        listUser.find((a) => a.value === user)
                          ? listUser.find((a) => a.value === user)
                          : null
                      }
                      onChange={(value) =>
                        handlerUser(
                          value,
                          setLoading,
                          setUser,
                          Services,
                          Swal,
                          setStatusInstancia,
                          setZapMais,
                          setInstanceName,
                          setOpenModalQrcode,
                          handleClearInstanceId,
                          setInstanceStateChecked,
                          setZapiData
                        )
                      }
                      options={listUser}
                      classNamePrefix="select2-selection"
                      placeholder="Selecione..."
                    />
                  </FormGroup>
                </Col>
                <Col xl={4} md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Filtro de Conversas</Label>
                    <Select
                      value={
                        filtroList.find((a) => a.value === filtro)
                          ? filtroList.find((a) => a.value === filtro)
                          : null
                      }
                      onChange={(value) => handlerFiltro(value)}
                      options={filtroList}
                      classNamePrefix="select2-selection"
                      placeholder="Selecione..."
                    />
                  </FormGroup>
                </Col>
                <div>
                  <Row>
                    <Col
                      xl={12}
                      md={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => handlerCriarGrupo()}
                        className="newBg"
                        style={{
                          marginBottom: '-10px',
                          marginRight: 5,
                          marginTop: 5,
                          cursor: 'pointer !important',
                          backgroundColor: '#0D071B !important',
                          color: 'white'
                        }}
                      >
                        Criar Grupo
                      </Button>
                    </Col>
                  </Row>
                </div>

                <Col xl={12} md={12}>
                  <FrontTable
                    title={'Sua Tabela'}
                    columns={columns}
                    rows={rows}
                    tableConfig={tableConfig}
                    setTableConfig={setTableConfig}
                    setCheck={setCheck}
                    all={all}
                    handlerAll={handlerAll}
                    group={group}
                    justGroup={justGroup}
                    setJustGroup={setJustGroup}
                    setGroup={setGroup}
                    exportXlsx={exportarParaXlsx}
                    onUploadContatos={onUploadContatos}
                    onExportGroupContacts={handleExportGroupContacts}
                  />
                  <ModalGrupo
                    onClose={onCloseModalGrupo}
                    loadUsers={loadUsers}
                    showModal={showModalGrupo}
                  />
                  <ModalAddContato
                    onClose={onCloseModalAddGrupo}
                    loadUsers={loadUsers}
                    showModal={showModalAddGrupo}
                    tabela={tabela}
                    loadDadosTable={loadDadosTable}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                {userPlan !== 'BASIC' && (
                  <Col xl={4} md={4}>
                    <FormGroup>
                      <Label for="exampleEmail">Momento do envio</Label>
                      <Select
                        value={
                          listMomento.find((a) => a.value === momento)
                            ? listMomento.find((a) => a.value === momento)
                            : null
                        }
                        onChange={(value) => handlerMomento(value)}
                        options={listMomento}
                        classNamePrefix="select2-selection"
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col xl={4} md={4}>
                  <Label for="exampleEmail">Descrição da Nova Campanha</Label>
                  <Input
                    type="text"
                    onChange={(value) => {
                      setDescricao(value.target.value)
                      setCampanhaExist(false)
                      setBlock(false)
                    }}
                    value={descricao}
                  />
                </Col>
                {descricao.length === 0 && (
                  <Col xl={4} md={4}>
                    <FormGroup>
                      <Label for="exampleEmail">Campanha Existente</Label>
                      <Select
                        value={
                          campanhaList.find((a) => a.value === campanhaExist)
                            ? campanhaList.find(
                                (a) => a.value === campanhaExist
                              )
                            : null
                        }
                        onChange={(value) => handlerCampanhaExistente(value)}
                        options={campanhaList}
                        classNamePrefix="select2-selection"
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                )}

                <Col xl={4} md={4}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      Selecione o tipo de mensagem
                    </Label>
                    <Select
                      value={
                        zapMais
                          ? listTypeZapi.find((a) => a.value === type)
                            ? listTypeZapi.find((a) => a.value === type)
                            : null
                          : listType.find((a) => a.value === type)
                          ? listType.find((a) => a.value === type)
                          : null
                      }
                      styles={customStyles}
                      onChange={(value) => handlerType(value)}
                      options={zapMais ? listTypeZapi : listType}
                      isDisabled={block}
                      classNamePrefix="select2-selection"
                      placeholder="Selecione..."
                    />
                  </FormGroup>
                </Col>

                {momento === 'after' && (
                  <Col xl={4} md={4}>
                    <Form.Group>
                      <Label for="exampleEmail">Hora do Envio</Label>
                      <ReactDatePicker
                        className="form-control"
                        selected={horaEnvioDatePicker}
                        onChange={(date) => {
                          handleChangeTransferDate(date)
                        }}
                        timeIntervals={15}
                        showTimeSelect
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                        locale="pt-BR"
                      />
                    </Form.Group>
                  </Col>
                )}

                {(type === 'arquivo' ||
                  type === 'imagem' ||
                  type === 'imagem-botoes') && (
                  <Col xl={4} md={4}>
                    <FormGroup>
                      <Label for="exampleEmail">Imagem/Arquivo</Label>
                      <div className="file-uploader">
                        <Input
                          type="file"
                          isDisabled={block}
                          label="Upload"
                          onChange={handleImgSelected}
                          accept=".pdf, .png, .JPEG, .jpeg, .jpg, .svg, .webp"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                )}
                {(type === 'video-arquivo' || type === 'audio-arquivo') && (
                  <Col xl={4} md={4}>
                    <FormGroup>
                      <Label for="exampleEmail">Arquivo</Label>
                      <div className="file-uploader">
                        <Input
                          type="file"
                          isDisabled={block}
                          label="Upload"
                          onChange={handleVideoSelected}
                          accept=".3gp, .mp4 .ogg .mp3"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                )}

                {type === 'link' && (
                  <>
                    <Col xl={4} md={4}>
                      <FormGroup>
                        <Label for="exampleEmail">Link*</Label>
                        <div className="file-uploader">
                          <Input
                            type="text"
                            onChange={(value) => setLinkUrl(value.target.value)}
                            value={linkUrl}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </>
                )}

                {!block && (
                  <>
                    {(type === 'botoes' || type === 'imagem-botoes') && (
                      <Col xl={4} md={4}>
                        <FormGroup>
                          <Label for="exampleEmail">Titulo dos Botões</Label>
                          <div className="file-uploader">
                            <Input
                              type="text"
                              onChange={(value) =>
                                setTituloLink(value.target.value)
                              }
                              value={tituloLink}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    )}
                    {(type === 'botoes' || type === 'imagem-botoes') && (
                      <>
                        <Col xl={4} md={4}>
                          <FormGroup>
                            <Label for="exampleEmail">Botão 1*</Label>
                            <div className="file-uploader">
                              <Input
                                type="text"
                                onChange={(value) =>
                                  setBtn1(value.target.value)
                                }
                                value={btn1}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col xl={4} md={4}>
                          <FormGroup>
                            <Label for="exampleEmail">Botão 2*</Label>
                            <div className="file-uploader">
                              <Input
                                type="text"
                                onChange={(value) =>
                                  setBtn2(value.target.value)
                                }
                                value={btn2}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col xl={4} md={4}>
                          <FormGroup>
                            <Label for="exampleEmail">Botão 3</Label>
                            <div className="file-uploader">
                              <Input
                                type="text"
                                onChange={(value) =>
                                  setBtn3(value.target.value)
                                }
                                value={btn3}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </>
                )}

                {!block && type === 'arquivo' && (
                  <Col xl={4} md={4}>
                    <Label for="exampleEmail">Nome do Arquivo</Label>
                    <Input
                      type="text"
                      onChange={(value) => setNomeDoArquivo(value.target.value)}
                      value={nomeDoArquivo}
                    />
                  </Col>
                )}
                {!block && (
                  <Col xl={4} md={4} className={styles.checkSair}>
                    <Label for="exampleEmail">Possibilitar Sair</Label>
                    <Switch
                      onChange={() => {
                        setCheckSair(!checkSair)
                      }}
                      checked={checkSair}
                    />
                  </Col>
                )}
              </Row>

              <Row style={{ marginTop: 10 }}>
                <Col xl={8} md={12}>
                  {!block && (
                    <FormGroup>
                      <TextArea
                        value={message}
                        setValue={setMessage}
                        onAddVariable={handleAddVariable}
                      />
                    </FormGroup>
                  )}
                </Col>
                <Col
                  xl={4}
                  md={6}
                  style={{
                    marginTop: 20,
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    display: 'flex'
                  }}
                >
                  {statusInstancia && (
                    <Button
                      id="basic-button"
                      style={{
                        padding: '15px 30px 15px 30px',
                        backgroundColor: '#0D071B',
                        color: 'white',
                        fontWeight: 500
                      }}
                      onClick={(e) => {
                        handlerFile({
                          functionType: 'meu-chat',
                          zapMais,
                          tabela,
                          Swal,
                          block,
                          user,
                          descricao,
                          campanhaExist,
                          imagem,
                          type,
                          campanha,
                          linkUrl,
                          horaEnvio,
                          momento,
                          setLoading,
                          btn1,
                          btn2,
                          btn3,
                          Services,
                          mensagemCampanhaExistente,
                          nomeDoArquivo,
                          descricaoLink,
                          tituloLink,
                          linkAction,
                          checkSair,
                          setImagem,
                          setBtn1,
                          setBtn2,
                          setBtn3,
                          setUser,
                          setDescricaoLink,
                          setTituloLink,
                          setLinkUrl,
                          setLinkAction,
                          setCampanha,
                          setNomeDoArquivo,
                          setFile,
                          setMomento,
                          setHoraEnvio,
                          setHoraEnvioDatePicker,
                          setType,
                          setDescricao,
                          setCampanhaExist,
                          setCampanhaList,
                          setRows,
                          setTabela,
                          setCheckSair,
                          setTableConfig,
                          message,
                          setMessage
                        })
                      }}
                    >
                      <Send /> Enviar Mensagem
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MeuChat
