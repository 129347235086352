/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Button } from 'reactstrap'
import { toast } from 'react-toastify'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
///MODAIS

import Services from '../../services/auth'
import { formatDateT } from '../../util/string'
import './styles.css'

import 'chartist/dist/scss/chartist.scss'
import { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { ModalGrupo } from '../../components/modalGrupo'
import { ModalAddGrupo } from '../../components/modalAddGrupo'
import { exportFile } from 'util/function'
import { SelectComponent } from '../../components/selectComponent'
import ServicesMessage from '../../services/sendMessage'

registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Cod', field: 'id' },
  { label: 'Name', field: 'name' },
  { label: 'Quantidade de Contatos', field: 'numeroDeContatos' },
  { label: 'Criado Em', field: 'created_at' }
]

const Grupos = () => {
  const { listGrupos } = Services
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [idGrupo, setIdGrupo] = useState(false)
  const [showModalGrupo, setShowModalGrupo] = useState(false)
  const [showModalAddGrupo, setShowModalAddGrupo] = useState(false)
  const [instanciasList, setInstanciasList] = useState([])
  const [rendered, setRendered] = useState(false)

  const handleClose = () => {
    setLoading(false)
  }
  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      pagination: true,
      delete: true,
      more: true,
      uploadGrupo: true,
      download: true
    },
    config: {
      deleteText: 'Deletar grupo',
      downloadText: 'Exportar para XLSX'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null,
      sellerId: null
    },
    instanceId: false,
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const loadGroups = async (instanceId) => {
    instanceId = instanceId || tableConfig.instanceId
    setLoading(true)

    const response = await listGrupos({
      rowsPerPage: tableConfig.rowsPerPage,
      page: tableConfig.currentPage,
      searchTerm: tableConfig?.filters?.searchTerm,
      instanceId: instanceId
    })

    if (response.status === 200) {
      const responseData = response?.data?.data
      const formattedData = responseData.items.map((item) => ({
        ...item,
        hideDelete: !!item.integration,
        hideUploadGrupo: !!item.integration,
        created_at: formatDateT(item.created_at)
      }))

      setRows(formattedData)
      setLoading(false)
    } else {
      toast.error(response)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }

    if (rendered) {
      loadInstancias()
    }
  }, [rendered])


  const loadInstancias = async () => {
    const response = await ServicesMessage.loadInstancias()
    if (response.status === 200) {
      const data = []
      response?.data?.data?.data.map((item) => {
        data.push({
          ...item,
          label: item.name,
          value: item.id
        })
      })

      setInstanciasList(data)
    } else {
      toast.error(response?.msg)
      return false
    }
  }


  const handleCreateLinkInstance = (row) => {
    if (row?.checkApi) {
      const Url = `${process.env.REACT_APP_SITE_URL}ZAapMaisDetails/${row?.id}`
      window.open(
        Url,
        '_blank' // <- This is what makes it open in a new window.
      )
    } else {
      const idZapi = row?.id_zapi
      const tokenZapi = row?.token_zapi
      const Url = `${process.env.REACT_APP_SITE_URL}ZApiDetails/${idZapi}/${tokenZapi}`

      window.open(
        Url,
        '_blank' // <- This is what makes it open in a new window.
      )
    }
  }

  const handlerCriarGrupo = async () => {
    setShowModalGrupo(true)
  }

  const onCloseModalGrupo = () => {
    setShowModalGrupo(false)
  }
  const onCloseModalAddGrupo = () => {
    setShowModalAddGrupo(false)
  }

  const onUploadContatos = async (row) => {
    setShowModalAddGrupo(true)
    setIdGrupo(row?.id)
  }

  const onDeleteGrupo = async (row) => {
    const response = await Services.deleteGrupo({ id: row?.id })
    if (response.status === 200) {
      toast.success('Grupo excluído com sucesso!')
      loadGroups(tableConfig.instanceId)
    }
  }

  const onDownload = async (row) => {
    try {
      setLoading(true)
      const response = await Services.exportGroupContacts({ groupId: row?.id })
      console.log({ response })
      if (response.status === 200) {
        const responseData = response?.data

        exportFile({
          base64: responseData.data,
          fileName: responseData.fileName || '',
          extension: 'xlsx'
        })
        toast.success('Contato exportados com sucesso!')
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handlerInstancia = (value) => {
    if (value?.active) {
      setTableConfig((prev) => ({
        ...prev,
        instanceId: value?.value
      }))
      loadGroups(value?.value)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Ops..',
        text: 'Essa instância está desativada por falta de pagamento',
        cancelButtonText: 'Fechar',
        allowEscapeKey: false,
        allowOutsideClick: false
      })
    }
    
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}

      <div className="page-content">
        <MetaTags>
          <title>Grupos | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Grupos | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Grupos de disparo
                  </li>
                </ol>
              </Col>
            </Row>
            <Row>
            <Row style={{marginTop: 20}}>
            <Col xl={6} md={6} xs={12}>
                <SelectComponent
                  options={instanciasList}
                  value={
                    instanciasList.find((a) => a.value === tableConfig.instanceId)
                      ? instanciasList.find((a) => a.value === tableConfig.instanceId)
                      : null
                  }
                  onChange={(value) => handlerInstancia(value)}
                  placeholder="Instância"
                />
            </Col>
          </Row>
              <Col
                xl={12}
                md={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  variant="outlined"
                  onClick={() => handlerCriarGrupo()}
                  className="newBg"
                  style={{
                    marginBottom: '-50px',
                    marginRight: 5,
                    marginTop: 5,
                    cursor: 'pointer',
                    backgroundColor: '#0D071B !important',
                    color: 'white'
                  }}
                >
                  Criar Grupo
                </Button>
              </Col>
            </Row>
          </div>
          <DatatableTables
            title="Meus Grupos de Disparo"
            columns={columns}
            rows={rows}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            onResendTickets={handleCreateLinkInstance}
            onUploadContatos={onUploadContatos}
            onDelete={onDeleteGrupo}
            handleDownload={onDownload}
          />
        </Container>
      </div>
      <ModalGrupo
        onClose={onCloseModalGrupo}
        loadGroups={loadGroups}
        showModal={showModalGrupo}
        instanceId={tableConfig.instanceId}
      />
      <ModalAddGrupo
        onClose={onCloseModalAddGrupo}
        loadGroups={loadGroups}
        showModal={showModalAddGrupo}
        idGrupo={idGrupo}
      />
    </React.Fragment>
  )
}

Grupos.propTypes = {
  t: PropTypes.any
}

export default Grupos
