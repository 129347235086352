import { api } from './axios';

export default {
  saveSegmentation: async (data) => {
    const response = await api
      .post('/segmentations', data)
      .then((response) => response)
      .catch((error) => error.response);
      
    return response;
  },
  getSegmentedClients: async (id) => {
    const response = await api
      .get('/segmentations', {
        params: { id }
      })
      .then((response) => response)
      .catch((error) => error.response);

    return response;
  },
  listSegmentations: async (instanceId) => {
    const response = await api
      .get('/segmentations/list', {
        params: { instance_id: instanceId }
      })
      .then((response) => response)
      .catch((error) => error.response);

    return response;
  },
  deleteSegmentation: async (id) => {
    const response = await api
      .delete(`/segmentations`, { params: { id } })
      .then((response) => response)
      .catch((error) => error.response);

    return response;
  }
};
