import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, Label } from 'reactstrap'

import { Loading } from '../../components/Loading'
import { toast } from 'react-toastify'
import DatatableTables from '../../components/Table'

import 'chartist/dist/scss/chartist.scss'
import { formatDateT } from '../../util/string'
import styles from './styles.module.scss'
import { CardIndicador } from '../../components/CardIndicador'
import Services from '../../services/sendMessage'
import { formatToBrazillianDateDayMonth, validarData } from 'util/function'
import { PageHeader } from 'components/pageHeader'
import { SelectComponent } from 'components/selectComponent'
import { Form } from 'react-bootstrap'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import { format } from 'date-fns'
import ptBR from 'date-fns/locale/pt-BR'

registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Data', field: 'created_at' },
  { label: 'Campanha', field: 'name' },
  { label: 'Instância', field: 'nameInstancia' },
  { label: 'Tipo', field: 'type' },
  { label: 'Status', field: 'status', html: true },
  { label: 'N° Total', field: 'messageTotal' },
  { label: 'Enviadas', field: 'messageSent', html: true },
  { label: 'Recebidas', field: 'messageReceived', html: true },
  { label: 'Lidas', field: 'messageRead', html: true }
]

const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [rows, setRows] = useState([])
  const [instanciasList, setInstanciasList] = useState([])
  const [rendered, setRendered] = useState(false)

  const [valueDatePicker, setValueDatePicker] = useState({
    startsAt: null,
    endsAt: null
  })

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      order: true,
      more: false,
      pagination: true,
      resendPurchase: true,
      createCheckout: localStorage.getItem('root@session')
    },
    config: {
      resendPurchaseText: 'Reenviar Qr codes da compra'
    },
    filters: {
      instanceId: null,
      endsAt: null,
      startsAt: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const loadIndicators = async () => {
    setLoading(true)
    console.log(tableConfig)
    const response = await Services.loadIndicators({
      rowsPerPage: tableConfig.rowsPerPage,
      page: tableConfig.currentPage,
      instanceId: tableConfig.filters.instanceId,
      startsAt: tableConfig.filters.startsAt,
      endsAt: tableConfig.filters.endsAt
    })

    if (response.status === 200) {
      console.log(
        'response?.data?.data?.campanhaList',
        response?.data?.data?.campanhaList
      )
      const now = new Date().getTime()
      const resume = response?.data?.data?.campanhaList.map((item) => {
        console.log({ horaEnvio: item.horaEnvio })
        let sendDate = {
          string: null,
          timestamp: null
        }

        if (item.horaEnvio && validarData(item.horaEnvio)) {
          sendDate = {
            string: formatToBrazillianDateDayMonth(item.horaEnvio),
            timestamp: new Date(item.horaEnvio).getTime()
          }
        }

        const SI = '<span style="color: red">---</span>'

        const percMessageRead =
          (item.messageRead * 100) / item.messageTotal < 1
            ? SI
            : `${((item.messageRead * 100) / item.messageTotal).toFixed(2)}%`

        const percMessageSent =
          (item.messageSent * 100) / item.messageTotal === 0
            ? SI
            : `${((item.messageSent * 100) / item.messageTotal).toFixed(2)}%`

        const percMessageReceived =
          (item.messageReceived * 100) / item.messageTotal === 0
            ? SI
            : `${((item.messageReceived * 100) / item.messageTotal).toFixed(
                2
              )}%`

        return {
          ...item,
          created_at: formatDateT(item.created_at),
          status:
            !item.horaEnvio || sendDate?.timestamp < now
              ? `<span style="color: #03182d; font-weight: 500">Enviado</span>`
              : `<div style="color:#dc3545; font-size: 16px"><div>Agendado (${sendDate.string})</div><div>`,
          messageRead: percMessageRead,
          messageSent: percMessageSent,
          messageReceived: percMessageReceived
        }
      })
      setTableConfig((prev) => ({
        ...prev,
        total: response?.data?.data?.campanhaNumber
      }))

      setRows(resume)
      setData(response?.data?.data)
      setLoading(false)
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }

  const loadInstancias = async () => {
    const response = await Services.loadInstancias()

    if (response.status === 200) {
      const data = []

      response?.data?.data?.data.map((item) => {
        data.push({
          label: item.name,
          value: item.id
        })
      })

      setInstanciasList(data)
    } else {
      toast.error(response?.msg)
      return false
    }
  }

  const handleClose = () => {
    setLoading(false)
  }

  const handlerInstancia = (value) => {
    setTableConfig((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        instanceId: value
      }
    }))
  }

  function handleChangeDate(value, field) {
    const formattedDate = format(value, 'yyyy-MM-dd')
    console.log({ value, formattedDate })
    setTableConfig((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        [field]: formattedDate
      }
    }))
    setValueDatePicker((prev) => ({
      ...prev,
      [field]: value
    }))
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }

    if (rendered) {
      loadInstancias()
      loadIndicators()
    }
  }, [rendered])

  useEffect(() => {
    if (!rendered) return
    loadIndicators()
  }, [tableConfig.currentPage, tableConfig.filters])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}

      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Zap+</title>
        </MetaTags>
        <Container fluid>
          <PageHeader subtitle="Bem Vindo a Zap +" />
          <Row>
            <CardIndicador
              icon={'mdi mdi-cellphone'}
              value={data?.instanciaNumber || 0}
              type={'number'}
              text={'Whatsapp Cadastrados'}
              color="primary"
              xl={3}
              md={3}
              xs={12}
            />
            <CardIndicador
              icon={'mdi mdi-access-point'}
              value={data?.messageSent || 0}
              type={'number'}
              text={'Total Mensagens Enviadas'}
              color="primary"
              xl={3}
              md={3}
              xs={12}
            />
            <CardIndicador
              icon={'mdi mdi-send-check'}
              value={
                data?.messageTotal
                  ? `${(data?.messageTotal !== 0
                      ? (data?.messageReceived * 100) / data?.messageTotal
                      : 0
                    ).toFixed(2)} %`
                  : '0%'
              }
              type={'number'}
              text={'Mensagem Recebidas'}
              color="primary"
              xl={3}
              md={3}
              xs={6}
            />
            <CardIndicador
              icon={'mdi mdi-eye-check'}
              value={
                data?.messageTotal
                  ? `${(data?.messageTotal !== 0
                      ? (data?.messageRead * 100) / data?.messageTotal
                      : 0
                    ).toFixed(2)}%`
                  : '0%'
              }
              type={'number'}
              text={'Mensagem Visualizadas'}
              color="primary"
              xl={3}
              md={3}
              xs={12}
            />
          </Row>
          <Row>
            <Col xs={12} sm={4} className={styles.formSelect} item>
              <Form.Group>
                <Label for="exampleEmail">Instância</Label>
                <SelectComponent
                  options={instanciasList}
                  value={
                    instanciasList.find(
                      (a) => a.value === tableConfig.filters.instanceId
                    )
                      ? instanciasList.find(
                          (a) => a.value === tableConfig.filters.instanceId
                        )
                      : null
                  }
                  onChange={(value) => handlerInstancia(value?.value)}
                  placeholder="Instância"
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={4} className={styles.formSelect} item>
              <Form.Group>
                <Label for="exampleEmail">Data inicial</Label>
                <ReactDatePicker
                  className="form-control"
                  selected={valueDatePicker.startsAt}
                  placeholderText="Selecione uma data"
                  onChange={(date) => {
                    handleChangeDate(date, 'startsAt')
                  }}
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                />
              </Form.Group>
            </Col>

            <Col xs={12} sm={4} className={styles.formSelect} item>
              <Form.Group>
                <Label for="exampleEmail">Data final</Label>
                <ReactDatePicker
                  className="form-control"
                  selected={valueDatePicker.endsAt}
                  placeholderText="Selecione uma data"
                  onChange={(date) => {
                    handleChangeDate(date, 'endsAt')
                  }}
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <DatatableTables
                title={'Campanhas'}
                columns={columns}
                rows={rows}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any
}

export default Dashboard
