import React from 'react'
import { Th, Thead, Tr } from 'react-super-responsive-table'
import { TableColumn, TableConfig } from 'types/global'

export interface TableHeaderProps {
  columns: TableColumn[]
  tableConfig: TableConfig
  rows: any
}

export const TableHeader = ({ tableConfig, columns, rows }: TableHeaderProps) => {
  return (
    <Thead>
      <Tr className="tableHead">
        {tableConfig?.options?.collapse && (
          <Th
            data-testid="collapse"
            data-priority="index"
            className="tableHeadName"
          ></Th>
        )}
        {columns.map((column, index) => (
          <Th key={index} data-priority="index" className="tableHeadName">
            {column?.label}
          </Th>
        ))}
        {tableConfig?.options?.more && rows.find( item => !item.hideMoreOptions) && (
          <Th data-priority="index" className="tableHeadName">
            Opções
          </Th>
        )}
      </Tr>
    </Thead>
  )
}
