import {Spinner} from "reactstrap"

import "./style.css"

export function Loading({open}) {
  return (
    <>
    {open && (
      <div className='boxLoading'>
      <Spinner
    style={{ width: "3rem", height: "3rem" }}
    type="grow"
    color="light"
  />
   </div>
    )}
    </>
  )
}
