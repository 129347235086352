import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import Services from '../../services/user'
import './styles.scss'
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Label
} from 'reactstrap'
import { Loading } from '../Loading'
import {
  handlerUser,
  handlerFile,
  handleFileSelected
} from '../../services/instanceFunctions'

export const ModalAddGrupo = ({ showModal, onClose, loadUsers, idGrupo }) => {
  const [loading, setLoading] = useState(false)
  const [dados, setDados] = useState({
    name: ''
  })
  const [file, setFile] = useState(null)
  const [tabela, setTabela] = useState([])

  const handleClose = () => {
    setLoading(false)
  }

  const handleSubmitSendQrcodes = async () => {
    console.log(tabela)
    setLoading(true)
    const response = await Services.uploadGrupo({
      tabela,
      idGrupo
    })
    if (response.status === 200) {
      onClose()
      loadUsers()
      setLoading(false)
    } else {
      toast.error(response?.msg || 'Ocorreu um erro ao tentar salvar')
      setLoading(false)
      return false
    }
  }

  const handleCancelSendQrcodes = () => {
    onClose()
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container>
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Popular Grupo</h1>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col xl={12} md={12}>
              <FormGroup>
                <Label for="exampleEmail">Planilha de Excel</Label>
                <div className="file-uploader">
                  <Input
                    type="file"
                    label="Upload"
                    accept=".xlsx"
                    onChange={(e) =>
                      handleFileSelected(
                        e,
                        setFile,
                        setLoading,
                        setTabela,
                        false,
                        false,
                        false,
                        'popular'
                      )
                    }
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row className="footerModalButtons" style={{ marginTop: 40 }}>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handleSubmitSendQrcodes}
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handleCancelSendQrcodes}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
