import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import Services from '../../services/user'
import './styles.scss'
import { Loading } from '../Loading'
import Select from 'react-select'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { CurrencyInput } from 'components/CurrencyInput'
import { cnpjMask, onlyUnsignedNumbers, phoneMask } from 'util/string'
import { User } from 'types/global'

interface IModalAddUser {
  showModal: boolean
  onClose: () => void
  loadUsers: () => Promise<void>
  user: User | null
}

interface IErrorMessage {
  name: string | null
  email: string | null
  password: string | null
  plano: string | null
}

interface IUserModal
  extends Pick<
    User,
    'name' | 'cnpj' | 'responsavel' | 'email' | 'phone' | 'plano'
  > {
  errorMessage: IErrorMessage
  password: string
}

const planoList = [
  { value: 'BASIC', label: 'BASIC' },
  { value: 'STANDARD', label: 'STANDARD' },
  { value: 'PREMIUM', label: 'PREMIUM' }
]

export const ModalAddUser = ({
  showModal,
  onClose,
  loadUsers,
  user = null
}: IModalAddUser) => {
  const [loading, setLoading] = useState(false)
  const [showCopy, setShowCopy] = useState(false)
  const [dados, setDados] = useState<IUserModal>({
    name: '',
    cnpj: '',
    responsavel: '',
    email: '',
    password: '',
    phone: '',
    plano: 'BASIC',
    errorMessage: {
      name: null,
      email: null,
      password: null,
      plano: null
    }
  })

  const handleSubmitNewUser = async () => {
    try {
      if (
        !dados.name ||
        !dados.email ||
        (!user && !dados.password) ||
        !dados.plano
      ) {
        toast.error('Preencha todos os campos')
        const newDados = { ...dados }

        if (!dados.name) {
          newDados.errorMessage.name = 'Preencha com o nome do cliente'
        }
        if (!dados.email) {
          newDados.errorMessage.email = 'Preencha com o email do cliente'
        }
        if (!dados.password) {
          newDados.errorMessage.password = 'Defina uma senha'
        }
        if (!dados.plano) {
          newDados.errorMessage.plano = 'Escolha um plano'
        }
        setDados(newDados)
        return false
      }

      setLoading(true)
      let response = null
      if (user) {
        response = await Services.updateUser({ id: user.id, ...dados })
      } else {
        response = await Services.saveUser(dados)
      }
      if (response.status === 200) {
        toast.success(response?.data?.msg ? response?.data?.msg : 'Salvo')
        loadUsers()
        setLoading(false)
        if (user) {
          onClose()
        } else {
          setShowCopy(true)
        }
      } else {
        toast.error(response?.msg || 'Ocorreu um erro ao tentar salvar')
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const handleCancelCreateUser = () => {
    onClose()
  }

  const handleCopyUser = () => {
    navigator.clipboard.writeText(
      `Email: ${dados.email}\nSenha: ${dados.password}`
    )
    toast.success('Email e senha copiados')
    onClose()
  }

  const handlerDados = (key, value) => {
    console.log('heeeere')

    setDados((prev) => ({
      ...prev,
      errorMessage: {
        ...prev.errorMessage,
        [key]: null
      },
      [key]: value
    }))
  }

  const handlerPlano = (value) => {
    setDados((prev) => ({
      ...prev,
      plano: value
    }))
  }

  useEffect(() => {
    console.log({ user })

    if (user && showModal) {
      setDados({
        ...dados,
        cnpj: user.cnpj ? user.cnpj : '',
        email: user.email,
        name: user.name,
        phone: user.phone ? user.phone : '',
        plano: user.plano,
        responsavel: user.responsavel
      })
    }
  }, [user, showModal])

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container className="containerModalAddUser">
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Criar novo Usuário</h1>
            </Col>
          </Row>
          <Row style={{ margin: 10, rowGap: 15 }}>
            <Col xl={4} md={4}>
              <FormGroup>
                <Form.Label>Nome*</Form.Label>
                <Form.Control
                  value={dados.name}
                  onChange={(event) => handlerDados('name', event.target.value)}
                  isInvalid={!!dados?.errorMessage?.name}
                />

                <Form.Control.Feedback type="invalid">
                  {dados?.errorMessage?.name}
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
            <Col xl={4} md={4}>
              <FormGroup>
                <Form.Label>CNPJ</Form.Label>
                <Form.Control
                  value={cnpjMask(dados.cnpj)}
                  onChange={(event) => {
                    if (onlyUnsignedNumbers(event.target.value)?.length <= 14) {
                      handlerDados(
                        'cnpj',
                        onlyUnsignedNumbers(event.target.value)
                      )
                    }
                  }}
                />
              </FormGroup>
            </Col>
            <Col xl={4} md={4}>
              <FormGroup>
                <Form.Label>Responsável</Form.Label>
                <Form.Control
                  value={dados.responsavel}
                  onChange={(event) =>
                    handlerDados('responsavel', event.target.value)
                  }
                />
              </FormGroup>
            </Col>
            <Col xl={4} md={4}>
              <FormGroup>
                <Form.Label>Fone</Form.Label>
                <Form.Control
                  value={phoneMask(dados.phone)}
                  onChange={(event) => {
                    if (onlyUnsignedNumbers(event.target.value)?.length <= 11) {
                      handlerDados(
                        'phone',
                        onlyUnsignedNumbers(event.target.value)
                      )
                    }
                  }}
                />
              </FormGroup>
            </Col>
            <Col xl={4} md={4}>
              <FormGroup>
                <Form.Label>E-mail*</Form.Label>
                <Form.Control
                  value={dados.email}
                  type="email"
                  onChange={(event) =>
                    handlerDados('email', event.target.value)
                  }
                  isInvalid={!!dados?.errorMessage?.email}
                />

                <Form.Control.Feedback type="invalid">
                  {dados?.errorMessage?.email}
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
            {!user && (
              <Col xl={4} md={4}>
                <FormGroup>
                  <Form.Label>Senha*</Form.Label>
                  <Form.Control
                    value={dados.password}
                    type="password"
                    onChange={(event) =>
                      handlerDados('password', event.target.value)
                    }
                    isInvalid={!!dados?.errorMessage?.password}
                  />

                  <Form.Control.Feedback type="invalid">
                    {dados?.errorMessage?.password}
                  </Form.Control.Feedback>
                </FormGroup>
              </Col>
            )}
            <Col xl={4} md={4}>
              <FormGroup>
                <Form.Label>Plano*</Form.Label>
                <Select
                  options={planoList}
                  value={
                    planoList.find((a) => a.value === dados.plano)
                      ? planoList.find((a) => a.value === dados.plano)
                      : null
                  }
                  onChange={(value) => handlerPlano(value?.value)}
                  placeholder="Plano"
                  className={!!dados.errorMessage.plano ? 'select-error' : ''}
                />
                {!!dados.errorMessage.plano && (
                  <>
                    <span className="is-invalid"></span>
                    <p className="invalid-feedback">
                      {dados.errorMessage.plano}
                    </p>
                  </>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className="container-buttons">
            <Button
              onClick={handleCancelCreateUser}
              variant="secondary"
              color="secondary"
            >
              Fechar
            </Button>
            {showCopy ? (
              <Button onClick={handleCopyUser} variant="success">
                Copiar email e senha
              </Button>
            ) : (
              <Button onClick={handleSubmitNewUser} variant="primary">
                Salvar
              </Button>
            )}
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
