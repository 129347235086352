import React from 'react'
import { Col, Row } from 'react-bootstrap'

export interface PageHeaderProps {
  title?: string | null
  subtitle: string
}

export const PageHeader = ({ title = null, subtitle }: PageHeaderProps) => {
  return (
    <div className="page-title-box">
      <Row className="align-items-center">
        <Col md={8}>
          <br></br>
          {title && <h6 className="page-title">{title}</h6>}
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item active">{subtitle}</li>
          </ol>
        </Col>
      </Row>
    </div>
  )
}
