import { useGoogleLogin } from '@react-oauth/google'
import { toast } from 'react-toastify'
import { authenticationService } from 'services/authentication'
import Swal from 'sweetalert2'

export const useLogin = (callback) => {
  const getGoogleAuthUrl = async (instanceId: number) => {
    const response = await authenticationService.getGoogleAuthUrl(instanceId)
    if (response.status === 200) {
      return window.open(response.data.url, '_blank')
    }
    toast.error(
      response.data.message || 'Não foi possível autenticar no momento'
    )
    return null
  }

  const signInGoogle = async (code: string, instanceId: number) => {
    const response = await authenticationService.signInGoogle(code, instanceId)
    if (response.status === 200) {
      callback()
      return toast.success(response.data.message || 'Conectado ao Google')
    }
    toast.error(
      response.data.message || 'Não foi possível autenticar no momento'
    )
    return null
  }

  const disconnectGoogle = async (instanceId: number) => {
    const response = await authenticationService.disconnectGoogle(instanceId)
    if (response.status === 200) {
      callback()
      return toast.success(response.data.message || 'Desconectado')
    }
    toast.error(
      response.data.message || 'Não foi possível desconectar no momento'
    )
    return null
  }

  const showGoogleLoginAlert = (instance: any) => {
    if (instance.googleState) {
      return showGoogleLoginDisconnectAlert(instance.id)
    }
    showGoogleLoginConnectAlert(instance.id)
  }

  const showGoogleLoginConnectAlert = (instanceId: number) => {
    Swal.fire({
      icon: 'info',
      title: 'Conectar ao google?',
      text: 'Após conectar ao google, todos os contatos adicionados ao ZapMais por planilha ou integrações serão salvos nos contatos da sua conta',
      confirmButtonText: 'Conectar',
      cancelButtonText: 'Fechar',
      showCancelButton: true
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        getGoogleAuthUrl(instanceId)
      }
    })
  }

  const showGoogleLoginDisconnectAlert = (instanceId: number) => {
    Swal.fire({
      icon: 'info',
      title: 'Desconectar do google?',
      text: 'Após desconectar do google os contatos não serão mais salvos na sua conta',
      confirmButtonText: 'Desconectar',
      cancelButtonText: 'Fechar',
      showCancelButton: true
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        disconnectGoogle(instanceId)
      }
    })
  }

  return { showGoogleLoginAlert, signInGoogle }
}
