import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags'
import React, { useState } from 'react'
import './styles.css'

import { Row, Col, Alert } from 'reactstrap'
import { Link, Redirect } from 'react-router-dom'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'
// import images
import logoSm from '../../assets/images/logos/zap_mais.png'
import backGround from '../../assets/images/whats.jpg'
import { createUser } from '../../store/auth/login/actions'
import { useUserInfo } from 'context/userContext'
import { Loading } from 'components/Loading'

const Register = ({ match }) => {
  const { setUserName } = useUserInfo()

  const idIndicacao = match?.params?.indicacao

  const [loading, setLoading] = useState(false)
  const [login, setLogin] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')

  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    try {
      values = {
        ...values,
        indicacao: idIndicacao
      }
      setLoading(true)
      const result = await createUser(values)
      setLoading(false)

      if (result.status === 200) {
        setError(false)
        setMessage('')
        console.log(result.message)
        setUserName(result.userName)
        setLogin(true)
      } else {
        setLogin(false)
        setError(true)
        setMessage(result.message)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  return login ? (
    <Redirect to="/assinaturas" />
  ) : (
    <div className="login-page">
      <Loading open={loading} />
      <MetaTags>
        <title>Registro | Zap+</title>
      </MetaTags>
      <Row className="justify-content-center">
        <Col
          xs={12}
          md={6}
          xl={6}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div className="text-primary text-center p-4">
            <Link to="/" className="logo">
              <img src={logoSm} height="60" alt="logo" />
            </Link>
          </div>
          <div className="p-3">
            <AvForm
              className="form-horizontal mt-4"
              onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
              }}
            >
              {error && <Alert color="danger">{message}</Alert>}
              <Row>
                <Col xs={6} md={6} xl={6}>
                  <div className="mb-3">
                    <AvField
                      name="name"
                      label="Nome"
                      value=""
                      className="form-control"
                      placeholder="Nome da Empresa"
                      type="text"
                      required
                    />
                  </div>
                </Col>
                <Col xs={6} md={6} xl={6}>
                  <div className="mb-3">
                    <AvField
                      name="email"
                      label="Email"
                      value=""
                      className="form-control"
                      placeholder="Insira o email"
                      type="email"
                      required
                    />
                  </div>
                </Col>
                <Col xs={6} md={6} xl={6}>
                  <div className="mb-3">
                    <AvField
                      name="password"
                      label="Senha"
                      value=""
                      type="password"
                      required
                      placeholder="Insira a Senha"
                    />
                  </div>
                </Col>
                <Col xs={6} md={6} xl={6}>
                  <div className="mb-3">
                    <AvField
                      name="cnpj"
                      label="CNPJ"
                      value=""
                      className="form-control"
                      placeholder="Insira o CNPJ"
                      type="text"
                      required
                    />
                  </div>
                </Col>
                <Col xs={6} md={6} xl={6}>
                  <div className="mb-3">
                    <AvField
                      name="responsavel"
                      label="Responsável"
                      value=""
                      className="form-control"
                      placeholder="Nome do Responsável"
                      type="text"
                      required
                    />
                  </div>
                </Col>
                <Col xs={6} md={6} xl={6}>
                  <div className="mb-3">
                    <AvField
                      name="contato"
                      label="Telefone"
                      value=""
                      className="form-control"
                      placeholder="Telefone para contato"
                      type="text"
                      required
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm={6}></Col>
                <Col sm={6} className="text-end">
                  <button
                    className="btn  w-md waves-effect waves-light newBg"
                    type="submit"
                  >
                    Criar Conta
                  </button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Col>
        <Col
          xs={12}
          md={6}
          xl={6}
          className="background-login"
          style={{ backgroundImage: `url(${backGround})` }}
        ></Col>
      </Row>
    </div>
  )
}

export default Register

Register.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func
}
