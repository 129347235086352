import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'

//i18n
import { withTranslation } from 'react-i18next'
// Redux
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'

// users
import user1 from '../../../assets/images/users/person.jpg'
import { useUserInfo } from 'context/userContext'
import './styles.css'

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false)
  const { userPlan, setIsRoot, setUserPlan, userName } = useUserInfo()
  function logout() {
    localStorage.removeItem('authUser')
    localStorage.removeItem('userName')
    setIsRoot(false)
    setUserPlan('')
    props.history.push('/login')
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
            <span
              style={{
                fontSize: '14px',
                fontWeight: '700',
                marginRight: '2px'
              }}
              className="mobile-hide"
            >
              {userName}
            </span>
            <span
              style={{
                fontSize: '14px',
                fontWeight: '700',
                marginRight: '10px'
              }}
              className="mobile-hide"
            > |{' '}{userPlan}
            </span>
          <img
            className="rounded-circle header-profile-user"
            src={user1}
            alt="Header Avatar"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {' '}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t('Perfil')}{' '}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link
            onClick={() => {
              logout()
            }}
            className="dropdown-item"
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Sair')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
