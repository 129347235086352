/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
// import {  } from 'react-feather'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
///MODAIS

import Services from '../../services/auth'
import { phoneMask, formatDateT } from '../../util/string'
import './styles.scss'

import 'chartist/dist/scss/chartist.scss'
import { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { ModalConfirm } from 'components/modalConfirm'
import instanceService from 'services/instance'
import { useLogin } from 'hooks/useLogin'
import { useHistory, useLocation } from 'react-router-dom'

registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Cod', field: 'id' },
  { label: 'Name', field: 'name' },
  { label: 'Status', field: 'status' },
  { label: 'API', field: 'use_local', html: true },
  { label: 'Google', field: 'google', html: true }
]

const Instancias = () => {
  const { search } = useLocation()
  const history = useHistory()

  const queryParams = useMemo(() => new URLSearchParams(search), [search])

  const { listInstance } = Services

  const [rendered, setRendered] = useState(false)
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModalConfirm, setShowModalConfirm] = useState(false)
  const [showModalConfirmLogout, setShowModalConfirmLogout] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const { deleteQueue, logoutInstance } = instanceService

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      linkInstance: true,
      order: true,
      more: true,
      pagination: true,
      delete: true,
      logout: true
    },
    config: {
      deleteText: 'Apagar fila',
      logoutText: 'Desconectar do whatsapp'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null,
      sellerId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const loadInstances = useCallback(async () => {
    setLoading(true)

    const response = await listInstance({
      rowsPerPage: tableConfig.rowsPerPage,
      page: tableConfig.currentPage,
      searchTerm: tableConfig?.filters?.searchTerm
    })

    if (response.status === 200) {
      const responseData = response?.data?.data
      const formattedData = responseData.items.map((item) => ({
        ...item,
        phone: item.phone ? phoneMask(item.phone) : null,
        status: item.status === 1 ? 'Ativo' : 'Desativado',
        connected:
          item.connected === 1
            ? `<span style="color: #20027b; font-weight: 500">Conectado</span>`
            : `<span style="color: #f23702">Desconectado</span>`,
        payment:
          item.payment === 1
            ? `<span style="color: #20027b; font-weight: 500">Pago</span>`
            : `<span style="color: #f23702">Pendente</span>`,
        use_local:
          item.checkApi === 1
            ? `<span style="color: #20027b; font-weight: 500">Zap+</span>`
            : `<span style="color: #f23702">Z-Api</span>`,
        google:
          item.googleState
            ? `<span style="color: #20027b; font-weight: 500">Conectado</span>`
            : `<span style="color: #f23702">Não conectado</span>`,
        created_at: formatDateT(item.created_at)
      }))

      setRows(formattedData)
      setLoading(false)
    } else {
      toast.error(response)
      setLoading(false)
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  const handleCreateLinkInstance = (row) => {
    if (row?.checkApi) {
      const Url = `${process.env.REACT_APP_SITE_URL}ZAapMaisDetails/${row?.id}`
      window.open(
        Url,
        '_blank' // <- This is what makes it open in a new window.
      )
    } else {
      const idZapi = row?.id_zapi
      const tokenZapi = row?.token_zapi
      const Url = `${process.env.REACT_APP_SITE_URL}ZApiDetails/${idZapi}/${tokenZapi}`

      window.open(
        Url,
        '_blank' // <- This is what makes it open in a new window.
      )
    }
  }

  function handleDelete(row) {
    setShowModalConfirm(true)
    setCurrentRow(row)
  }

  function handleLogout(row) {
    setShowModalConfirmLogout(true)
    setCurrentRow(row)
  }

  async function handleRemoveQueue() {
    try {
      setLoading(true)
      const response = await deleteQueue({ instanceLocalId: currentRow.id })
      setLoading(false)
      setShowModalConfirm(false)
      setCurrentRow(null)
      if (response.status === 200) {
        toast.success(response?.data?.msg || 'Mensagens removidas')
      } else {
        toast.error(
          response?.response?.data?.msg ||
          'Não foi possível remover as mensagens da fila'
        )
      }
    } catch (error) {
      setLoading(false)
      setShowModalConfirm(false)
      console.log({ error })
    }
  }

  function handleCancelRemoveQueue() {
    setShowModalConfirm(false)
    setCurrentRow(null)
  }

  async function handleConfirmLogout() {
    try {
      setLoading(true)
      const response = await logoutInstance({ instanceLocalId: currentRow.id })
      setLoading(false)
      setShowModalConfirmLogout(false)
      setCurrentRow(null)
      if (response.status === 200) {
        toast.success(response?.data?.msg || 'Desconectado')
      } else {
        toast.error(
          response?.response?.data?.msg || 'Não foi possível se desconectar'
        )
      }
    } catch (error) {
      setLoading(false)
      setShowModalConfirmLogout(false)
      console.log({ error })
    }
  }

  function handleCancelLogout() {
    setShowModalConfirmLogout(false)
    setCurrentRow(null)
  }

  const generateCustomOptions = () => {
    return [
      {
        handleClick: (row) => showGoogleLoginAlert(row),
        labelText: {
          true: 'Desconectar do Google', false: 'Conectar ao Google'
        },
        icon: <i className='mdi mdi-google' style={{ fontSize: 24 }} role='img'></i>,
        field: 'googleState'
      }
    ]
  }

  const handleGoogleSignIn = async () => {
    await signInGoogle(queryParams.get('code'), Number(queryParams.get('state')))
    history.replace({
      search: ''
    })
  }

  const { showGoogleLoginAlert, signInGoogle } = useLogin(loadInstances)

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
      return
    } else {
      loadInstances()
    }
  }, [tableConfig.rowsPerPage, tableConfig.currentPage, tableConfig.filters, rendered])

  useEffect(() => {
    if (rendered && queryParams.get('state') && queryParams.get('code')) {
      handleGoogleSignIn();
    }
  }, [queryParams, rendered])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} />}

      <div className="page-content">
        <MetaTags>
          <title>Instâncias | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Instâncias | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Instâncias
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <DatatableTables
            title={'Meus Whatsapp'}
            columns={columns}
            rows={rows}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            onDelete={handleDelete}
            onResendTickets={handleCreateLinkInstance}
            onLogout={handleLogout}
            options={generateCustomOptions()}
          />
          <ModalConfirm
            showModal={showModalConfirm}
            text="Remover todas as mensagens da fila dessa instância?"
            title="Apagar mensagem da fila"
            onCancel={handleCancelRemoveQueue}
            onConfirm={handleRemoveQueue}
          />
          <ModalConfirm
            showModal={showModalConfirmLogout}
            text="Não será possível iniciar campanhas até conectar novamente. As mensagens na fila não serão enviadas"
            title="Desconectar do WhatsApp?"
            onCancel={handleCancelLogout}
            onConfirm={handleConfirmLogout}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

Instancias.propTypes = {
  t: PropTypes.any
}

export default Instancias
