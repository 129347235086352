import { z } from 'zod'

export const contactSchema = z.object({
  id: z.number().optional(),
  phone: z
    .string({ message: 'Informe o número de whatsapp do contato' })
    .min(1, 'Informe o número de whatsapp do contato'),
  name: z.string({ message: 'Informe o nome do contato' }).optional()
})

export const addContact = z.object({
  contacts: z.array(contactSchema)
})

export type AddContact = z.infer<typeof addContact>

export type ContactSchema = z.infer<typeof contactSchema>
