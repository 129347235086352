import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, FormGroup } from 'reactstrap'

import { Loading } from '../../components/Loading'
import { toast } from 'react-toastify'
import DatatableTables from '../../components/Table'
import { formatCurrency, formatDateSimplesWithHours } from '../../util/string'

import 'chartist/dist/scss/chartist.scss'
import Services from '../../services/sorte'
import { PageHeader } from 'components/pageHeader'
import { Card } from 'react-bootstrap'
import SwitchWithLabel from '../../components/Switch'


const columns = [
  { label: 'Id Transação', field: 'id' },
  { label: 'Usuario', field: 'user_id' },
  { label: 'Status', field: 'status', html: true},
  { label: 'Quantidade de SMS', field: 'quantidade' },
  { label: 'Valor', field: 'total' },
  { label: 'Data', field: 'created_at' }
]

const Transaction = () => {
  const [loading, setLoading] = useState(false)
  const [rows, setRows] = useState([])
  const [checked, setChecked] = useState(true)

  const [tableConfig, setTableConfig] = useState({
    options: {
      pagination: true
    },
    config: {},
    filters: {
      searchTerm: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const loadIndicators = async (id) => {
    setLoading(true)
    const response = await Services.loadTransaction({
      rowsPerPage: tableConfig.rowsPerPage,
      page: tableConfig.currentPage,
      checked
    })

    if (response.status === 200) {
      const formatData = response?.data?.data?.items?.map((item) => {
        return {
          ...item,
          created_at: formatDateSimplesWithHours(item?.created_at),
          total: formatCurrency({value:item?.total}),
          status: item?.status === 1 ? '<span class="badge badge-success">Pago</span>' : 
           item?.PaymentExpirationDateTime > new Date() ? '<span class="badge badge-warning">Pendente</span>' : '<span class="badge badge-danger">Expirado</span>'
        }
      })
      setRows(formatData)
      setLoading(false)
      setTableConfig({
        ...tableConfig,
        total: response?.data?.data.total
      })
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }

  const handleClose = () => {
    setLoading(false)
  }

  useEffect(() => {
      loadIndicators()
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters, checked])


  const handlerChecked = async () => {
    setChecked(!checked)
  }


  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <div className="page-content">
        <MetaTags>
          <title></title>
        </MetaTags>
        <Container fluid>
          <PageHeader subtitle="Historico de Transações SMS" />

          <Row>
            <Card>
              <Card.Body>
              <SwitchWithLabel
                    onChange={() => {
                     handlerChecked()
                    }}
                    checked={checked}
                    label="Somente Pagos"
                  />
                <Col xl={12}>
                  <DatatableTables
                    title={'Mensagens'}
                    columns={columns}
                    rows={rows}
                    tableConfig={tableConfig}
                    setTableConfig={setTableConfig}
                  />
                </Col>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Transaction.propTypes = {
  t: PropTypes.any
}

export default Transaction
