/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Input, FormGroup, Label 
} from 'reactstrap'

import { toast } from 'react-toastify'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
///MODAIS
import Select from 'react-select'
import {
  formatDateT,
  formatCurrency,
  phoneMaskRegex,
  phoneMask
} from '../../util/string'
import './styles.css'
import Services from '../../services/sendMessage'
import InstanceService from '../../services/instance'

import 'chartist/dist/scss/chartist.scss'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { ModalAddCashback } from '../../components/modalAddCashback'
import { ModalDeleteCashback } from '../../components/modalDeleteCashback'
import { ModalResgatarCashback } from '../../components/modalResgatarCashback'
import { SelectComponent } from '../../components/selectComponent'
import { CardIndicador } from '../../components/CardIndicador'


registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Cod', field: 'id' },
  { label: 'Name', field: 'name' },
  { label: 'Telefone', field: 'fone' },
  { label: 'Valor', field: 'value' },
  { label: 'Status', field: 'status', html: true },
  { label: 'Verificador', field: 'hash' },
  { label: 'Valido até', field: 'due_date' },
  { label: 'Criado Em', field: 'created_at' }
]

const Cashback = () => {
  const [rows, setRows] = useState([])
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)
  const [idGrupo, setIdGrupo] = useState(false)
  const [showModalCashback, setShowModalCashback] = useState(false)
  const [showModalAddGrupo, setShowModalAddGrupo] = useState(false)
  const [instanciasList, setInstanciasList] = useState([])
  const [rendered, setRendered] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [modalResgatar, setModalResgatar] = useState(false)
  const [idCashback, setIdCashback] = useState(false)

  const [statusList, setStatusList] = useState([
    { label: 'Todos', value: 0 },
    { label: 'Disponivel', value: 1 }
  ])

  const handleClose = () => {
    setLoading(false)
  }

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      pagination: true,
      more: true,
      deleteCashback: true,
      resgatarCashback: true
    },
    config: {
      deleteText: 'Deletar Cashback',
      resgatarText: 'Resgatar Cashback'
    },
    filters: {
      searchTerm: null,
      status: 0,
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null,
      sellerId: null
    },
    total: 0,
    currentPage: 0,
    userId: false,
    rowsPerPage: 50
  })

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }

    if (rendered) {
      loadInstancias()
    }
  }, [rendered])

  useEffect(() => {
    if (tableConfig?.userId) {
      getCashback(tableConfig?.userId)
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters.searchTerm,  tableConfig.filters.status])


const getCashback = async (idValue) => {  
  const result = await InstanceService.getCashback({
    id: idValue,
    page: tableConfig.currentPage,
    limit: tableConfig.rowsPerPage,
    searchTerm: tableConfig.filters.searchTerm,
    status: tableConfig.filters.status
  });

  if (result.status === 200) {

    console.log(result?.data)

    const formattedData = result?.data?.data?.map((item) => {
      return {
        ...item,
        fone: phoneMaskRegex(item.fone),
        value: formatCurrency({value: item.value}),
        status: item.expired ? 
        '<span class="badge badge-expired">Expirado</span>' :
          item.status ? 
            '<span class="badge badge-success">Disponivel</span>' :
              '<span class="badge badge-danger">Utilizado</span>',
        due_date: formatDateT(item.due_date),
        created_at: formatDateT(item.created_at)
      }
    })

    setTableConfig((prev) => ({
      ...prev,
      total: result?.data?.total
    }))

    setData(result?.data?.indicador)

    setRows(formattedData)
    setLoading(false)
    return true
  } else {
    toast.error("Falha ao carregar cashback")
    setLoading(false)
    return false
  }
}

  const loadInstancias = async () => {
    const response = await Services.loadInstancias()

    if (response.status === 200) {
      const data = []
      response?.data?.data?.data.map((item) => {
        data.push({
          label: item.name,
          value: item.id
        })
      })

      setInstanciasList(data)
    } else {
      toast.error(response?.msg)
      return false
    }
  }

  const handlerCashback = async () => {
    if (tableConfig?.userId) {
      setShowModalCashback(true)
    } else {
      toast.error("Selecione uma instancia")
    } 
  }

  const onCloseModalCashback = () => {
    setShowModalCashback(false)
  }

  const handlerInstancia = (value) => {
    setTableConfig((prev) => ({
      ...prev,
      userId: value
    }))

    getCashback(value)
  }

  const onDelete = async (item) => {
    console.log("delete")
    console.log(item)
    setModalDelete(true)
    setIdCashback(item.id)
  }

  const onResgateCashback = async (item) => {
    console.log("resgate")
    console.log(item)
    setModalResgatar(true)
    setIdCashback(item)
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <div className="page-content">
        <MetaTags>
          <title>Cashback | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Cashback | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Bem vindo a gestão de Cashback</li>
                </ol>
              </Col>
            </Row>
            <Row>
              <Col
                xl={12}
                md={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button variant="outlined"
                  onClick={() => handlerCashback()}
                  className="newBg">  Adicionar Cashback </Button>
              </Col>
            </Row>
          </div>
          <Row style={{marginTop: 50}}>
            <Col xl={4} md={4} xs={12}>
            <Label for="exampleEmail">Instancia</Label>
                <SelectComponent
                  options={instanciasList}
                  value={
                    instanciasList.find((a) => a.value === tableConfig.userId)
                      ? instanciasList.find((a) => a.value === tableConfig.userId)
                      : null
                  }
                  onChange={(value) => handlerInstancia(value?.value)}
                  placeholder="Instância"
                />
            </Col>
            <Col xl={4} md={4} xs={12}>
            <Label for="exampleEmail">Status</Label>
                <SelectComponent
                  options={statusList}
                  value={
                    statusList.find((a) => a.value === tableConfig.filters.status)
                      ? statusList.find((a) => a.value === tableConfig.filters.status)
                      : null
                  }
                  onChange={(value) => {
                    setTableConfig((prev) => ({
                      ...prev,
                      filters: {
                        ...prev.filters,
                        status: value?.value
                      }
                    }))
                  }}
                  placeholder="Instância"
                />
            </Col>
            <Col xl={4} md={4} xs={12}>
            <Label for="exampleEmail">Pesquisar</Label>
                  <Input
                    type="text"
                    onChange={(value) => {
                      setTableConfig((prev) => ({
                        ...prev,
                        filters: {
                          ...prev.filters,
                          searchTerm: value.target.value
                        }
                      }))
                    }}
                    value={tableConfig.filters.searchTerm}
                  />
            </Col>
          </Row>
            <Row style={{marginTop: 25}}>
            <CardIndicador
              icon={'mdi mdi-send-check'}
              value={data?.total || 0}
              type={'number'}
              text={'Total de Cashback'}
              color="primary"
              xl={3}
              md={3}
              xs={12}
            />
            <CardIndicador
              icon={'mdi mdi-finance'}
              value={data?.value || 0}
              type={'money'}
              text={'Valor Total'}
              color="primary"
              xl={3}
              md={3}
              xs={12}
            />
          
          </Row>
          <DatatableTables
            title={'Lista de Cashback'}
            columns={columns}
            rows={rows}
            tableConfig={tableConfig}
            setTableConfig={setTableConfig}
            onDelete={onDelete}
            onResgateCashback={onResgateCashback}
          />
        </Container>
      </div>
      <ModalAddCashback
        onClose={onCloseModalCashback}
        showModal={showModalCashback}
        id={tableConfig?.userId}
        getCashback={getCashback}
      />
      <ModalDeleteCashback
        onClose={() => setModalDelete(false)}
        showModal={modalDelete}
        idCashback={idCashback}
        id={tableConfig?.userId}
        getCashback={getCashback}
      />
        <ModalResgatarCashback
        onClose={() => setModalResgatar(false)}
        showModal={modalResgatar}
        itemCash={idCashback}
        id={tableConfig?.userId}
        getCashback={getCashback}
      />
    </React.Fragment>
  )
}

Cashback.propTypes = {
  t: PropTypes.any
}

export default Cashback
