import React from 'react'
import ReactModal from 'react-modal'
//@ts-ignore
import styles from './styles.module.scss'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { AddContact, addContact, ContactSchema } from './addContact.schema'
import { PlusCircle, Trash } from 'react-feather'
import { onlyNumbers, phoneMask } from 'util/string'

interface IModalInstanciaProps {
  showModal: boolean
  onClose: () => void
  onConfirm: (data: ContactSchema[]) => void
}

export const ModalAddContacts = ({
  showModal,
  onClose,
  onConfirm
}: IModalInstanciaProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<AddContact>({
    resolver: zodResolver(addContact),
    defaultValues: {
      contacts: [{ name: '', phone: '' }] // Start with one contact
    }
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'contacts'
  })

  const handleAddContact = () => {
    append({ name: '', phone: '' })
  }

  const handleConfirm = (data: AddContact) => {
    console.log(data)
    onConfirm(data.contacts)
  }

  return (
    <ReactModal
      isOpen={showModal}
      className={styles.reactModal}
      overlayClassName="customModalOverlay"
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <Container className="containerModalAddInstance">
        <form className="d-flex flex-column gap-4">
          <Row className="modalText">
            <Col xs={12}>
              <h2>Adicionar contatos</h2>
            </Col>
          </Row>

          {fields.map((field, index) => (
            <Row key={field.id}>
              <Col xs={5}>
                <FormGroup>
                  <Form.Label>Nome</Form.Label>
                  <Controller
                    control={control}
                    name={`contacts.${index}.name`}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={!!errors?.contacts?.[index]?.name}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.contacts?.[index]?.name?.message}
                  </Form.Control.Feedback>
                </FormGroup>
              </Col>
              <Col xs={5}>
                <FormGroup>
                  <Form.Label>WhatsApp*</Form.Label>
                  <Controller
                    control={control}
                    name={`contacts.${index}.phone`}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        value={phoneMask(field.value)}
                        onChange={(e) =>
                          field.onChange(
                            onlyNumbers(e.target.value).slice(0, 11)
                          )
                        }
                        isInvalid={!!errors?.contacts?.[index]?.phone}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors?.contacts?.[index]?.phone?.message}
                  </Form.Control.Feedback>
                </FormGroup>
              </Col>
              <Col xs={2} className="d-flex">
                <Button
                  className="h-fit mt-auto"
                  variant="outline-danger"
                  onClick={() => remove(index)}
                >
                  <Trash />
                </Button>
              </Col>
            </Row>
          ))}

          <Row>
            <Col className="w-full text-end">
              <Button onClick={handleAddContact} variant="outline-success">
                Adicionar contato <PlusCircle />
              </Button>
            </Col>
          </Row>
          <Row className="container-buttons">
            <Button onClick={onClose} variant="secondary">
              Fechar
            </Button>
            <Button onClick={handleSubmit(handleConfirm)} variant="success">
              Salvar
            </Button>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
