import React, { useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import './styles.scss'
import { Loading } from '../Loading'
import Select from 'react-select'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { CurrencyInput } from 'components/CurrencyInput'
import plansService from 'services/plans'
import userServices from '../../services/user'

interface IModalAddUser {
  showModal: boolean
  onClose: () => void
  callback: () => Promise<void>
}

interface IErrorMessage {
  name: string | null
  price: string | null
  planId: string | null
  userId: string | null
}

interface ICustomPlanModal {
  id?: number
  name: string | null
  planId: number | null
  userId: number | null
  price: number | null
  errorMessage: IErrorMessage
}

export const ModalAddCustomPlan = ({
  showModal,
  onClose,
  callback
}: IModalAddUser) => {
  const { selectPlans, createCustomPlan } = plansService
  const { selectUsers } = userServices

  const [loading, setLoading] = useState(false)
  const [showCopy, setShowCopy] = useState(false)

  const [plans, setPlans] = useState([])
  const [users, setUsers] = useState([])

  const [dados, setDados] = useState<ICustomPlanModal>({
    name: null,
    planId: null,
    price: null,
    userId: null,
    errorMessage: {
      name: null,
      planId: null,
      price: null,
      userId: null
    }
  })

  const handleSubmitNewCustomPlan = async () => {
    try {
      if (!dados.name || !dados.planId || !dados.userId || !dados.price) {
        toast.error('Preencha todos os campos')
        const newDados = { ...dados }

        if (!dados.name) {
          newDados.errorMessage.name = 'Preencha com um nome para o plano'
        }
        if (!dados.planId) {
          newDados.errorMessage.planId = 'Selecione um dos planos do sistema'
        }
        if (!dados.userId) {
          newDados.errorMessage.userId =
            'Selecione o usuário que terá acesso ao plano'
        }
        if (!dados.price) {
          newDados.errorMessage.price = 'Escolha o preço do plano'
        }
        setDados(newDados)
        return false
      }

      setLoading(true)
      let response = await createCustomPlan(dados)
      if (response.status === 200) {
        toast.success(response?.data?.msg ? response?.data?.msg : 'Salvo')
        callback()
        setLoading(false)
        onClose()
      } else {
        toast.error(
          response?.response?.data?.msg || 'Ocorreu um erro ao tentar salvar'
        )
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const loadPlans = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await selectPlans()

      if (response.status === 200) {
        setPlans(response.data.data)
        setLoading(false)
      } else {
        toast.error(response)
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [])

  const loadUsers = useCallback(async () => {
    setLoading(true)
    console.log('loading')

    try {
      const response = await selectUsers()

      if (response.status === 200) {
        setUsers(response.data.data)
        setLoading(false)
      } else {
        toast.error(response)
        setLoading(false)
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }, [])

  const handleCancelCreateCustomPlan = () => {
    onClose()
  }

  const handlerDados = (key, value) => {
    console.log('heeeere')

    setDados((prev) => ({
      ...prev,
      errorMessage: {
        ...prev.errorMessage,
        [key]: null
      },
      [key]: value
    }))
  }

  useEffect(() => {
    if (showModal) {
      loadPlans()
      loadUsers()
    }
  }, [showModal])

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container className="containerModalAddCustomPlan">
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Criar novo plano personalizado</h1>
            </Col>
          </Row>
          <Row style={{ margin: 10, rowGap: 15 }}>
            <Col xs={12} md={6}>
              <FormGroup>
                <Form.Label>Usuário</Form.Label>
                <Select
                  value={
                    users.find((a) => a.value === dados.userId)
                      ? users.find((a) => a.value === dados.userId)
                      : null
                  }
                  onChange={(value) => {
                    handlerDados('userId', value.value)
                  }}
                  options={users}
                  classNamePrefix="select2-selection"
                  placeholder="Selecione..."
                  className={!!dados.errorMessage.userId ? 'select-error' : ''}
                />
                {!!dados.errorMessage.userId && (
                  <>
                    <span className="is-invalid"></span>
                    <p className="invalid-feedback">
                      {dados.errorMessage.userId}
                    </p>
                  </>
                )}
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Form.Label>Plano</Form.Label>
                <Select
                  value={
                    plans.find((a) => a.value === dados.planId)
                      ? plans.find((a) => a.value === dados.planId)
                      : null
                  }
                  onChange={(value) => {
                    handlerDados('planId', value.value)
                  }}
                  options={plans}
                  classNamePrefix="select2-selection"
                  placeholder="Selecione..."
                  className={!!dados.errorMessage.planId ? 'select-error' : ''}
                />
                {!!dados.errorMessage.planId && (
                  <>
                    <span className="is-invalid"></span>
                    <p className="invalid-feedback">
                      {dados.errorMessage.planId}
                    </p>
                  </>
                )}
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup>
                <Form.Label>Nome*</Form.Label>
                <Form.Control
                  value={dados.name}
                  onChange={(event) => handlerDados('name', event.target.value)}
                  isInvalid={!!dados?.errorMessage?.name}
                />

                <Form.Control.Feedback type="invalid">
                  {dados?.errorMessage?.name}
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <CurrencyInput
                variant="outlined"
                label="Valor*"
                value={dados.price}
                handleChange={(value: number) => {
                  handlerDados('price', value)
                }}
                error={!!dados.errorMessage.price && dados.price !== 0}
                helperText={dados.errorMessage.price}
                className={!!dados.errorMessage.price ? 'select-error' : ''}
              />
              {!!dados.errorMessage.price && (
                <>
                  <span className="is-invalid"></span>
                  <p className="invalid-feedback">{dados.errorMessage.price}</p>
                </>
              )}
              {plans?.find((item) => item.value === dados?.planId) && (
                <Form.Text className="text-muted">
                  O valor sugerido é{' '}
                  <b>
                    {plans?.find((item) => item.value === dados?.planId)?.price}
                  </b>
                </Form.Text>
              )}
            </Col>
          </Row>
          <Row className="container-buttons">
            <Button
              onClick={handleCancelCreateCustomPlan}
              variant="secondary"
              color="secondary"
            >
              Fechar
            </Button>
            <Button onClick={handleSubmitNewCustomPlan} variant="primary">
              Salvar
            </Button>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
