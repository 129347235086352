import React, { useState } from 'react'
import { ChevronDown, ChevronUp, Trash, Eye } from 'react-feather'
import { Td, Tr } from 'react-super-responsive-table'
import { ColOptions } from '../colOptions'

export const Row = ({
  row,
  columns,
  tableConfig,
  onAddSeller,
  onResendTickets,
  onDelete,
  onResgateCashback,
  onUploadContatos,
  CollapseComponent,
  onCreateCheckout,
  callback,
  onUpdatePassword,
  onEdit,
  onAdd,
  onLogout,
  onSwitch,
  handlerTrash,
  currentEmail,
  onDownload,
  options = [],
  onView
}) => {
  const [open, setOpen] = useState(false)

  const handleOpenOrder = async () => {
    setOpen(!open)
  }
  const handleAddInstance = () => {
    onAddSeller(row)
  }

  const handleLinkInstance = () => {
    onResendTickets(row)
  }

  const handleDelete = () => {
    onDelete(row)
  }

  const handlerResgatarCashback = () => {
    onResgateCashback(row)
  }

  const handlerUploadContatos = () => {
    onUploadContatos(row)
  }

  const handlerUpdatePassword = () => {
    onUpdatePassword(row)
  }

  const handlerEdit = () => {
    onEdit(row)
  }

  const handlerAdd = () => {
    onAdd(row)
  }

  const handlerLogout = () => {
    onLogout(row)
  }

  const handlerSwitch = () => {
    onSwitch(row)
  }

  const handlerDownload = () => {
    onDownload(row)
  }

  return (
    <>
      <Tr>
        {tableConfig?.options?.collapse && (
          <Td>
            <span onClick={() => handleOpenOrder()}>
              {open ? <ChevronUp /> : <ChevronDown />}
            </span>
          </Td>
        )}
        {columns.map((col) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <Td>
              {col.field === 'valor_fluxo' ? (
                row?.['type'] === 1 ? (
                  <span style={{ color: '#020413', fontWeight: 600 }}>
                    {row?.[col.field]}
                  </span>
                ) : (
                  <span style={{ color: '#a6142c', fontWeight: 600 }}>
                    {row?.[col.field]}
                  </span>
                )
              ) : col.html ? (
                <span dangerouslySetInnerHTML={{ __html: row?.[col.field] }} />
              ) : (
                row?.[col.field]
              )}
            </Td>
          )
        })}
        {tableConfig?.options?.trash &&
          (currentEmail ? (
            <Td
              onClick={() => {
                currentEmail === row['email']
                  ? console.log('')
                  : handlerTrash(row)
              }}
            >
              <div className="menuItem">
                {currentEmail !== row['email'] && (
                  <>
                    <Trash />
                    {tableConfig?.config?.handleTrashButton}
                  </>
                )}
              </div>
            </Td>
          ) : (
            <Td
              onClick={() => {
                handlerTrash(row)
              }}
            >
              <div className="menuItem">
                <Trash />
                {tableConfig?.config?.handleTrashButton}
              </div>
            </Td>
          ))}
            {tableConfig?.options?.view &&
            <Td
              onClick={() => {onView(row)}}
              style={{
                cursor: "pointer"
              }}
            >
              <Eye />
            </Td>
          }
        {tableConfig?.options?.more && !row.hideMoreOptions && (
          <ColOptions
            row={row}
            tableConfig={tableConfig}
            handleAddInstance={handleAddInstance}
            handleLinkInstance={handleLinkInstance}
            handleDelete={handleDelete}
            handlerResgatarCashback={handlerResgatarCashback}
            handlerUploadContatos={handlerUploadContatos}
            handlerUpdatePassword={handlerUpdatePassword}
            handlerEdit={handlerEdit}
            handlerAdd={handlerAdd}
            handlerLogout={handlerLogout}
            handlerSwitch={handlerSwitch}
            handlerDownload={handlerDownload}
            options={options}
          />
        )}
      </Tr>
      {tableConfig?.options?.collapse && CollapseComponent && open && (
        <>
          <CollapseComponent
            open={open}
            tableConfig={tableConfig}
            onCreateCheckout={onCreateCheckout}
            row={row}
            columns={columns}
            callback={callback}
          />
        </>
      )}
    </>
  )
}
