import { api } from './axios'

export default {
  getClientesReport: async ({ startDate, endDate, instanceId }) => {
    const response = await api
      .get('/mais-modas/client-report', {
        params: {
          startDate,
          endDate,
          instanceId
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  }
}
