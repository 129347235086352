import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import Services from '../../services/user'
import './styles.scss'
import { Loading } from '../Loading'
import { handleFileSelected } from '../../services/instanceFunctions'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { Instance } from 'types/global'

interface ModalConfirmProps {
  showModal: boolean
  onConfirm: () => Promise<void>
  onCancel: () => void
  title: string
  text: string
}

export const ModalConfirm = ({
  showModal,
  onConfirm,
  onCancel,
  title,
  text
}: ModalConfirmProps) => {
  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal containerModalConfirm"
      overlayClassName="customModalOverlay"
    >
      <Container>
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>{title}</h1>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col xl={12} md={12}>
              <FormGroup>
                <Form.Label for="exampleEmail">{text}</Form.Label>
              </FormGroup>
            </Col>
          </Row>
          <Row className="container-buttons">
            <Button onClick={onCancel} variant="secondary">
              Cancelar
            </Button>
            <Button onClick={onConfirm} variant="primary">
              Confirmar
            </Button>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
