/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { Loading } from '../../components/Loading'
import './styles.scss'
import { ChevronDown, ChevronUp, MoreVertical } from 'react-feather'
import 'chartist/dist/scss/chartist.scss'
import { registerLocale } from 'react-datepicker'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import DatatableTables from '../../components/Table'

import ptBR from 'date-fns/locale/pt-BR'
import { ModalMudarPlano } from '../../components/modalMudarPlano'
import { formatCurrency, onlyUnsignedNumbers } from '../../util/string'
import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row
} from 'react-bootstrap'
import plansSercice from 'services/plans'
import {
  Plan,
  CheckoutCreditCardProps,
  SubscriptionProps,
  SubscriptionBodyProps,
  CreditCardPaymentBodyProps
} from 'types/global'
import {
  isValidCnpj,
  isValidCpf,
  isValidCreditCardNumber,
  isValidEmail,
  isValidExpirationDate
} from 'util/function'
import externalServices from 'services/externalRequest'
import { ModalInstancia } from 'components/modalInstancia'
import { CheckoutAddress } from 'components/checkoutArea/address'
import { CheckoutPersonalInfo } from 'components/checkoutArea/personalInfo'
import { CheckoutPayment } from 'components/checkoutArea/payment'
import { SubscriptionCollapse } from './subscriptionsCollpse'

interface CheckoutUserProps {
  name: string
  email: string
  document: string
  phone: string
}

interface CheckoutUserProsWitherrorMessage extends CheckoutUserProps {
  errorMessage: CheckoutUserProps
}

interface CheckoutAddressProps {
  cep: string
  street: string
  number: string
  district: string
  city: string
  state: string
}

interface CheckoutAddressProsWitherrorMessage extends CheckoutAddressProps {
  errorMessage: CheckoutAddressProps
}

interface CheckoutCreditCardPropsWitherrorMessage
  extends CheckoutCreditCardProps {
  errorMessage: CheckoutCreditCardProps
}

interface CheckoutSuccessProps {
  image?: string
  action?: string
}

registerLocale('pt-BR', ptBR)
const obj = JSON.parse(localStorage.getItem('authUser'))

export const Subscriptions = () => {
  const [loading, setLoading] = useState(false)
  const [rendered, setRendered] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<Plan>(null)
  const [paymentMethod, setPaymentMethod] = useState<string>(null)
  const [paymentMethodError, setPaymentMethodError] = useState<string>('')
  const [hasPlan, setHasPlan] = useState(false)
  const [plans, setPlans] = useState<Plan[]>([])
  const [openCheckout, setOpenCheckout] = useState(false)
  const [checkoutErrorMessage, setCheckoutErrorMessage] = useState('')
  const [instanceQuantity, setInstanceQuantity] = useState(1)
  const [subscriptions, setSubscriptions] = useState<SubscriptionProps[]>([])
  const [showCheckoutArea, setShowCheckoutArea] = useState(true)
  const [showModalInstance, setShowModalInstance] = useState(false)
  const [isCustomSubscription, setIsCustomSubscription] = useState(false)
  const [showAddNewPaymentMethod, setShowAddNewPaymentMethod] = useState(false)

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<
    null | number
  >(null)

  const [selectedSubscription, setSelectedSubscription] =
    useState<null | SubscriptionProps>(null)

  const [checkoutSuccessFeedback, setCheckoutSuccessFeedback] =
    useState<CheckoutSuccessProps | null>(null)
  // useState<CheckoutSuccessProps | null>({
  //   image: null,
  //   copy: 'fkladjflakdfjalskdfjlsakdjf'
  // })
  const [checkoutUser, setCheckoutUser] =
    useState<CheckoutUserProsWitherrorMessage>({
      name: null,
      email: null,
      document: null,
      phone: null,
      errorMessage: {
        name: null,
        email: null,
        document: null,
        phone: null
      }
    })
  const [checkoutAddress, setCheckoutAddress] =
    useState<CheckoutAddressProsWitherrorMessage>({
      cep: null,
      street: null,
      number: null,
      district: null,
      city: null,
      state: null,
      errorMessage: {
        cep: null,
        street: null,
        number: null,
        district: null,
        city: null,
        state: null
      }
    })
  const [checkoutCreditCard, setCheckoutCreditCard] =
    useState<CheckoutCreditCardPropsWitherrorMessage>({
      number: null,
      holderName: null,
      holderDocument: null,
      expiration: null,
      cvv: null,
      errorMessage: {
        number: null,
        holderName: null,
        holderDocument: null,
        expiration: null,
        cvv: null
      }
    })

  const [checkoutStep, setCheckoutStep] = useState(1)

  const nextStep = () => {
    setCheckoutStep(checkoutStep + 1)
  }

  const previousStep = () => {
    setCheckoutStep(checkoutStep - 1)
  }

  const loadPlans = async () => {
    try {
      setLoading(true)
      const result = await plansSercice.getPlans()
      console.log(result.data.data)

      setLoading(false)
      if (result.status === 200) {
        if (result.data?.data?.hasPlan) {
          setHasPlan(true)
          setPlans([result.data.data?.plan])
          setSelectedPlan(result.data?.data?.plan)
        } else {
          setPlans(result.data.data)
          if (result.data.data.length === 1) {
            setSelectedPlan(result.data.data[0])
          }
        }
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const loadSubscriptions = async () => {
    try {
      setLoading(true)
      const result = await plansSercice.getSubscriptions()
      console.log(result.data.data)

      setLoading(false)
      if (result.status === 200) {
        setSubscriptions(result.data.data)
        if (result.data?.data?.length > 0) {
          setShowCheckoutArea(false)
        }
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const handleChangeInstanceQtd = async (type: 'minus' | 'plus') => {
    if (isCustomSubscription) return

    if (type === 'plus') {
      setInstanceQuantity(instanceQuantity + 1)
    } else {
      if (instanceQuantity > 1) {
        setInstanceQuantity(instanceQuantity - 1)
      }
    }
  }
  const handleChangeInputInstanceQtd = async (value) => {
    if (Number(value) >= 1) {
      setInstanceQuantity(Number(value))
    } else {
      setInstanceQuantity(1)
    }
  }

  const handleOpenCheckout = () => {
    console.log('here')

    setOpenCheckout(true)
  }

  const handleCheckCheckoutUser = () => {
    let newCheckoutUser = { ...checkoutUser }
    if (
      !checkoutUser.name ||
      !isValidEmail(checkoutUser.email) ||
      (!isValidCpf(checkoutUser.document) &&
        !isValidCnpj(checkoutUser.document)) ||
      checkoutUser.phone.length < 10
    ) {
      if (!checkoutUser.name) {
        newCheckoutUser.errorMessage.name = 'Este campo é obrigatório!'
      }
      if (!isValidEmail(checkoutUser.email)) {
        newCheckoutUser.errorMessage.email = 'Informe um email válido!'
      }
      if (
        !isValidCpf(checkoutUser.document) &&
        !isValidCnpj(checkoutUser.document)
      ) {
        newCheckoutUser.errorMessage.document = 'Informe um CPF/CPNJ válido!'
      }
      if (checkoutUser.phone?.length < 10) {
        newCheckoutUser.errorMessage.phone =
          'Informe um número de telefone válido!'
      }
      return setCheckoutUser(newCheckoutUser)
    }
    setCheckoutStep(checkoutStep + 1)
  }

  const handleCheckCheckoutAddress = () => {
    let newCheckoutAddress = { ...checkoutAddress }
    if (
      !checkoutAddress.cep ||
      !checkoutAddress.street ||
      !checkoutAddress.number ||
      !checkoutAddress.district ||
      !checkoutAddress.city ||
      !checkoutAddress.state
    ) {
      if (!checkoutAddress.cep) {
        newCheckoutAddress.errorMessage.cep = 'Este campo é obrigatório!'
      }
      if (!checkoutAddress.street) {
        newCheckoutAddress.errorMessage.street = 'Este campo é obrigatório!'
      }
      if (!checkoutAddress.number) {
        newCheckoutAddress.errorMessage.number = 'Este campo é obrigatório!'
      }
      if (!checkoutAddress.district) {
        newCheckoutAddress.errorMessage.district = 'Este campo é obrigatório!'
      }
      if (!checkoutAddress.city) {
        newCheckoutAddress.errorMessage.city = 'Este campo é obrigatório!'
      }
      if (!checkoutAddress.state) {
        newCheckoutAddress.errorMessage.state = 'Este campo é obrigatório!'
      }
      return setCheckoutAddress(newCheckoutAddress)
    }
    if (
      !checkoutCreditCard.holderDocument &&
      checkoutUser.document?.length === 11
    ) {
      setCheckoutCreditCard({
        ...checkoutCreditCard,
        holderDocument: checkoutUser.document
      })
    }
    setCheckoutStep(checkoutStep + 1)
  }

  function handleConfirmation(result) {
    console.log({ result })
  }

  function handleSuccessConfirmation() {
    loadSubscriptions()
  }

  function handleSuccessPaymentConfirmation() {
    loadSubscriptions()
    handleAddPaymentMethodBack()
  }

  function handleSelectOtherPlan() {
    setSelectedPlan(null)
  }

  const onCloseModalInstance = () => {
    setShowModalInstance(false)
    setSelectedSubscription(null)
  }

  const handleSubmitSubscription = async () => {
    try {
      if (!paymentMethod) {
        return setPaymentMethodError('Selecione a forma de pagamento!')
      }

      if (paymentMethod === 'cc') {
        let newCheckoutCreditCard = { ...checkoutCreditCard }

        if (
          !checkoutCreditCard.number ||
          !checkoutCreditCard.holderName ||
          !checkoutCreditCard.holderDocument ||
          !checkoutCreditCard.expiration ||
          !checkoutCreditCard.cvv
        ) {
          if (!isValidCreditCardNumber(checkoutCreditCard.number)) {
            newCheckoutCreditCard.errorMessage.number =
              'Este campo é obrigatório!'
          }
          if (
            !checkoutCreditCard.holderName ||
            checkoutCreditCard.holderName?.split(' ')?.length < 2
          ) {
            newCheckoutCreditCard.errorMessage.holderName =
              'Este campo é obrigatório!'
          }
          if (!isValidCpf(checkoutCreditCard.holderDocument)) {
            newCheckoutCreditCard.errorMessage.holderDocument =
              'Este campo é obrigatório!'
          }
          if (!checkoutCreditCard.cvv || checkoutCreditCard.cvv?.length <= 1) {
            newCheckoutCreditCard.errorMessage.cvv = 'Este campo é obrigatório!'
          }
          if (!isValidExpirationDate(checkoutCreditCard.expiration)) {
            newCheckoutCreditCard.errorMessage.expiration =
              'Este campo é obrigatório!'
          }

          return setCheckoutCreditCard(newCheckoutCreditCard)
        }
      }

      let body: SubscriptionBodyProps = {
        customSubscription: selectedSubscription,
        customer: {
          name: checkoutUser.name,
          email: checkoutUser.email,
          document: checkoutUser.document,
          phone: checkoutUser.phone
        },
        address: {
          cep: checkoutAddress.cep,
          street: checkoutAddress.street,
          number: checkoutAddress.number,
          district: checkoutAddress.district,
          city: checkoutAddress.city,
          state: checkoutAddress.state
        },
        payment: {
          paymentMethod,
          creditCard:
            paymentMethod === 'cc'
              ? {
                  number: checkoutCreditCard.number,
                  holderName: checkoutCreditCard.holderName,
                  holderDocument: checkoutCreditCard.holderDocument,
                  expiration: `${checkoutCreditCard.expiration[3]}${checkoutCreditCard.expiration[4]}${checkoutCreditCard.expiration[0]}${checkoutCreditCard.expiration[1]}`,
                  cvv: checkoutCreditCard.cvv
                }
              : null
        }
      }

      if (!isCustomSubscription) {
        body.plan = {
          id: selectedPlan.id,
          price: selectedPlan.price,
          customPlan: selectedPlan.customPlan,
          quantity: instanceQuantity
        }
      }

      console.log({ selectedPlan })
      console.log({ instanceQuantity })

      console.log({ body })
      setCheckoutErrorMessage('')
      setLoading(true)
      const response = await plansSercice.createSubscription(body)
      setLoading(false)

      console.log({ response })
      if (response.status === 200) {
        if (paymentMethod === 'boleto') {
          const data = response.data.data
          setCheckoutSuccessFeedback({
            action: data.pdf
          })
        } else if (paymentMethod === 'pix') {
          const data = response.data.data
          console.log({ data })

          setCheckoutSuccessFeedback({
            action: data.qrCode,
            image: data.qrCodeUrl
          })
          checkPayment(data.transactionId)
        } else if (paymentMethod === 'cc') {
          console.log('success')
          Swal.fire({
            icon: 'success',
            title: response.data.msg?.title || 'Compra realizada',
            html:
              response.data.msg?.text ||
              'Agora você já pode criar suas instâncias',
            confirmButtonText: 'Fechar',
            showCancelButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then(handleSuccessConfirmation)
        }
      } else if (response.response.status === 400) {
        const errorMsg = response.response.data.error
        console.log('400')
        setCheckoutErrorMessage(errorMsg?.title || 'Compra não autorizada!')
        Swal.fire({
          icon: 'error',
          title: errorMsg?.title || 'Não autorizado',
          html: errorMsg?.text || 'Revise seus dados e tente novamente',
          confirmButtonText: 'Fechar',
          showCancelButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(handleConfirmation)
      } else {
      }
    } catch (error) {
      setLoading(false)
      console.log({ error })
    }
  }

  const handleSubmitCreditCardPayment = async () => {
    try {
      let newCheckoutCreditCard = { ...checkoutCreditCard }

      if (
        !checkoutCreditCard.number ||
        !checkoutCreditCard.holderName ||
        !checkoutCreditCard.holderDocument ||
        !checkoutCreditCard.expiration ||
        !checkoutCreditCard.cvv
      ) {
        if (!isValidCreditCardNumber(checkoutCreditCard.number)) {
          newCheckoutCreditCard.errorMessage.number =
            'Este campo é obrigatório!'
        }
        if (
          !checkoutCreditCard.holderName ||
          checkoutCreditCard.holderName?.split(' ')?.length < 2
        ) {
          newCheckoutCreditCard.errorMessage.holderName =
            'Este campo é obrigatório!'
        }
        if (!isValidCpf(checkoutCreditCard.holderDocument)) {
          newCheckoutCreditCard.errorMessage.holderDocument =
            'Este campo é obrigatório!'
        }
        if (!checkoutCreditCard.cvv || checkoutCreditCard.cvv?.length <= 1) {
          newCheckoutCreditCard.errorMessage.cvv = 'Este campo é obrigatório!'
        }
        if (!isValidExpirationDate(checkoutCreditCard.expiration)) {
          newCheckoutCreditCard.errorMessage.expiration =
            'Este campo é obrigatório!'
        }

        return setCheckoutCreditCard(newCheckoutCreditCard)
      }

      let body: CreditCardPaymentBodyProps = {
        subscriptionId: selectedSubscription.id,
        payment: {
          creditCard: {
            number: checkoutCreditCard.number,
            holderName: checkoutCreditCard.holderName,
            holderDocument: checkoutCreditCard.holderDocument,
            expiration: `${checkoutCreditCard.expiration[3]}${checkoutCreditCard.expiration[4]}${checkoutCreditCard.expiration[0]}${checkoutCreditCard.expiration[1]}`,
            cvv: checkoutCreditCard.cvv
          }
        }
      }
      console.log({ body })
      setCheckoutErrorMessage('')
      setLoading(true)
      const response = await plansSercice.createCreditCardPayment(body)
      setLoading(false)

      console.log({ response })
      if (response.status === 200) {
        console.log('success')
        Swal.fire({
          icon: 'success',
          title: response.data.msg?.title || 'Pagamento realizado',
          html:
            response.data.msg?.text ||
            'Agora você já pode criar suas instâncias',
          confirmButtonText: 'Fechar',
          showCancelButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(handleSuccessPaymentConfirmation)
      } else if (response.response.status === 400) {
        const errorMsg = response.response.data.error
        console.log('400')
        setCheckoutErrorMessage(errorMsg?.title || 'Compra não autorizada!')
        Swal.fire({
          icon: 'error',
          title: errorMsg?.title || 'Não autorizado',
          html: errorMsg?.text || 'Revise seus dados e tente novamente',
          confirmButtonText: 'Fechar',
          showCancelButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(handleConfirmation)
      } else {
      }
    } catch (error) {
      setLoading(false)
      console.log({ error })
    }
  }

  const handleCopy = () => {
    console.log('ok')
    if (paymentMethod === 'pix') {
      try {
        navigator.clipboard.writeText(checkoutSuccessFeedback.action)
        return toast.success('O código pix foi copiado')
      } catch (error) {
        return toast.error('Não foi possível copiar o código')
      }
    }
  }

  const handleChangeCheckoutUser = ({ field, value }) => {
    console.log('here')
    console.log({ field, value })

    setCheckoutUser({
      ...checkoutUser,
      errorMessage: {
        ...checkoutUser.errorMessage,
        [field]: null
      },
      [field]: value
    })
  }

  const handleChangeCheckoutAddress = async ({ field, value }) => {
    try {
      console.log('here')
      console.log({ field, value })

      setCheckoutAddress({
        ...checkoutAddress,
        errorMessage: {
          ...checkoutAddress.errorMessage,
          [field]: null
        },
        [field]: value
      })

      if (field === 'cep' && value?.length === 8) {
        const result = await externalServices.getCep(value)
        console.log({ result })
        if (result.data && !result?.data?.erro) {
          setCheckoutAddress({
            ...checkoutAddress,
            cep: onlyUnsignedNumbers(result.data.cep),
            street: result.data.logradouro,
            district: result.data.bairro,
            city: result.data.localidade,
            state: result.data.uf
          })
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }

  const handleChangeCheckoutCreditCard = async ({ field, value }) => {
    try {
      console.log('here')
      console.log({ field, value })

      setCheckoutCreditCard({
        ...checkoutCreditCard,
        errorMessage: {
          ...checkoutCreditCard.errorMessage,
          [field]: null
        },
        [field]: value
      })
    } catch (error) {
      console.log({ error })
    }
  }

  const handleChangePaymentMethod = (value: string) => {
    console.log(value)
    setPaymentMethod(value)
    if (value) {
      setPaymentMethodError('')
    }
  }

  async function checkPayment(transactionId: number) {
    try {
      const controlInterval = setInterval(async () => {
        const response = await plansSercice.getPaymentStatus(transactionId)
        if (response.status === 200 && response?.data?.data?.status) {
          setShowCheckoutArea(false)
          loadSubscriptions()
          clearInterval(controlInterval)
        }
      }, 5000)
    } catch (error) {
      console.log(error)
    }
  }

  const handleAddPaymentMethodToCustomSubscription = (
    subscription: SubscriptionProps
  ) => {
    setIsCustomSubscription(true)
    setShowCheckoutArea(true)
    handleOpenCheckout()
    setSelectedSubscription(subscription)
    setInstanceQuantity(subscription.quantity)
  }

  const handleAddPaymentMethod = async (
    newPaymentMethod: string,
    subscription: SubscriptionProps
  ) => {
    try {
      console.log({ newPaymentMethod })
      console.log({ subscription })
      setSelectedSubscription(subscription)
      setShowAddNewPaymentMethod(true)
      setPaymentMethod(newPaymentMethod)
      if (newPaymentMethod !== 'cc') {
        setLoading(true)
        const response = await plansSercice.getSubscriptionInfo(
          subscription.id,
          newPaymentMethod
        )
        console.log({ response })

        if (response.status === 200) {
          const data = response?.data?.data
          console.log({ data })

          if (newPaymentMethod === 'boleto' && data?.pdf) {
            setCheckoutSuccessFeedback({
              action: data.pdf
            })
          } else if (newPaymentMethod === 'pix' && data?.qrCode) {
            setCheckoutSuccessFeedback({
              action: data.qrCode,
              image: data.qrCodeUrl
            })

            checkPayment(data.transactionId)
          }
        } else {
          Swal.fire({
            icon: 'warning',
            title:
              response?.response?.data?.error?.title ||
              'Não foi possível buscar as informações',
            html:
              response?.response?.data?.error?.text ||
              'As informações para a forma de pagamento escolhida não estão disponíveis',
            confirmButtonText: 'Fechar',
            showCancelButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false
          }).then(handleAddPaymentMethodBack)
        }
        setLoading(false)
        console.log({ response })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const handleAddPaymentMethodBack = async () => {
    try {
      setShowAddNewPaymentMethod(false)
      setPaymentMethod(null)
      setSelectedSubscription(null)
      setLoading(false)
      setCheckoutSuccessFeedback(null)
      setCheckoutStep(1)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!rendered) {
      return setRendered(true)
    }
    loadSubscriptions()
    loadPlans()
  }, [rendered])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} />}

      <div className="page-content containerPlanos">
        <MetaTags>
          <title>Assinaturas | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col
                md={12}
                className={
                  !showCheckoutArea
                    ? 'd-flex justify-content-end'
                    : 'd-flex justify-content-between'
                }
              >
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    {showCheckoutArea && !hasPlan
                      ? 'Veja todos os nossos planos e escolha o que mais se encaixa com você.'
                      : null}
                  </li>
                </ol>
                {!showCheckoutArea && !showAddNewPaymentMethod ? (
                  <Button
                    variant="success"
                    onClick={() => setShowCheckoutArea(true)}
                  >
                    + Criar nova assinatura
                  </Button>
                ) : subscriptions.length > 0 ? (
                  <Button
                    variant="success"
                    onClick={() => {
                      setShowCheckoutArea(false)
                      setIsCustomSubscription(false)
                      setOpenCheckout(false)
                      setSelectedSubscription(null)
                      setShowAddNewPaymentMethod(false)
                      setInstanceQuantity(1)
                      setCheckoutSuccessFeedback(null)
                      setCheckoutStep(1)
                    }}
                  >
                    <i className="mdi mdi-arrow-left-bold"></i> Voltar para
                    minhas assinaturas
                  </Button>
                ) : null}
              </Col>
            </Row>
          </div>
          {showCheckoutArea ? (
            <Card>
              <Card.Body>
                <Row
                  className={
                    hasPlan || checkoutSuccessFeedback
                      ? 'justify-content-center'
                      : ''
                  }
                >
                  {!hasPlan && !checkoutSuccessFeedback ? (
                    <Col md={selectedPlan ? '6' : '12'}>
                      <Row className="plans-container">
                        {plans?.length
                          ? plans.map((item, index) => {
                              if (selectedPlan && selectedPlan.id !== item.id) {
                                return null
                              } else {
                                return (
                                  <Col
                                    xs={12}
                                    sm={selectedPlan ? 6 : 4}
                                    className={`plan-card ${
                                      selectedPlan?.id === item.id
                                        ? 'selected'
                                        : ''
                                    } ${item.favorite ? 'favorite' : ''}`}
                                  >
                                    {item.favorite ? (
                                      <div className="plan-tag">
                                        <span>Indicado</span>
                                      </div>
                                    ) : null}
                                    <h3 className="plan-title">{item.name}</h3>
                                    <div className="plan-price">
                                      {formatCurrency({ value: item.price })}
                                      /mês
                                    </div>
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item.description
                                      }}
                                    />
                                    <a
                                      onClick={() => {
                                        setSelectedPlan(item)
                                      }}
                                      className="plan-button"
                                    >
                                      Selecionar
                                    </a>
                                  </Col>
                                )
                              }
                            })
                          : null}
                      </Row>
                      {selectedPlan && plans.length > 1 ? (
                        <Row>
                          <div
                            className="select-another-plan"
                            onClick={handleSelectOtherPlan}
                          >
                            <i className="mdi mdi-arrow-left-bold"></i>{' '}
                            Selecionar outro plano
                          </div>
                        </Row>
                      ) : null}
                    </Col>
                  ) : null}
                  {selectedPlan ? (
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={12}>
                          <Card>
                            <Card.Body>
                              <div className="container-instance-number">
                                <div className="container-header-text">
                                  <i
                                    className="mdi mdi-cellphone"
                                    style={{ fontSize: 40 }}
                                  ></i>
                                  <h5>Quantidade de instâncias</h5>
                                </div>
                                <InputGroup className="mb-3 input-group-instance-qtd">
                                  <InputGroup.Text
                                    onClick={() =>
                                      handleChangeInstanceQtd('minus')
                                    }
                                  >
                                    -
                                  </InputGroup.Text>
                                  <Form.Control
                                    className="input-instance-qtd"
                                    aria-label="Quantidade de instâncias"
                                    value={instanceQuantity}
                                    onChange={(event) =>
                                      handleChangeInputInstanceQtd(
                                        event.target.value
                                      )
                                    }
                                  />
                                  <InputGroup.Text
                                    onClick={() =>
                                      handleChangeInstanceQtd('plus')
                                    }
                                  >
                                    +
                                  </InputGroup.Text>
                                </InputGroup>
                              </div>
                              <div className="container-footer">
                                <h4>
                                  Valor Total:{' '}
                                  {isCustomSubscription
                                    ? formatCurrency({
                                        value: selectedSubscription.price
                                      })
                                    : formatCurrency({
                                        value:
                                          selectedPlan.price * instanceQuantity
                                      })}
                                </h4>
                                {!isCustomSubscription && (
                                  <Button
                                    variant="success"
                                    className="button-subscribe"
                                    onClick={handleOpenCheckout}
                                  >
                                    Assinar
                                  </Button>
                                )}
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                      {openCheckout ? (
                        <Row className="container-checkout">
                          <Col xs={12}>
                            <Card>
                              <Card.Body>
                                {checkoutStep === 1 ? (
                                  <CheckoutPersonalInfo
                                    checkoutUser={checkoutUser}
                                    handleChangeCheckoutUser={
                                      handleChangeCheckoutUser
                                    }
                                    handleCheckCheckoutUser={
                                      handleCheckCheckoutUser
                                    }
                                  />
                                ) : null}
                                {checkoutStep === 2 ? (
                                  <CheckoutAddress
                                    checkoutAddress={checkoutAddress}
                                    handleChangeCheckoutAddress={
                                      handleChangeCheckoutAddress
                                    }
                                    handleCheckCheckoutAddress={
                                      handleCheckCheckoutAddress
                                    }
                                    previousStep={previousStep}
                                  />
                                ) : null}
                                {checkoutStep === 3 ? (
                                  <CheckoutPayment
                                    checkoutCreditCard={checkoutCreditCard}
                                    checkoutErrorMessage={checkoutErrorMessage}
                                    checkoutSuccessFeedback={
                                      checkoutSuccessFeedback
                                    }
                                    handleChangeCheckoutCreditCard={
                                      handleChangeCheckoutCreditCard
                                    }
                                    handleChangePaymentMethod={
                                      handleChangePaymentMethod
                                    }
                                    handleCopy={handleCopy}
                                    handleSubmitSubscription={
                                      handleSubmitSubscription
                                    }
                                    loadSubscriptions={loadSubscriptions}
                                    paymentMethod={paymentMethod}
                                    paymentMethodError={paymentMethodError}
                                    previousStep={previousStep}
                                    selectedPlan={selectedPlan}
                                    setShowCheckoutArea={setShowCheckoutArea}
                                  />
                                ) : null}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  ) : null}
                </Row>
                {!isCustomSubscription && (
                  <Row>
                    <p style={{ marginTop: 40 }}>
                      * Todos os valores são por instância
                    </p>
                  </Row>
                )}
              </Card.Body>
            </Card>
          ) : null}

          {!showAddNewPaymentMethod &&
          !showCheckoutArea &&
          subscriptions.length > 0 ? (
            <Card>
              <Card.Header>Suas assinaturas</Card.Header>
              {subscriptions.map((item, index) => {
                return (
                  <Card.Body className="card-subscription" key={index}>
                    <Row>
                      <Col xs={6} sm={3}>
                        {selectedSubscriptionId === item.id ? (
                          <ChevronUp
                            className="card-subscription-chevron"
                            onClick={() => setSelectedSubscriptionId(null)}
                          />
                        ) : (
                          <ChevronDown
                            className="card-subscription-chevron"
                            onClick={() => setSelectedSubscriptionId(item.id)}
                          />
                        )}
                        Instâncias criadas:{' '}
                        {item.instances ? item.instances.length : 0} de{' '}
                        {item.quantity}
                      </Col>
                      <Col xs={6} sm={3}>
                        Valor Total: {formatCurrency({ value: item.price })}
                      </Col>
                      <Col xs={6} sm={2}>
                        Plano {item.name}
                      </Col>
                      <Col xs={6} sm={2}>
                        <Badge
                          bg={item.status === 'Pago' ? 'success' : 'secondary'}
                        >
                          {item.status}
                        </Badge>
                      </Col>
                      <Col xs={6} sm={2}>
                        {item.addPaymentMethod ? (
                          <Button
                            variant="primary"
                            onClick={() => {
                              handleAddPaymentMethodToCustomSubscription(item)
                            }}
                          >
                            Completar inscrição
                          </Button>
                        ) : item.statusId !== 2 ? (
                          <DropdownButton
                            variant="secondary"
                            title="Opções de pagamento"
                          >
                            <Dropdown.Item
                              eventKey="1"
                              onClick={() =>
                                handleAddPaymentMethod('pix', item)
                              }
                            >
                              Pagar com pix
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="2"
                              onClick={() =>
                                handleAddPaymentMethod('boleto', item)
                              }
                            >
                              Pagar com boleto
                            </Dropdown.Item>
                            <Dropdown.Item
                              eventKey="3"
                              onClick={() => handleAddPaymentMethod('cc', item)}
                            >
                              Pagar com cartão de crédito
                            </Dropdown.Item>
                          </DropdownButton>
                        ) : (
                          <Button
                            variant="success"
                            disabled={
                              item.instances?.length >= item.quantity ||
                              item.statusId !== 2
                            }
                            onClick={() => {
                              setShowModalInstance(true)
                              setSelectedSubscription(item)
                            }}
                          >
                            + Criar instância nesse plano
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Collapse
                        in={item.id === selectedSubscriptionId}
                        unmountOnExit
                      >
                        <SubscriptionCollapse subscription={item} />
                      </Collapse>
                    </Row>
                  </Card.Body>
                )
              })}
            </Card>
          ) : null}

          {showAddNewPaymentMethod && (
            <Row className="container-checkout justify-content-center">
              <Row className="justify-content-center">
                <Col xs={12} md={6}>
                  <Card>
                    <Card.Body>
                      <div className="container-instance-number">
                        <div className="container-header-text">
                          <i
                            className="mdi mdi-cellphone"
                            style={{ fontSize: 40 }}
                          ></i>
                          <h5>Quantidade de instâncias</h5>
                        </div>
                        <InputGroup className="mb-3 input-group-instance-qtd">
                          <InputGroup.Text>-</InputGroup.Text>
                          <Form.Control
                            className="input-instance-qtd"
                            aria-label="Quantidade de instâncias"
                            value={instanceQuantity}
                            onChange={(event) =>
                              handleChangeInputInstanceQtd(event.target.value)
                            }
                          />
                          <InputGroup.Text>+</InputGroup.Text>
                        </InputGroup>
                      </div>
                      <div className="container-footer">
                        <h4>
                          Valor Total:{' '}
                          {isCustomSubscription
                            ? formatCurrency({
                                value: selectedSubscription.price
                              })
                            : formatCurrency({
                                value: selectedSubscription?.price
                              })}
                        </h4>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Col xs={12} md={6}>
                <Card>
                  <Card.Body>
                    <CheckoutPayment
                      checkoutCreditCard={checkoutCreditCard}
                      checkoutErrorMessage={checkoutErrorMessage}
                      checkoutSuccessFeedback={checkoutSuccessFeedback}
                      handleChangeCheckoutCreditCard={
                        handleChangeCheckoutCreditCard
                      }
                      handleChangePaymentMethod={handleChangePaymentMethod}
                      handleCopy={handleCopy}
                      handleSubmitSubscription={
                        paymentMethod === 'cc'
                          ? handleSubmitCreditCardPayment
                          : handleAddPaymentMethodBack
                      }
                      loadSubscriptions={loadSubscriptions}
                      paymentMethod={paymentMethod}
                      paymentMethodError={paymentMethodError}
                      previousStep={handleAddPaymentMethodBack}
                      selectedPlan={selectedPlan}
                      setShowCheckoutArea={setShowAddNewPaymentMethod}
                      addPaymentMethod={true}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>

      <ModalInstancia
        onClose={onCloseModalInstance}
        callback={loadSubscriptions}
        showModal={showModalInstance}
        subscription={selectedSubscription}
      />
    </React.Fragment>
  )
}
