import { api } from './axios'

const Services = {
    getIntegration: async () => {
        const response = await api
            .get('/integration')
            .then(({ ...response }) => response)
            .catch(({ ...response }) => response)
        return response
    },
}
export default Services
