import React from 'react'
import { Form } from 'react-bootstrap'
import { MessageVariableButtons } from '../MessageVariableButtons'
//@ts-ignore
import styles from './styles.module.scss'

export const TextArea = ({ 
  onAddVariable,
  value,
  setValue,
  title = "Mensagem",
  legend = true
}) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label style={{marginTop:20}}>{title ? title : 'Mensagem'}</Form.Label>
      <MessageVariableButtons onAddVariable={onAddVariable} />
      <Form.Control
        id="message-text-area"
        as="textarea"
        rows={10}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    {
      legend ? (
      <>
      <Form.Text className={styles.formText}>
        {`Você pode usar variáveis para personalizar a mensagem. Exemplo: Olá {Nome do participante}, tudo bem?.`}
      </Form.Text>
      <Form.Text className={styles.formText}>
        Para colocar em negrito, coloque o texto entre asteriscos. Exemplo: *
        <b>Olá</b>*, tudo bem?.
      </Form.Text>
      <Form.Text className={styles.formText}>
        Para colocar em itálico, coloque o texto entre underline. Exemplo: _
        <i>Olá</i>_, tudo bem?.
      </Form.Text>
      <Form.Text className={styles.formText}>
        Para colocar em negrito e itálico, coloque o texto entre asteriscos e
        underline. Exemplo: *_
        <b>
          <i>Olá</i>
        </b>
        _*, tudo bem?.
      </Form.Text>
      <Form.Text className={styles.formText}>
        Para deixar riscado, coloque o texto entre til. Exemplo: ~<s>Olá</s>~,
        tudo bem?.
      </Form.Text>
      <Form.Text className={styles.formText}>
        <b style={{ color: '#dc3545' }}>ATENÇÃO:</b> Não utilize *, _ ou ~ após um espaço. Por exemplo: "*Olá
        cliente *"
      </Form.Text>
      </>
      ) : (<></>)}
    </Form.Group>
  )
}
