import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import './styles.scss'
import { Col, Container } from 'reactstrap'
import { Button, Card, Form } from 'react-bootstrap'
//@ts-ignore
import styles from './styles.module.scss'
import Services from 'services/user'
import { toast } from 'react-toastify'
//@ts-ignore
import RefreshIcon from '../../assets/images/refresh-icon.png'
//@ts-ignore
import QrCodeBackground from '../../assets/images/qr-code-background.png'
import { RefreshCw, AlertCircle } from 'react-feather'
import { useInstance } from 'hooks/useInstance'

interface ModalQrcodeProps {
  showModal: boolean
  onClose: () => void
  onSuccess: () => void
  instanceId: null | number
  instanceName: null | string
  statusInstance: number
  instanceChecked: boolean
}

export const ModalQrcode = ({
  showModal,
  onClose,
  instanceId,
  instanceName,
  statusInstance,
  onSuccess,
  instanceChecked
}: ModalQrcodeProps) => {
  const { confirmRefreshInstance } = useInstance()

  const countQrCode = useRef(0)
  const controlInterval = useRef(null)
  const controlIntervalStatus = useRef(null)

  //   const [loading, setLoading] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoadingQrcode, setIsLoadingQrcode] = useState(true)
  const [qrcodeUrl, setQrcodeUrl] = useState(null)
  const [showRefreshButton, setShowRefreshButton] = useState(false)

  const loadInstance = useCallback(async () => {
    console.log({ countQrCode, isAuthenticated })
    if (countQrCode.current >= 1 || isAuthenticated) {
      clearInterval(controlInterval.current)
      clearInterval(controlIntervalStatus.current)
      countQrCode.current = 0
      setIsLoadingQrcode(false)
      setShowRefreshButton(true)
      return
    }
    console.log('sldk')
    countQrCode.current = countQrCode.current + 1
    // return;
    const data = JSON.parse(localStorage.getItem('@zapmais-qrcode'))
    console.log({ data })
    console.log(new Date(data?.expiration))
    if (data && data?.instanceId && data?.expiration > new Date().getTime()) {
      // O objeto ainda está válido
      const qrTemp = data.qr
      setQrcodeUrl(qrTemp)
      setShowRefreshButton(false)
      setIsLoadingQrcode(false)
      setIsAuthenticated(false)
      return
      // Faça algo com os dados
    } else {
      // O objeto expirou ou não existe
      localStorage.removeItem('@zapmais-qrcode')
    }
    const response = await Services.getQrCodeZapmais({ instanceId })
    console.log(response)
    if (response.status === 200) {
      if (response.data.connected) {
        setIsLoadingQrcode(false)
        setIsAuthenticated(true)
        clearInterval(controlInterval.current)
        countQrCode.current = 0
        localStorage.removeItem('@zapmais-qrcode')
        onSuccess()
      } else {
        const qr = response?.data?.qr

        const expiration = new Date().getTime() + 20000 // 60 segundos depois da hora atual

        const data = {
          instanceId,
          qr,
          expiration
        }

        localStorage.setItem('@zapmais-qrcode', JSON.stringify(data))
        setQrcodeUrl(response?.data?.qr)
        setShowRefreshButton(false)
        setIsLoadingQrcode(false)
        setIsAuthenticated(false)
      }
      console.log(response?.data)
      return true
    } else {
      toast.error(
        response?.msg || 'Não foi possível carregar os dados da instância'
      )
      return false
    }
  }, [instanceId, countQrCode, isAuthenticated])

  function handleClickRefresh() {
    setIsLoadingQrcode(true)
    setShowRefreshButton(false)
    loadInstance()
    controlInterval.current = setInterval(() => {
      loadInstance()
    }, 60000)
  }

  const checkInstanceStatus = useCallback(
    async (load = false) => {
      const response = await Services.getStatusInstance({ instanceId })
      console.log(response)
      if (response.status === 200) {
        if (response.data.connected) {
          setIsLoadingQrcode(false)
          setIsAuthenticated(true)
          clearInterval(controlInterval.current)
          clearInterval(controlIntervalStatus.current)
          countQrCode.current = 0
          onSuccess()

          localStorage.removeItem('@zapmais-qrcode')
        } else {
          if (load) {
            loadInstance()
          }
        }
        console.log(response?.data)
        return true
      } else {
        toast.error(
          response?.msg || 'Não foi possível carregar os dados da instância'
        )
        return false
      }
    },
    [instanceId, countQrCode, isAuthenticated]
  )

  useEffect(() => {
    if (instanceId && showModal) {
      checkInstanceStatus(true)
      // getMessageQueue()
      controlInterval.current = setInterval(() => {
        console.log('aqui')
        loadInstance()
      }, 60000)
      controlIntervalStatus.current = setInterval(() => {
        console.log('aqui')
        checkInstanceStatus()
      }, 10000)
    }
    return () => {
      console.log('limpar')

      clearInterval(controlInterval.current)
      clearInterval(controlIntervalStatus.current)
      setIsAuthenticated(false)
      setIsLoadingQrcode(true)
      setQrcodeUrl(null)
      setShowRefreshButton(false)
    }
  }, [instanceId, showModal, loadInstance])

  console.log({ showModal, instanceId, instanceChecked, statusInstance })

  if (!showModal || !instanceId || !instanceChecked || statusInstance) {
    return null
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="modal-zap-mais"
      overlayClassName="modal-zap-mais-overlay"
    >
      <Container>
        <Card.Body>
          {/* <Col xs={12} md={4}> */}
          <div className={styles.textHeaderContainer}>
            <h4 className="page-title">
              {isAuthenticated ? 'Conectado' : 'Leia o QRCode'}
            </h4>
            <h6
              className="page-title"
              dangerouslySetInnerHTML={{
                __html: isAuthenticated
                  ? 'Sua instância está conectada, você já pode enviar mensagens'
                  : `Abra o aplicativo do whatsapp e leia o QRCode abaixo para se conectar a instância <b>${instanceName}</b>`
              }}
            />
          </div>
          <div className={styles.containerQrcode}>
            {isLoadingQrcode ? (
              <div className={styles.loadingQrcodeContainer}>
                <div className={`spinner-border`} role="status"></div>
                <div className={styles.loadingText}>Carregando Qr code</div>
              </div>
            ) : showRefreshButton ? (
              <div
                className={styles.containerRefreshQrcode}
                onClick={handleClickRefresh}
              >
                <div
                  style={{ color: '#00ac5a' }}
                  className={styles.containerImgRefreshQrcode}
                >
                  <img
                    src={RefreshIcon}
                    alt="Ícone de refresh"
                    className={styles.iconRefresh}
                  />
                  <img
                    src={QrCodeBackground}
                    alt="qr code background"
                    className={styles.qrCodeBackground}
                  />
                </div>
                Clique para recarregar o qr code
              </div>
            ) : isAuthenticated ? (
              <div
                style={{ color: '#00ac5a' }}
                className={styles.constainerIsAuthenticated}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  viewBox="0 0 512 512"
                  height="40"
                  width="40"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                </svg>
              </div>
            ) : (
              <img src={qrcodeUrl} alt="Imagem qr code" />
            )}
          </div>
          {/* </Col> */}
        </Card.Body>
        <div className={styles.helperTextContainer}>
          <small>
            <AlertCircle size={18} />
            Caso seu qr code nao seja gerado, clique em{' '}
            <b>Atualizar instância</b>!
          </small>
        </div>
        <Card.Footer className="modal-zap-mais-footer" style={{ gap: 8 }}>
          <Button
            variant="danger"
            onClick={() => confirmRefreshInstance(instanceId)}
          >
            Atualizar instância <RefreshCw />
          </Button>
          {statusInstance ? (
            <Button onClick={onClose} color="success">
              Fechar
            </Button>
          ) : (
            <Button onClick={onClose}>Escolher outra instância</Button>
          )}
        </Card.Footer>
      </Container>
    </ReactModal>
  )
}
