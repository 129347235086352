import React, { useEffect, useState } from 'react'
import {
  Badge,
  Col,
  Nav,
  Row,
  Tab,
  TabContent,
  TabPane,
  Tabs
} from 'react-bootstrap'
import DatatableTables from '../../components/Table'
import { SubscriptionProps, TransactionResumeProps } from 'types/global'
import { formatCurrency } from 'util/string'
import SwitchWithLabel from 'components/Switch'
import plans from 'services/plans'
import Swal from 'sweetalert2'
import { exportFile } from 'util/function'
import { Loading } from 'components/Loading'

const columns = [
  { label: 'Identificador', field: 'id' },
  { label: 'Descrição', field: 'name' },
  { label: 'Data de criação', field: 'createdAt' }
]

const columnsPayments = [
  { label: 'Identificador', field: 'id' },
  { label: 'Competência', field: 'competence' },
  { label: 'Status', field: 'statusName', html: true },
  { label: 'Valor Total', field: 'amount' },
  { label: 'Valor Pago', field: 'paymentAmount' }
]

interface SubscriptionCollapseProps {
  subscription: SubscriptionProps
}

const HeaderTableTitle = ({ title, paid, setPaid }) => {
  return (
    <div className="custom-header">
      <div className="custom-title">{title}</div>
      <div className="custom-check">
        <SwitchWithLabel
          onChange={() => {
            setPaid(!paid)
          }}
          checked={paid}
          label="Pagos"
        />
      </div>
    </div>
  )
}

export function SubscriptionCollapse({
  subscription
}: SubscriptionCollapseProps) {
  const [paymentRows, setPaymentRows] = useState([])
  const [paidFilter, setPaidFilter] = useState(false)
  const [loading, setLoading] = useState(false)

  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      more: false,
      pagination: true
    },
    config: {
      downloadText: 'Download do boleto'
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  function handleSuccessPaymentConfirmation(baseUrl: string) {
    exportFile({
      base64: baseUrl,
      extension: '.pdf',
      fileName: 'boleto_zapmais'
    })
  }
  const handleAddPaymentMethodBack = () => {}

  async function handleDownload(row: TransactionResumeProps) {
    try {
      console.log({ row })
      setLoading(true)
      const response = await plans.getSubscriptionInfo(
        subscription.localId,
        'boleto'
      )
      setLoading(false)

      if (response.status === 200) {
        const data = response?.data?.data
        console.log({ data })

        Swal.fire({
          icon: 'success',
          title: response.data.msg?.title || 'Boleto disponível',
          html:
            response.data.msg?.text ||
            '<b>O cliente já consegue fazer isso.</b><br /><br /> Da próxima vez diz pra ele baixar na página de assinaturas',
          confirmButtonText: 'Baixar arquivo',
          showCancelButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(() => handleSuccessPaymentConfirmation(response.data.data.pdf))
      } else {
        Swal.fire({
          icon: 'warning',
          title:
            response?.response?.data?.error?.title ||
            'Não foi possível buscar as informações',
          html:
            response?.response?.data?.error?.text ||
            'As informações para a forma de pagamento escolhida não estão disponíveis',
          confirmButtonText: 'Fechar',
          showCancelButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false
        }).then(handleAddPaymentMethodBack)
      }
    } catch (error) {
      console.log({ error })

      setLoading(false)
    }
  }

  useEffect(() => {
    if (subscription?.payments?.length > 0) {
      let payments = subscription.payments
      if (paidFilter) {
        payments = payments.filter((item) => item.status === 'Pago')
      }
      console.log({ payments })

      const newPaymentsRows = payments.map((item, index) => ({
        ...item,
        amount: formatCurrency({ value: item.amount }),
        paymentAmount: formatCurrency({ value: item.paymentAmount }),
        statusName:
          item.status === 'Pago'
            ? `<span class="badge bg-success">${item.status}</span>`
            : `<span class="badge bg-secondary">${item.status}</span>`,
        hideMoreOptions: index < payments.length - 1 || item.status === 'Pago'
      }))
      console.log({ newPaymentsRows })

      setPaymentRows(newPaymentsRows)
    }
  }, [subscription, paidFilter])

  return (
    <Row className="collapse-subscription">
      <Loading open={loading} />
      <Col>
        <Tabs
          defaultActiveKey="instances"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="instances" title="Instâncias">
            <DatatableTables
              title={'Instâncias nessa assinatura'}
              columns={columns}
              rows={subscription.instances || []}
              tableConfig={tableConfig}
              setTableConfig={setTableConfig}
              emptyText="Nenhuma instância criada até o momento"
              layoutCollapse={true}
            />
          </Tab>
          <Tab eventKey="payments" title="Pagamentos">
            <DatatableTables
              title={
                <HeaderTableTitle
                  title="Cobranças nesta assinatura"
                  paid={paidFilter}
                  setPaid={setPaidFilter}
                />
              }
              columns={columnsPayments}
              rows={paymentRows}
              tableConfig={{
                ...tableConfig,
                options: {
                  more: true,
                  download: true
                }
              }}
              setTableConfig={setTableConfig}
              emptyText="Nenhum cobrança criada até o momento"
              layoutCollapse={true}
              handleDownload={handleDownload}
            />
          </Tab>
        </Tabs>
      </Col>
    </Row>
  )
}
