import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'
import { NumericFormat } from 'react-number-format'

const CustomTextField = (props) => {
  return (
    <Input
      {...props}
      fullWidth
      variant="outlined"
      InputProps={{ style: { backgroundColor: '#fff' } }}
    />
  )
}

export const CurrencyInput = ({
  variant = 'outlined',
  margin = 'normal',
  value,
  label,
  handleChange,
  error = null,
  helperText = null,
  className = ''
}) => {
  return (
    <FormGroup>
      <Label>{label}</Label>
      <NumericFormat
        className={className}
        value={value}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale={true}
        customInput={CustomTextField}
        margin={margin}
        variant={variant}
        onValueChange={(values) =>
          handleChange(values?.floatValue ? values.floatValue : null)
        }
        error={error}
        helperText={helperText}
      />
    </FormGroup>
  )
}
