import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col, FormGroup, Button } from 'reactstrap'

import { Loading } from '../../components/Loading'
import { toast } from 'react-toastify'
import DatatableTables from '../../components/Table'

import 'chartist/dist/scss/chartist.scss'
import Select from 'react-select'
import styles from './styles.module.scss'
import { CardIndicador } from '../../components/CardIndicador'
import Services from '../../services/sorte'
import MonthYearSelect from '../../components/SelectMes'
import { SelectComponent } from 'components/selectComponent'
import { useUserInfo } from 'context/userContext'

import { format, startOfMonth } from 'date-fns';
import { formatCurrency, formatDateSimples } from '../../util/string'
import { ModalPix } from '../../components/modalPix'
import './style.css'

const columns = [
  { label: 'Data', field: 'data' },
  { label: 'Total', field: 'total' },
  { label: 'Whatsapp', field: 'zap' },
  { label: 'Sms', field: 'sms' },
  { label: 'Número', field: 'telefones' },
  { label: 'Media por Número', field: 'media' }
]

const Sorte = () => {
  const { isRoot, userId, userName } = useUserInfo()

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState([])
  const [rows, setRows] = useState([])
  const [month, setMonth] = useState(format(startOfMonth(new Date()), 'yyyy-MM'))
  const [idSelecionado, setIdSelecionado] = useState(false)
  const [userSmsList, setUserSmsList] = useState([])

  const [tableConfig, setTableConfig] = useState({
    options: {
      order: true,
      pagination: true,
    },
    total: 0,
    currentPage: 0,
    userId: null,
    rowsPerPage: 20
  })

  const loadIndicators = async () => {
    setLoading(true)

    const response = await Services.loadIndicators({
      rowsPerPage: tableConfig.rowsPerPage,
      page: tableConfig.currentPage,
      idSelecionado
    })

    if (response.status === 200) {
        const formatData = response?.data?.data?.message_per_day?.map((item) => {
        return {
          ...item,
          data: formatDateSimples(item?.data),
          media: Number(item?.media)?.toFixed(2)
        }
      })
       setRows(formatData)
       setData(response?.data?.data?.dados)
       setLoading(false)
    } else {
      toast.error(response?.msg)
      setLoading(false)
      return false
    }
  }

  const handleClose = () => {
    setLoading(false)
  }

  const onCangeDate = (value) => {
    setMonth(value)
  }

  const makePayment = async () => {
    setShowModal(true)
  }

  const onClose = async () => {
    setShowModal(false)
  }

  const getUsersSms = async () => {
      const response = await Services.loadUsersSms()
      if (response.status === 200) {
        setUserSmsList(response?.data?.data)
      } else {
        toast.error(response?.msg)
      }
  }

  useEffect(() => {
    if(idSelecionado) {
      loadIndicators()
    }
  }, [tableConfig.currentPage, month, idSelecionado])

  useEffect(() => {
    getUsersSms()
  }, [])

  return (
    <React.Fragment>
      <ModalPix showModal={showModal} onClose={onClose} idSelecionado={idSelecionado} />
      {loading && <Loading open={loading} handleClose={handleClose} />}
      <div className="page-content">
        <MetaTags>
          <title>Painel SMS</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Painel SMS</li>
                </ol>
              </Col>
            </Row>
          </div>

          <Row>
          <CardIndicador
            icon={'mdi mdi-cellphone'} value={ data?.total || 0}
            type={'number'} text={'Enviadas Total'} color="primary"
            xl={3} md={3} xs={6}
          />
          <CardIndicador
            icon={'mdi mdi-cellphone'} value={ data?.ultimos90 || 0}
            type={'number'} text={'Últimos 90 Dias'} color="primary"
            xl={3} md={3} xs={6}
          />
           <CardIndicador
            icon={'mdi mdi-cellphone'} value={data?.saldo}
            type={'number'} text={'Saldo'} color={
              Number(data?.saldo) > 0 ? "success" : "warning" }
            xl={3} md={3} xs={6}
          />
          </Row>
          <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 20,
            
          }}
          >


        <Col xs={6} sm={4} md={4}>
          <SelectComponent
            options={userSmsList}
            value={
              userSmsList.find((a) => a.value === idSelecionado)
                ? userSmsList.find((a) => a.value === idSelecionado)
                : null
            }
            onChange={(value) => setIdSelecionado(value.value)}
            placeholder="Usuário"
          />
        </Col>
            <Col xs={12} sm={4}  item>
             <Button
                  variant="outlined"
                  onClick={() => makePayment()}
                  className="newBg"
                  style={{
                    marginBottom: '0px',
                    marginRight: 5,
                    marginTop: 5,
                    cursor: 'pointer',
                    backgroundColor: '#0D071B !important',
                    color: 'white'
                  }}
                >
                 Adicionar Créditos
                </Button>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <DatatableTables
                title={'Campanhas'}
                columns={columns}
                rows={rows}
                tableConfig={tableConfig}
                setTableConfig={setTableConfig}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Sorte.propTypes = {
  t: PropTypes.any
}

export default Sorte
