import Resizer from 'react-image-file-resizer'

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = ''
    const reader = new FileReader()
    reader.readAsDataURL(file)
    // on reader load somthing...
    reader.onload = () => {
      baseURL = reader.result
      resolve(baseURL)
    }
  })
}

export function redimensionar(file) {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, // Is the file of the image which will resized.
      1920, // Is the maxWidth of the resized new image.
      1080, // Is the maxHeight of the resized new image.
      'webp', // Is the compressFormat of the resized new image.
      80, // Is the quality of the resized new image.
      0, // Is the degree of clockwise rotation to apply to uploaded image.
      (prev) => {
        resolve(prev)
      }, // Is the callBack function of the resized new image URI.
      'base64' // Is the output type of the resized new image.
    )
  })
}

export function calcularTamBase64(file, x) {
  const avatar = file.split(',')[1]
  const pad = avatar.slice(-2)
  const padlen = pad === '==' ? 2 : pad.slice(-1) === '=' ? 1 : null
  // eslint-disable-next-line no-mixed-operators
  let kb = (avatar.length / 4) * 3 - padlen
  kb = (kb / 1024 / 1024).toFixed(2)
  console.log(`${x} - size is: ${kb}Mb`)
}

export const onlyUnsignedNumbers = (_string) => _string?.replace(/[^0-9]+/g, '')

export const isValidCpf = (cpf_string) => {
  cpf_string = onlyUnsignedNumbers(cpf_string)
  if (!cpf_string) return false

  // Elimina cpf_strings invalidos conhecidos
  if (
    cpf_string?.length !== 11 ||
    cpf_string === '00000000000' ||
    cpf_string === '11111111111' ||
    cpf_string === '22222222222' ||
    cpf_string === '33333333333' ||
    cpf_string === '44444444444' ||
    cpf_string === '55555555555' ||
    cpf_string === '66666666666' ||
    cpf_string === '77777777777' ||
    cpf_string === '88888888888' ||
    cpf_string === '99999999999'
  ) {
    return false
  }
  // Valida 1o digito
  let add = 0
  for (let i = 0; i < 9; i++) {
    add += parseInt(cpf_string.charAt(i)) * (10 - i)
  }
  let rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf_string.charAt(9))) {
    return false
  }

  // Valida 2o digito
  add = 0
  for (let i = 0; i < 10; i++) {
    add += parseInt(cpf_string.charAt(i)) * (11 - i)
  }
  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }
  if (rev !== parseInt(cpf_string.charAt(10))) {
    return false
  }

  return true
}

export const phoneMaskForList = (number) => {
  const phone = onlyUnsignedNumbers(number)
  return phone?.replace(/(\d{2})(\d{5})(\d)/, '($1) $2-$3')
}

//Máscara para data
export const dateMask = (date) => {
  if (date === null || date === undefined) return
  //if (String(date).length === 11) {
  return (
    String(date)
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      // .replace(/(\d{3})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, '$1')
  )
  //}
}

export function isValidEmail(email) {
  if (!email) {
    return false
  }
  const usuario = email.substring(0, email.indexOf('@'))
  const dominio = email.substring(email.indexOf('@') + 1, email.length)
  if (
    usuario.length >= 1 &&
    dominio.length >= 3 &&
    usuario.search('@') === -1 &&
    dominio.search('@') === -1 &&
    usuario.search(' ') === -1 &&
    dominio.search(' ') === -1 &&
    dominio.search('.') !== -1 &&
    dominio.indexOf('.') >= 1 &&
    dominio.lastIndexOf('.') < dominio.length - 1
  ) {
    return true
  } else {
    return false
  }
}

export const isValidDate = (data) => {
  try {
    data = data.split('/')
    const dia = parseInt(data[0])
    const mes = parseInt(data[1])
    const ano = parseInt(data[2])

    if (mes > 12 || mes < 1) {
      return false
    }

    if (
      (mes === 4 || mes === 6 || mes === 9 || mes === 11) &&
      (dia > 30 || dia < 0)
    ) {
      return false
    }

    if (
      (mes === 1 ||
        mes === 2 ||
        mes === 5 ||
        mes === 7 ||
        mes === 8 ||
        mes === 10 ||
        mes === 12) &&
      (dia > 31 || dia < 0)
    ) {
      return false
    }

    if (mes === 2 && (dia > 28 || dia < 0)) {
      return false
    }

    if (ano < 1900) {
      return false
    }

    return true
  } catch (error) {
    return false
  }
}

export const formatDate = (date) => {
  const splitDate = date.split('/')
  if (splitDate.length === 3) {
    const formattedDate = `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`
    return formattedDate
  }
  return '01-01-2000'
}

export function validarData(dateString) {
  const data = new Date(dateString)
  return data instanceof Date && !isNaN(data)
}

export function formatToBrazillianDateDayMonth(dateString) {
  const date = new Date(dateString)
  const options = {
    day: '2-digit',
    month: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }
  const formatter = new Intl.DateTimeFormat('pt-BR', options)
  return formatter.format(date)
}

export const isValidCnpj = (cnpj) => {
  if (!cnpj) return false

  const cleanedCNPJ = cnpj.replace(/\D/g, '')

  if (cleanedCNPJ.length !== 14) return false

  // Verifica se todos os dígitos são iguais
  if (/^(\d)\1{13}$/.test(cleanedCNPJ)) return false

  let size = cleanedCNPJ.length - 2
  let numbers = cleanedCNPJ.substring(0, size)
  const digits = cleanedCNPJ.substring(size)
  let sum = 0
  let pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--
    if (pos < 2) pos = 9
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result !== parseInt(digits.charAt(0))) return false

  size = size + 1
  numbers = cleanedCNPJ.substring(0, size)
  sum = 0
  pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--
    if (pos < 2) pos = 9
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (result !== parseInt(digits.charAt(1))) return false

  return true
}

export const isValidCreditCardNumber = (creditCard) => {
  //Check if the number contains only numeric value
  //and is of between 13 to 19 digits
  const regex = new RegExp('^[0-9]{13,19}$')
  if (!regex.test(creditCard)) {
    return false
  }

  let checksum = 0 // running checksum total
  let j = 1 // takes value of 1 or 2

  // Process each digit one by one starting from the last
  for (let i = creditCard.length - 1; i >= 0; i--) {
    let calc = 0
    // Extract the next digit and multiply by 1 or 2 on alternative digits.
    calc = Number(creditCard.charAt(i)) * j

    // If the result is in two digits add 1 to the checksum total
    if (calc > 9) {
      checksum = checksum + 1
      calc = calc - 10
    }

    // Add the units element to the checksum total
    checksum = checksum + calc

    // Switch the value of j
    if (Number(j) === 1) {
      j = 2
    } else {
      j = 1
    }
  }

  //Check if it is divisible by 10 or not.
  return checksum % 10 === 0
}

export const isValidExpirationDate = (expirationDate) => {
  console.log(expirationDate)
  if (expirationDate?.length !== 4) return false

  const expirationMonth = expirationDate.substring(0, 2)
  const expirationYear = expirationDate.substring(2)
  console.log(expirationMonth)
  console.log(expirationYear)
  if (Number(expirationMonth) > 12) {
    return false
  }
  const expirationDateTemp = new Date(
    `20${expirationYear}`,
    Number(expirationMonth) - 1,
    1
  )

  const today = new Date()
  if (Object.prototype.toString.call(expirationDateTemp) === '[object Date]') {
    if (isNaN(expirationDateTemp.getTime())) {
      return false
    }
  }
  console.log(expirationDateTemp)
  console.log(today)
  if (expirationDateTemp < today) {
    return false
  }
  // console.log('there');
  return true
}

export const exportFile = ({ base64, fileName, extension }) => {
  const linkSource = base64
  const link = document.createElement('a')
  link.href = linkSource
  link.download = `${fileName}.${extension}`
  document.body.appendChild(link)
  link.click()
  link.remove()
}
