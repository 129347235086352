import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

// //Import Scrollbar
import SimpleBar from 'simplebar-react'

// MetisMenu
import MetisMenu from 'metismenujs'
import { withRouter, Link } from 'react-router-dom'
import './styles.css'
import { withTranslation } from 'react-i18next'
import { useUserInfo } from 'context/userContext'
import logosmImg from '../../assets/images/logos/zap_mais.png'
import logoImgSmall from '../../assets/images/logos/z_mais.png'

// const obj = JSON.parse(localStorage.getItem('authUser'))
const planosAutorizacao = [
  {
    plano: 'BASIC',
    autorizacao: {
      dashboard: true,
      disparador: true,
      envio_simplificado: true,
      meuChat: true,
      copyrigth: false,
      grupos: false,
      meusWhatsapp: true,
      tutorial: true,
      planos: true
    }
  },
  {
    plano: 'STANDARD',
    autorizacao: {
      dashboard: true,
      disparador: true,
      envio_simplificado: true,
      meuChat: true,
      copyrigth: true,
      grupos: true,
      meusWhatsapp: true,
      tutorial: true,
      planos: true
    }
  },
  {
    plano: 'PREMIUM',
    autorizacao: {
      dashboard: true,
      disparador: true,
      envio_simplificado: true,
      meuChat: true,
      copyrigth: true,
      grupos: true,
      meusWhatsapp: true,
      tutorial: true,
      planos: true
    }
  }
]

const SidebarContent = (props) => {
  const ref = useRef()
  const { isRoot, userPlan, userId, hasAutomationCampaign } = useUserInfo()

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add('active')
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show')
    }

    if (parent) {
      parent.classList.add('mm-active')
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add('mm-show') // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add('mm-active') // li
          parent3.childNodes[0].classList.add('mm-active') //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add('mm-show') // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add('mm-show') // li
              parent5.childNodes[0].classList.add('mm-active') // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu('#side-menu')
      let matchingMenuItem = null
      const ul = document.getElementById('side-menu')
      const items = ul.getElementsByTagName('a')
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div className="navbar-brand-box" style={{ paddingTop: '20px' }}>
          <Link to="/" className="logo logo-dark">
            <span className="logo-sm">
              <img src={logoImgSmall} style={{ height: '60px' }} />
            </span>
            <span className="logo-lg">
              <img src={logosmImg} style={{ height: '60px' }} />
            </span>
          </Link>
          <Link to="/" className="logo logo-light">
            <span className="logo-sm">
              <img src={logoImgSmall} style={{ height: '60px' }} />
            </span>
            <span className="logo-lg">
              <img src={logosmImg} style={{ height: '60px' }} />
            </span>
          </Link>
        </div>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {planosAutorizacao.filter((plano) => plano?.plano === userPlan)[0]
              ?.autorizacao['dashboard'] && (
              <li>
                <Link to="/dashboard" className="waves-effect">
                  <span>{props.t('Dashboard')}</span>
                </Link>
              </li>
            )}
            {isRoot ? (
              <li>
                <Link to="/#" className="waves-effect">
                  <span className="badge rounded-pill bg-success float-end">
                    +
                  </span>
                  <span>{props.t('Root')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <Link to="/relatorio-financeiro" className="waves-effect">
                      <span>{props.t('Financeiro')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/users" className="waves-effect">
                      <span>{props.t('Usuarios')}</span>
                    </Link>
                  </li>
                  {/* {isRoot && (
                    <li>
                      <Link to="/pages" className="waves-effect">
                        <span>{props.t('Zap Page')}</span>
                        <span className="new-tag">NOVO</span>
                      </Link>
                    </li>
                  )} */}
                  {isRoot && (
                    <li>
                      <Link
                        to="/planos-personalizados"
                        className="waves-effect"
                      >
                        <span>{props.t('Planos personalizado')}</span>
                      </Link>
                    </li>
                  )}
                  <li>
                    <Link to="/logs" className="waves-effect">
                      <span>{props.t('Logs')}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-success float-end">
                  +
                </span>
                <span>{props.t('Whatsapp')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/enviar-planilha" className="waves-effect">
                    <span>{props.t('Disparador')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/envio-simplificado" className="waves-effect">
                    <span>{props.t('Envio simplificado')}</span>
                    <span className="new-tag">NOVO</span>
                  </Link>
                </li>
                <li>
                  <Link to="/meu-chat" className="waves-effect">
                    <span>{props.t('Meu Chat')}</span>
                  </Link>
                </li>
                {planosAutorizacao.filter(
                  (plano) => plano?.plano === userPlan
                )[0]?.autorizacao['grupos'] && (
                  <li>
                    <Link to="/grupos" className="waves-effect">
                      <span>{props.t('Grupos de Disparo')}</span>
                    </Link>
                  </li>
                )}
                {hasAutomationCampaign && (
                  <li>
                    <Link to="/campanhas-de-automacao" className="waves-effect">
                      <span>{props.t('Campanhas de automação')}</span>
                    </Link>
                  </li>
                )}
              </ul>
            </li>

            {/* {planosAutorizacao.filter((plano) => plano?.plano === userPlan)[0]
              ?.autorizacao['copyrigth'] && (
              <li>
                <Link to="/copy" className="waves-effect">
                  <span>{props.t('Copyright')}</span>
                </Link>
              </li>
            )} */}
            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-success float-end">
                  +
                </span>
                <span>{props.t('SMS')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/enviar-sms" className="waves-effect">
                    <span>{props.t('Enviar Sms')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/painel" className="waves-effect">
                    <span>{props.t('Créditos')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/list" className="waves-effect">
                    <span>{props.t('Histórico de Envio')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/transaction" className="waves-effect">
                    <span>{props.t('Transações')}</span>
                  </Link>
                </li>
              </ul>
            </li>

            {(Number(userId) === 35 || isRoot) && (
              <li>
                <Link to="/#" className="waves-effect">
                  <span className="badge rounded-pill bg-success float-end">
                    +
                  </span>
                  <span>{props.t('Mais modas')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="true">
                  <li>
                    <Link to="/mais-modas-relatorios" className="waves-effect">
                      <span>{props.t('Relatório de clientes')}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-success float-end">
                  +
                </span>
                <span>{props.t('CRM')}</span>
                <span className="new-tag">NOVO</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/bot" className="waves-effect">
                    <span>{props.t('Bot Zap+')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/leads" className="waves-effect">
                    <span>{props.t('Base de Leads')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/segmentation" className="waves-effect">
                    <span>{props.t('Segmentações')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/cashback" className="waves-effect">
                    <span>{props.t('Cashback')}</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-success float-end">
                  +
                </span>
                <span>{props.t('Minha Conta')}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                <li>
                  <Link to="/assinaturas" className="waves-effect">
                    <span>{props.t('Assinaturas')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/instance" className="waves-effect">
                    <span>{props.t('Meus Whatsapp')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/security" className="waves-effect">
                    <span>{props.t('Acesso')}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/integration" className="waves-effect">
                    <span>{props.t('Integrações Via API')}</span>
                  </Link>
                </li>
              </ul>
            </li>
             <li>
              <Link to="/#" className="waves-effect">
                <span className="badge rounded-pill bg-success float-end">
                  +
                </span>
                <span>{props.t('Informações')}</span>
                <span className="new-tag">NOVO</span>
              </Link>
              <ul className="sub-menu" aria-expanded="true">
                {/* <li>
                  <Link to="/tutorial" className="waves-effect">
                    <span>{props.t('Tutoriais da Plataforma')}</span>
                  </Link>
                </li> */}
               <li>
                  <Link to="/partners" className="waves-effect">
                    <span>{props.t('Integrações')}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/politica-de-privacidade" className="waves-effect">
                    <span>{props.t('Politica de Privacidade')}</span>
                  </Link>
                </li>
              </ul>
            </li> 
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
}

export default withRouter(withTranslation()(SidebarContent))
