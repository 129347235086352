import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import Services from '../../services/user'
import { onlyNumbers, phoneMask } from '../../util/string'
import './styles.css'
import PhoneInput from 'react-phone-number-input'
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  Label,
  FormFeedback
} from 'reactstrap'
import { Loading } from '../Loading'
import {
  handlerUser,
  handlerFile,
  handleFileSelected
} from '../../services/instanceFunctions'

export const ModalAddContato = ({
  showModal,
  onClose,
  tabela,
  showModalAddGrupo,
  loadDadosTable
}) => {
  console.log('tabela', tabela)
  const [loading, setLoading] = useState(false)

  const [dados, setDados] = useState({
    name: '',
    phone: ''
  })
  const [file, setFile] = useState(null)
  const [telefoneInvalido, setTelefoneInvalido] = useState(false)
  const handleClose = () => {
    setLoading(false)
  }

  const handleSubmitContact = async () => {
    console.log(tabela)
    const newTabela = tabela

    newTabela.unshift({
      id: newTabela.length + 1,
      name: dados.name,
      fone: dados.phone,
      lastMessage: '---'
    })

    loadDadosTable(newTabela)
    onClose()
  }

  const handleCancelModal = () => {
    onClose()
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
    >
      <Container>
        <Loading open={loading} handleClose={handleClose} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Adicionar contato</h1>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col xl={12} md={12}>
              <FormGroup>
                <Label for="nomeInput">Nome</Label>
                <Input
                  type="text"
                  name="nomeInput"
                  id="nomeInput"
                  value={dados.name}
                  onChange={(event) =>
                    setDados({ ...dados, name: event.target.value })
                  }
                  // invalid={nomeInvalido}
                />
                <FormFeedback>Por favor, insira um nome válido.</FormFeedback>
                <Label for="telefoneInput">Telefone</Label>
                <Input
                  type="text"
                  name="numero"
                  id="numeroInput"
                  value={phoneMask(dados.phone)}
                  onChange={(event) =>
                    setDados({
                      ...dados,
                      phone: onlyNumbers(event.target.value)
                    })
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="footerModalButtons" style={{ marginTop: 40 }}>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handleSubmitContact}
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handleCancelModal}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
