import React, { Dispatch, SetStateAction } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import {
  cepMaskV2,
  cpfMask,
  creditCardMask,
  expirationDateMask,
  onlyUnsignedNumbers
} from 'util/string'
import Select from 'react-select'
import { listPaymentMethods } from 'util/mocks'
import { Plan } from 'types/global'

interface CheckoutAddressProps {
  paymentMethod: string
  handleChangePaymentMethod: (value: string) => void
  paymentMethodError: string
  checkoutSuccessFeedback: any
  handleChangeCheckoutCreditCard: ({
    field,
    value
  }: {
    field: any
    value: any
  }) => Promise<void>
  checkoutErrorMessage: string
  checkoutCreditCard: any
  handleSubmitSubscription: () => Promise<void>
  selectedPlan: Plan
  handleCopy: () => void
  setShowCheckoutArea: Dispatch<SetStateAction<boolean>>
  loadSubscriptions: () => Promise<void>
  previousStep: () => void
  addPaymentMethod?: boolean
}

export const CheckoutPayment = ({
  paymentMethod,
  handleChangePaymentMethod,
  paymentMethodError,
  checkoutSuccessFeedback,
  handleChangeCheckoutCreditCard,
  checkoutErrorMessage,
  checkoutCreditCard,
  handleSubmitSubscription,
  selectedPlan,
  handleCopy,
  setShowCheckoutArea,
  loadSubscriptions,
  previousStep,
  addPaymentMethod = false
}: CheckoutAddressProps) => {
  return (
    <Form>
      <Form.Group>
        <Form.Label>Método de pagamento</Form.Label>
        <Select
          options={listPaymentMethods}
          value={
            listPaymentMethods.find((a) => a.value === paymentMethod)
              ? listPaymentMethods.find((a) => a.value === paymentMethod)
              : null
          }
          placeholder="Selecione..."
          onChange={(value) => handleChangePaymentMethod(value?.value)}
          className={!!paymentMethodError ? 'select-error' : ''}
          isDisabled={addPaymentMethod}
        />
        {!!paymentMethodError && (
          <>
            <span className="is-invalid"></span>
            <p className="invalid-feedback">{paymentMethodError}</p>
          </>
        )}
      </Form.Group>
      {!checkoutSuccessFeedback && paymentMethod === 'cc' ? (
        <div className="container-credit-card-fields">
          <Form.Group className="mb-2">
            <Form.Label>Número</Form.Label>
            <Form.Control
              value={creditCardMask(checkoutCreditCard.number)}
              onChange={(event) =>
                handleChangeCheckoutCreditCard({
                  field: 'number',
                  value: onlyUnsignedNumbers(event.target.value)
                })
              }
              isInvalid={!!checkoutCreditCard.errorMessage.number}
            />
            <Form.Control.Feedback type="invalid">
              {checkoutCreditCard?.errorMessage?.number}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Nome no cartão</Form.Label>
            <Form.Control
              value={checkoutCreditCard.holderName}
              onChange={(event) =>
                handleChangeCheckoutCreditCard({
                  field: 'holderName',
                  value: event.target.value
                })
              }
              isInvalid={!!checkoutCreditCard.errorMessage.holderName}
            />
            <Form.Control.Feedback type="invalid">
              {checkoutCreditCard?.errorMessage?.holderName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Validade</Form.Label>
            <Form.Control
              value={expirationDateMask(checkoutCreditCard.expiration)}
              onChange={(event) =>
                handleChangeCheckoutCreditCard({
                  field: 'expiration',
                  value: event.target.value
                })
              }
              isInvalid={!!checkoutCreditCard.errorMessage.expiration}
            />
            <Form.Control.Feedback type="invalid">
              {checkoutCreditCard?.errorMessage?.expiration}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>CVV</Form.Label>
            <Form.Control
              value={checkoutCreditCard.cvv}
              onChange={(event) =>
                handleChangeCheckoutCreditCard({
                  field: 'cvv',
                  value: event.target.value
                })
              }
              isInvalid={!!checkoutCreditCard.errorMessage.cvv}
            />
            <Form.Control.Feedback type="invalid">
              {checkoutCreditCard?.errorMessage?.cvv}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>CPF do portador do cartão</Form.Label>
            <Form.Control
              value={cpfMask(checkoutCreditCard.holderDocument)}
              onChange={(event) =>
                handleChangeCheckoutCreditCard({
                  field: 'holderDocument',
                  value: onlyUnsignedNumbers(event.target.value)
                })
              }
              isInvalid={!!checkoutCreditCard.errorMessage.holderDocument}
            />
            <Form.Control.Feedback type="invalid">
              {checkoutCreditCard?.errorMessage?.holderDocument}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      ) : null}
      {!checkoutSuccessFeedback && checkoutErrorMessage && (
        <Alert className="mt-4" key="danger" variant="danger">
          {checkoutErrorMessage}
        </Alert>
      )}
      {checkoutSuccessFeedback ? (
        <div className="container-feedback-success">
          {paymentMethod === 'pix' && (
            <h5 className="feedback-header-message">
              Leia o qr code abaixo ou copie o código
            </h5>
          )}
          {paymentMethod === 'boleto' && (
            <h5 className="feedback-header-message">
              O boleto foi gerado, clique no botão abaixo para baixar
            </h5>
          )}
          {checkoutSuccessFeedback?.image ? (
            <img
              className="image-qrcode"
              src={checkoutSuccessFeedback?.image}
              alt="Qr code para pagamento"
            />
          ) : null}
        </div>
      ) : null}

      {!checkoutSuccessFeedback ? (
        <div className="container-step-buttons pt-4">
          <Button
            variant="secondary"
            className="button-previous"
            onClick={previousStep}
            type="button"
          >
            {addPaymentMethod ? 'Voltar' : 'Anterior'}
          </Button>
          <Button
            variant="success"
            className="button-next"
            onClick={handleSubmitSubscription}
            type="button"
          >
            Finalizar
          </Button>
        </div>
      ) : (
        <>
          {paymentMethod === 'boleto' ? (
            <a
              className="button-download btn btn-success"
              href={checkoutSuccessFeedback?.action}
              target="_blank"
              download={`Boleto ${selectedPlan.name}.pdf`}
              onClick={() => {
                setShowCheckoutArea(false)
                loadSubscriptions()
              }}
            >
              <i className="mdi mdi-download"></i>
              Baixar boleto
            </a>
          ) : (
            <Button
              variant="success"
              className="button-copy"
              onClick={handleCopy}
              type="button"
            >
              Copiar
              <i className="mdi mdi-content-copy"></i>
            </Button>
          )}
        </>
      )}
    </Form>
  )
}
