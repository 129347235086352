import { AxiosError } from 'axios'
import { api } from './axios'

export const authenticationService = {
  getGoogleAuthUrl: async (instanceId: number) => {
    const response = await api
      .get('/user/signin-google-get-url', {
        params: {
          instanceId
        }
      })
      .then((response) => response)
      .catch((response: AxiosError) => response.response)

    return response
  },
  signInGoogle: async (code: string, instanceId: number) => {
    const response = await api
      .post('/user/signin-google', {
        code,
        instanceId
      })
      .then((response) => response)
      .catch((response: AxiosError) => response.response)

    return response
  },
  disconnectGoogle: async (instanceId: number) => {
    const response = await api
      .post('/user/signout-google', {
        instanceId
      })
      .then((response) => response)
      .catch((response: AxiosError) => response.response)

    return response
  }
}
