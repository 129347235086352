import React, {  } from 'react'
import { Collapse } from 'reactstrap'
import { Tr, Td } from 'react-super-responsive-table'

import { SubscriptionCollapse } from 'pages/Subscriptions/subscriptionsCollpse'

export function SubscriptionCollapseTable({ open, row }) {
  return (
    <Tr>
      <Td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
        <Collapse
          isOpen={open}
          timeout="auto"
          unmountOnExit
          style={{ paddingTop: 20, padding: 30 }}
        >
          <SubscriptionCollapse subscription={row} />
        </Collapse>
      </Td>
    </Tr>
  )
}
