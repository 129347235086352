import React from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { cepMaskV2, onlyUnsignedNumbers } from 'util/string'

interface CheckoutAddressProps {
  checkoutAddress: any
  handleChangeCheckoutAddress: ({
    field,
    value
  }: {
    field: string
    value: any
  }) => Promise<void>
  previousStep: () => void
  handleCheckCheckoutAddress: () => void
}

export const CheckoutAddress = ({
  checkoutAddress,
  handleChangeCheckoutAddress,
  previousStep,
  handleCheckCheckoutAddress
}: CheckoutAddressProps) => {
  return (
    <Form>
      <Row>
        <Col xs={12}>
          <Form.Group className="mb-2">
            <Form.Label>CEP</Form.Label>
            <Form.Control
              value={cepMaskV2(checkoutAddress.cep)}
              onChange={(event) =>
                handleChangeCheckoutAddress({
                  field: 'cep',
                  value: onlyUnsignedNumbers(event.target.value)
                })
              }
              isInvalid={!!checkoutAddress.errorMessage.cep}
            />
            <Form.Control.Feedback type="invalid">
              {checkoutAddress?.errorMessage?.cep}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12}>
          <Form.Group className="mb-2">
            <Form.Label>Rua</Form.Label>
            <Form.Control
              value={checkoutAddress.street}
              onChange={(event) =>
                handleChangeCheckoutAddress({
                  field: 'street',
                  value: event.target.value
                })
              }
              isInvalid={!!checkoutAddress.errorMessage.street}
            />
            <Form.Control.Feedback type="invalid">
              {checkoutAddress?.errorMessage?.street}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-2">
            <Form.Label>Número</Form.Label>
            <Form.Control
              value={checkoutAddress.number}
              onChange={(event) =>
                handleChangeCheckoutAddress({
                  field: 'number',
                  value: event.target.value
                })
              }
              isInvalid={!!checkoutAddress.errorMessage.number}
            />
            <Form.Control.Feedback type="invalid">
              {checkoutAddress?.errorMessage?.number}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-2">
            <Form.Label>Bairro</Form.Label>
            <Form.Control
              value={checkoutAddress.district}
              onChange={(event) =>
                handleChangeCheckoutAddress({
                  field: 'district',
                  value: event.target.value
                })
              }
              isInvalid={!!checkoutAddress.errorMessage.district}
            />
            <Form.Control.Feedback type="invalid">
              {checkoutAddress?.errorMessage?.district}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-2">
            <Form.Label>Cidade</Form.Label>
            <Form.Control
              value={checkoutAddress.city}
              onChange={(event) =>
                handleChangeCheckoutAddress({
                  field: 'city',
                  value: event.target.value
                })
              }
              isInvalid={!!checkoutAddress.errorMessage.city}
            />
            <Form.Control.Feedback type="invalid">
              {checkoutAddress?.errorMessage?.city}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} sm={6}>
          <Form.Group className="mb-2">
            <Form.Label>Estado</Form.Label>
            <Form.Control
              value={checkoutAddress.state}
              onChange={(event) =>
                handleChangeCheckoutAddress({
                  field: 'state',
                  value: event.target.value
                })
              }
              isInvalid={!!checkoutAddress.errorMessage.state}
            />
            <Form.Control.Feedback type="invalid">
              {checkoutAddress?.errorMessage?.state}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <div className="container-step-buttons pt-4">
        <Button
          variant="secondary"
          className="button-previous"
          onClick={previousStep}
          type="button"
        >
          Anterior
        </Button>
        <Button
          variant="success"
          className="button-next"
          onClick={handleCheckCheckoutAddress}
          type="button"
        >
          Próximo
        </Button>
      </div>
    </Form>
  )
}
