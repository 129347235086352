import { api } from './axios'
import {
  ApiResponse,
  CreditCardPaymentBodyProps,
  CustomPlanBodyProps,
  CustomPlanProps,
  CustomSubscriptionBodyProps,
  PaginationProps,
  Plan,
  SubscriptionBodyProps,
  SubscriptionProps,
  TransctionPaymentStatus
} from 'types/global'

export default {
  getPlans: async (): Promise<ApiResponse<any>> => {
    const response = await api
      .get(`/plans`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createSubscription: async (
    body: SubscriptionBodyProps
  ): Promise<ApiResponse<any>> => {
    const response = await api
      .post(`/subscription`, body)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getSubscriptions: async (): Promise<ApiResponse<SubscriptionProps[]>> => {
    const response = await api
      .get(`/subscriptions`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },

  listCustomPlans: async ({
    rowsPerPage,
    page,
    userId,
    planId,
    active
  }): Promise<ApiResponse<PaginationProps<CustomPlanProps>>> => {
    const response = await api
      .get('/custom-plans', {
        params: {
          rowsPerPage,
          page,
          userId,
          planId,
          active
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  selectPlans: async () => {
    console.log()
    const response = await api
      .get('/plans/select')
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  createCustomPlan: async (
    body: CustomPlanBodyProps
  ): Promise<ApiResponse<any>> => {
    const response = await api
      .post('/custom-plan', body)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  updateCustomPlanStatus: async ({
    id,
    active
  }: {
    id: number
    active: number
  }): Promise<ApiResponse<any>> => {
    const response = await api
      .put(`/custom-plan/${id}`, {
        active
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)
    return response
  },
  getPaymentStatus: async (
    transactionId: number
  ): Promise<ApiResponse<TransctionPaymentStatus>> => {
    const response = await api
      .get(`/transaction/status/${transactionId}`)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createCustomSubscription: async (
    body: CustomSubscriptionBodyProps
  ): Promise<ApiResponse<any>> => {
    const response = await api
      .post(`/custom-subscription`, body)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  getSubscriptionInfo: async (
    subscriptionId: number,
    newPaymentMethod: string
  ): Promise<ApiResponse<any>> => {
    const response = await api
      .get(`/subscription-info/${subscriptionId}`, {
        params: {
          paymentMethod: newPaymentMethod
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
  createCreditCardPayment: async (
    body: CreditCardPaymentBodyProps
  ): Promise<ApiResponse<any>> => {
    const response = await api
      .post(`/subscription/credit-card-payment`, body)
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response)

    return response
  },
}
