import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import './styles.scss'
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'
import InstanceService from '../../services/instance'
import { Loading } from '../Loading'

export const ModalResgatarCashback = ({ showModal, onClose, id, itemCash, getCashback }) => {
  const [loading, setLoading] = useState(false)

  const handlerCancel = () => {
    onClose()
  }

  const handlerSubmit = async () => {
    setLoading(true)
    const result = await InstanceService.useCashback({
      id: itemCash?.id
    });

    if (result.status === 200) {
      toast.success('Cashback resgatado com sucesso!')
      setLoading(false)
      onClose()
      getCashback(id)
    } else {
      setLoading(false)
      toast.error('Erro ao resgatar cashback!')
    }
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container style={{minHeight: 250}}>
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>Você está resgatando um Cashback!</h1>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
                <h2>Codigo: {itemCash?.hash}</h2>
              </Col>
              <Col xs={6}>
                <h5><b>Nome:</b> {itemCash?.name}</h5>
              </Col>
              <Col xs={6}>
                <h5><b>Valor:</b> {itemCash?.value}</h5>
              </Col>
          </Row>
          <Row className="footerModalButtons" style={{ marginTop: 40 }}>
         
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handlerCancel}
                variant="contained"
                color="secondary"
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="buttonsModal"
                onClick={handlerSubmit}
                variant="contained"
                color="primary"
              >
                Confirmar
              </Button>
            </Col>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
