/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  FormGroup,
  Label
} from 'reactstrap'
import { toast } from 'react-toastify'
import Excel from 'exceljs'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
///MODAIS
import Select from 'react-select'
import { BotService } from '../../services/bot'
import { SelectComponent } from '../../components/selectComponent'
import FrontTable from '../../components/TableFrontSide'

import Services from '../../services/auth'
import ServicesMessage from '../../services/sendMessage'
import Swal from 'sweetalert2'

import {
  formatCurrency,
  cnpjMask,
  phoneMask,
  formatDateT
} from '../../util/string'
import './styles.css'

import 'chartist/dist/scss/chartist.scss'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import { format } from 'date-fns'
import { ModalAccess } from '../../components/modalAccess'

registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Id', field: 'id' },
  { label: 'Nome', field: 'name' },
  { label: 'Telefone', field: 'telefone' },
  { label: 'Email', field: 'email' },
  { label: 'CPF', field: 'cpf' },
  { label: 'Sexo', field: 'genero' },
  { label: 'Extra', field: 'camposExtra' },
  { label: 'Tag', field: 'tags' },
  { label: 'Data', field: 'created_at' }
]

const Leads = () => {
  const { listClients, listClientsAll } = Services
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [idGrupo, setIdGrupo] = useState(false)
  const [showModalGrupo, setShowModalGrupo] = useState(false)
  const [showModalAddGrupo, setShowModalAddGrupo] = useState(false)
  const [rendered, setRendered] = useState(false)
  const [instanciasList, setInstanciasList] = useState([])
  const [availableFields, setAvailableFields] = useState([])
  const [searchType, setSearchType] = useState('all')
  const [searchField, setSearchField] = useState('')
  const [searchValue, setSearchValue] = useState('')

  const handleClose = () => {
    setLoading(false)
  }
  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      pagination: true,
      delete: false,
      more: false,
      downloadXlsx: true,
      uploadGrupo: false
    },
    config: {
      deleteText: 'Deletar Acesso'
    },
    instanceId: false,
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const loadUsers = async (idInstanciaNew) => {
    setLoading(true)

    const response = await listClients({
      rowsPerPage: tableConfig.rowsPerPage,
      page: tableConfig.currentPage,
      instanceId: idInstanciaNew
    })

    if (response.status === 200) {
      let formattedData = response?.data?.data

      formattedData = formattedData.map((item) => {
        let parsedCamposExtra = []

        if (item.camposExtra) {
          try {
            parsedCamposExtra = JSON.parse(item.camposExtra)
          } catch (error) {
            console.error(
              `Erro ao fazer parse de camposExtra para o item com ID ${item.id}:`,
              error
            )
            toast.error(
              `Erro ao carregar campos extras para o cliente ${item.name}.`
            )
            parsedCamposExtra = [
              { key: 'Erro', value: 'Formato de dados inválido' }
            ]
          }
        }

        return {
          ...item,
          camposExtra: parsedCamposExtra,
          created_at: formatDateT(item.created_at)
        }
      })

      console.log(response?.data)

      setRows(formattedData)
      setTableConfig((prev) => ({
        ...prev,
        total: response?.data?.total
      }))
      setLoading(false)
    } else {
      toast.error(response)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (tableConfig.instanceId) {
      loadUsers(tableConfig.instanceId)
    }
  }, [tableConfig.currentPage, tableConfig.instanceId])

  useEffect(() => {
    const loadAvailableFields = async () => {
      try {
        const response = await Services.loadCamposExtras(tableConfig.instanceId)
        if (response.status === 200) {
          setAvailableFields(response.data.data)
        } else {
          toast.error('Erro ao carregar campos extras.')
        }
      } catch (error) {
        toast.error('Erro ao carregar campos extras.')
        console.error('Erro ao carregar campos extras:', error)
      }
    }

    loadAvailableFields()
  }, [tableConfig.instanceId])

  const handleSearch = async () => {
    try {
      console.log('Iniciando a busca com os parâmetros:', {
        instanceId: tableConfig.instanceId,
        searchType,
        searchKey: searchField,
        searchValue
      })

      const response = await Services.filterClients({
        instanceId: tableConfig.instanceId,
        searchType,
        searchKey: searchField,
        searchValue
      })

      if (response.status === 200) {
        let filteredData = response.data.data

        console.log('Dados filtrados antes da formatação:', filteredData)

        // Processa os dados filtrados da mesma forma que na função loadUsers
        filteredData = filteredData.map((item) => {
          let parsedCamposExtra = []

          if (item.camposExtra) {
            try {
              parsedCamposExtra = JSON.parse(item.camposExtra)
              console.log('Campos extras parseados:', parsedCamposExtra)
            } catch (error) {
              console.error(
                `Erro ao fazer parse de camposExtra para o item com ID ${item.id}:`,
                error
              )
              toast.error(
                `Erro ao carregar campos extras para o cliente ${item.name}.`
              )
              parsedCamposExtra = [
                { key: 'Erro', value: 'Formato de dados inválido' }
              ]
            }
          }

          // Se os dados extras vierem separados, os adicionamos ao array
          if (item.key && item.value) {
            parsedCamposExtra.push({ key: item.key, value: item.value })
          }

          return {
            ...item,
            camposExtra: parsedCamposExtra,
            created_at: formatDateT(item.created_at) // Se você estiver usando uma função de formatação de data
          }
        })

        console.log('Dados filtrados após a formatação:', filteredData)

        setRows(filteredData)
      } else {
        console.error('Erro ao filtrar clientes, resposta da API:', response)
        toast.error('Erro ao filtrar clientes.')
      }
    } catch (error) {
      console.error('Erro ao buscar clientes:', error)
      toast.error('Erro ao buscar clientes.')
    }
  }

  const loadInstancias = async () => {
    const response = await ServicesMessage.loadInstancias()

    console.log(response)

    if (response.status === 200) {
      const data = []
      response?.data?.data?.data.map((item) => {
        data.push({
          ...item,
          label: item.name,
          value: item.id
        })
      })

      setInstanciasList(data)
    } else {
      toast.error(response?.msg)
      return false
    }
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    }

    if (rendered) {
      loadInstancias()
    }
  }, [rendered])

  const handlerInstancia = (value) => {
    console.log('----------------------------')
    console.log(value)
    if (value?.active) {
      setTableConfig((prev) => ({
        ...prev,
        instanceId: value?.value
      }))

      loadUsers(value?.value)
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Ops..',
        text: 'Essa instância está desativada por falta de pagamento',
        cancelButtonText: 'Fechar',
        allowEscapeKey: false,
        allowOutsideClick: false
      })
    }
  }

  async function exportarParaXlsx() {
    setLoading(true)

    const response = await listClientsAll({
      instanceId: tableConfig.instanceId
    })

    if (response.status === 200) {
      const tabela = response?.data?.data

      console.log('==================')
      console.log(tabela)
      console.log('==================')

      const workbook = new Excel.Workbook()
      const worksheet = workbook.addWorksheet('Planilha')
      worksheet.columns = [
        { header: 'Id', key: 'id' },
        { header: 'Nome', key: 'nome' },
        { header: 'Telefone', key: 'telefone' },
        { header: 'Email', key: 'email' },
        { header: 'CPF', key: 'cpf' },
        { header: 'Sexo', key: 'genero' },
        { header: 'Extra', key: 'camposExtra' },
        { header: 'Tag', key: 'tags' },
        { header: 'Data', key: 'created_at' }
      ]

      const linhas = tabela.filter(
        (item) =>
          item?.telefone &&
          item.telefone.trim() !== '' &&
          !item.telefone.includes('@') &&
          !item.telefone.includes('-')
      )
      worksheet.addRows(
        linhas.map((item) => ({
          id: item?.id ?? '',
          nome: item?.name ?? '',
          telefone: item?.telefone ?? '',
          email: item?.email ?? '',
          cpf: item?.cpf ?? '',
          genero: item?.genero ?? '',
          camposExtra: item?.camposExtra ?? '',
          tags: item?.tags ?? ''
        }))
      )

      const planilhaExcel = await workbook.xlsx.writeBuffer()

      const blob = new Blob([planilhaExcel], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })

      const url = URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = url
      a.download = 'PlanilhaMeusContatos-ZAp+.xlsx'
      a.click()

      URL.revokeObjectURL(url)
      setLoading(false)

      return planilhaExcel
    } else {
      toast.error(response)
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loading open={loading} handleClose={handleClose} />}

      <div className="page-content">
        <MetaTags>
          <title>Leads | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Leads | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo a gestão de Leads
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row style={{ marginTop: 20 }}>
            <Col xl={6} md={6} xs={12}>
              <SelectComponent
                options={instanciasList}
                value={
                  instanciasList.find((a) => a.value === tableConfig.instanceId)
                    ? instanciasList.find(
                        (a) => a.value === tableConfig.instanceId
                      )
                    : null
                }
                onChange={(value) => handlerInstancia(value)}
                placeholder="Instância"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col xl={8} md={12} xs={12}>
              <Row
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <Col xs={12} md={6}>
                  <SelectComponent
                    options={[
                      {
                        label: 'Busca por Telefone / Nome / Email ',
                        value: 'all'
                      },
                      { label: 'CPF', value: 'cpf' }
                      // { label: 'Extra', value: 'extra' }
                    ]}
                    onChange={(value) => setSearchType(value.value)}
                    placeholder="Tipo de Busca"
                  />
                </Col>

                {searchType === 'extra' && (
                  <Col xs={12} md={4}>
                    <SelectComponent
                      options={availableFields.map((field) => ({
                        label: field.key_field,
                        value: field.key_field
                      }))}
                      onChange={(value) => setSearchField(value.value)}
                      placeholder="Escolha o campo extra"
                    />
                  </Col>
                )}

                <Col xs={12} md={6}>
                  <Input
                    type="text"
                    placeholder="Valor da busca"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Col>

                <Col xs={12} md={2}>
                  <Button color="dark" onClick={handleSearch}>
                    Buscar
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <FrontTable
            title={'Meus Leads'}
            columns={columns}
            rows={rows}
            tableConfig={tableConfig}
            exportXlsx={exportarParaXlsx}
            setTableConfig={setTableConfig}
            loadUsers={loadUsers}
            availableFields={availableFields}
            showActions={true}
            showAddButton={true}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

Leads.propTypes = {
  t: PropTypes.any
}

export default Leads
