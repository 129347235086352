export const styles = (color: string, value2: number) => ({
  col: {
    paddingLeft: 4,
    paddingRight: 4
  },
  card: {
    backgroundColor:
      color === 'secondary'
        ? '#336963d9'
        : color === 'warning'
        ? '#df0b17ba'
        : color === 'info'
        ? '#ffecb5'
        : color === 'success'
        ? '#009688c2'
        : '#0D071B',
    padding: 15,
    marginBottom: 10
  },
  cima: { width: '100%', display: 'flex', alignItems: 'center' },
  logo: {
    fontSize: color === 'secondary' ? 25 : 30,
    color: 'white'
  },
  baixo: {
    width: '100%',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  indicador: {
    marginBottom: 0,
    display: 'flex',
    marginRight: 5,
    color: 'white',
    fontWeight: 600,
    fontSize: color === 'secondary' ? 18 : 22,
    textAlign: 'end' as const,
    justifyContent: 'flex-end'
  },
  value2: {
    marginBottom: 0,
    marginRight: 5,
    color: 'white',
    fontWeight: 300,
    fontSize: color === 'secondary' ? 11 : 13,
    textAlign: 'end' as const
  },
  label: {
    width: '80%',
    marginBottom: 0,
    color: 'white',
    fontWeight: 500,
    fontSize: color === 'secondary' ? 10 : 12
  },
  labelDiv: {
    width: '80%',
    display: 'flex',
    marginLeft: 5,
    justifyContent: value2 ? 'space-between' : 'flex-start'
  }
})
