import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import './styles.scss';
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Label
} from 'reactstrap';
import ServicesSegmentation from '../../services/segmentation'
import { Loading } from '../Loading';

export const ModalSegmentacao = ({ showModal, onClose, instanceId }) => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([{ field: '', operator: '', value: '' }]);
  const [logic, setLogic] = useState('AND');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handlerCancel = () => {
    onClose();
  };

  const handlerSubmit = async () => {
    
    const segmentationData = {
      logic,
      filters
    };

    try {
      setLoading(true);
      const result = await ServicesSegmentation.saveSegmentation({
        conditions: segmentationData,
        instance_id: instanceId,
        name,
        description
      });
      if (result.status === 200) {
        toast.success('Segmentação criada com sucesso');
        setFilters([{ field: '', operator: '', value: '' }])
        setName("")
        setDescription("")
        onClose(); // Fecha o modal
        setLoading(false);
      } else {
        toast.error('Erro ao criar segmentação');
        setLoading(false);
      }
    
    } catch (error) {
      toast.error('Erro ao criar segmentação');
      setLoading(false);
    }
  };

  const addFilter = () => {
    setFilters([...filters, { field: '', operator: '', value: '' }]);
  };

  const removeFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const handleFilterChange = (index, field, value) => {
    const newFilters = [...filters];
    newFilters[index][field] = value;
    setFilters(newFilters);
  };

  const renderValueField = (filter, index) => {
    // Se o campo selecionado for "campoExtra", renderize dois inputs: key e value
    if (filter.field === 'camposExtra') {
      return (
        <>
          <Col md={3}>
            <Input
              type="text"
              placeholder="Nome do Campo"
              value={filter.value?.key || ''}
              onChange={(e) => handleFilterChange(index, 'value', { ...filter.value, key: e.target.value })}
            />
          </Col>
          <Col md={3}>
            <Input
              type="text"
              placeholder="Valor"
              value={filter.value?.value || ''}
              onChange={(e) => handleFilterChange(index, 'value', { ...filter.value, value: e.target.value })}
            />
          </Col>
        </>
      );
    }
    // Caso contrário, renderize um único input para o valor
    return (
      <Col md={3}>
        <Input
          type="text"
          placeholder="Valor"
          value={filter.value}
          onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
        />
      </Col>
    );
  };

  const renderFilterForm = () => {
    return (
      <div>
         <FormGroup className="mb-3">
         <Label for="name">Nome</Label>
              <Input
                type="text"
                placeholder="Nome"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
          </FormGroup>
          <FormGroup className="mb-3">
         <Label for="description">Descrição</Label>
              <Input
                type="text"
                placeholder="Descrição"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
          </FormGroup>
        <FormGroup className="mb-3">
          <Label for="logic">Lógica</Label>
          <Input
            type="select"
            value={logic}
            onChange={(e) => setLogic(e.target.value)}
          >
            <option value="AND">AND</option>
            <option value="OR">OR</option>
          </Input>
        </FormGroup>

        {filters.map((filter, index) => (
          <Row key={index} className="mb-3">
            <Col md={3}>
              <Input
                type="select"
                value={filter.field}
                onChange={(e) => handleFilterChange(index, 'field', e.target.value)}
              >
                <option value="">Selecione o Campo</option>
                <option value="camposExtra">camposExtra</option>
                <option value="name">Nome</option>
                <option value="email">Email</option>
                <option value="telefone">Telefone</option>
                <option value="cpf">CPF</option>
                <option value="genero">Gênero</option>
                <option value="aniversario">Aniversário</option>
                <option value="origem_lead">Origem Lead</option>
                <option value="cidade">Cidade</option>
                <option value="estado">Estado</option>
                <option value="observacoes">Observações</option>
                <option value="status">Status</option>
                <option value="tags">Tags</option>
                <option value="created_at">Criado Em</option>
              </Input>
            </Col>
            <Col md={3}>
              <Input
                type="select"
                value={filter.operator}
                onChange={(e) => handleFilterChange(index, 'operator', e.target.value)}
              >
                <option value="=">Igual</option>
                <option value="!=">Diferente</option>
                <option value=">">Maior Que</option>
                <option value="<">Menor Que</option>
                <option value=">=">Maior Ou Igual</option>
                <option value="<=">Menor Ou Igual</option>
                <option value="contains">Inclui</option>
                <option value="not contains">Não Inclui</option>
              </Input>
            </Col>

            {/* Renderize o campo de valor com base no campo selecionado */}
            {renderValueField(filter, index)}

            <Col md={3}>
              <Button color="danger" onClick={() => removeFilter(index)}>Remover</Button>
            </Col>
          </Row>
        ))}

        <Button color="success" onClick={addFilter}>Adicionar Filtro</Button>
      </div>
    );
  };

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container style={{ minHeight: 250 }}>
        <Loading open={loading} />
        <Row className="modalText">
          <Col xs={12}>
            <h1 className="titleRoot">Criar uma Segmentação</h1>
          </Col>
        </Row>
        <Row>
          {/* Chame a função renderFilterForm */}
          {renderFilterForm()}
        </Row>
        <Row className="footerModalButtons" style={{ marginTop: 40 }}>
          <Col xs={6}>
            <Button
              className="buttonsModal"
              onClick={handlerCancel}
              variant="contained"
              color="secondary"
            >
              Cancelar
            </Button>
          </Col>
          <Col xs={6}>
            <Button
              className="buttonsModal"
              onClick={handlerSubmit}
              variant="contained"
              color="primary"
            >
              Confirmar
            </Button>
          </Col>
        </Row>
      </Container>
    </ReactModal>
  );
};
