import axios from 'axios'
export default {
  getCep: async (value: string) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${value}/json`, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (response.ok) {
        const jsonResponse = await response.json()
        return {
          data: jsonResponse
        }
      } else {
        return {
          error: true
        }
      }
    } catch (error) {
      return {
        error: true
      }
    }
  }
}
