import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames'
import DatatableTables from '../index.js'
import {
  Col,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap'
import { Tr, Td } from 'react-super-responsive-table'

import './styles.scss'
import { Instance } from 'types/global.js'
import instanceService from 'services/instance.js'
import { ModalAutomationCampaign } from 'components/modalAutomationCampaing'

export function UserInstanceCollapse({ open, row, callback }) {
  const [instanceCampaigns, setInstanceCampaigns] = useState([])
  const [loading, setLoading] = useState(false)
  const [showModalAutomationCampaign, setShowModalAutomationCampaign] =
    useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const [toggle, setToggle] = useState('1')

  const handleChange = useCallback(
    (newValue = '1') => {
      setToggle(newValue)
    },
    [row?.id]
  )
  const columns = [
    { label: 'Id', field: 'id' },
    { label: 'Nome', field: 'name' },
    { label: 'Tipo', field: 'type' },
    { label: 'Ativa', field: 'activeText' }
  ]

  const [tableConfig, setTableConfig] = useState({
    options: {
      edit: true,
      pagination: false,
      more: true
    },
    config: {
      editText: 'Editar Campanha'
    },
    total: 0,
    currentPage: 1,
    rowsPerPage: 10
  })

  const handleEdit = (row: Instance) => {
    console.log({ row })
    setCurrentRow(row)
    setShowModalAutomationCampaign(true)
  }

  const onCloseModalAddAutomationCampaign = () => {
    setCurrentRow(null)
    setShowModalAutomationCampaign(false)
  }

  async function loadInstanceCampaign() {
    try {
      setLoading(true)
      const response = await instanceService.getAutomationCampaign(row.id)
      console.log(response.data)

      setLoading(false)
      if (response?.status === 200) {
        const data = response.data.map((item) => ({
          ...item,
          activeText: item.active ? 'Sim' : 'Não'
        }))
        setInstanceCampaigns(data)
      }
    } catch (error) {
      setLoading(false)
      console.log({ error })
    }
  }

  useEffect(() => {
    if (row?.id && open) {
      loadInstanceCampaign()
    }
  }, [row, open])

  return (
    <Tr className="collapse-user-instance">
      <Td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
        <Collapse isOpen={open} timeout="auto" unmountOnExit>
          <Nav tabs style={{ justifyContent: 'flex-start', marginTop: 15 }}>
            <NavItem>
              <NavLink
                className={classnames({ active: toggle === '1' })}
                onClick={() => {
                  handleChange('1')
                }}
              >
                Campanhas de automação
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={toggle}>
            <TabPane tabId="1">
              <Row>
                <Col xs={12} style={{ padding: 10 }}>
                  {instanceCampaigns.length > 0 ? (
                    <>
                      <DatatableTables
                        rows={instanceCampaigns}
                        columns={columns}
                        tableConfig={tableConfig}
                        layoutCollapse={true}
                        onEdit={handleEdit}
                      />

                      <ModalAutomationCampaign
                        onClose={onCloseModalAddAutomationCampaign}
                        loadUsers={callback}
                        showModal={showModalAutomationCampaign}
                        instanceId={row?.id}
                        campaign={currentRow}
                      />
                    </>
                  ) : (
                    <div className="warning">
                      {loading
                        ? 'Carregando...'
                        : 'Nenhuma campanha de automação para essa instância'}
                    </div>
                  )}
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Collapse>
      </Td>
    </Tr>
  )
}
