import React from 'react'

import { Col, Card } from 'reactstrap'
import { formatCurrency } from '../../util/string'
import { styles } from './styles'

export interface CardIndicatorProps {
  value: number
  text: string
  color: string
  xl: number
  md: number
  xs: number
  type: string
  value2?: number | null
  icon: string
  style?: string
  icon2?: string | null
  text2?: string | null
}

export const CardIndicador = ({
  value,
  text,
  color,
  xl,
  md,
  xs,
  type,
  value2 = null,
  icon = 'mdi mdi-cart-outline',
  style = '1-info',
  icon2 = null,
  text2 = null
}: CardIndicatorProps) => {
  const customStyles = styles(color, value2)
  function getNumberFormated({ type, value }) {
    let formattedValue = null
    switch (type) {
      case 'money':
        formattedValue = formatCurrency({ value })
        break
      case 'number-formatted':
        formattedValue = formatCurrency({ value, decimal: 0, style: 'decimal' })
        break
      default:
        formattedValue = value
        break
    }
    return formattedValue
  }

  return (
    <Col xl={xl} md={md} xs={xs} style={customStyles.col}>
      <Card style={customStyles.card}>
        <div style={customStyles.cima}>
          <i style={customStyles.logo} className={icon} role='img'></i>
          <div style={customStyles.labelDiv}>
            <h5 style={customStyles.label}>{text.toUpperCase()}</h5>
            {style === '1-info' && value2 && (
              <h5 style={customStyles.value2}>{value2} </h5>
            )}
            {style === '2-info' && <h5 style={customStyles.value2}>{value}</h5>}
          </div>
        </div>
        {style === '2-info' ? (
          <div style={customStyles.cima}>
            <i style={customStyles.logo} className={icon2} role='img'></i>
            <div style={customStyles.labelDiv}>
              <h5 style={customStyles.label}>{text2.toUpperCase()}</h5>
              <h5 style={customStyles.value2}>{value2} </h5>
            </div>
          </div>
        ) : (
          <div style={customStyles.baixo}>
            <h4 style={customStyles.indicador}>
              {getNumberFormated({ type, value })}
            </h4>
          </div>
        )}
      </Card>
    </Col>
  )
}
