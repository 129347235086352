import React, { useState, useEffect } from 'react'
import { Form, Pagination } from 'react-bootstrap'
import './styles.scss'

export const PaginationComponent = ({
  pageNumber,
  tableConfig,
  setTableConfig
}) => {
  const [page, setPage] = useState([])

  useEffect(() => {
    let aux = []
    for (let index = 0; index < pageNumber; index++) {
      aux.push(index + 1)
    }

    setPage(aux)
  }, [pageNumber, tableConfig.currentPage])

  const goToPage = (page) => {
    setTableConfig((prev) => ({
      ...prev,
      currentPage: page
    }))
  }

  const handlePageSizeChange = (event) => {
    const newPageSize = parseInt(event.target.value)
    setTableConfig((prev) => ({
      ...prev,
      currentPage: 0,
      rowsPerPage: newPageSize
    }))
  }

  const renderPaginationItems = () => {
    const currentPage = tableConfig.currentPage + 1
    const totalPages = page.length
    const visiblePages = 5

    let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2))
    let endPage = startPage + visiblePages - 1

    if (endPage > totalPages) {
      endPage = totalPages
      startPage = Math.max(1, endPage - visiblePages + 1)
    }

    const paginationItems = []

    if (startPage > 1) {
      paginationItems.push(
        <Pagination.Ellipsis key="start-ellipsis" disabled />
      )
    }

    for (let page = startPage; page <= endPage; page++) {
      paginationItems.push(
        <Pagination.Item
          key={page}
          active={currentPage === page}
          onClick={() => goToPage(page - 1)}
        >
          {page}
        </Pagination.Item>
      )
    }

    if (endPage < totalPages) {
      paginationItems.push(<Pagination.Ellipsis key="end-ellipsis" disabled />)
    }

    return paginationItems
  }

  return (
    <div className="container-pagination">
      <Form.Select
        value={tableConfig.rowsPerPage}
        onChange={handlePageSizeChange}
      >
        <option value="20">20 por página</option>
        <option value="30">30 por página</option>
        <option value="50">50 por página</option>
        <option value="100">100 por página</option>
      </Form.Select>
      <Pagination>
        <Pagination.First
          onClick={() => goToPage(0)}
          disabled={tableConfig.currentPage === 0}
        />
        <Pagination.Prev
          onClick={() => goToPage(tableConfig.currentPage - 1)}
          disabled={tableConfig.currentPage === 0}
        />

        {renderPaginationItems()}

        <Pagination.Next
          onClick={() => goToPage(tableConfig.currentPage + 1)}
          disabled={tableConfig.currentPage === pageNumber - 1}
        />
        <Pagination.Last
          onClick={() => goToPage(pageNumber - 1)}
          disabled={tableConfig.currentPage === pageNumber - 1}
        />
      </Pagination>
    </div>
  )
}
