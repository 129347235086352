/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback } from 'react'
import MetaTags from 'react-meta-tags'
import { Container, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import Select from 'react-select'
import ReactDatePicker, { registerLocale } from 'react-datepicker'

import { Loading } from '../../components/Loading'
import DatatableTables from '../../components/Table'
///MODAIS

import Services from '../../services/auth'
import { phoneMask, formatDateT } from '../../util/string'
import './styles.scss'

import 'chartist/dist/scss/chartist.scss'
import ptBR from 'date-fns/locale/pt-BR'
import { ModalConfirm } from 'components/modalConfirm'
import instanceService from 'services/instance'
import { Button, Card, Form, FormGroup } from 'react-bootstrap'
import instance from 'services/instance'
import { format } from 'date-fns'
import reports from 'services/reports'
import { exportFile } from 'util/function'

registerLocale('pt-BR', ptBR)

const columns = [
  { label: 'Cod', field: 'id' },
  { label: 'Name', field: 'name' },
  { label: 'Status', field: 'status' },
  // { label: "Pagamento", field: "payment", html: true },
  { label: 'API', field: 'use_local', html: true }
]

export const RelatoriosMaisModas = () => {
  const { getInstancesMaisModas } = instance
  const { getClientesReport } = reports
  const [instanceList, setInstanceList] = useState([])
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [showModalConfirmLogout, setShowModalConfirmLogout] = useState(false)
  const [currentRow, setCurrentRow] = useState(null)
  const [instanceId, setInstanceId] = useState(null)
  const [rendered, setRendered] = useState(false)
  const { deleteQueue, logoutInstance } = instanceService

  const handleClose = () => {
    setLoading(false)
  }
  const [tableConfig, setTableConfig] = useState({
    options: {
      collapse: false,
      linkInstance: true,
      order: true,
      more: true,
      pagination: true,
      delete: true,
      logout: true
    },
    config: {
      deleteText: 'Apagar fila',
      logoutText: 'Desconectar do whatsapp'
    },
    filters: {
      searchTerm: null,
      statusTransaction: 'paid',
      paidSale: false,
      startSaleDate: null,
      endSaleDate: null,
      eventId: null,
      sellerId: null
    },
    total: 0,
    currentPage: 0,
    rowsPerPage: 20
  })

  const loadInstances = useCallback(async () => {
    setLoading(true)

    const response = await getInstancesMaisModas()

    if (response.status === 200) {
      const responseData = response?.data
      console.log({ responseData })

      setInstanceList(responseData)
      setLoading(false)
    } else {
      toast.error(response)
      setLoading(false)
    }
  }, [tableConfig.currentPage, tableConfig.rowsPerPage, tableConfig.filters])

  async function generateReport() {
    try {
      if (!instanceId) {
        return toast.error('Escolha uma loja')
      }
      if (!startDate || !endDate) {
        return toast.error('Escolha a data inicial e a data final')
      }
      const formattedStartDate = format(startDate, 'yyyy-MM-dd')
      const formattedEndDate = format(endDate, 'yyyy-MM-dd')
      setLoading(true)
      const response = await getClientesReport({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        instanceId
      })

      if (response.status === 200) {
        exportFile({
          base64: response.data.report,
          fileName: `Clientes-${
            instanceList.find((a) => a.value === instanceId).label
          }`,
          extension: 'xlsx'
        })
        toast.success(
          response?.data?.msg || 'O arquivo foi exportado com sucesso!'
        )
      } else {
        toast.error(
          response?.response?.data?.msg || 'Não foi possível gerar o relatório'
        )
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log({ error })
    }
  }

  useEffect(() => {
    if (!rendered) {
      setRendered(true)
    } else {
      loadInstances()
    }
  }, [rendered])

  return (
    <React.Fragment>
      {loading && <Loading open={loading} />}

      <div className="page-content">
        <MetaTags>
          <title>Relatórios Mais modas | Zap+</title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <br></br>
                <h6 className="page-title">Relatórios Mais modas | Zap+</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Bem vindo aos relatórios Mais Modas
                  </li>
                </ol>
              </Col>
            </Row>
          </div>
          <Row>
            <Card style={{ padding: 20, paddingBottom: 150 }}>
              <Card.Body>
                <Row style={{ marginTop: 30 }}>
                  <Col xl={4} md={4}>
                    <FormGroup>
                      <Form.Label for="exampleEmail">
                        Clientes de qual loja?
                      </Form.Label>
                      <Select
                        value={
                          instanceList.find((a) => a.value === instanceId)
                            ? instanceList.find((a) => a.value === instanceId)
                            : null
                        }
                        onChange={(value) => setInstanceId(value.value)}
                        options={instanceList}
                        classNamePrefix="select2-selection"
                        placeholder="Selecione..."
                      />
                    </FormGroup>
                  </Col>
                  <Col xl={4} md={4}>
                    <Form.Group>
                      <Form.Label for="exampleEmail">Data inicial</Form.Label>
                      <ReactDatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={(date) => {
                          console.log({ date })
                          setStartDate(date)
                        }}
                        dateFormat="dd/MM/yyyy"
                        locale="pt-BR"
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={4} md={4}>
                    <Form.Group>
                      <Form.Label for="exampleEmail">Data Final</Form.Label>
                      <ReactDatePicker
                        className="form-control"
                        selected={endDate}
                        onChange={(date) => {
                          console.log({ date })
                          setEndDate(date)
                        }}
                        dateFormat="dd/MM/yyyy"
                        locale="pt-BR"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ marginTop: 30 }} className="justify-content-end">
                  <Col xs={12} sm={4} className="d-flex justify-content-end">
                    <Button variant="success" onClick={generateReport}>
                      Gerar relatório
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
