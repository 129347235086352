import React, { useEffect, useState, useCallback } from 'react'
import { Container, Row, Col } from "reactstrap"
import Service from '../../services/auth'

const Footer = () => {
  
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <div className="col-12">
              © {new Date().getFullYear()} Zap+
            </div>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
