import React from 'react'
import { Collapse } from 'reactstrap'
import { Tr, Td } from 'react-super-responsive-table'
import ReactJson from 'react-json-view'

import './styles.scss'

export function LogsCollapse({ open, row }) {
  const data = JSON.parse(row.data)
  const alternativeBody = JSON.parse(row.body)
  console.log(data);

  return (
    <Tr>
      <Td style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
        <Collapse
          isOpen={open}
          timeout="auto"
          unmountOnExit
          style={{ paddingTop: 20, padding: 30 }}
        >
          <div>
            <b>Nome:</b> {data.name}
          </div>
          <br />
          <div>
            <b>Erro:</b>
            {typeof data.message === 'object' ? (
              <ReactJson theme="monokai" src={data.message} />
            ) : (
              data.message
            )}
          </div>
          <br />
          <div>
            <b>Detalhes:</b>{' '}
            {typeof data.stack === 'object' ? (
              <ReactJson theme="monokai" src={data.stack} />
            ) : (
              data.stack
            )}
          </div>
          <br />
          <div>
            <b>Body:</b> <ReactJson theme="monokai" src={data.body || alternativeBody} />
          </div>
        </Collapse>
      </Td>
    </Tr>
  )
}
