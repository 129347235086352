import React from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { Row as CustomRow } from './tableComponents/tableRow'

import { Table, Tbody } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import './datatable.css'
import { TableHeader } from './tableComponents/tableHeader'
import { Header } from './tableComponents/header'
import { PaginationComponent } from './tableComponents/pagination'

const ResponsiveTables = ({
  title = null,
  columns = [],
  rows = [],
  tableConfig = null,
  setTableConfig = null,
  CollapseComponent = null,
  callback = () => null,
  onCreateCheckout = () => null,
  onResendPurchaseTickets = () => null,
  onAddSeller = null,
  onCreateLink = () => null,
  layoutCollapse = false,
  onAddCourtesyTicket = () => null,
  onMarkAsUsed = () => null,
  onResendTickets = null,
  onDelete = null,
  onResgateCashback = () => null,
  onUploadContatos = () => null,
  onEdit = null,
  handleClickSellerLink = () => null,
  handleDownload = (row) => null,
  handlerTrash = () => null,
  currentEmail = null,
  addOrganizer = null,
  onUpdatePassword = null,
  onAdd = null,
  onLogout = null,
  onSwitch = null,
  emptyText = '',
  options = [],
  onView = () => null,
}) => {
  const pageNumber = Math.ceil(
    Number(tableConfig?.total) / Number(tableConfig?.rowsPerPage)
  )

  return (
    <React.Fragment>
      <div className={`page-content ${layoutCollapse ? 'tableCollapse' : ''}`}>
        <div className="container-fluid">
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Header addOrganizer={addOrganizer} title={title} />
                  {rows?.length > 0 ? (
                    <>
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            className="table table-striped table-bordered"
                          >
                            <TableHeader
                              columns={columns}
                              tableConfig={tableConfig}
                              rows={rows}
                            />

                            <Tbody>
                              {rows.map((row, index) => (
                                <CustomRow
                                  key={`${index}_${row.field}`}
                                  row={row}
                                  columns={columns}
                                  tableConfig={tableConfig}
                                  CollapseComponent={CollapseComponent}
                                  onAddSeller={onAddSeller}
                                  onCreateLink={onCreateLink}
                                  onAddCourtesyTicket={onAddCourtesyTicket}
                                  onCreateCheckout={onCreateCheckout}
                                  onMarkAsUsed={onMarkAsUsed}
                                  onResendPurchaseTickets={
                                    onResendPurchaseTickets
                                  }
                                  onResendTickets={onResendTickets}
                                  onDelete={onDelete}
                                  onResgateCashback={onResgateCashback}
                                  onUploadContatos={onUploadContatos}
                                  callback={callback}
                                  onEdit={onEdit}
                                  handleClickSellerLink={handleClickSellerLink}
                                  onDownload={handleDownload}
                                  onUpdatePassword={onUpdatePassword}
                                  onAdd={onAdd}
                                  onLogout={onLogout}
                                  onSwitch={onSwitch}
                                  handlerTrash={handlerTrash}
                                  onView={onView}
                                  currentEmail={currentEmail}
                                  options={options}
                                />
                              ))}
                            </Tbody>
                          </Table>
                          {!layoutCollapse &&
                            tableConfig?.options?.pagination && (
                              <PaginationComponent
                                pageNumber={pageNumber}
                                tableConfig={tableConfig}
                                setTableConfig={setTableConfig}
                              />
                            )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="table-empty-div">{emptyText}</div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ResponsiveTables
