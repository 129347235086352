import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { toast } from 'react-toastify'
import Services from '../../services/user'
import './styles.scss'

import { Loading } from '../Loading'
import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { CurrencyInput } from 'components/CurrencyInput'
import { Instance, SubscriptionProps, User } from 'types/global'

interface IModalInstanciaProps {
  showModal: boolean
  onClose: () => void
  callback: () => Promise<void>
  subscription: SubscriptionProps | null
  instance?: Instance | null
}

interface IErrorMessage {
  name: string | null
}

interface IInstanceModal extends Pick<Instance, 'name'> {
  errorMessage: IErrorMessage
}

const planoList = [
  { value: 'R$ 149,90', label: 'BASIC' },
  { value: 'R$ 199,90', label: 'STANDARD' },
  { value: 'R$ 299,90', label: 'PREMIUM' }
]

export const ModalInstancia = ({
  showModal,
  onClose,
  subscription,
  callback,
  instance = null
}: IModalInstanciaProps) => {
  const [loading, setLoading] = useState(false)
  const [tokenCopy, setTokenCopy] = useState('')
  const [dados, setDados] = useState<IInstanceModal>({
    name: null,
    errorMessage: {
      name: null
    }
  })

  const handleSubmitNewInstance = async () => {
    try {
      if (!dados.name) {
        toast.error('Preencha todos os campos')
        const newDados = { ...dados }

        if (!dados.name) {
          newDados.errorMessage.name = 'Preencha com um nome para a instância'
        }

        setDados(newDados)
        return false
      }

      setLoading(true)
      let response = null
      if (instance) {
        const payload = {
          ...dados,
          instanceId: instance.id
        }
        response = await Services.updateInstance(payload)
      } else {
        const payload = {
          ...dados,
          userId: subscription.userId,
          subscriptionId: subscription.id
        }
        console.log({ payload })

        response = await Services.saveInstance(payload)
      }
      if (response.status === 200) {
        toast.success(
          response.data?.msg ? response.data?.msg : 'Instância salva'
        )
        onClose()
        callback()
        setLoading(false)
      } else {
        toast.error(response?.msg || 'Ocorreu um erro ao tentar salvar')
        setLoading(false)
        return false
      }
    } catch (error) {
      console.log({ error })
      setLoading(false)
    }
  }

  const handleCancelNewInstance = () => {
    onClose()
  }

  const handlerDados = (key: string, value: string | number) => {
    setDados((prev) => ({
      ...prev,
      errorMessage: {
        ...prev.errorMessage,
        [key]: null
      },
      [key]: value
    }))
  }

  useEffect(() => {
    console.log({ subscription })

    if (instance && showModal) {
      setDados({
        ...dados,
        name: instance.name
      })
    }
  }, [instance, showModal])

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={`Minimal Modal`}
      className="reactModal"
      overlayClassName="customModalOverlay"
    >
      <Container className="containerModalAddInstance">
        <Loading open={loading} />
        <form>
          <Row className="modalText">
            <Col xs={12}>
              <h1 className={'titleRoot'}>
                {instance ? 'Atualizar instância' : 'Criar nova instância'}
              </h1>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col xs={12}>
              <FormGroup>
                <Form.Label>Nome*</Form.Label>
                <Form.Control
                  value={dados.name}
                  onChange={(event) => handlerDados('name', event.target.value)}
                  isInvalid={!!dados?.errorMessage?.name}
                />

                <Form.Control.Feedback type="invalid">
                  {dados?.errorMessage?.name}
                </Form.Control.Feedback>
              </FormGroup>
            </Col>
          </Row>

          <Row className="container-buttons">
            <Button onClick={handleCancelNewInstance} variant="secondary">
              Fechar
            </Button>
            <Button onClick={handleSubmitNewInstance} variant="primary">
              Salvar
            </Button>
          </Row>
        </form>
      </Container>
    </ReactModal>
  )
}
